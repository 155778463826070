import { Box, FormControl, HStack, InputProps, chakra } from "@chakra-ui/react";
import React, { useContext } from "react";
import { primaryWhite } from "../../../theme/theme";
import CustomInput from "../CustomInput";
import ErrorContainer from "../ErrorContainer";
import { FormContext } from "./FormContainer";

interface Props extends InputProps {
  name: string;
  action?: React.ReactNode;
  ref?: React.Ref<any>;
  withLabel?: boolean;
  onChange?: (event: any) => void;
  label?: string;
  [key: string]: any;
}

function FormInput({
  name,
  action,
  register: propRegister,
  placeholder,
  isRequired,
  onChange,
  defaultValue,
  ref,
  withLabel,
  label,
  ...rest
}: Props) {
  const formContext = useContext(FormContext);
  const { initialValues, register, errors } = formContext;
  if (register == null && !register) {
    throw new Error(
      "This component must be used in the context of a <FormContainer> component"
    );
  }
  const options = { required: isRequired, onChange: onChange };

  return (
    <ErrorContainer error={errors[name] ?? ""}>
      <FormControl>
        {withLabel && (
          <HStack
            alignItems={"flex-end"}
            justifyContent={"flex-start"}
            w={"100%"}
            pl={"1rem"}
          >
            <Box bg={primaryWhite} mb={"-10px"} zIndex={100} p={"0 .5rem"}>
              <chakra.label
                htmlFor={name}
                textTransform={"capitalize"}
                color={"gray.500"}
              >
                {label ? label.replaceAll("_", " ") : name.replaceAll("_", " ")}
              </chakra.label>
            </Box>
          </HStack>
        )}
        <HStack>
          <CustomInput
            ref={ref}
            {...rest}
            bg={"transparent"}
            defaultValue={`${
              initialValues && initialValues[name]
                ? initialValues[name]
                : initialValues && defaultValue
                ? defaultValue
                : ""
            }`}
            _focus={{
              outline: "none",
              boxShadow: "none",
            }}
            placeholder={`${placeholder}`}
            {...(register
              ? register(name, { ...options })
              : propRegister(name, { ...options }))}
            isRequired={isRequired}
          />
          {action}
        </HStack>
      </FormControl>
    </ErrorContainer>
  );
}

export default FormInput;
