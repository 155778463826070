import { RpcError } from "grpc-web";
import React from "react";
import { useDispatch } from "react-redux";
import { notify } from "../../../gateway/Functions";
import { gRPCDeleteDepartment } from "../../../gateway/gRPC/Utils/BranchMethods";
import strings from "../../../localization/en";
import { updateTables } from "../../../redux/actions/actions";
import { openModalAlert } from "../../../redux/ModalAlertObj";
import CustomDeleteButton from "../../buttons/CustomDeleteButton";

const DeleteDepartmentItem = ({ id }: { id: string }) => {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      openModalAlert(
        `${strings.delete} ${strings.department}`,
        `${strings.delete_description}`,
        "danger",
        true,
        true,
        onSubmit
      )
    );
  };

  const onSubmit = async () => {
    setLoading(true);
    gRPCDeleteDepartment(id)
      .then(() => {
        dispatch(updateTables());
        notify(
          "success",
          "bottomLeft",
          strings.success,
          `Department deleted successfully`
        );
      })
      .catch((error: RpcError) => {
        notify("error", "bottomLeft", strings.error, error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CustomDeleteButton
      tooltipLabel={`${strings.delete} ${strings.department}`}
      onClick={handleDelete}
      loading={loading}
    />
  );
};

export default DeleteDepartmentItem;
