import { ChatdeskFilterType } from "../../types/DataTypes";
import { CHATDESK_FILTERS, RESET_CHATDESK_FILTERS } from "../actions/actions";

interface SetChatdeskFiltersAction {
	type: typeof CHATDESK_FILTERS;
	payload: ChatdeskFilterType;
}

interface ResetChatdeskFiltersAction {
	type: typeof RESET_CHATDESK_FILTERS;
}

type ChatdeskFiltersAction =
	| SetChatdeskFiltersAction
	| ResetChatdeskFiltersAction;

const ChatdeskFilters = (
	state: ChatdeskFilterType[] = [{ label: "", value: [] }],
	action: ChatdeskFiltersAction
): ChatdeskFilterType[] => {
	switch (action.type) {
		case CHATDESK_FILTERS:
			const updatedState = state?.map((filter) =>
				filter?.label === action?.payload?.label
					? { ...filter, value: action?.payload?.value }
					: filter
			);

			const labelExists = state.some(
				(filter) => filter.label === action.payload.label
			);

			return labelExists ? updatedState : [...state, action.payload];
		case RESET_CHATDESK_FILTERS:
			return [];
		default:
			return state;
	}
};

export default ChatdeskFilters;
