import * as React from 'react';
import {Box, chakra, HStack, Img} from "@chakra-ui/react"
import {useSelector} from "react-redux";
import {CountryCodeData} from "../types/DataTypes";

interface BrandProps {
    size?: string;
    icon?: boolean;
    fill?: string;
    background?: string;
    withCountry?: boolean;
}

const Brand = ({icon, withCountry, size, fill, background: bg}: BrandProps) => {
    const userLocation: CountryCodeData = useSelector((state: any) => state.UserLocation);
    const brand = process.env.REACT_APP_CUSTOM_BRAND;
    return (
        brand === "MNOTIFY"?
        <HStack
            spacing={0}
            position={"relative"}
        >
            <Img src={require("../assets/images/mnotify.webp")} alt={"MNotify"}/>
        </HStack>:
        <HStack
            spacing={0}
            position={"relative"}
        >
            <chakra.svg margin={"10px auto 20px auto"} width="100%" height={size ? size : "2rem"}
                        viewBox={`0 0 ${icon ? "35" : "175"} 34`}
                        fill={bg ? bg : "none"} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.00459914 14.1781C0.00459914 16.7024 -0.213451 19.6839 2.35325 20.971C3.16959 21.3803 4.46031 21.8967 4.86664 20.6155C5.5154 18.5701 3.93461 14.1077 7.20763 8.64913C7.96924 7.37923 8.56611 6.7947 9.57981 5.78254C10.7554 4.6087 12.9682 3.50218 14.6554 3.07624C17.8306 2.27452 21.5539 2.94525 24.055 4.80589L24.9633 5.5107C25.4254 5.86452 26.2041 6.69807 26.5894 7.16682C28.0676 8.96492 29.2508 11.5532 29.6353 13.8409C30.1463 16.8807 30.0317 19.1743 28.8675 21.9598C28.1733 23.6207 26.7133 25.5289 25.4127 26.8501C24.8209 27.451 24.6165 27.3127 24.6165 28.4702C24.6165 28.7076 24.752 29.0648 24.8564 29.2077C25.5825 30.2021 27.173 28.8361 27.7511 28.2552C31.1415 24.8472 30.5931 22.5584 32.168 21.3933C32.3526 21.2567 32.5 21.1798 32.6818 21.0428C33.3958 20.5048 33.8123 19.8701 34.1338 19.0416C34.5629 17.9357 34.7942 15.6029 34.653 14.4817C34.4228 12.6541 34.3318 10.5323 32.798 9.37958C32.4853 9.14465 32.0316 8.97309 31.6454 8.80266C31.1276 8.5742 30.8498 8.46546 30.4576 8.08855C29.5948 7.25922 29.0566 6.15411 28.3009 5.23745C28.0055 4.87885 27.7401 4.65095 27.4571 4.29657L26.5568 3.46922C25.3911 2.56975 24.9743 2.14551 23.4043 1.37787C20.3417 -0.119652 16.9615 -0.387267 13.6516 0.529951C12.3906 0.87926 11.3443 1.38322 10.2912 2.01282L9.12471 2.80553C8.93275 2.94863 8.75042 3.07005 8.57008 3.23034L7.52974 4.14812C6.95668 4.72194 6.25688 5.57071 5.75727 6.23298L4.9018 7.33444C4.57912 7.68036 4.24538 7.95953 3.85124 8.24236C3.44973 8.53026 3.059 8.74998 2.66175 9.01196C0.619052 10.3593 0.00459914 11.3816 0.00459914 14.1784V14.1781Z"
                    fill={`${fill ?? "#333235"}`}/>
                <path
                    d="M21.2081 21.1234C21.7267 21.4684 21.4724 20.7408 21.266 20.4346C20.5582 20.4346 20.5769 20.6693 19.8088 20.4476C19.6333 20.3968 19.2686 20.2723 19.1209 20.2675C18.7991 20.2571 18.7242 20.4061 18.7242 20.7216C18.4875 20.7216 18.2022 20.6684 18.0426 20.5377L17.9612 20.4464C17.7857 20.2732 17.7153 20.3346 17.4511 20.2642C17.2809 20.2188 17.1874 20.163 17.0484 20.0901C17.1301 19.7867 17.93 19.0588 15.7047 19.5013C15.4365 19.5548 15.3055 19.6008 15.0508 19.6571C14.6054 19.7554 14.6326 19.643 14.3329 19.5737C13.1479 20.3619 13.5517 20.6856 13.1108 20.9425C12.8113 21.1172 12.8593 21.0028 12.5839 21.2803C12.3937 21.4721 11.9193 22.0009 11.8691 22.2927C11.8212 22.5716 11.9797 22.6006 11.845 23.0136C11.7713 23.2395 11.6536 23.4051 11.5915 23.6265C11.4319 24.1947 11.921 24.435 12.1382 24.739C13.2435 26.2869 13.3577 25.6861 14.4335 25.7894C14.898 25.8342 14.9348 25.6179 15.3888 25.5525C15.6894 25.5092 15.7197 25.5725 15.7773 25.6007C16.1022 25.7593 15.6417 25.5435 16.166 25.9604L16.5783 26.5312C16.838 26.8495 16.4975 26.6884 16.7357 27.1137C16.9415 27.4813 17.5821 27.9101 17.5299 28.9076C17.478 29.8975 16.8273 29.4834 17.5503 30.8989C17.8205 31.4283 17.8038 31.7331 17.9793 32.1948C18.0624 32.4131 18.3338 32.8137 18.4004 33.1001C18.4574 33.3455 18.2484 33.752 19.1047 33.6148C20.0892 33.4573 20.0172 33.5064 20.8474 32.7041C21.1265 32.4345 21.0383 32.55 21.1608 32.1545C21.2634 31.8229 21.3468 31.9153 21.592 31.7215C22.0985 31.3215 21.273 30.9167 21.959 30.5367C22.5745 30.1955 22.7574 30.2747 22.8692 29.6611C23.0696 28.5583 22.3982 28.3422 22.9054 27.5163C23.4972 26.5523 24.0995 26.4602 24.5994 25.6404C24.7678 25.3644 25.16 24.7446 24.9635 24.3376C24.5231 24.1277 23.9024 24.7677 23.5159 24.4576C23.2386 24.235 23.5298 24.4962 23.4243 24.1959C23.2854 23.8004 22.6213 24.0206 21.9592 22.3285C21.6788 21.6118 21.3156 21.5234 21.2081 21.1234V21.1234Z"
                    fill={`${fill ?? "#EF4823"}`}/>
                {!icon && <>
                    <path
                        d="M47.8997 23.9929H44.9533C44.7758 23.9929 44.7222 23.8247 44.7222 23.6484V9.70083C44.7222 9.43294 44.8569 9.29913 45.1266 9.29913H47.4954C50.3989 9.29913 52.1751 11.2136 52.1751 14.1204V19.2863C52.1751 21.9621 50.3544 23.9929 47.8997 23.9929ZM42.7578 8.09373V25.1409C42.7578 25.6645 43.2169 26.002 43.74 26.002H47.5532C50.133 26.002 52.6225 24.5422 53.5378 22.3622C53.8217 21.6858 54.1395 20.6652 54.1395 19.7457V13.6041C54.1395 12.3252 53.5387 10.5471 52.7087 9.63013C52.0237 8.8732 51.4914 8.40839 50.4809 7.94048C49.8568 7.65146 48.7855 7.3475 47.9 7.3475H43.5668C43.207 7.3475 42.7578 7.71146 42.7578 8.09373Z"
                        fill={`${fill ?? "#EF4823"}`}/>
                    <path
                        d="M71.7611 24.5671C70.427 24.5671 69.2128 24.0226 68.5394 23.3479C66.6456 21.4506 66.8234 17.8787 68.4229 16.2572C69.753 14.9084 72.1935 14.6033 73.9189 15.4208C76.6413 16.7107 76.9427 20.4134 75.6718 22.5405C75.219 23.2983 74.5671 23.8361 73.7431 24.1831C73.2415 24.3944 72.4487 24.5671 71.7611 24.5671ZM65.1748 19.6883C65.1748 22.4194 66.5154 24.8679 68.815 25.8866C72.8187 27.6602 75.5505 25.2558 75.9786 25.2558C76.7201 25.2558 76.1094 26.0595 77.5384 26.0595C77.8484 26.0595 78.3474 25.7463 78.3474 25.1987V14.2357C78.3474 13.4723 77.0215 13.1235 76.5531 13.7731C75.9628 14.5923 76.1675 14.3647 75.0744 13.814C70.4763 11.497 65.1748 14.2498 65.1748 19.6883Z"
                        fill={`${fill ?? "#EF4823"}`}/>
                    <path
                        d="M82.7381 8.09367V19.5733C82.7381 21.8967 83.515 23.9258 85.2546 25.2236C86.1849 25.9177 87.1629 26.2952 88.3734 26.4873C89.2254 26.6225 89.9346 26.6482 90.1665 25.9186C90.363 25.3 90.1098 24.5667 89.1523 24.5656C87.1651 24.563 85.3788 23.2793 84.9095 21.2615C84.6203 20.0181 84.7598 17.854 84.7598 16.3591V8.78243C84.7598 7.70239 84.8545 7.11786 83.6043 7.11786C83.0409 7.11786 82.7381 7.52154 82.7381 8.09367Z"
                        fill={`${fill ?? "#EF4823"}`}/>
                    <path
                        d="M58.126 14.4649V20.3195C58.126 22.0883 58.7994 23.6568 59.6098 24.6424C60.1474 25.2963 61.1387 26.6084 62.1306 26.2501C62.819 26.0014 63.0751 25.1005 62.47 24.6134C61.8963 24.1512 61.657 24.1554 61.0145 23.1897C60.328 22.1581 60.148 21.5158 60.1483 20.2048L60.1511 14.4623C60.1432 13.0631 58.126 13.0699 58.126 14.4649Z"
                        fill={`${fill ?? "#EF4823"}`}/>
                    <path
                        d="M58.126 10.2748C58.126 11.6049 60.0904 11.5275 60.0904 10.3897C60.0904 9.74628 59.7266 9.35669 59.1082 9.35669C58.5819 9.35669 58.126 9.73952 58.126 10.2748Z"
                        fill={`${fill ?? "#EF4823"}`}/>
                    <path
                        d="M104.403 15.7276H96.1417C95.8624 15.7276 95.6795 15.5462 95.6795 15.2684C95.6795 13.692 95.5281 12.3162 96.4295 10.9626C96.5035 10.8516 96.5565 10.7727 96.6478 10.6629C96.9558 10.2922 97.0666 10.1637 97.4809 9.88314C99.0881 8.79493 101.591 8.77267 103.068 9.87976C104.965 11.3029 104.866 13.1066 104.866 15.2107C104.866 15.4881 104.702 15.7276 104.403 15.7276ZM93.6572 13.9484V25.1409C93.6572 26.3111 95.2837 26.2936 95.5936 25.5725C95.783 25.1319 95.6733 19.4801 95.6761 18.4223C95.6772 18.0046 95.7949 17.7367 96.1993 17.7367H104.346C105.052 17.7367 104.865 18.7415 104.865 19.6308C104.865 20.4072 104.773 25.1894 104.943 25.5812C105.201 26.1778 106.888 26.5176 106.888 24.9116V14.6946C106.888 11.3632 105.992 8.98817 103.229 7.71094C99.1323 5.81707 93.6572 8.07856 93.6572 13.9484Z"
                        fill={`${fill ?? "#333235"}`}/>
                    <path
                        d="M165.414 24.5667C162.858 24.5667 160.85 22.7253 160.85 20.1471C160.85 18.6181 160.973 17.3755 162.076 16.2568C163.403 14.9114 165.779 14.6238 167.493 15.3839C170.92 16.9033 170.944 22.6884 167.396 24.1825C166.895 24.3938 166.102 24.5667 165.414 24.5667ZM158.828 19.6305C158.828 21.5072 159.338 23.2732 160.604 24.5236C161.196 25.1084 161.655 25.5256 162.468 25.8862C166.47 27.659 169.204 25.2555 169.632 25.2555C170.366 25.2555 169.747 26.0592 171.191 26.0592C171.496 26.0592 172 25.731 172 25.1983V14.2354C172 13.7863 171.499 13.4317 170.96 13.4317C170.065 13.4317 170.127 14.2928 169.632 14.2928C169.483 14.2928 168.917 13.9089 168.727 13.8137C166.735 12.8094 164.79 12.6984 162.69 13.5376C160.382 14.4596 158.828 16.8129 158.828 19.6305Z"
                        fill={`${fill ?? "#333235"}`}/>
                    <path
                        d="M142.767 7.97908V25.1986C142.767 25.4671 142.861 25.6812 143.012 25.8167C143.412 26.1767 144.531 26.2567 144.748 25.3873C144.922 24.6882 144.246 21.4049 146.756 19.9204C149.584 18.2476 154.002 19.5068 153.976 23.9352C153.969 25.2118 153.706 26.0598 155.073 26.0598C156.201 26.0598 155.94 24.838 155.94 23.5918C155.94 22.7056 155.709 21.8112 155.445 21.1559C155.026 20.1142 154.443 19.3488 153.58 18.6471C153.269 18.3947 153.031 18.2705 152.672 18.0569C151.684 17.4707 150.798 17.5752 150.798 16.991C150.798 16.6769 151.531 15.7571 151.757 15.4185C151.887 15.2241 151.97 15.0923 152.103 14.901C152.328 14.5793 152.697 14.203 152.607 13.7607C152.489 13.1796 151.903 12.9728 151.376 12.9728C150.959 12.9728 150.79 13.3353 150.614 13.5934L149.133 15.6813C149.019 15.849 148.944 15.9619 148.825 16.1211C148.719 16.2622 148.642 16.3949 148.545 16.5312C147.55 17.9234 147.997 17.0986 146.329 17.8888C145.896 18.0938 145.673 18.3679 145.252 18.3679C144.692 18.3679 144.789 17.8223 144.79 17.2775C144.791 16.0298 144.846 8.12866 144.749 7.73259C144.51 6.76185 142.767 6.83565 142.767 7.97908Z"
                        fill={`${fill ?? "#333235"}`}/>
                    <path
                        d="M109.719 14.2932C109.719 15.4257 111.137 15.0217 111.535 15.2445C112.129 15.5767 111.915 16.744 111.915 17.6224C111.915 20.0157 111.867 22.7536 111.912 25.0869C111.932 26.1244 113.197 26.3796 113.718 25.6697C114 25.2858 113.879 23.2195 113.879 22.6733L113.879 16.3012C113.873 15.4555 114.057 15.1459 114.86 15.1524C115.425 15.1572 117.482 15.2321 117.926 15.0414C118.626 14.7409 118.525 13.6186 117.729 13.4532C117.056 13.3132 114.766 13.616 114.355 13.3042C113.589 12.7228 114.445 11.35 114.792 10.8368C115.449 9.86377 116.721 9.12853 117.982 9.06965C118.536 9.04374 119.484 9.10459 119.484 8.03694C119.484 7.40565 118.846 7.06113 118.56 7.06113C115.659 7.06113 113.327 8.72457 112.435 10.9635C111.809 12.5326 112.28 13.4552 110.876 13.4324C110.25 13.4222 109.719 13.6408 109.719 14.2932Z"
                        fill={`${fill ?? "#333235"}`}/>
                    <path
                        d="M122.372 19.5159V25.3133C122.372 25.9004 123.131 26.2232 123.733 26.0348C124.468 25.8046 124.336 25.2263 124.336 24.3375C124.336 23.2217 124.254 19.7357 124.425 18.801C124.494 18.4275 124.552 18.2125 124.661 17.8869C124.774 17.5485 124.851 17.368 125.018 17.0936L125.491 16.4158C126.119 15.6079 127.483 14.9814 128.612 14.9814C130.451 14.9814 130.66 15.3831 131.385 15.3831C131.929 15.3831 132.309 14.9851 132.309 14.4073C132.309 13.6616 131.65 13.4898 131.061 13.2946C127.875 12.2385 124.387 13.6278 122.969 16.7803C122.65 17.4908 122.372 18.5165 122.372 19.5159Z"
                        fill={`${fill ?? "#333235"}`}/>
                    <path
                        d="M135.198 14.4649V20.3195C135.198 22.0854 135.875 23.6565 136.682 24.6424C136.913 24.9244 137.094 25.1608 137.35 25.4137C137.592 25.6532 137.866 25.9124 138.167 26.0941C139.124 26.6698 139.878 25.9453 139.878 25.3706C139.878 24.3737 139.131 24.7588 138.086 23.1897C137.4 22.1581 137.22 21.5158 137.22 20.2048L137.223 14.4623C137.215 13.0631 135.198 13.0699 135.198 14.4649Z"
                        fill={`${fill ?? "#333235"}`}/>
                    <path
                        d="M135.198 10.2748C135.198 11.6049 137.162 11.5275 137.162 10.3897C137.162 9.74628 136.799 9.35669 136.18 9.35669C135.654 9.35669 135.198 9.73952 135.198 10.2748Z"
                        fill={`${fill ?? "#333235"}`}/>

                </>}</chakra.svg>
            {
                withCountry &&
                <Box
                    position={"absolute"}
                    top={0}
                    right={"10px"}
                    fontSize={"1.0rem"}
                >{userLocation.flag_icon}</Box>
            }
        </HStack>
    )
}
export default Brand