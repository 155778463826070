import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Surveys from "../components/surveys/Surveys";
import ViewQuestionnaire from "../components/surveys/ViewQuestionnaire";
import CreateQuestionnaire from "../components/surveys/questionnaire/CreateQuestionnaire";
import Apps from "../pages/Subscriptions";
import { RouteDataType } from "../types/DataTypes";
import SurveyResponse from "../components/surveys/responses/SurveyResponse";

const Phone = lazy(() => import("../components/phone/phone"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const Login = lazy(() => import("../pages/Login"));
const SignUp = lazy(() => import("../pages/SignUp"));
const WebChat = lazy(() => import("../pages/WebChat"));

const authRoutes: RouteDataType[] = [
  {
    path: "/login",
    component: <Login />,
  },
  {
    path: "/register",
    component: <SignUp />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    path: "/da-dialer",
    component: <Phone />,
  },
  {
    path: "/webchat",
    component: <WebChat />,
  },
  {
    path: "/surveys",
    component: <Surveys />,
  },
  {
    path: "/surveys/:surveyId",
    component: <ViewQuestionnaire />,
  },
  {
    path: "/surveys/add_survey",
    component: <CreateQuestionnaire />,
  },
  {
    path: "/surveys/:surveyId/new_response",
    component: <SurveyResponse />,
  },
];

export default authRoutes;
