import ReactApexChart from "react-apexcharts";
import { LineGraphProps } from "../../../types/DataTypes";
import { subtleColors } from "../../../assets/data";
import { Box, Text } from "@chakra-ui/react";

interface BarGraphProps extends LineGraphProps {
  labels: string[];
  series: number[];
  nps: string;
}

const SimplePieChart = ({ labels, series,nps }: BarGraphProps) => {
  const options = {
    options: {
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "10px",
              fontWeight: "normal",
            },
            value: {
              fontSize: "34px",
              fontWeight: "bold",
            },
            total: {
              show: true,
              label: "NPS",
              formatter: function (w:any) {
                return `${nps}%`
              },
            },
          },
        },
      },
      colors: [...labels.map((_,index:number) => subtleColors[index])],
      labels: labels.map((label: string) => label.toUpperCase()),
    },
  };

  return (
    <ReactApexChart
      options={options.options}
      series={series}
      type={"radialBar"}
      height={350}
      width={350}
    />
  );
};

export default SimplePieChart;
