/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: label.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.proto = require('./label_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.LabelServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.LabelServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateLabelRequest,
 *   !proto.da.proto.CreateLabelResponse>}
 */
const methodDescriptor_LabelService_CreateLabel = new grpc.web.MethodDescriptor(
  '/da.proto.LabelService/CreateLabel',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateLabelRequest,
  proto.da.proto.CreateLabelResponse,
  /**
   * @param {!proto.da.proto.CreateLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.LabelServiceClient.prototype.createLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.LabelService/CreateLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_CreateLabel,
      callback);
};


/**
 * @param {!proto.da.proto.CreateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.LabelServicePromiseClient.prototype.createLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.LabelService/CreateLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_CreateLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadLabelRequest,
 *   !proto.da.proto.ReadLabelResponse>}
 */
const methodDescriptor_LabelService_ReadLabel = new grpc.web.MethodDescriptor(
  '/da.proto.LabelService/ReadLabel',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadLabelRequest,
  proto.da.proto.ReadLabelResponse,
  /**
   * @param {!proto.da.proto.ReadLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.LabelServiceClient.prototype.readLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.LabelService/ReadLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_ReadLabel,
      callback);
};


/**
 * @param {!proto.da.proto.ReadLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.LabelServicePromiseClient.prototype.readLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.LabelService/ReadLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_ReadLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadLabelsRequest,
 *   !proto.da.proto.ReadLabelsResponse>}
 */
const methodDescriptor_LabelService_ReadLabels = new grpc.web.MethodDescriptor(
  '/da.proto.LabelService/ReadLabels',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadLabelsRequest,
  proto.da.proto.ReadLabelsResponse,
  /**
   * @param {!proto.da.proto.ReadLabelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadLabelsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadLabelsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadLabelsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.LabelServiceClient.prototype.readLabels =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.LabelService/ReadLabels',
      request,
      metadata || {},
      methodDescriptor_LabelService_ReadLabels,
      callback);
};


/**
 * @param {!proto.da.proto.ReadLabelsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadLabelsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.LabelServicePromiseClient.prototype.readLabels =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.LabelService/ReadLabels',
      request,
      metadata || {},
      methodDescriptor_LabelService_ReadLabels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateLabelRequest,
 *   !proto.da.proto.UpdateLabelResponse>}
 */
const methodDescriptor_LabelService_UpdateLabel = new grpc.web.MethodDescriptor(
  '/da.proto.LabelService/UpdateLabel',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateLabelRequest,
  proto.da.proto.UpdateLabelResponse,
  /**
   * @param {!proto.da.proto.UpdateLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.LabelServiceClient.prototype.updateLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.LabelService/UpdateLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_UpdateLabel,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.LabelServicePromiseClient.prototype.updateLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.LabelService/UpdateLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_UpdateLabel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteLabelRequest,
 *   !proto.da.proto.DeleteLabelResponse>}
 */
const methodDescriptor_LabelService_DeleteLabel = new grpc.web.MethodDescriptor(
  '/da.proto.LabelService/DeleteLabel',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteLabelRequest,
  proto.da.proto.DeleteLabelResponse,
  /**
   * @param {!proto.da.proto.DeleteLabelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteLabelResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteLabelResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteLabelResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.LabelServiceClient.prototype.deleteLabel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.LabelService/DeleteLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_DeleteLabel,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteLabelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteLabelResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.LabelServicePromiseClient.prototype.deleteLabel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.LabelService/DeleteLabel',
      request,
      metadata || {},
      methodDescriptor_LabelService_DeleteLabel);
};


module.exports = proto.da.proto;

