import { ADD_PHONE_INSTANCE, REMOVE_PHONE_INSTANCE } from "../actions/actions";

export default function PhoneInstances(state: string[] = [], action: any) {
  switch (action.type) {
    case ADD_PHONE_INSTANCE:
      if (state.includes(action.payload)) return state;
      return [...state, action.payload];
    case REMOVE_PHONE_INSTANCE:
      return state.filter((instance) => instance !== action.payload);
    default:
      return state;
  }
}
