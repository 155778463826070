import {CityData, CountryCodeData} from "../types/DataTypes";

export const initialCountry: CountryCodeData = {
    country_code: "+254",
    name: "Kenya",
    flag_icon: "🇰🇪",
}
export const countries: CountryCodeData[] = [
    {
        "name": "extension",
        "country_code": "~Ext",
        "flag_icon": "👨‍💻"
    },
    {
        "name": "Kenya",
        "country_code": "+254",
        "flag_icon": "🇰🇪"
    },
    {
        "name": "Uganda",
        "country_code": "+256",
        "flag_icon": "🇺🇬"
    },
    {
        "name": "Rwanda",
        "country_code": "+250",
        "flag_icon": "🇷🇼"
    },
    {
        "name": "Nigeria",
        "country_code": "+234",
        "flag_icon": "🇳🇬"
    },
    {
        "name": "South Africa",
        "country_code": "+27",
        "flag_icon": "🇿🇦"
    },
    {
        "name": "Zambia",
        "country_code": "+260",
        "flag_icon": "🇿🇲"
    },
    {
        "name": "Zimbabwe",
        "country_code": "+263",
        "flag_icon": "🇿🇼"
    },
    {
        "name": "Ethiopia",
        "country_code": "+251",
        "flag_icon": "🇪🇹"
    },
    {
        "name": "Ghana",
        "country_code": "+233",
        "flag_icon": "🇬🇭"
    },
    {
        "name": "Tanzania",
        "country_code": "+255",
        "flag_icon": "🇹🇿"
    },

]

export const cities: CityData[] = [
    {
        "cityName": "Nairobi",
        "countryName": "Kenya",
        "coordinates": {"latitude": -1.286389, "longitude": 36.817223}
    },
    {
        "cityName": "Mombasa",
        "countryName": "Kenya",
        "coordinates": {"latitude": -4.043477, "longitude": 39.668206}
    },
    {
        "cityName": "Kisumu",
        "countryName": "Kenya",
        "coordinates": {"latitude": -0.091702, "longitude": 34.767956}
    },
    {
        "cityName": "Lagos",
        "countryName": "Nigeria",
        "coordinates": {"latitude": 6.5244, "longitude": 3.3792}
    },
    {
        "cityName": "Abuja",
        "countryName": "Nigeria",
        "coordinates": {"latitude": 9.0579, "longitude": 7.4951}
    },
    {
        "cityName": "Kano",
        "countryName": "Nigeria",
        "coordinates": {"latitude": 12.0022, "longitude": 8.591956}
    },
    {
        "cityName": "Accra",
        "countryName": "Ghana",
        "coordinates": {"latitude": 5.560014, "longitude": -0.205744}
    },
    {
        "cityName": "Kumasi",
        "countryName": "Ghana",
        "coordinates": {"latitude": 6.6885, "longitude": -1.6244}
    },
    {
        "cityName": "Tamale",
        "countryName": "Ghana",
        "coordinates": {"latitude": 9.4008, "longitude": -0.8393}
    },
    {
        "cityName": "Kampala",
        "countryName": "Uganda",
        "coordinates": {"latitude": 0.3136, "longitude": 32.5814}
    },
    {
        "cityName": "Entebbe",
        "countryName": "Uganda",
        "coordinates": {"latitude": 0.0560, "longitude": 32.4794}
    },
    {
        "cityName": "Gulu",
        "countryName": "Uganda",
        "coordinates": {"latitude": 2.7667, "longitude": 32.3050}
    },
    {
        "cityName": "Dar es Salaam",
        "countryName": "Tanzania",
        "coordinates": {"latitude": -6.7924, "longitude": 39.2083}
    },
    {
        "cityName": "Dodoma",
        "countryName": "Tanzania",
        "coordinates": {"latitude": -6.1731, "longitude": 35.7416}
    },
    {
        "cityName": "Arusha",
        "countryName": "Tanzania",
        "coordinates": {"latitude": -3.3869, "longitude": 36.6826}
    },
    {
        "cityName": "Lusaka",
        "countryName": "Zambia",
        "coordinates": {"latitude": -15.3875, "longitude": 28.3228}
    },
    {
        "cityName": "Kitwe",
        "countryName": "Zambia",
        "coordinates": {"latitude": -12.8399, "longitude": 28.0122}
    },
    {
        "cityName": "Ndola",
        "countryName": "Zambia",
        "coordinates": {"latitude": -12.9587, "longitude": 28.6366}
    },
    {
        "cityName": "Johannesburg",
        "countryName": "South Africa",
        "coordinates": {"latitude": -26.2041, "longitude": 28.0473}
    },
    {
        "cityName": "Cape Town",
        "countryName": "South Africa",
        "coordinates": {"latitude": -33.9249, "longitude": 18.4241}
    },
    {
        "cityName": "Durban",
        "countryName": "South Africa",
        "coordinates": {"latitude": -29.8579, "longitude": 31.0292}
    },
    {
        "cityName": "Juba",
        "countryName": "South Sudan",
        "coordinates": {"latitude": 4.8594, "longitude": 31.5713}
    },
    {
        "cityName": "Bor",
        "countryName": "South Sudan",
        "coordinates": {"latitude": 6.2070, "longitude": 31.5590}
    },
    {
        "cityName": "Malakal",
        "countryName": "South Sudan",
        "coordinates": {"latitude": 9.5414, "longitude": 31.6554}
    },
    {
        "cityName": "Addis Ababa",
        "countryName": "Ethiopia",
        "coordinates": {"latitude": 9.1450, "longitude": 38.7250}
    },
    {
        "cityName": "Dire Dawa",
        "countryName": "Ethiopia",
        "coordinates": {"latitude": 9.6000, "longitude": 41.8661}
    },
    {
        "cityName": "Bahir Dar",
        "countryName": "Ethiopia",
        "coordinates": {"latitude": 11.6000, "longitude": 37.3833}
    },
    {
        "cityName": "Kigali",
        "countryName": "Rwanda",
        "coordinates": {"latitude": -1.9403, "longitude": 29.8739}
    },
    {
        "cityName": "Butare",
        "countryName": "Rwanda",
        "coordinates": {"latitude": -2.6036, "longitude": 29.7359}
    },
    {
        "cityName": "Gitarama",
        "countryName": "Rwanda",
        "coordinates": {"latitude": -2.0744, "longitude": 29.7561}
    },
    {
        "cityName": "Harare",
        "countryName": "Zimbabwe",
        "coordinates": {"latitude": -17.8292, "longitude": 31.0522}
    },
    {
        "cityName": "Bulawayo",
        "countryName": "Zimbabwe",
        "coordinates": {"latitude": -20.1667, "longitude": 28.5833}
    },
    {
        "cityName": "Mutare",
        "countryName": "Zimbabwe",
        "coordinates": {"latitude": -18.9707, "longitude": 32.6704}
    }
]
// const otherCountries= [
// {
//   "name": "Algeria",
//   "country_code": "+213",
//   "flag_icon": "🇩🇿"
// },
// {
//   "name": "Angola",
//   "country_code": "+244",
//   "flag_icon": "🇦🇴"
// },
// {
//   "name": "Benin",
//   "country_code": "+229",
//   "flag_icon": "🇧🇯"
// },
// {
//   "name": "Botswana",
//   "country_code": "+267",
//   "flag_icon": "🇧🇼"
// },
// {
//   "name": "Burkina Faso",
//   "country_code": "+226",
//   "flag_icon": "🇧🇫"
// },
// {
//   "name": "Burundi",
//   "country_code": "+257",
//   "flag_icon": "🇧🇮"
// },
// {
//   "name": "Cabo Verde",
//   "country_code": "+238",
//   "flag_icon": "🇨🇻"
// },
// {
//   "name": "Cameroon",
//   "country_code": "+237",
//   "flag_icon": "🇨🇲"
// },
// {
//   "name": "Central African Republic",
//   "country_code": "+236",
//   "flag_icon": "🇨🇫"
// },
// {
//   "name": "Chad",
//   "country_code": "+235",
//   "flag_icon": "🇹🇩"
// },
// {
//   "name": "Comoros",
//   "country_code": "+269",
//   "flag_icon": "🇰🇲"
// },
// {
//   "name": "Democratic Republic of the Congo",
//   "country_code": "+243",
//   "flag_icon": "🇨🇩"
// },
// {
//   "name": "Republic of the Congo",
//   "country_code": "+242",
//   "flag_icon": "🇨🇬"
// },
// {
//   "name": "Cote d'Ivoire",
//   "country_code": "+225",
//   "flag_icon": "🇨🇮"
// },
// {
//   "name": "Djibouti",
//   "country_code": "+253",
//   "flag_icon": "🇩🇯"
// },
// {
//   "name": "Egypt",
//   "country_code": "+20",
//   "flag_icon": "🇪🇬"
// },
// {
//   "name": "Equatorial Guinea",
//   "country_code": "+240",
//   "flag_icon": "🇬🇶"
// },
// {
//   "name": "Eritrea",
//   "country_code": "+291",
//   "flag_icon": "🇪🇷"
// },
// {
//   "name": "Eswatini",
//   "country_code": "+268",
//   "flag_icon": "🇸🇿"
// },
// {
//   "name": "Ethiopia",
//   "country_code": "+251",
//   "flag_icon": "🇪🇹"
// },
// {
//   "name": "Gabon",
//   "country_code": "+241",
//   "flag_icon": "🇬🇦"
// },
// {
//   "name": "Gambia",
//   "country_code": "+220",
//   "flag_icon": "🇬🇲"
// },
// {
//   "name": "Ghana",
//   "country_code": "+233",
//   "flag_icon": "🇬🇭"
// },
// {
//   "name": "Guinea",
//   "country_code": "+224",
//   "flag_icon": "🇬🇳"
// },
// {
//   "name": "Guinea-Bissau",
//   "country_code": "+245",
//   "flag_icon": "🇬🇼"
// },
// {
//   "name": "Kenya",
//   "country_code": "+254",
//   "flag_icon": "🇰🇪"
// },
// {
//   "name": "Lesotho",
//   "country_code": "+266",
//   "flag_icon": "🇱🇸"
// },
// {
//   "name": "Liberia",
//   "country_code": "+231",
//   "flag_icon": "🇱🇷"
// },
// {
//   "name": "Libya",
//   "country_code": "+218",
//   "flag_icon": "🇱🇾"
// },
// {
//   "name": "Madagascar",
//   "country_code": "+261",
//   "flag_icon": "🇲🇬"
// },
// {
//   "name": "Malawi",
//   "country_code": "+265",
//   "flag_icon": "🇲🇼"
// },
// {
//   "name": "Mali",
//   "country_code": "+223",
//   "flag_icon": "🇲🇱"
// },
// {
//   "name": "Mauritania",
//   "country_code": "+222",
//   "flag_icon": "🇲🇷"
// },
// {
//   "name": "Mauritius",
//   "country_code": "+230",
//   "flag_icon": "🇲🇺"
// },
// {
//   "name": "Morocco",
//   "country_code": "+212",
//   "flag_icon": "🇲🇦"
// },
// {
//   "name": "Mozambique",
//   "country_code": "+258",
//   "flag_icon": "🇲🇿"
// },
// {
//   "name": "Namibia",
//   "country_code": "+264",
//   "flag_icon": "🇳🇦"
// },
// {
//   "name": "Niger",
//   "country_code": "+227",
//   "flag_icon": "🇳🇪"
// },
// {
//   "name": "Nigeria",
//   "country_code": "+234",
//   "flag_icon": "🇳🇬"
// },
// {
//   "name": "Rwanda",
//   "country_code": "+250",
//   "flag_icon": "🇷🇼"
// },
// {
//   "name": "Sao Tome and Principe",
//   "country_code": "+239",
//   "flag_icon": "🇸🇹"
// },
// {
//   "name": "Senegal",
//   "country_code": "+221",
//   "flag_icon": "🇸🇳"
// },
// {
//   "name": "Seychelles",
//   "country_code": "+248",
//   "flag_icon": "🇸🇨"
// },
// {
//   "name": "Sierra Leone",
//   "country_code": "+232",
//   "flag_icon": "🇸🇱"
// },
// {
//   "name": "Somalia",
//   "country_code": "+252",
//   "flag_icon": "🇸🇴"
// },
// {
//   "name": "South Africa",
//   "country_code": "+27",
//   "flag_icon": "🇿🇦"
// },
// {
//   "name": "South Sudan",
//   "country_code": "+211",
//   "flag_icon": "🇸🇸"
// },
// {
//   "name": "Sudan",
//   "country_code": "+249",
//   "flag_icon": "🇸🇩"
// },
// {
//   "name": "Swaziland",
//   "country_code": "+268",
//   "flag_icon": "🇸🇿"
// },
// {
//   "name": "Tanzania",
//   "country_code": "+255",
//   "flag_icon": "🇹🇿"
// },
// {
//   "name": "Togo",
//   "country_code": "+228",
//   "flag_icon": "🇹🇬"
// },
// {
//   "name": "Tunisia",
//   "country_code": "+216",
//   "flag_icon": "🇹🇳"
// },
// {
//   "name": "Uganda",
//   "country_code": "+256",
//   "flag_icon": "🇺🇬"
// },
// {
//   "name": "Zambia",
//   "country_code": "+260",
//   "flag_icon": "🇿🇲"
// },
// {
//   "name": "Zimbabwe",
//   "country_code": "+263",
//   "flag_icon": "🇿🇼"
// }
// ]

