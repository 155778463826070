/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: user.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var organisations_pb = require('./organisations_pb.js')

var agents_pb = require('./agents_pb.js')

var branch_pb = require('./branch_pb.js')

var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./user_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.UserServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.UserServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateUserRequest,
 *   !proto.da.proto.CreateUserResponse>}
 */
const methodDescriptor_UserService_CreateUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/CreateUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateUserRequest,
  proto.da.proto.CreateUserResponse,
  /**
   * @param {!proto.da.proto.CreateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.createUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser,
      callback);
};


/**
 * @param {!proto.da.proto.CreateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.createUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/CreateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_CreateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadUserRequest,
 *   !proto.da.proto.ReadUserResponse>}
 */
const methodDescriptor_UserService_ReadMe = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/ReadMe',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadUserRequest,
  proto.da.proto.ReadUserResponse,
  /**
   * @param {!proto.da.proto.ReadUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.readMe =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/ReadMe',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadMe,
      callback);
};


/**
 * @param {!proto.da.proto.ReadUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.readMe =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/ReadMe',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadMe);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadUserRequest,
 *   !proto.da.proto.ReadUserResponse>}
 */
const methodDescriptor_UserService_ReadUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/ReadUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadUserRequest,
  proto.da.proto.ReadUserResponse,
  /**
   * @param {!proto.da.proto.ReadUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.readUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/ReadUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadUser,
      callback);
};


/**
 * @param {!proto.da.proto.ReadUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.readUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/ReadUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadUsersRequest,
 *   !proto.da.proto.ReadUsersResponse>}
 */
const methodDescriptor_UserService_ReadUsers = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/ReadUsers',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadUsersRequest,
  proto.da.proto.ReadUsersResponse,
  /**
   * @param {!proto.da.proto.ReadUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadUsersResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.readUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/ReadUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadUsers,
      callback);
};


/**
 * @param {!proto.da.proto.ReadUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadUsersResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.readUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/ReadUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadUsers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateUserRequest,
 *   !proto.da.proto.UpdateUserResponse>}
 */
const methodDescriptor_UserService_UpdateUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/UpdateUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateUserRequest,
  proto.da.proto.UpdateUserResponse,
  /**
   * @param {!proto.da.proto.UpdateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.updateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.updateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/UpdateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_UpdateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeactivateUserRequest,
 *   !proto.da.proto.DeactivateUserResponse>}
 */
const methodDescriptor_UserService_DeactivateUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/DeactivateUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeactivateUserRequest,
  proto.da.proto.DeactivateUserResponse,
  /**
   * @param {!proto.da.proto.DeactivateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeactivateUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeactivateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeactivateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeactivateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.deactivateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/DeactivateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeactivateUser,
      callback);
};


/**
 * @param {!proto.da.proto.DeactivateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeactivateUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.deactivateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/DeactivateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeactivateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeactivateUserRequest,
 *   !proto.da.proto.DeactivateUserResponse>}
 */
const methodDescriptor_UserService_ActivateUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/ActivateUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeactivateUserRequest,
  proto.da.proto.DeactivateUserResponse,
  /**
   * @param {!proto.da.proto.DeactivateUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeactivateUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeactivateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeactivateUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeactivateUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.activateUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/ActivateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ActivateUser,
      callback);
};


/**
 * @param {!proto.da.proto.DeactivateUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeactivateUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.activateUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/ActivateUser',
      request,
      metadata || {},
      methodDescriptor_UserService_ActivateUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteUserRequest,
 *   !proto.da.proto.DeleteUserResponse>}
 */
const methodDescriptor_UserService_DeleteUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/DeleteUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteUserRequest,
  proto.da.proto.DeleteUserResponse,
  /**
   * @param {!proto.da.proto.DeleteUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.deleteUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteUser,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.deleteUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/DeleteUser',
      request,
      metadata || {},
      methodDescriptor_UserService_DeleteUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAllUsersWithoutTokenRequest,
 *   !proto.da.proto.ReadAllUsersWithoutTokenResponse>}
 */
const methodDescriptor_UserService_ReadAllUsersWithoutToken = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/ReadAllUsersWithoutToken',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAllUsersWithoutTokenRequest,
  proto.da.proto.ReadAllUsersWithoutTokenResponse,
  /**
   * @param {!proto.da.proto.ReadAllUsersWithoutTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAllUsersWithoutTokenResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAllUsersWithoutTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAllUsersWithoutTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAllUsersWithoutTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.readAllUsersWithoutToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/ReadAllUsersWithoutToken',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadAllUsersWithoutToken,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAllUsersWithoutTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAllUsersWithoutTokenResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.readAllUsersWithoutToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/ReadAllUsersWithoutToken',
      request,
      metadata || {},
      methodDescriptor_UserService_ReadAllUsersWithoutToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.RoleToUserRequest,
 *   !proto.da.proto.RoleToUserResponse>}
 */
const methodDescriptor_UserService_AddRoleToUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/AddRoleToUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.RoleToUserRequest,
  proto.da.proto.RoleToUserResponse,
  /**
   * @param {!proto.da.proto.RoleToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.RoleToUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.RoleToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.RoleToUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.RoleToUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.addRoleToUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/AddRoleToUser',
      request,
      metadata || {},
      methodDescriptor_UserService_AddRoleToUser,
      callback);
};


/**
 * @param {!proto.da.proto.RoleToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.RoleToUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.addRoleToUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/AddRoleToUser',
      request,
      metadata || {},
      methodDescriptor_UserService_AddRoleToUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.RoleToUserRequest,
 *   !proto.da.proto.RoleToUserResponse>}
 */
const methodDescriptor_UserService_RemoveRoleFromUser = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/RemoveRoleFromUser',
  grpc.web.MethodType.UNARY,
  proto.da.proto.RoleToUserRequest,
  proto.da.proto.RoleToUserResponse,
  /**
   * @param {!proto.da.proto.RoleToUserRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.RoleToUserResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.RoleToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.RoleToUserResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.RoleToUserResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.removeRoleFromUser =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/RemoveRoleFromUser',
      request,
      metadata || {},
      methodDescriptor_UserService_RemoveRoleFromUser,
      callback);
};


/**
 * @param {!proto.da.proto.RoleToUserRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.RoleToUserResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.removeRoleFromUser =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/RemoveRoleFromUser',
      request,
      metadata || {},
      methodDescriptor_UserService_RemoveRoleFromUser);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UsersToRoleRequest,
 *   !proto.da.proto.UsersToRoleResponse>}
 */
const methodDescriptor_UserService_AddUsersToRole = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/AddUsersToRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UsersToRoleRequest,
  proto.da.proto.UsersToRoleResponse,
  /**
   * @param {!proto.da.proto.UsersToRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UsersToRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UsersToRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UsersToRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UsersToRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.addUsersToRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/AddUsersToRole',
      request,
      metadata || {},
      methodDescriptor_UserService_AddUsersToRole,
      callback);
};


/**
 * @param {!proto.da.proto.UsersToRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UsersToRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.addUsersToRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/AddUsersToRole',
      request,
      metadata || {},
      methodDescriptor_UserService_AddUsersToRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.SearchUsersRequest,
 *   !proto.da.proto.SearchUsersResponse>}
 */
const methodDescriptor_UserService_SearchUsers = new grpc.web.MethodDescriptor(
  '/da.proto.UserService/SearchUsers',
  grpc.web.MethodType.UNARY,
  proto.da.proto.SearchUsersRequest,
  proto.da.proto.SearchUsersResponse,
  /**
   * @param {!proto.da.proto.SearchUsersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.SearchUsersResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.SearchUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.SearchUsersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.SearchUsersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.UserServiceClient.prototype.searchUsers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.UserService/SearchUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_SearchUsers,
      callback);
};


/**
 * @param {!proto.da.proto.SearchUsersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.SearchUsersResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.UserServicePromiseClient.prototype.searchUsers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.UserService/SearchUsers',
      request,
      metadata || {},
      methodDescriptor_UserService_SearchUsers);
};


module.exports = proto.da.proto;

