import { HStack, Box } from "@chakra-ui/react";
import { SubmitHandler } from "react-hook-form";
import { MdAdd } from "react-icons/md";
import { Section } from "../../../gateway/gRPC/pb/surveys_pb";
import { gray_50 } from "../../../theme/theme";
import CustomButton from "../../buttons/CustomButton";
import FormContainer from "../../forms/FormContainer/FormContainer";
import FormInput from "../../forms/FormContainer/FormInput";
import FormTextArea from "../../forms/FormContainer/FormTextArea";
import { SubHeading } from "../../headings/Headings";
import { Spin } from "antd";

interface SectionFormProps {
  onSubmit: SubmitHandler<Section.AsObject>;
  sectionIndex: string;
  loading?: boolean;
}
const SurveySectionForm = ({
  sectionIndex,
  onSubmit,
  loading,
}: SectionFormProps) => {
  const handleSectionSubmit = (data: Section.AsObject) => {
    data.index = parseInt(sectionIndex);
    onSubmit(data);
  };
  return (
    <Box w={"100%"} _hover={{ bg: gray_50 }} p={".5rem"}>
      <Spin spinning={loading??false}>
        <FormContainer columns={1} noFooter onSubmit={handleSectionSubmit}>
          {/* TODO: Change Submit Handler */}
          <SubHeading>
            {sectionIndex === "1" ? "Add Main Section" : "Add New Section"}{" "}
            {`: Section ${sectionIndex}`}
          </SubHeading>
          <FormInput name="title" placeholder={"Section title"} />
          <FormTextArea
            name="description"
            placeholder={"Add Section description (Max 300 characters) "}
            maxLength={300}
          />
          <HStack justifyContent={"flex-end"}>
            <CustomButton type="submit">
              <MdAdd />
              {"Add Section"}
            </CustomButton>
          </HStack>
        </FormContainer>
      </Spin>
    </Box>
  );
};

export default SurveySectionForm;
