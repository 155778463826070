import React, { useEffect } from "react";
import {
	Box,
	Flex,
	Grid,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import {
	MdCheck,
	MdErrorOutline,
	MdInfoOutline,
	MdOutlineDangerous,
	MdOutlineWarningAmber,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "./buttons/CustomButton";
import strings from "../localization/main";
import { closeModalAlert } from "../redux/ModalAlertObj";

export type AlertType = "success" | "error" | "warning" | "info" | "danger";

const getIconColor = (type: AlertType) => {
	switch (type) {
		case "success":
			return "green";
		case "error":
			return "red";
		case "info":
			return "blue";
		case "warning":
			return "orange";
		case "danger":
			return "red";
	}
};

const ShowAlert = () => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		title,
		message,
		type,
		isOpen: show,
		confirm,
		callback,
	} = useSelector((state: any) => state.ModalAlertReducer);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(closeModalAlert());
		onClose();
	};
	const handleConfirm = () => {
		if (callback) {
			setLoading(true);
			callback();
			setLoading(false);
			handleClose();
		}
	};
	useEffect(() => {
		if (show) {
			onOpen();
		}
	}, [show]);
	useEffect(() => {
		if (isOpen) {
			setTimeout(
				() => {
					handleClose();
				},
				confirm ? 15000 : 5000
			);
		}
	}, [isOpen]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={handleClose} isCentered size={"xs"}>
				<ModalOverlay />
				<ModalContent onClick={(e: any) => e.stopPropagation()}>
					<ModalCloseButton />
					<ModalBody textAlign={"center"}>
						<Grid
							placeItems={"center"}
							width={"50px"}
							mx={"auto"}
							my={".5rem"}
							height={"50px"}
							borderRadius={"3px"}
							fontSize={"2rem"}
							bg={`${getIconColor(type)}.100` ?? "gray.100"}
							color={getIconColor(type) ?? "black.500"}
						>
							{type === "success" && <MdCheck />}
							{type === "error" && <MdErrorOutline />}
							{type === "info" && <MdInfoOutline />}
							{type === "warning" && <MdOutlineWarningAmber />}
							{type === "danger" && <MdOutlineDangerous />}
						</Grid>
						<Box my={".5rem"} textAlign={"center"}>
							<Text
								fontFamily={"Poppins,sans-serif"}
								fontSize={"1rem"}
								fontWeight={"900"}
								px={"2rem"}
								my={".5rem"}
							>
								{title}
							</Text>
							<Text fontSize={"sm"} color={"secondary.800"} mb={".5rem"}>
								{message}
							</Text>
						</Box>
					</ModalBody>
					<ModalFooter>
						<Flex
							justify={"flex-end"}
							w={"100%"}
							align={"center"}
							gap={".5rem"}
						>
							{!confirm && (
								<CustomButton
									flexGrow={1}
									colorScheme={"gray"}
									onClick={onClose}
								>
									Ok
								</CustomButton>
							)}
							{confirm && (
								<>
									<CustomButton
										flexGrow={1}
										colorScheme={"gray"}
										onClick={onClose}
									>
										Cancel
									</CustomButton>
									<CustomButton
										flexGrow={1}
										onClick={handleConfirm}
										loading={loading}
										loadingText={strings.loading}
									>
										<MdOutlineWarningAmber
											color={getIconColor(type) ?? "black.500"}
										/>{" "}
										{"  "}
										<Text m={0}
											color={getIconColor(type) ?? "black.500"}
										>{`${strings.proceed}`}</Text>
									</CustomButton>
								</>
							)}
						</Flex>
					</ModalFooter>
					{confirm && (
						<ModalFooter textAlign={"center"}>
							<Box width={"100%"}>
								<Text
									fontSize={"xs"}
									mx={"auto"}
									color={"gray.500"}
									textAlign={"center"}
								>
									{strings.delete_disclaimer}
								</Text>
							</Box>
						</ModalFooter>
					)}
				</ModalContent>
			</Modal>
		</>
	);
};

export default ShowAlert;
