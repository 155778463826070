import { Dispatch } from "@reduxjs/toolkit";
import { notification } from "antd";
import type { NotificationPlacement } from "antd/es/notification";
import axios, { AxiosResponse } from "axios";
import DOMPurify from "dompurify";
import moment, { Moment } from "moment";
import "moment-timezone";
import { ReactFlowInstance } from "reactflow";
import {
	deTokenize,
	expandRightBar,
	resetTokens,
	setDialState,
	setLogOut,
	setLoggedIn,
	setProfile,
	setUserLocation,
	userLogin,
	userLogout,
	userSip,
	removeUserSip,
	resetProfile,
} from "../redux/actions/actions";
import {
	CodedDialState,
	CountryCodeData,
	DialPlanDataType,
	DispositionDataType,
	EventsDataType,
	NodeDataType,
	NodeOptionsDataType,
	UserSip,
} from "../types/DataTypes";
import axiosCRMinstance from "./AxiosInstance";
import geoCode from "./GeoCoding";
import { logout, readContacts } from "./gRPC/Utils/OrgMethods";
import { User } from "./gRPC/pb/user_pb";

export const getBaseUrl = () => {
	return process.env.NODE_ENV === "development"
		? process.env.REACT_APP_DEV_BASE_URL
		: process.env.REACT_APP_PROD_BASE_URL;
};
export const BASE_URL = getBaseUrl();

export const getCdBaseUrl = () => {
	return process.env.NODE_ENV === "development"
		? process.env.REACT_APP_CHATDESK_DEV_BASE_URL
		: process.env.REACT_APP_CHATDESK_PROD_BASE_URL;
};

export const getCdWebSocketUrl = () => {
	return process.env.NODE_ENV === "development"
		? process.env.REACT_APP_CHATDESK_DEV_WEBSOCKET_BASE_URL
		: process.env.REACT_APP_CHATDESK_PROD_WEBSOCKET_BASE_URL;
};

export const getNotificationUrl = () => {
	return process.env.NODE_ENV === "development"
		? process.env.REACT_APP_DEV_NOTIFICATION
		: process.env.REACT_APP_PROD_NOTIFICATION;
};

export const websocketUrl =
	process.env.NODE_ENV === "development"
		? process.env.REACT_APP_CHATDESK_DEV_WEBSOCKET_BASE_URL
		: process.env.REACT_APP_CHATDESK_PROD_WEBSOCKET_BASE_URL;

export const getCMBaseUrl = () => {
	return process.env.NODE_ENV === "development"
		? process.env.REACT_APP_CM_DEV_BASE_URL
		: process.env.REACT_APP_CM_PROD_BASE_URL;
};

export const getBotBuilderUrl = () => {
	return process.env.NODE_ENV === "development"
		? process.env.REACT_APP_BOT_BUILDER_DEV_URL
		: process.env.REACT_APP_BOT_BUILDER_PROD_URL;
};

const BOT_BUILDER_URL = getBotBuilderUrl();

const CD_BASE_URL = getCdBaseUrl();
const CM_BASE_URL = getCMBaseUrl();

export let config = async () => {
	return {
		headers: {
			Authorization: `Token ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		},
	};
};

export const setUser = async (
	userObj: User.AsObject,
	token: string,
	dispatch: Dispatch
) => {
	if (userObj.id) {
		dispatch(setLoggedIn(true));
		try {
			dispatch(userLogin(userObj, token));
			dispatch(setProfile(userObj));
			await getCMList("organization/get_by_auth")
				.then(async (res: any) => {
					const orgData = res.data;
					await getCMList("call_operation/get_ext_by_auth")
						.then((res: any) => {
							const userData = res.data;
							// let userData = JSON.parse(userObj.oldUserData);
							const sip: UserSip = {
								ext: userData?.extension ?? "",
								pwd: userData?.password ?? "",
								base_url: orgData?.domain ?? "",
								username: userObj?.username ?? "",
								port: orgData?.port ?? "",
								pbx: orgData?.pbx_type ?? "",
								ip: orgData?.ip ?? "",
								orgId: orgData?.new_id ?? "",
							};
							dispatch(userSip(sip));
						})
						.catch((err) => {
							console.log("Profile Error:", err);
						});

					//console.log('Organisation:', res);
				})
				.catch((err) => {
					console.log("Org Error:", err);
				});

			return userObj.id;
		} catch (e) {
			console.warn(e);
		}
	} else {
		console.error("Invalid userObj structure:", userObj);
	}
	return null;
};

export const logoutUser = async (token: string, dispatch: Dispatch) => {
	try {
		await logout(token);
		dispatch(userLogout());
		dispatch(setLogOut());
		dispatch(removeUserSip());
		dispatch(resetProfile());
	} catch (error) {
		console.error("Error logging out:", error);
		throw error;
	}
};

export const logoutFunc = async (dispatch: Dispatch) => {
	try {
		dispatch(userLogout());
		dispatch(setLogOut());
		dispatch(deTokenize());
		dispatch(setLoggedIn(false));
		dispatch(resetTokens());
		localStorage.clear();
	} catch (e) {
		console.warn(e);
	}
};
export const notify = (
	type: string,
	placement: NotificationPlacement,
	message: string,
	description: string
) => {
	const notif =
		type === "success"
			? notification.success
			: type === "error"
			? notification.error
			: type === "warning"
			? notification.warning
			: notification.info;

	notif({
		message: message,
		description: description,
		placement: placement,
	});
};

export const getItem = async (url: any) => {
	return await axiosCRMinstance.get(`${url}/`);
};

export const getMultipleItems = async <T>(
	ids: number[],
	url: string
): Promise<T[]> => {
	let data: T[] = [];
	for (let i = 0; i < ids?.length; i++) {
		let id = ids[i];
		let item = await getItem(`${url}/${id}`);
		data.push(item.data);
	}
	return data;
};

export const postItem = async (url: string, data: any) => {
	return axiosCRMinstance.post(`${url}/`, data);
};
export const putItem = async (url: string, id: string | number, data: any) => {
	return axiosCRMinstance.put(`${url}/${id}/`, data);
};

// export const addAgent = async (url: string, data: any) => {
//   return axiosCRMinstance.post(`${url}/`, data);
// };
export const formatDialState = (values: CodedDialState) => {
	let { number: dialState, countryCode: code } = values;
	if (code !== "~Ext") {
		return `${code.slice(1)}${
			dialState.startsWith("0") ? dialState.slice(1) : dialState
		}`;
	}
	return dialState;
};
export const formatSupDialState = (type: string, ext: string | number) => {
	if (type === "spy") return `021${ext}`;
	if (type === "whisper") return `022${ext}`;
	if (type === "barge") return `023${ext}`;
};

export const getList = async (url: any) => {
	try {
		const list: AxiosResponse = await axiosCRMinstance.get(
			`${url}/`,
			await config()
		);
		return list.data?.results;
	} catch (e) {
		console.warn(e);
		return [];
	}
};
export const getFilteredAccountsList = async (url: any) => {
	const list: AxiosResponse = await axiosCRMinstance.get(`${url}`);
	return list.data?.results;
};
export const getPaginatedList = async (url: any) => {
	const list: AxiosResponse = await axiosCRMinstance.get(`${url}`);
	return list;
};
export const getAccountContacts = async (
	url: any,
	id: Number,
	search: string
) => {
	const list: AxiosResponse = await axiosCRMinstance.get(
		`${url}/?account=${id}&search=${search}`
	);
	return list.data?.results;
};
export const filterList = async (url: any, search?: string) => {
	if (search) {
		const list: AxiosResponse = await axiosCRMinstance.get(
			url.includes("?") ? `${url}&search=${search}` : `${url}?search=${search}`
		);
		return list?.data?.results;
	} else {
		const list: AxiosResponse = await axiosCRMinstance.get(`${url}`);
		return list?.data?.results;
	}
};
export const filterPaginatedList = async (url: any, search?: string) => {
	if (search) {
		const list: AxiosResponse = await axiosCRMinstance.get(
			url.includes("?")
				? `${url}&search=${search ?? ""}`
				: `${url}?search=${search ?? ""}`
		);
		return list;
	} else {
		const list: AxiosResponse = await axiosCRMinstance.get(
			`${url}`,
			await config()
		);
		return list;
	}
};
export const filterGsuite = async (url: any) => {
	return await axiosCRMinstance.get(`${url}`);
};

export const getFilteredItem = async (url: any) => {
	return await axiosCRMinstance.get(`${url}`);
};
export const crmOverviewData = async () => {
	return await axiosCRMinstance.get(
		`crm-overview/totals_acc_lead_contact_deal/`
	);
};
export const randomColor = () => {
	let color = "#";
	for (let i = 0; i < 3; i++)
		color += (
			"c" + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)
		).slice(-2);
	return color;
};
export const getNodeItem = async (url: any) => {
	return await axios.get(`${CD_BASE_URL + url}/`, await config());
};
export const getNodeItemWithFilters = async (url: any) => {
	return await axios.get(`${CD_BASE_URL + url}`, await config());
};
export const getNodeList = async (
	url: string,
	size?: number | string,
	page?: number | string,
	signal?: AbortSignal
) => {
	let paramUrl = `${CD_BASE_URL + url}?size=${size ?? 50}&page=${page ?? 1}`;
	const list: AxiosResponse = await axios.get(`${paramUrl}`, {
		...(await config()),
		signal,
	});
	return list.data;
};
export const getFilteredNodeList = async (url: any, signal?: AbortSignal) => {
	return axios.get(`${CD_BASE_URL + url}`, { ...(await config()), signal });
};
export const postNodeItem = async (url: string, data: any) => {
	return axios.post(`${CD_BASE_URL + url}/`, data, await config());
};
export const filterTicketsByTicketId = (tickets: any, ticketId: string) => {
	return tickets.filter((ticket: any) => ticket.ticketId === ticketId);
};
export const scrollToBottom = (ref: any) => {
	ref.scrollIntoView(true);
};

export const putNodeItem = async (url: string, data: any) => {
	return axios.put(`${CD_BASE_URL + url}/`, data, await config());
};
export const deleteNodeItem = async (url: string) => {
	return axios.delete(`${CD_BASE_URL + url}/`, await config());
};
export const deleteItem = async (url: string) => {
	return axiosCRMinstance.delete(`${url}/`);
};
export const getLastIndex = (arr: any): number => {
	return arr.length - 1;
};
export const getTicketDisposition = async () => {
	let dataObj: DispositionDataType = {
		categories: [],
		statuses: [],
		priorities: [],
		services: [],
		sources: [],
		tags: [],
		clients: [],
		agents: [],
	};
	await getNodeList("categories").then((res: any) => {
		dataObj.categories = [...res.data.rows];
	});
	await getNodeList("statuses").then((res: any) => {
		dataObj.statuses = [...res.data.rows];
	});
	await getNodeList("priorities").then((res: any) => {
		dataObj.priorities = [...res.data.rows];
	});
	await getNodeList("services").then((res: any) => {
		dataObj.services = [...res.data.rows];
	});
	await getNodeList("sources").then((res: any) => {
		dataObj.sources = res.data;
	});
	await getNodeList("tags").then((res: any) => {
		dataObj.tags = [...res.data.rows];
	});
	await getNodeList("agents").then((res: any) => {
		dataObj.agents = [...res.data.rows];
	});
	await getNodeList("clients").then((res: any) => {
		dataObj.clients = [...res.data.rows];
	});
	return dataObj;
};

// Call Module Helpers
export const getCMList = async (url: string) => {
	return await axios.get(`${CM_BASE_URL}${url}`, await config());
};

export const getCMItem = async (url: string, id: number | string) => {
	return await axios.get(`${CM_BASE_URL}${url}/${id}`);
};
export const getCMItemWithToken = async (url: string, id: number | string) => {
	return await axios.get(`${CM_BASE_URL}${url}/${id}`, await config());
};

export const postCMItem = async <T>(url: string, data: T) => {
	return await axios.post(`${CM_BASE_URL}${url}`, data, await config());
};

export const putCMItem = async (url: string, data: any) => {
	return axios.put(`${CM_BASE_URL + url}/`, data, await config());
};

export const deleteCMItem = async (url: string) => {
	return axios.delete(`${CM_BASE_URL + url}/`, await config());
};

export const formatDate = (date: string, time?: boolean) => {
	return moment(date).isValid()
		? moment(date).format(`${time ? "hh:mm A" : ""} MMM DD YYYY`)
		: "-";
};

export const formatDateSecs = (date: number) => {
	return moment.utc(date * 1000).format("DD/MM/YY");
};

export const formatCurrency = (amount: number, currency: string) => {
	return Intl.NumberFormat("en-US", {
		style: "currency",
		currency: currency || "USD",
	}).format(amount);
};

export const getHoursOfTheDay = async () => {
	const hours = [];
	for (let i = 0; i < 24; i++) {
		hours.push({
			text: `${i < 10 ? `0${i}` : i}:00`,
			value: i,
		});
	}
	return hours;
};

export const getDatesOfCurrentWeek = async (date: Moment) => {
	const dates = [];
	const firstDayOfWeek = date.clone().startOf("week");
	const lastDayOfWeek = date.clone().endOf("week");
	const current = firstDayOfWeek.clone();
	while (current.isBefore(lastDayOfWeek)) {
		dates.push({
			text: current.format("DD"),
			value: current.format("YYYY-MM-DD"),
			week: current.week(),
			weekday: moment().weekday(current.weekday()).format("ddd"),
		});
		current.add(1, "day");
	}
	return dates;
};
export const isToday = (date: Moment) => {
	return moment().isSame(date, "day");
};
export const gsuiteGetAuth = async (url: string) => {
	return axiosCRMinstance.get(BASE_URL + url);
};
export const formatEmail = (email: string) => {
	let emailChecks = /noreply|atlassian|slack/g;
	if (email.match(emailChecks)) return `noreply@${email.split("@")[1]}`;
	return email;
};
export const getNewDateTimeZone = () => {
	let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
	let shortTimezone = timezone.split("/")[1];
	return shortTimezone;
};
export const getNewDateTimeZoneOffset = () => {
	let offset = new Date().getTimezoneOffset();
	if (offset > 0) {
		return `GMT-${offset / 60}`;
	}
	return `GMT+${(offset / 60).toString().split("-")[1]}`;
};
export const filterEvent = (
	date: string,
	events: EventsDataType[]
): EventsDataType[] => {
	let filterDate = moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]");
	return events.filter((event) => event.start === filterDate);
};
// export const getMultipleItems = async <T>(
//   ids: number[],
//   url: string
// ): Promise<T[]> => {
//   let data: T[] = [];
//   for (let i = 0; i < ids?.length; i++) {
//     let id = ids[i];
//     let item = await getItem(`${url}/${id}`);
//     data.push(item.data);
//   }
//   return data;
// };
export const authenticateGsuite = async (resp: any): Promise<boolean> => {
	if (
		"message" in resp &&
		resp.message === "token does not exist please authenticate"
	) {
		return false;
	}
	return true;
};
export const getRegexMatchFromString = (regex: RegExp, text: string) => {
	const match = (text ?? "").match(regex);
	return match ? match[0] : text;
};
export const assignIdToArrayOfObjects = <T>(arr: T[]): T[] => {
	return arr.map((item: any, index: number) => {
		item.id = index;
		return item;
	});
};
export const getAccountsCsv = async () => {
	return await axiosCRMinstance.get(`crm-account/export_account_csv/`);
};
export const getProductsCsv = async () => {
	return await axiosCRMinstance.get(`crm-product/export_product_csv/`);
};
export const getLeadsCsv = async () => {
	return await axiosCRMinstance.get(`crm-lead/export_lead_csv/`);
};
export const getContactsCsv = async () => {
	return await axiosCRMinstance.get(`crm-contact/export_contact_csv/`);
};
export const getDealsCsv = async () => {
	return await axiosCRMinstance.get(`crm-opportunity/export_opportunity_csv/`);
};
export const uploadContactsCsv = async (file: any, url: string) => {
	return await postItem(url, file);
};
export const uploadProductsCsv = async (file: any) => {
	return await axiosCRMinstance.post(`crm-product/import_product_csv/`, file);
};
export const uploadLeadsCsv = async (file: any) => {
	return await axiosCRMinstance.post(`crm-lead/import_lead_csv/`, file);
};
export const getDealsLost = async (year: Number) => {
	return await axiosCRMinstance.get(
		`crm-overview/deals/bar_graph/lost/${year}/`
	);
};
export const getDealsWon = async (year: Number) => {
	return await axiosCRMinstance.get(
		`crm-overview/deals/bar_graph/won/${year}/`
	);
};
export const getDealsCreated = async (year: Number) => {
	return await axiosCRMinstance.get(
		`crm-overview/deals/bar_graph/new/${year}/`
	);
};
export const dealsStatistics = async (year: Number, dealStatus: string) => {
	return await axiosCRMinstance.get(
		`crm-overview/deals/bar_graph/${dealStatus}/${year}/`
	);
};
export const leadsStatistics = async (year: Number, leadStatus: string) => {
	return await axiosCRMinstance.get(
		`crm-overview/leads_created_converted/${leadStatus}/${year}/`
	);
};

export const getCallerFromContacts = async <T>(call: T): Promise<any> => {
	return (
		await readContacts("", 1).then((res) => {
			return res?.contactsList;
		})
	).find((contact: any) =>
		contact.phone.includes(
			((call as any).remoteIdentity?.uri?.user).slice(
				4,
				((call as any).remoteIdentity?.uri?.user).length - 1
			)
		)
	);
};
export const getContact = async (caller: string, contacts: any[]) => {
	let number = () => {
		if (caller?.length > 10) return caller?.slice(4, caller?.length - 1);
		return caller;
	};
	return contacts?.find(
		(contact: any) => contact?.phone?.includes(number()) ?? ""
	);
};

export const getAgentExtension = async <T>(): Promise<AxiosResponse<T>> => {
	return await axios.get(`${CM_BASE_URL}agent/extension/ `, await config());
};
export const secondsToDuration = (seconds: number): string => {
	const hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	const minutes = Math.floor(seconds / 60);
	seconds %= 60;
	return `${hours}h ${minutes}m ${seconds}s`;
};

export const matchPhoneNumber = (phoneNumber: string): boolean => {
	const phoneRegex = /^(?:\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
	return phoneRegex.test(phoneNumber);
};

export const formatPlans = (nodes: DialPlanDataType[]) => {
	let formatedPlans: any[] = [];
	for (let node of nodes) {
		let plan: any = {};
		plan.id = node.id;
		plan.name = node.name;
		plan.description = node.description;
		plan.hasMultipleDIDs = node.did.length > 1;
		plan.dids = node.did;
		plan.nodes = [...formatNodes(node.nodes)];
		formatedPlans.push(plan);
	}
	return formatedPlans;
};
export const formatNodes = (nodes: NodeDataType[]) => {
	let formatedNodes: any[] = [];
	for (let node of nodes) {
		let plan: any = {};
		plan.id = node.id;
		plan.type = node.node_type;
		plan.data = {
			id: node.id,
			name: node.name,
			recording: node.recording,
			options: [...formatNodeOptions(node.options)],
			next_node: node.next_node,
			previous_node: node.previous_node,
			description: node.description,
			node_type: node.node_type,
		};
		plan.position = {
			x: parseInt(`${node.position.x}`),
			y: parseInt(`${node.position.y}`),
		};
		formatedNodes.push(plan);
	}
	return formatedNodes;
};
export const formatNodeOptions = (options: NodeOptionsDataType[]) => {
	let formatedOptions: NodeOptionsDataType[] = [];
	for (let option of options) {
		formatedOptions.push({
			name: option.name,
			destination: option.destination,
			option: option.option,
		});
	}
	return formatedOptions;
};
export const formatEdgesFromNodes = (nodes: any[]) => {
	let formatedEdges: any[] = [];
	for (let node of nodes) {
		(node.data.options ?? []).forEach((option: any, index: number) => {
			formatedEdges.push({
				id: `${node.id}-${option.destination}`,
				source: node.id,
				target: option.destination,
				sourceHandle: `source-${option.destination}`,
				targetHandle: `target-${option.destination}`,
				type: "custom",
				data: option,
			});
		});
	}
	return formatedEdges;
};

// export const formatBaseUrl = (url: string) => {
//   if (!url) return "invalid.base.url";
//   return (url.match(/(?<=http:\/\/).*/) as any)[0] ?? "";
// };
export const getWssBaseUrl = (url: string) => {
	// if (!url) return "invalid.base.url";
	// return (
	//   (url.match(/(?<=http:\/\/)(.*?)(?=:)|(?<=http:\/\/).*/) as any)[0] ?? ""
	// );
	return url;
};
export const onClickDial = (phoneNumber: string, dispatch: Dispatch) => {
	dispatch(setDialState(phoneNumber));
	dispatch(expandRightBar({ expanded: true, component: "phone" }));
};
export const getFeatureCode = (value: string) => {
	if (!value) return "";
	return value.match(/(?<=:).*/)?.[0] ?? "";
};
export const hasAccess = (role: string, level: 1 | 2 | 3) => {
	if (role.toLowerCase() === "agent" && level < 2) return true;
	if (role.toLowerCase() === "supervisor" && level < 3) return true;
	if (role.toLowerCase() === "admin") return true;
	return false;
};

export const downloadFile = async (url: any, filename: any, headers: any) => {
	try {
		const response = await fetch(url, {
			headers: {
				Authorization: headers.headers.Authorization,
			},
		});
		const blob = await response.blob();
		const downloadUrl = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.href = downloadUrl;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		a.remove();
		window.URL.revokeObjectURL(downloadUrl);
	} catch (err) {
		console.error("Error downloading file: ", err);
	}
};

export const handleClickCSV = async (
	e: React.MouseEvent<HTMLAnchorElement>
) => {
	e.preventDefault();
	const href = e.currentTarget.href;
	const filename = e.currentTarget.download;
	downloadFile(href, filename, await config());
};

export const contactFormater = <T>(
	setCaller: React.Dispatch<React.SetStateAction<any | null>>,
	call: T,
	agents: any[]
) => {
	if (((call as any)?.remoteIdentity?.uri?.user?.length ?? 0) > 6) {
		getCallerFromContacts<T>(call).then((contact: any | undefined) => {
			if (contact) {
				setCaller(contact);
			} else {
				setCaller({
					first_name: (call as any)?.remoteIdentity.displayName,
				} as any);
			}
		});
	} else {
		setCaller({
			first_name:
				agents.find(
					(agent) => agent.reg_user === (call as any)?.remoteIdentity?.uri?.user
				)?.agent ?? `Agent ${(call as any)?.remoteIdentity?.uri?.user}`,
		} as any);
	}
};

export async function copyTextToClipboard(text: any) {
	return await navigator.clipboard.writeText(text);
}

export const getWallet = async () => {
	return await axiosCRMinstance.get(`wallets/`);
};

export const topupMainWalletWithStripe = async () => {
	return await axiosCRMinstance.post(`payments/stripe/checkout/`, {
		success_url: `${window.location.origin}/stripe/checkout-success?sessionId={CHECKOUT_SESSION_ID}`,
		cancel_url: `${window.location.origin}/stripe/checkout-cancelled?sessionId={CHEKCOUT_SESSION_ID}`,
		reason: "WALLET_TOPUP",
	});
};

export const topupMainWalletWithMpesa = async (data: {
	phoneNumber: string;
	amount: number;
}) => {
	return await axiosCRMinstance.post(`payments/mpesa/`, {
		...data,
		reason: "WALLET_TOPUP",
	});
};

export const topUpBranchWallet = async ({
	amount,
	wallet,
}: {
	amount: number;
	wallet: number;
}) => {
	return await axiosCRMinstance.post(`wallet-topups/`, {
		currency_amount_added: amount,
		wallet,
	});
};

export const getWalletById = async (id: string) => {
	return await axiosCRMinstance.get(`wallets${id}`);
};

export const listSubscriptions = async () => {
	return await axiosCRMinstance.get(`subscriptions/account/`);
};

export const listAvailablePlans = async ({
	queryParams = new URLSearchParams({ priceList: "DEFAULT" }),
}) => {
	return await axiosCRMinstance.get(
		`subscriptions/plans/?${queryParams.toString()}`
	);
};

export const purchaseSubscription = async (
	planName: string,
	quantity: number,
	startDate: string
) => {
	return await axiosCRMinstance.post(`subscriptions/account/`, {
		startDate,
		planName,
		quantity,
	});
};

export const cancelSubscription = async () => {
	throw Error("Not Implemented");
};

export const getPaymentMethods = async () => {
	return await axiosCRMinstance.get(`payment-methods/`);
};

export const getPaymentMethodById = async (id: string) => {
	return axiosCRMinstance.get(`payment/methods/${id}/`);
};

export const setupStripeCards = async ({
	queryParams,
}: {
	queryParams: URLSearchParams;
}) => {
	return await axiosCRMinstance.post(
		`subscriptions/stripe/checkout/?${queryParams.toString()}`,
		null
	);
};

export const deletePaymentMethod = async (methodId: string) => {
	return await axiosCRMinstance.delete(`payment-methods/${methodId}/`);
};

export const setDefaultPaymentMethod = async (methodId: string) => {
	return await axiosCRMinstance.put(
		`payment-methods/${methodId}/?setDefault=true`,
		null
	);
};

export const getInvoices = async () => {
	return await axiosCRMinstance.get(`subscriptions/invoices/`);
};

export const getInvoiceById = async (id: string) => {
	return await axiosCRMinstance.get(`subscriptions/invoices/${id}/`);
};

export const getInvoicePayments = async () => {
	return await axiosCRMinstance.get(`subscriptions/payments/`);
};

export const getInvoicePaymentById = async (id: string) => {
	return await axiosCRMinstance.get(`suscriptions/payments/${id}`);
};

export const updateBillingAccount = async (accountId: string, data: object) => {
	return await axiosCRMinstance.put(`billing/accounts/${accountId}/`, data);
};

export const getWalletTransactions = async (walletId?: number) => {
	const topups = await axiosCRMinstance.get(
		`wallet-topups/${walletId ? "?wallet=" + walletId : ""}`
	);

	const withdrawals = await axiosCRMinstance.get(
		`wallet-withdrawals/${walletId ? "?wallet=" + walletId : ""}`
	);

	return {
		topups: topups.data,
		withdrawals: withdrawals.data,
	};
};

export const getInAppServices = async () => {
	return await axiosCRMinstance.get(`consumable-apps/`);
};
export const splitUnderscore = (string: string): string => {
	return string.replaceAll("_", " ");
};

export function geoFindMe(dispatch: Dispatch) {
	if (!navigator.geolocation) {
		notify(
			"warning",
			"bottomLeft",
			"Error",
			"Geolocation is not supported by your browser"
		);
		return;
	}

	function success(position: any) {
		let latitude = position.coords.latitude;
		let longitude = position.coords.longitude;
		let location: CountryCodeData = geoCode({ latitude, longitude });
		if (location) {
			dispatch(setUserLocation(location));
		}
	}

	function error() {
		notify("error", "bottomLeft", "Error", "Unable to retrieve your location");
	}

	navigator.geolocation.getCurrentPosition(success, error);
}

export const getWhatsAppCampaignAnalytics = async (
	url: string,
	campaignId: any
) => {
	let paramUrl = `${CD_BASE_URL + url}?campaign_id=${campaignId}`;
	const list: AxiosResponse = await axios.get(`${paramUrl}`, await config());
	return list.data;
};

export const checkPermission = async (
	userId: number,
	perm: string
): Promise<boolean> => {
	let permission: boolean = false;

	return permission;
};

export const sortRoles = (oldRoles: number[], newRoles: number[]): number[] => {
	const set2 = new Set(newRoles);
	const resultArray = oldRoles.filter((item) => !set2.has(item));
	return resultArray;
};

export const removeHtmlTags = (input: any) => {
	input = input.replace(/&nbsp;/g, " ");
	return input.replace(/<[^>]*>/g, "");
};

export const createMarkup = (html: any) => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

export const fetchFlow = async (
	flowId: string, // Assuming flowId is a string
	reactFlowInstance: ReactFlowInstance
): Promise<void> => {
	try {
		const flow = await getFlows("flows", flowId);

		if (!flow || !flow.data) {
			throw new Error("Invalid flow data");
		}

		const { nodes, edges, viewport } = flow.data;
		const { x = 0, y = 0, zoom = 1 } = viewport || {};

		reactFlowInstance.setNodes(nodes || []);
		reactFlowInstance.setEdges(edges || []);
		reactFlowInstance.setViewport({ x, y, zoom });
	} catch (error) {
		console.error("Error fetching or updating flow:", error);
	}
};

export const postFlowItem = async (url: string, data: any) => {
	return await axios.post(`${BOT_BUILDER_URL + url}`, data, await config());
};

export const getFlows = async (
	url: string,

	flowId?: string
) => {
	let paramUrl;
	flowId
		? (paramUrl = `${BOT_BUILDER_URL + url}/${flowId}`)
		: (paramUrl = `${BOT_BUILDER_URL + url}`);
	const list: AxiosResponse = await axios.get(`${paramUrl}`, await config());
	return list.data;
};

export const fetchBotItems = async (
	url: string,
	size?: number | string,
	page?: number | string
) => {
	let paramUrl = `${BOT_BUILDER_URL + url}?per_page=${size ?? 10}&page=${
		page ?? 1
	}`;
	const list: AxiosResponse = await axios.get(`${paramUrl}`, await config());
	return list.data;
};

export const fetchBotItem = async (url: string, id: string) => {
	const response = await axios.get(
		`${BOT_BUILDER_URL + url}/${id}`,
		await config()
	);
	return response;
};

export const postBotItem = async (url: string, data: any) => {
	const response = await axios.post(
		`${BOT_BUILDER_URL + url}`,
		data,
		await config()
	);
	return response;
};

export const deleteBotItem = async (url: string) => {
	const response = await axios.delete(
		`${BOT_BUILDER_URL + url}`,
		await config()
	);
	return response;
};

export const updateBotItem = async (url: string, data: any) => {
	const response = await axios.patch(
		`${BOT_BUILDER_URL + url}`,
		data,
		await config()
	);
	return response;
};

export const getTimezoneOffset = () => {
	const currentTimezone = moment.tz.guess();
	const min = moment.tz(currentTimezone).utcOffset();
	const hrs = Math.floor(min / 60);
	const minRem = min % 60;
	return `${hrs >= 0 ? "+" : "-"}${Math.abs(hrs)
		.toString()
		.padStart(2, "0")}:${Math.abs(minRem).toString().padStart(2, "0")}`;
};

export const updateFilterQuery = (
	key: string,
	value: string,
	query: string
): string => {
	const params = query.split("&").filter(Boolean);
	const index = params.findIndex((param) => param.startsWith(`${key}=`));

	if (index !== -1) {
		params[index] = `${key}=${value}`;
	} else {
		params.push(`${key}=${value}`);
	}

	return params.join("&");
};
