import {EXPAND_RIGHT_BAR} from "../actions/actions";
import {RightBarAction, RightBarState} from "../../types/DataTypes";

const initialState:RightBarState = {
    expanded: false,
    component: "",
}
const ExpandRightBar = (state:RightBarState = initialState, action: RightBarAction) => {
    switch (action.type) {
        case EXPAND_RIGHT_BAR:
        return action.payload;
        default:
        return state;
    }
}
export default ExpandRightBar;