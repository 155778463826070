// source: agents.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.da.proto.AgentStatus', null, global);
goog.exportSymbol('proto.da.proto.AgentStatusRequest', null, global);
goog.exportSymbol('proto.da.proto.AgentStatusResponse', null, global);
goog.exportSymbol('proto.da.proto.StatusChange', null, global);
goog.exportSymbol('proto.da.proto.StatusChange.StatusType', null, global);
goog.exportSymbol('proto.da.proto.StatusChangeRequest', null, global);
goog.exportSymbol('proto.da.proto.StatusChangeResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.StatusChange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.StatusChange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.StatusChange.displayName = 'proto.da.proto.StatusChange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.AgentStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.AgentStatus.repeatedFields_, null);
};
goog.inherits(proto.da.proto.AgentStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.AgentStatus.displayName = 'proto.da.proto.AgentStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.AgentStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.AgentStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.AgentStatusRequest.displayName = 'proto.da.proto.AgentStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.StatusChangeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.StatusChangeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.StatusChangeRequest.displayName = 'proto.da.proto.StatusChangeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.AgentStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.AgentStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.AgentStatusResponse.displayName = 'proto.da.proto.AgentStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.StatusChangeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.StatusChangeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.StatusChangeResponse.displayName = 'proto.da.proto.StatusChangeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.StatusChange.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.StatusChange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.StatusChange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusChange.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statusType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    startTime: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endTime: jspb.Message.getFieldWithDefault(msg, 5, ""),
    agentStatusId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.StatusChange}
 */
proto.da.proto.StatusChange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.StatusChange;
  return proto.da.proto.StatusChange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.StatusChange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.StatusChange}
 */
proto.da.proto.StatusChange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.da.proto.StatusChange.StatusType} */ (reader.readEnum());
      msg.setStatusType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentStatusId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.StatusChange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.StatusChange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.StatusChange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusChange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatusType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getStartTime();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndTime();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAgentStatusId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.da.proto.StatusChange.StatusType = {
  UNKNOWN_STATUS_TYPE: 0,
  ONLINE: 1,
  OFFLINE: 2,
  BUSY: 3,
  AVAILABLE: 4,
  IN_A_MEETING: 5,
  IN_A_CALL: 6,
  ON_A_BREAK: 7,
  ON_OUTBOUND_QUEUE: 8,
  ON_INBOUND_QUEUE: 9,
  ON_WRAP_UP: 10,
  ON_MONITORING_QUEUE: 11,
  ON_TICKET_QUEUE: 12
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.StatusChange.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusType status_type = 2;
 * @return {!proto.da.proto.StatusChange.StatusType}
 */
proto.da.proto.StatusChange.prototype.getStatusType = function() {
  return /** @type {!proto.da.proto.StatusChange.StatusType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.da.proto.StatusChange.StatusType} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setStatusType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.da.proto.StatusChange.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string start_time = 4;
 * @return {string}
 */
proto.da.proto.StatusChange.prototype.getStartTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end_time = 5;
 * @return {string}
 */
proto.da.proto.StatusChange.prototype.getEndTime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string agent_status_id = 6;
 * @return {string}
 */
proto.da.proto.StatusChange.prototype.getAgentStatusId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setAgentStatusId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string organisation_id = 7;
 * @return {string}
 */
proto.da.proto.StatusChange.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusChange} returns this
 */
proto.da.proto.StatusChange.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.AgentStatus.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.AgentStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.AgentStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.AgentStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AgentStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    agentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statusChangesList: jspb.Message.toObjectList(msg.getStatusChangesList(),
    proto.da.proto.StatusChange.toObject, includeInstance),
    organisationId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.AgentStatus}
 */
proto.da.proto.AgentStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.AgentStatus;
  return proto.da.proto.AgentStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.AgentStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.AgentStatus}
 */
proto.da.proto.AgentStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentId(value);
      break;
    case 3:
      var value = new proto.da.proto.StatusChange;
      reader.readMessage(value,proto.da.proto.StatusChange.deserializeBinaryFromReader);
      msg.addStatusChanges(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.AgentStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.AgentStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.AgentStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AgentStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatusChangesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.da.proto.StatusChange.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.AgentStatus.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.AgentStatus} returns this
 */
proto.da.proto.AgentStatus.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string agent_id = 2;
 * @return {string}
 */
proto.da.proto.AgentStatus.prototype.getAgentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.AgentStatus} returns this
 */
proto.da.proto.AgentStatus.prototype.setAgentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated StatusChange status_changes = 3;
 * @return {!Array<!proto.da.proto.StatusChange>}
 */
proto.da.proto.AgentStatus.prototype.getStatusChangesList = function() {
  return /** @type{!Array<!proto.da.proto.StatusChange>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.StatusChange, 3));
};


/**
 * @param {!Array<!proto.da.proto.StatusChange>} value
 * @return {!proto.da.proto.AgentStatus} returns this
*/
proto.da.proto.AgentStatus.prototype.setStatusChangesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.da.proto.StatusChange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.StatusChange}
 */
proto.da.proto.AgentStatus.prototype.addStatusChanges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.da.proto.StatusChange, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.AgentStatus} returns this
 */
proto.da.proto.AgentStatus.prototype.clearStatusChangesList = function() {
  return this.setStatusChangesList([]);
};


/**
 * optional string organisation_id = 4;
 * @return {string}
 */
proto.da.proto.AgentStatus.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.AgentStatus} returns this
 */
proto.da.proto.AgentStatus.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.AgentStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.AgentStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.AgentStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AgentStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.AgentStatusRequest}
 */
proto.da.proto.AgentStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.AgentStatusRequest;
  return proto.da.proto.AgentStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.AgentStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.AgentStatusRequest}
 */
proto.da.proto.AgentStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.AgentStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.AgentStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.AgentStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AgentStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string agent_id = 1;
 * @return {string}
 */
proto.da.proto.AgentStatusRequest.prototype.getAgentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.AgentStatusRequest} returns this
 */
proto.da.proto.AgentStatusRequest.prototype.setAgentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.StatusChangeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.StatusChangeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.StatusChangeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusChangeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuschange: (f = msg.getStatuschange()) && proto.da.proto.StatusChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.StatusChangeRequest}
 */
proto.da.proto.StatusChangeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.StatusChangeRequest;
  return proto.da.proto.StatusChangeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.StatusChangeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.StatusChangeRequest}
 */
proto.da.proto.StatusChangeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.StatusChange;
      reader.readMessage(value,proto.da.proto.StatusChange.deserializeBinaryFromReader);
      msg.setStatuschange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.StatusChangeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.StatusChangeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.StatusChangeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusChangeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuschange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.StatusChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusChange statusChange = 1;
 * @return {?proto.da.proto.StatusChange}
 */
proto.da.proto.StatusChangeRequest.prototype.getStatuschange = function() {
  return /** @type{?proto.da.proto.StatusChange} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.StatusChange, 1));
};


/**
 * @param {?proto.da.proto.StatusChange|undefined} value
 * @return {!proto.da.proto.StatusChangeRequest} returns this
*/
proto.da.proto.StatusChangeRequest.prototype.setStatuschange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.StatusChangeRequest} returns this
 */
proto.da.proto.StatusChangeRequest.prototype.clearStatuschange = function() {
  return this.setStatuschange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.StatusChangeRequest.prototype.hasStatuschange = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.AgentStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.AgentStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.AgentStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AgentStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    agentStatus: (f = msg.getAgentStatus()) && proto.da.proto.AgentStatus.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.AgentStatusResponse}
 */
proto.da.proto.AgentStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.AgentStatusResponse;
  return proto.da.proto.AgentStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.AgentStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.AgentStatusResponse}
 */
proto.da.proto.AgentStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.AgentStatus;
      reader.readMessage(value,proto.da.proto.AgentStatus.deserializeBinaryFromReader);
      msg.setAgentStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.AgentStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.AgentStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.AgentStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AgentStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgentStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.AgentStatus.serializeBinaryToWriter
    );
  }
};


/**
 * optional AgentStatus agent_status = 1;
 * @return {?proto.da.proto.AgentStatus}
 */
proto.da.proto.AgentStatusResponse.prototype.getAgentStatus = function() {
  return /** @type{?proto.da.proto.AgentStatus} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.AgentStatus, 1));
};


/**
 * @param {?proto.da.proto.AgentStatus|undefined} value
 * @return {!proto.da.proto.AgentStatusResponse} returns this
*/
proto.da.proto.AgentStatusResponse.prototype.setAgentStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.AgentStatusResponse} returns this
 */
proto.da.proto.AgentStatusResponse.prototype.clearAgentStatus = function() {
  return this.setAgentStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.AgentStatusResponse.prototype.hasAgentStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.StatusChangeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.StatusChangeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.StatusChangeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusChangeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusChange: (f = msg.getStatusChange()) && proto.da.proto.StatusChange.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.StatusChangeResponse}
 */
proto.da.proto.StatusChangeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.StatusChangeResponse;
  return proto.da.proto.StatusChangeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.StatusChangeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.StatusChangeResponse}
 */
proto.da.proto.StatusChangeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.StatusChange;
      reader.readMessage(value,proto.da.proto.StatusChange.deserializeBinaryFromReader);
      msg.setStatusChange(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.StatusChangeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.StatusChangeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.StatusChangeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusChangeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusChange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.StatusChange.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusChange status_change = 1;
 * @return {?proto.da.proto.StatusChange}
 */
proto.da.proto.StatusChangeResponse.prototype.getStatusChange = function() {
  return /** @type{?proto.da.proto.StatusChange} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.StatusChange, 1));
};


/**
 * @param {?proto.da.proto.StatusChange|undefined} value
 * @return {!proto.da.proto.StatusChangeResponse} returns this
*/
proto.da.proto.StatusChangeResponse.prototype.setStatusChange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.StatusChangeResponse} returns this
 */
proto.da.proto.StatusChangeResponse.prototype.clearStatusChange = function() {
  return this.setStatusChange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.StatusChangeResponse.prototype.hasStatusChange = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.da.proto);
