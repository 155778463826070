import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { subtleColors } from "../../../assets/data";
import { LineGraphProps } from "../../../types/DataTypes";
import { primaryBlack } from "../../../theme/theme";

interface BarGraphProps extends LineGraphProps {
  reload: boolean;
  labels: string[];
  series: number[];
}

const SimplePieChart = ({ reload, labels, series }: BarGraphProps) => {
  const [graphKey, setGraphKey] = useState(0);

  useEffect(() => {
    setGraphKey(graphKey + 1);
  }, [reload]);
  const options = {
    chart: {
      redrawOnParentResize: true,
    },

    dataLabels: {
      enabled: true,
      formatter: function (val: string) {
        return parseInt(`${val}`).toFixed(0) + "%";
      },
      style: {
        fontSize: "12px",
        colors: [primaryBlack],
        boxShadow: false,
      },
    },
    xAxis: {
      enabled: false,
    },

    colors: labels.map((_: string, index: number) => subtleColors[index]),

    fill: {
      type: "solid",
    },
    labels: labels,
  };

  return (
    <ReactApexChart
      key={graphKey}
      options={options}
      series={series}
      type={"pie"}
      height={"100%"}
      width={"100%"}
    />
  );
};

export default SimplePieChart;
