import { SET_LOGGED_IN } from "../actions/actions";


const LoggedIn = (state: boolean = false, action: any) => {
    switch (action.type) {
        case SET_LOGGED_IN:
            return action.payload;
        default:
            return state;
    }
}
export default LoggedIn;