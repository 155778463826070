import { useDisclosure } from "@chakra-ui/react";
import { RpcError } from "grpc-web";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import { BiGitBranch } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../../../../gateway/Functions";
import {
  CreateDepartmentResponse,
  Department,
} from "../../../../gateway/gRPC/pb/department_pb";
import { User } from "../../../../gateway/gRPC/pb/user_pb";
import { gRPCCreateDepartment } from "../../../../gateway/gRPC/Utils/BranchMethods";
import strings from "../../../../localization/main";
import { updateTables } from "../../../../redux/actions/actions";
import CustomButton from "../../../buttons/CustomButton";
import MyModal from "../../../general/MyModal";
import NewDepartmentForm from "./NewDepartmentForm";

interface Props {
  iconOnly?: boolean;
}

function AddNewDepartment({ iconOnly }: Props) {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const user: User.AsObject = useSelector((state: any) => state.Profile);

  const onSubmit: SubmitHandler<Department.AsObject> = (
    data: Department.AsObject
  ) => {
    gRPCCreateDepartment(data)
      .then((res: CreateDepartmentResponse.AsObject) => {
        dispatch(updateTables());
        notify(
          "success",
          "bottomLeft",
          strings.success,
          strings.added_department_successfully
        );
      })
      .catch((error: RpcError) => {
        console.error("Error creating department:", error);
        notify("error", "bottomLeft", "Error", strings.error_adding_department);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };
  return (
    <>
      <CustomButton
        tooltipLabel={strings.add_department}
        hasArrow
        colorScheme={"gray"}
        fontSize={"xs"}
        onClick={onOpen}
      >
        <BiGitBranch /> {!iconOnly && strings.add_department}
      </CustomButton>
      <MyModal
        size={"3xl"}
        closeOnOverlayClick={false}
        disclosure={{ isOpen, onClose, onOpen }}
        scrollBehavior={"inside"}
        title={strings.add_department}
      >
        <NewDepartmentForm
          loading={loading}
          onSubmit={onSubmit}
          title={strings.add_department}
          onClose={onClose}
        />
      </MyModal>
    </>
  );
}

export default AddNewDepartment;
