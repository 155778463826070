import React from "react";
import { Grid, Tooltip } from "@chakra-ui/react";

interface IconButtonProps {
	icon: React.ReactNode;
	onClick?: () => void;
	fluid?: boolean;
	[key: string]: any;
}
const IconButton = ({
	icon,
	fluid,
	onClick,
	placement,
	title,
	...rest
}: IconButtonProps) => {
	const handleOnClick = () => {
		if (onClick) {
			onClick();
		}
	};
	return (
		<Tooltip label={title} placement={placement ?? "bottom"} hasArrow>
			<Grid
				w={fluid ? "auto" : "30px"}
				h={fluid ? "auto" : "30px"}
				bg={rest.bg ?? "gray.100"}
				color={"gray.600"}
				p={fluid ? ".2rem" : ".5rem"}
				fontSize={"md"}
				borderRadius={"3px"}
				placeItems={"center"}
				cursor={"pointer"}
				onClick={handleOnClick}
				_hover={{
					transform: "scale(1.1)",
					transition: "all 0.2s ease-in-out",
					bg: rest.bgh ?? "gray.300",
				}}
				{...rest}
			>
				{icon}
			</Grid>
		</Tooltip>
	);
};

export default IconButton;
