/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: apps.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./apps_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AppServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AppServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateAppRequest,
 *   !proto.da.proto.CreateAppResponse>}
 */
const methodDescriptor_AppService_CreateApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/CreateApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateAppRequest,
  proto.da.proto.CreateAppResponse,
  /**
   * @param {!proto.da.proto.CreateAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.createApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/CreateApp',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateApp,
      callback);
};


/**
 * @param {!proto.da.proto.CreateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.createApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/CreateApp',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAppRequest,
 *   !proto.da.proto.ReadAppResponse>}
 */
const methodDescriptor_AppService_ReadApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/ReadApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAppRequest,
  proto.da.proto.ReadAppResponse,
  /**
   * @param {!proto.da.proto.ReadAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.readApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/ReadApp',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadApp,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.readApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/ReadApp',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAppsRequest,
 *   !proto.da.proto.ReadAppsResponse>}
 */
const methodDescriptor_AppService_ReadApps = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/ReadApps',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAppsRequest,
  proto.da.proto.ReadAppsResponse,
  /**
   * @param {!proto.da.proto.ReadAppsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAppsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAppsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAppsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAppsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.readApps =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/ReadApps',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadApps,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAppsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAppsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.readApps =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/ReadApps',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadApps);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateAppRequest,
 *   !proto.da.proto.UpdateAppResponse>}
 */
const methodDescriptor_AppService_UpdateApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/UpdateApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateAppRequest,
  proto.da.proto.UpdateAppResponse,
  /**
   * @param {!proto.da.proto.UpdateAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.updateApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/UpdateApp',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateApp,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.updateApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/UpdateApp',
      request,
      metadata || {},
      methodDescriptor_AppService_UpdateApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteAppRequest,
 *   !proto.da.proto.DeleteAppResponse>}
 */
const methodDescriptor_AppService_DeleteApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/DeleteApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteAppRequest,
  proto.da.proto.DeleteAppResponse,
  /**
   * @param {!proto.da.proto.DeleteAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.deleteApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/DeleteApp',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteApp,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.deleteApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/DeleteApp',
      request,
      metadata || {},
      methodDescriptor_AppService_DeleteApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateAppRequest,
 *   !proto.da.proto.UpdateAppResponse>}
 */
const methodDescriptor_AppService_RegenerateAppKeyAndSecret = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/RegenerateAppKeyAndSecret',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateAppRequest,
  proto.da.proto.UpdateAppResponse,
  /**
   * @param {!proto.da.proto.UpdateAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.regenerateAppKeyAndSecret =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/RegenerateAppKeyAndSecret',
      request,
      metadata || {},
      methodDescriptor_AppService_RegenerateAppKeyAndSecret,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.regenerateAppKeyAndSecret =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/RegenerateAppKeyAndSecret',
      request,
      metadata || {},
      methodDescriptor_AppService_RegenerateAppKeyAndSecret);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ApproveAppRequest,
 *   !proto.da.proto.ApproveAppResponse>}
 */
const methodDescriptor_AppService_ApproveApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/ApproveApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ApproveAppRequest,
  proto.da.proto.ApproveAppResponse,
  /**
   * @param {!proto.da.proto.ApproveAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ApproveAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ApproveAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ApproveAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ApproveAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.approveApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/ApproveApp',
      request,
      metadata || {},
      methodDescriptor_AppService_ApproveApp,
      callback);
};


/**
 * @param {!proto.da.proto.ApproveAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ApproveAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.approveApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/ApproveApp',
      request,
      metadata || {},
      methodDescriptor_AppService_ApproveApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ApproveAppRequest,
 *   !proto.da.proto.ApproveAppResponse>}
 */
const methodDescriptor_AppService_RejectApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/RejectApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ApproveAppRequest,
  proto.da.proto.ApproveAppResponse,
  /**
   * @param {!proto.da.proto.ApproveAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ApproveAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ApproveAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ApproveAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ApproveAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.rejectApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/RejectApp',
      request,
      metadata || {},
      methodDescriptor_AppService_RejectApp,
      callback);
};


/**
 * @param {!proto.da.proto.ApproveAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ApproveAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.rejectApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/RejectApp',
      request,
      metadata || {},
      methodDescriptor_AppService_RejectApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateTemplateAppRequest,
 *   !proto.da.proto.CreateTemplateAppResponse>}
 */
const methodDescriptor_AppService_CreateTemplateApp = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/CreateTemplateApp',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateTemplateAppRequest,
  proto.da.proto.CreateTemplateAppResponse,
  /**
   * @param {!proto.da.proto.CreateTemplateAppRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateTemplateAppResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateTemplateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateTemplateAppResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateTemplateAppResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.createTemplateApp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/CreateTemplateApp',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateTemplateApp,
      callback);
};


/**
 * @param {!proto.da.proto.CreateTemplateAppRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateTemplateAppResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.createTemplateApp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/CreateTemplateApp',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateTemplateApp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateAppWithTemplateRequest,
 *   !proto.da.proto.CreateAppWithTemplateResponse>}
 */
const methodDescriptor_AppService_CreateAppWithTemplate = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/CreateAppWithTemplate',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateAppWithTemplateRequest,
  proto.da.proto.CreateAppWithTemplateResponse,
  /**
   * @param {!proto.da.proto.CreateAppWithTemplateRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateAppWithTemplateResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateAppWithTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateAppWithTemplateResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateAppWithTemplateResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.createAppWithTemplate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/CreateAppWithTemplate',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateAppWithTemplate,
      callback);
};


/**
 * @param {!proto.da.proto.CreateAppWithTemplateRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateAppWithTemplateResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.createAppWithTemplate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/CreateAppWithTemplate',
      request,
      metadata || {},
      methodDescriptor_AppService_CreateAppWithTemplate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAppTemplatesRequest,
 *   !proto.da.proto.ReadAppTemplatesResponse>}
 */
const methodDescriptor_AppService_ReadAppTemplates = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/ReadAppTemplates',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAppTemplatesRequest,
  proto.da.proto.ReadAppTemplatesResponse,
  /**
   * @param {!proto.da.proto.ReadAppTemplatesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAppTemplatesResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAppTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAppTemplatesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAppTemplatesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.readAppTemplates =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/ReadAppTemplates',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadAppTemplates,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAppTemplatesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAppTemplatesResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.readAppTemplates =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/ReadAppTemplates',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadAppTemplates);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadConsoleAppsRequest,
 *   !proto.da.proto.ReadConsoleAppsResponse>}
 */
const methodDescriptor_AppService_ReadConsoleApps = new grpc.web.MethodDescriptor(
  '/da.proto.AppService/ReadConsoleApps',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadConsoleAppsRequest,
  proto.da.proto.ReadConsoleAppsResponse,
  /**
   * @param {!proto.da.proto.ReadConsoleAppsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadConsoleAppsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadConsoleAppsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadConsoleAppsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadConsoleAppsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AppServiceClient.prototype.readConsoleApps =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AppService/ReadConsoleApps',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadConsoleApps,
      callback);
};


/**
 * @param {!proto.da.proto.ReadConsoleAppsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadConsoleAppsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AppServicePromiseClient.prototype.readConsoleApps =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AppService/ReadConsoleApps',
      request,
      metadata || {},
      methodDescriptor_AppService_ReadConsoleApps);
};


module.exports = proto.da.proto;

