import { SessionState } from "sip.js"
import { ActiveCallState, ActiveCallStateAction } from "../../types/DataTypes"
import { REMOVE_ACTIVE_CALL, SET_ACTIVE_CALL, UPDATE_SESSION_STATE } from "../actions/actions"

const initialState: ActiveCallState = {
    call: null,
    notify: false,
    direction: "",
    sessionState: SessionState.Initial,
}
export default function ActiveCall(state: ActiveCallState = initialState, action: ActiveCallStateAction): ActiveCallState {
    switch (action.type) {
        case SET_ACTIVE_CALL:
            return { ...action.payload }
        case REMOVE_ACTIVE_CALL:
            return { ...initialState }
            case UPDATE_SESSION_STATE:
            return { ...state, sessionState: action.payload.sessionState }
        default:
            return state
    }
}
