import {RESET_FLOW, SELECT_FLOW} from "../actions/actions";

const SelectFlow = (state: string = "", action: any) => {
    switch (action.type) {
        case SELECT_FLOW:
            return action.payload;
        case RESET_FLOW:
            return "";
        default:
            return state;
    }
}
export default SelectFlow;