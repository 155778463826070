import React from 'react';
import CustomButton from "./CustomButton";
import strings from "../../localization/main";

interface FormCancelButtonProps {
    onClick: () => void
    [key: string]: any
}

function FormCancelButton({onClick,...rest}: FormCancelButtonProps) {
    return (
        <CustomButton
            onClick={() => onClick()}
            textTransform={"capitalize"}
            mr={".5rem"} variant={"outline"}
            {...rest}
        >
            {`${strings.cancel}`}
        </CustomButton>
    );
}

export default FormCancelButton;