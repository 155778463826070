import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";
import { borderColor, gray_50, primaryWhite } from "../../theme/theme";
import { BongaCardProps } from "../../types/DataTypes";

interface Props extends BongaCardProps {
  header?: ReactNode;
  footer?: ReactNode;
}
const BongaCard = ({ children, header, footer, ...rest }: Props) => {
  return (
    <Box
      borderRadius={"3px"}
      border={`1px solid ${borderColor}`}
      bg={primaryWhite}
      h={"stretch"}
      {...rest}
    >
      {header && (
        <Box
          borderRadius={"3px 3px 0 0"}
          borderBottom={`1px solid ${borderColor}`}
          bg={gray_50}
          p={".5rem"}
        >
          {header}
        </Box>
      )}
      <Box p={".5rem"}>{children}</Box>
      {footer && (
        <Box
          borderRadius={"0 0 3px 3px"}
          borderTop={`1px solid ${borderColor}`}
          bg={gray_50}
          p={".5rem"}
        >
          {footer}
        </Box>
      )}
    </Box>
  );
};

export default BongaCard;
