/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: organisations.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.proto = require('./organisations_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.OrganisationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.OrganisationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateOrganisationRequest,
 *   !proto.da.proto.CreateOrganisationResponse>}
 */
const methodDescriptor_OrganisationService_CreateOrganisation = new grpc.web.MethodDescriptor(
  '/da.proto.OrganisationService/CreateOrganisation',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateOrganisationRequest,
  proto.da.proto.CreateOrganisationResponse,
  /**
   * @param {!proto.da.proto.CreateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrganisationServiceClient.prototype.createOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrganisationService/CreateOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_CreateOrganisation,
      callback);
};


/**
 * @param {!proto.da.proto.CreateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrganisationServicePromiseClient.prototype.createOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrganisationService/CreateOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_CreateOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadOrganisationRequest,
 *   !proto.da.proto.ReadOrganisationResponse>}
 */
const methodDescriptor_OrganisationService_ReadOrganisation = new grpc.web.MethodDescriptor(
  '/da.proto.OrganisationService/ReadOrganisation',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadOrganisationRequest,
  proto.da.proto.ReadOrganisationResponse,
  /**
   * @param {!proto.da.proto.ReadOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrganisationServiceClient.prototype.readOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrganisationService/ReadOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ReadOrganisation,
      callback);
};


/**
 * @param {!proto.da.proto.ReadOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrganisationServicePromiseClient.prototype.readOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrganisationService/ReadOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ReadOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadOrganisationsRequest,
 *   !proto.da.proto.ReadOrganisationsResponse>}
 */
const methodDescriptor_OrganisationService_ReadOrganisations = new grpc.web.MethodDescriptor(
  '/da.proto.OrganisationService/ReadOrganisations',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadOrganisationsRequest,
  proto.da.proto.ReadOrganisationsResponse,
  /**
   * @param {!proto.da.proto.ReadOrganisationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadOrganisationsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadOrganisationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadOrganisationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrganisationServiceClient.prototype.readOrganisations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrganisationService/ReadOrganisations',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ReadOrganisations,
      callback);
};


/**
 * @param {!proto.da.proto.ReadOrganisationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadOrganisationsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrganisationServicePromiseClient.prototype.readOrganisations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrganisationService/ReadOrganisations',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_ReadOrganisations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateOrganisationRequest,
 *   !proto.da.proto.UpdateOrganisationResponse>}
 */
const methodDescriptor_OrganisationService_UpdateOrganisation = new grpc.web.MethodDescriptor(
  '/da.proto.OrganisationService/UpdateOrganisation',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateOrganisationRequest,
  proto.da.proto.UpdateOrganisationResponse,
  /**
   * @param {!proto.da.proto.UpdateOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrganisationServiceClient.prototype.updateOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrganisationService/UpdateOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_UpdateOrganisation,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrganisationServicePromiseClient.prototype.updateOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrganisationService/UpdateOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_UpdateOrganisation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteOrganisationRequest,
 *   !proto.da.proto.DeleteOrganisationResponse>}
 */
const methodDescriptor_OrganisationService_DeleteOrganisation = new grpc.web.MethodDescriptor(
  '/da.proto.OrganisationService/DeleteOrganisation',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteOrganisationRequest,
  proto.da.proto.DeleteOrganisationResponse,
  /**
   * @param {!proto.da.proto.DeleteOrganisationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteOrganisationResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteOrganisationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteOrganisationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrganisationServiceClient.prototype.deleteOrganisation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrganisationService/DeleteOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_DeleteOrganisation,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteOrganisationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteOrganisationResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrganisationServicePromiseClient.prototype.deleteOrganisation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrganisationService/DeleteOrganisation',
      request,
      metadata || {},
      methodDescriptor_OrganisationService_DeleteOrganisation);
};


module.exports = proto.da.proto;

