import * as grpc from "grpc-web";
import {
  questionService,
  responseService,
  sectionService,
  surveyService,
} from "../globalClient/GlobalClients";
import {
  CreateQuestionRequest,
  CreateQuestionResponse,
  CreateResponseRequest,
  CreateResponseResponse,
  CreateSectionRequest,
  CreateSectionResponse,
  CreateSurveyRequest,
  CreateSurveyResponse,
  DeleteQuestionRequest,
  DeleteQuestionResponse,
  DeleteSectionRequest,
  DeleteSectionResponse,
  DeleteSurveyRequest,
  DeleteSurveyResponse,
  ReadAnalyticsRequest,
  ReadAnalyticsResponse,
  ReadResponseCSVRequest,
  ReadResponseCSVResponse,
  Question,
  ReadResponseResponse,
  ReadSurveyListRequest,
  ReadSurveyListResponse,
  ReadSurveyRequest,
  ReadSurveyResponse,
  Response,
  ResponseListRequest,
  ResponseListResponse,
  Section,
  Survey,
  UpdateQuestionRequest,
  UpdateQuestionResponse,
} from "../pb/surveys_pb";
import {
  formatQuestionJsonToQuestionClass,
  formatResponseJsonToResponseClass,
  formatSectionJsonToSectionClass,
  formatSurveyJsonToSurveyClass,
} from "./SurveyUtils";

export const getSurveyList = async (
  org: string,
): Promise<ReadSurveyListResponse.AsObject> => {
  const request = new ReadSurveyListRequest();
  request.setOrganisation(org);
  const res = new Promise<ReadSurveyListResponse.AsObject>(
    (resolve, reject) => {
      surveyService.readSurveyList(
        request,
        {
          "Content-Type": "application/grpc-web+proto",
        },
        (err: grpc.RpcError, response: ReadSurveyListResponse) => {
          if (err) {
            reject(err);
          }
          resolve(response?.toObject());
        },
      );
    },
  );
  return res;
};

export const createSurvey = async (
  data: Survey.AsObject,
): Promise<CreateSurveyResponse.AsObject> => {
  const request = new CreateSurveyRequest();
  request.setSurvey(formatSurveyJsonToSurveyClass(data));
  const res = new Promise<CreateSurveyResponse.AsObject>((resolve, reject) => {
    surveyService.createSurvey(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: CreateSurveyResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response?.toObject());
      },
    );
  });
  return res;
};

export const getSurveyById = async (
  id: string,
): Promise<ReadSurveyResponse.AsObject> => {
  const request = new ReadSurveyRequest();
  request.setId(id);
  const res = new Promise<ReadSurveyResponse.AsObject>((resolve, reject) => {
    surveyService.readSurvey(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: ReadSurveyResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response?.toObject());
      },
    );
  });
  return res;
};

export const createSurveyResponse = async (
  data: Response.AsObject,
): Promise<CreateResponseResponse.AsObject> => {
  const request = new CreateResponseRequest();

  request.setResponse(formatResponseJsonToResponseClass(data));
  const res = new Promise<CreateResponseResponse.AsObject>(
    (resolve, reject) => {
      responseService.createResponse(
        // Request
        request,
        // Metadata
        {
          "Content-Type": "application/grpc-web+proto",
        },
        // Callback
        (err: grpc.RpcError, response: CreateResponseResponse) => {
          if (err) {
            reject(err);
          }
          resolve(response?.toObject());
        },
      );
    },
  );
  return res;
};

export const ReadResponseById = async (
  id: string,
): Promise<ReadResponseResponse.AsObject> => {
  const request = new ReadSurveyRequest();
  request.setId(id);
  const res = new Promise<ReadSurveyResponse.AsObject>((resolve, reject) => {
    responseService.readResponse(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: ReadResponseResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response?.toObject());
      },
    );
  });
  return res;
};

export const ReadResponseBySurveyId = async (
  surveyId: string,
): Promise<ResponseListResponse.AsObject> => {
  const request = new ResponseListRequest();
  request.setSurveyId(surveyId);
  const res = new Promise<ResponseListResponse.AsObject>((resolve, reject) => {
    responseService.readResponseList(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: ResponseListResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response?.toObject());
      },
    );
  });
  return res;
};

export const ReadResponseAnalytics = async (
  surveyId: string,
): Promise<ReadAnalyticsResponse.AsObject> => {
  let request = new ReadAnalyticsRequest();
  request.setSurveyId(surveyId);
  const res = new Promise<ReadAnalyticsResponse.AsObject>((resolve, reject) => {
    responseService.readResponseAnalytics(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: ReadAnalyticsResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response?.toObject());
      },
    );
  });
  return res;
};

export const getCSVStream = async (
  surveyId: string,
): Promise<ReadResponseCSVResponse[]> => {
  return new Promise<ReadResponseCSVResponse[]>((resolve, reject) => {
    let req = new ReadResponseCSVRequest();
    req.setSurveyId(surveyId);
    const res = responseService.readResponseCSV(req, {
      "Content-Type": "application/grpc-web+proto",
    });
    const responseArray: ReadResponseCSVResponse[] = [];
    res.on("data", (chunk: ReadResponseCSVResponse) => {
      responseArray.push(chunk);
    });
    res.on("end", () => {
      resolve(responseArray);
    });
    res.on("error", (error) => {
      reject(error);
    });
  });
};

export const deleteSurvey = (
  surveyId: string,
): Promise<DeleteSurveyResponse> => {
  const request = new DeleteSurveyRequest();
  request.setId(surveyId);
  const res = new Promise<DeleteSurveyResponse>((resolve, reject) => {
    surveyService.deleteSurvey(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: DeleteSurveyResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response);
      },
    );
  });
  return res;
};

export const createQuestion = (
  data: Question.AsObject,
): Promise<CreateQuestionResponse.AsObject> => {
  const request = new CreateQuestionRequest();
  request.setQuestion(formatQuestionJsonToQuestionClass(data));
  const res = new Promise<CreateQuestionResponse.AsObject>((resolve, reject) => {
    questionService.createQuestion(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: CreateQuestionResponse) => {
        if (err) {
          reject(err);
        }
        if(response.getStatus()?.getCode() === 200){
          resolve(response.toObject());
        }
      },
    );
  });
  return res;
}
export const deleteQuestion = (
  question: Question.AsObject
): Promise<DeleteQuestionResponse> => {
  const request = new DeleteQuestionRequest();
  request.setQuestion(formatQuestionJsonToQuestionClass(question))
  const res = new Promise<DeleteQuestionResponse>((resolve, reject) => {
    questionService.deleteQuestion(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: DeleteQuestionResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response);
      },
    );
  });
  return res;
};

export const updateQuestion = (
  data: Question.AsObject,
): Promise<UpdateQuestionResponse.AsObject> => {
  const request = new UpdateQuestionRequest();
  request.setQuestion(formatQuestionJsonToQuestionClass(data));
  const res = new Promise<UpdateQuestionResponse.AsObject>((resolve, reject) => {
    questionService.updateQuestion(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: UpdateQuestionResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response.toObject());
      },
    );
  });
  return res;
};

export const createSection = (
  section: Section.AsObject
): Promise<CreateSectionResponse.AsObject> => {
  const request = new CreateSectionRequest();
  request.setSection(formatSectionJsonToSectionClass(section));
  const res = new Promise<CreateSectionResponse.AsObject>((resolve, reject) => {
    sectionService.createSection(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: CreateSectionResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response.toObject());
      },
    );
  });
  return res;
}
export const deleteSection = (
  section: Section.AsObject
): Promise<DeleteQuestionResponse> => {
  let request = new DeleteSectionRequest();
  request.setSection(formatSectionJsonToSectionClass(section))

  const res = new Promise<DeleteSectionResponse>((resolve, reject) => {
    sectionService.deleteSection(
      // Request
      request,
      // Metadata
      {
        "Content-Type": "application/grpc-web+proto",
      },
      // Callback
      (err: grpc.RpcError, response: DeleteSectionResponse) => {
        if (err) {
          reject(err);
        }
        resolve(response);
      },
    );
  });
  return res;
};
