// source: whatsapp_campaigns.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.da.proto.AnalyticsCount', null, global);
goog.exportSymbol('proto.da.proto.CreateWhatsappCampaignRequest', null, global);
goog.exportSymbol('proto.da.proto.CreateWhatsappCampaignResponse', null, global);
goog.exportSymbol('proto.da.proto.CreateWhatsappTemplateRequest', null, global);
goog.exportSymbol('proto.da.proto.CreateWhatsappTemplateResponse', null, global);
goog.exportSymbol('proto.da.proto.ListWhatsappCampaignRequest', null, global);
goog.exportSymbol('proto.da.proto.ListWhatsappCampaignResponse', null, global);
goog.exportSymbol('proto.da.proto.ListWhatsappTemplateRequest', null, global);
goog.exportSymbol('proto.da.proto.ListWhatsappTemplateResponse', null, global);
goog.exportSymbol('proto.da.proto.MessageStatusEvent', null, global);
goog.exportSymbol('proto.da.proto.StatusEvent', null, global);
goog.exportSymbol('proto.da.proto.UpdateWhatsappAnalyticsRequest', null, global);
goog.exportSymbol('proto.da.proto.UpdateWhatsappAnalyticsResponse', null, global);
goog.exportSymbol('proto.da.proto.UpdateWhatsappTemplateRequest', null, global);
goog.exportSymbol('proto.da.proto.UpdateWhatsappTemplateResponse', null, global);
goog.exportSymbol('proto.da.proto.ViewWhatsappCampaignAnalyticsRequest', null, global);
goog.exportSymbol('proto.da.proto.ViewWhatsappCampaignAnalyticsResponse', null, global);
goog.exportSymbol('proto.da.proto.ViewWhatsappCampaignRequest', null, global);
goog.exportSymbol('proto.da.proto.ViewWhatsappCampaignResponse', null, global);
goog.exportSymbol('proto.da.proto.ViewWhatsappTemplateRequest', null, global);
goog.exportSymbol('proto.da.proto.ViewWhatsappTemplateResponse', null, global);
goog.exportSymbol('proto.da.proto.WhatsappAnalytics', null, global);
goog.exportSymbol('proto.da.proto.WhatsappCampaign', null, global);
goog.exportSymbol('proto.da.proto.WhatsappTemplate', null, global);
goog.exportSymbol('proto.da.proto.WhatsappTemplateStatusUpdateRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.WhatsappTemplate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.WhatsappTemplate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.WhatsappTemplate.displayName = 'proto.da.proto.WhatsappTemplate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.WhatsappCampaign = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.WhatsappCampaign, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.WhatsappCampaign.displayName = 'proto.da.proto.WhatsappCampaign';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.StatusEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.StatusEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.StatusEvent.displayName = 'proto.da.proto.StatusEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.MessageStatusEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.MessageStatusEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.MessageStatusEvent.displayName = 'proto.da.proto.MessageStatusEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.WhatsappAnalytics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.WhatsappAnalytics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.WhatsappAnalytics.displayName = 'proto.da.proto.WhatsappAnalytics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.AnalyticsCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.AnalyticsCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.AnalyticsCount.displayName = 'proto.da.proto.AnalyticsCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateWhatsappTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateWhatsappTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateWhatsappTemplateRequest.displayName = 'proto.da.proto.CreateWhatsappTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateWhatsappTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateWhatsappTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateWhatsappTemplateResponse.displayName = 'proto.da.proto.CreateWhatsappTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ViewWhatsappTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ViewWhatsappTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ViewWhatsappTemplateRequest.displayName = 'proto.da.proto.ViewWhatsappTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ViewWhatsappTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ViewWhatsappTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ViewWhatsappTemplateResponse.displayName = 'proto.da.proto.ViewWhatsappTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ListWhatsappTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ListWhatsappTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ListWhatsappTemplateRequest.displayName = 'proto.da.proto.ListWhatsappTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ListWhatsappTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ListWhatsappTemplateResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ListWhatsappTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ListWhatsappTemplateResponse.displayName = 'proto.da.proto.ListWhatsappTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateWhatsappTemplateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateWhatsappTemplateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateWhatsappTemplateRequest.displayName = 'proto.da.proto.UpdateWhatsappTemplateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateWhatsappTemplateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateWhatsappTemplateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateWhatsappTemplateResponse.displayName = 'proto.da.proto.UpdateWhatsappTemplateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateWhatsappCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateWhatsappCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateWhatsappCampaignRequest.displayName = 'proto.da.proto.CreateWhatsappCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateWhatsappCampaignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateWhatsappCampaignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateWhatsappCampaignResponse.displayName = 'proto.da.proto.CreateWhatsappCampaignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ViewWhatsappCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ViewWhatsappCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ViewWhatsappCampaignRequest.displayName = 'proto.da.proto.ViewWhatsappCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ViewWhatsappCampaignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ViewWhatsappCampaignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ViewWhatsappCampaignResponse.displayName = 'proto.da.proto.ViewWhatsappCampaignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ListWhatsappCampaignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ListWhatsappCampaignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ListWhatsappCampaignRequest.displayName = 'proto.da.proto.ListWhatsappCampaignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ListWhatsappCampaignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ListWhatsappCampaignResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ListWhatsappCampaignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ListWhatsappCampaignResponse.displayName = 'proto.da.proto.ListWhatsappCampaignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.WhatsappTemplateStatusUpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.WhatsappTemplateStatusUpdateRequest.displayName = 'proto.da.proto.WhatsappTemplateStatusUpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ViewWhatsappCampaignAnalyticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.displayName = 'proto.da.proto.ViewWhatsappCampaignAnalyticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ViewWhatsappCampaignAnalyticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.displayName = 'proto.da.proto.ViewWhatsappCampaignAnalyticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateWhatsappAnalyticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateWhatsappAnalyticsRequest.displayName = 'proto.da.proto.UpdateWhatsappAnalyticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateWhatsappAnalyticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateWhatsappAnalyticsResponse.displayName = 'proto.da.proto.UpdateWhatsappAnalyticsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.WhatsappTemplate.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.WhatsappTemplate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.WhatsappTemplate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappTemplate.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: jspb.Message.getFieldWithDefault(msg, 3, ""),
    language: jspb.Message.getFieldWithDefault(msg, 4, ""),
    category: jspb.Message.getFieldWithDefault(msg, 5, ""),
    header: jspb.Message.getFieldWithDefault(msg, 6, ""),
    footer: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    integrationId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 11, ""),
    phoneNumberId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    wabaId: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.WhatsappTemplate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.WhatsappTemplate;
  return proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.WhatsappTemplate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBody(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeader(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFooter(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIntegrationId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhoneNumberId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setWabaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.WhatsappTemplate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.WhatsappTemplate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.WhatsappTemplate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappTemplate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHeader();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFooter();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIntegrationId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPhoneNumberId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getWabaId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string body = 3;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getBody = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setBody = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string language = 4;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string category = 5;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string header = 6;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getHeader = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setHeader = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string footer = 7;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getFooter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setFooter = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string integration_id = 9;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getIntegrationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setIntegrationId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string template_id = 10;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string reason = 11;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string phone_number_id = 12;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getPhoneNumberId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setPhoneNumberId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string waba_id = 13;
 * @return {string}
 */
proto.da.proto.WhatsappTemplate.prototype.getWabaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappTemplate} returns this
 */
proto.da.proto.WhatsappTemplate.prototype.setWabaId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.WhatsappCampaign.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.WhatsappCampaign.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.WhatsappCampaign} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappCampaign.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    startDate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endDate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contacts: jspb.Message.getFieldWithDefault(msg, 5, ""),
    frequency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    templateId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.WhatsappCampaign}
 */
proto.da.proto.WhatsappCampaign.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.WhatsappCampaign;
  return proto.da.proto.WhatsappCampaign.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.WhatsappCampaign} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.WhatsappCampaign}
 */
proto.da.proto.WhatsappCampaign.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndDate(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContacts(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFrequency(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTemplateId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.WhatsappCampaign.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.WhatsappCampaign.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.WhatsappCampaign} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappCampaign.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStartDate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndDate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContacts();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFrequency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTemplateId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start_date = 3;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getStartDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setStartDate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string end_date = 4;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getEndDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setEndDate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contacts = 5;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getContacts = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setContacts = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string frequency = 6;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getFrequency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string template_id = 8;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.da.proto.WhatsappCampaign.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappCampaign} returns this
 */
proto.da.proto.WhatsappCampaign.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.StatusEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.StatusEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.StatusEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: jspb.Message.getFieldWithDefault(msg, 1, ""),
    messageTemplateId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    messageTemplateLanguage: jspb.Message.getFieldWithDefault(msg, 3, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.StatusEvent}
 */
proto.da.proto.StatusEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.StatusEvent;
  return proto.da.proto.StatusEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.StatusEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.StatusEvent}
 */
proto.da.proto.StatusEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageTemplateId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageTemplateLanguage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.StatusEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.StatusEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.StatusEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.StatusEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessageTemplateId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessageTemplateLanguage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string event = 1;
 * @return {string}
 */
proto.da.proto.StatusEvent.prototype.getEvent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusEvent} returns this
 */
proto.da.proto.StatusEvent.prototype.setEvent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message_template_id = 2;
 * @return {string}
 */
proto.da.proto.StatusEvent.prototype.getMessageTemplateId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusEvent} returns this
 */
proto.da.proto.StatusEvent.prototype.setMessageTemplateId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message_template_language = 3;
 * @return {string}
 */
proto.da.proto.StatusEvent.prototype.getMessageTemplateLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusEvent} returns this
 */
proto.da.proto.StatusEvent.prototype.setMessageTemplateLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reason = 4;
 * @return {string}
 */
proto.da.proto.StatusEvent.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.StatusEvent} returns this
 */
proto.da.proto.StatusEvent.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.MessageStatusEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.MessageStatusEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.MessageStatusEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.MessageStatusEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    waId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timestamp: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.MessageStatusEvent}
 */
proto.da.proto.MessageStatusEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.MessageStatusEvent;
  return proto.da.proto.MessageStatusEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.MessageStatusEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.MessageStatusEvent}
 */
proto.da.proto.MessageStatusEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimestamp(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.MessageStatusEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.MessageStatusEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.MessageStatusEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.MessageStatusEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWaId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimestamp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string wa_id = 1;
 * @return {string}
 */
proto.da.proto.MessageStatusEvent.prototype.getWaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.MessageStatusEvent} returns this
 */
proto.da.proto.MessageStatusEvent.prototype.setWaId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string timestamp = 2;
 * @return {string}
 */
proto.da.proto.MessageStatusEvent.prototype.getTimestamp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.MessageStatusEvent} returns this
 */
proto.da.proto.MessageStatusEvent.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.da.proto.MessageStatusEvent.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.MessageStatusEvent} returns this
 */
proto.da.proto.MessageStatusEvent.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.WhatsappAnalytics.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.WhatsappAnalytics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.WhatsappAnalytics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappAnalytics.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contact: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sentAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deliveredAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    readAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reason: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sent: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    read: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    delivered: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    failed: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    waId: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.WhatsappAnalytics}
 */
proto.da.proto.WhatsappAnalytics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.WhatsappAnalytics;
  return proto.da.proto.WhatsappAnalytics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.WhatsappAnalytics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.WhatsappAnalytics}
 */
proto.da.proto.WhatsappAnalytics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContact(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSentAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeliveredAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReadAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSent(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRead(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDelivered(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFailed(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setWaId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.WhatsappAnalytics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.WhatsappAnalytics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.WhatsappAnalytics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappAnalytics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContact();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSentAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeliveredAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReadAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSent();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRead();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDelivered();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getFailed();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getWaId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contact = 2;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getContact = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setContact = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sent_at = 4;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getSentAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setSentAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string delivered_at = 5;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getDeliveredAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setDeliveredAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string read_at = 6;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getReadAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setReadAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string reason = 7;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool sent = 8;
 * @return {boolean}
 */
proto.da.proto.WhatsappAnalytics.prototype.getSent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setSent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool read = 9;
 * @return {boolean}
 */
proto.da.proto.WhatsappAnalytics.prototype.getRead = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setRead = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool delivered = 10;
 * @return {boolean}
 */
proto.da.proto.WhatsappAnalytics.prototype.getDelivered = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setDelivered = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool failed = 11;
 * @return {boolean}
 */
proto.da.proto.WhatsappAnalytics.prototype.getFailed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setFailed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string wa_id = 12;
 * @return {string}
 */
proto.da.proto.WhatsappAnalytics.prototype.getWaId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.WhatsappAnalytics} returns this
 */
proto.da.proto.WhatsappAnalytics.prototype.setWaId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.AnalyticsCount.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.AnalyticsCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.AnalyticsCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AnalyticsCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    sent: jspb.Message.getFieldWithDefault(msg, 1, 0),
    delivered: jspb.Message.getFieldWithDefault(msg, 2, 0),
    read: jspb.Message.getFieldWithDefault(msg, 3, 0),
    failed: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.AnalyticsCount}
 */
proto.da.proto.AnalyticsCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.AnalyticsCount;
  return proto.da.proto.AnalyticsCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.AnalyticsCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.AnalyticsCount}
 */
proto.da.proto.AnalyticsCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDelivered(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRead(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFailed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.AnalyticsCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.AnalyticsCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.AnalyticsCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.AnalyticsCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSent();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDelivered();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRead();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getFailed();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 sent = 1;
 * @return {number}
 */
proto.da.proto.AnalyticsCount.prototype.getSent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.AnalyticsCount} returns this
 */
proto.da.proto.AnalyticsCount.prototype.setSent = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 delivered = 2;
 * @return {number}
 */
proto.da.proto.AnalyticsCount.prototype.getDelivered = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.AnalyticsCount} returns this
 */
proto.da.proto.AnalyticsCount.prototype.setDelivered = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 read = 3;
 * @return {number}
 */
proto.da.proto.AnalyticsCount.prototype.getRead = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.AnalyticsCount} returns this
 */
proto.da.proto.AnalyticsCount.prototype.setRead = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 failed = 4;
 * @return {number}
 */
proto.da.proto.AnalyticsCount.prototype.getFailed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.AnalyticsCount} returns this
 */
proto.da.proto.AnalyticsCount.prototype.setFailed = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateWhatsappTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateWhatsappTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateWhatsappTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && proto.da.proto.WhatsappTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateWhatsappTemplateRequest}
 */
proto.da.proto.CreateWhatsappTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateWhatsappTemplateRequest;
  return proto.da.proto.CreateWhatsappTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateWhatsappTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateWhatsappTemplateRequest}
 */
proto.da.proto.CreateWhatsappTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappTemplate;
      reader.readMessage(value,proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateWhatsappTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateWhatsappTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateWhatsappTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappTemplate template = 1;
 * @return {?proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.CreateWhatsappTemplateRequest.prototype.getTemplate = function() {
  return /** @type{?proto.da.proto.WhatsappTemplate} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappTemplate, 1));
};


/**
 * @param {?proto.da.proto.WhatsappTemplate|undefined} value
 * @return {!proto.da.proto.CreateWhatsappTemplateRequest} returns this
*/
proto.da.proto.CreateWhatsappTemplateRequest.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateWhatsappTemplateRequest} returns this
 */
proto.da.proto.CreateWhatsappTemplateRequest.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateWhatsappTemplateRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateWhatsappTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateWhatsappTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateWhatsappTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && proto.da.proto.WhatsappTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateWhatsappTemplateResponse}
 */
proto.da.proto.CreateWhatsappTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateWhatsappTemplateResponse;
  return proto.da.proto.CreateWhatsappTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateWhatsappTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateWhatsappTemplateResponse}
 */
proto.da.proto.CreateWhatsappTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappTemplate;
      reader.readMessage(value,proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateWhatsappTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateWhatsappTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateWhatsappTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappTemplate template = 1;
 * @return {?proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.CreateWhatsappTemplateResponse.prototype.getTemplate = function() {
  return /** @type{?proto.da.proto.WhatsappTemplate} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappTemplate, 1));
};


/**
 * @param {?proto.da.proto.WhatsappTemplate|undefined} value
 * @return {!proto.da.proto.CreateWhatsappTemplateResponse} returns this
*/
proto.da.proto.CreateWhatsappTemplateResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateWhatsappTemplateResponse} returns this
 */
proto.da.proto.CreateWhatsappTemplateResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateWhatsappTemplateResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ViewWhatsappTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ViewWhatsappTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ViewWhatsappTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ViewWhatsappTemplateRequest}
 */
proto.da.proto.ViewWhatsappTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ViewWhatsappTemplateRequest;
  return proto.da.proto.ViewWhatsappTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ViewWhatsappTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ViewWhatsappTemplateRequest}
 */
proto.da.proto.ViewWhatsappTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ViewWhatsappTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ViewWhatsappTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ViewWhatsappTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.ViewWhatsappTemplateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ViewWhatsappTemplateRequest} returns this
 */
proto.da.proto.ViewWhatsappTemplateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ViewWhatsappTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ViewWhatsappTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ViewWhatsappTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && proto.da.proto.WhatsappTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ViewWhatsappTemplateResponse}
 */
proto.da.proto.ViewWhatsappTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ViewWhatsappTemplateResponse;
  return proto.da.proto.ViewWhatsappTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ViewWhatsappTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ViewWhatsappTemplateResponse}
 */
proto.da.proto.ViewWhatsappTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappTemplate;
      reader.readMessage(value,proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ViewWhatsappTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ViewWhatsappTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ViewWhatsappTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappTemplate template = 1;
 * @return {?proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.ViewWhatsappTemplateResponse.prototype.getTemplate = function() {
  return /** @type{?proto.da.proto.WhatsappTemplate} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappTemplate, 1));
};


/**
 * @param {?proto.da.proto.WhatsappTemplate|undefined} value
 * @return {!proto.da.proto.ViewWhatsappTemplateResponse} returns this
*/
proto.da.proto.ViewWhatsappTemplateResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.ViewWhatsappTemplateResponse} returns this
 */
proto.da.proto.ViewWhatsappTemplateResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.ViewWhatsappTemplateResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ListWhatsappTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ListWhatsappTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ListWhatsappTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ListWhatsappTemplateRequest}
 */
proto.da.proto.ListWhatsappTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ListWhatsappTemplateRequest;
  return proto.da.proto.ListWhatsappTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ListWhatsappTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ListWhatsappTemplateRequest}
 */
proto.da.proto.ListWhatsappTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ListWhatsappTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ListWhatsappTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ListWhatsappTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ListWhatsappTemplateResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ListWhatsappTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ListWhatsappTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ListWhatsappTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    templatesList: jspb.Message.toObjectList(msg.getTemplatesList(),
    proto.da.proto.WhatsappTemplate.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ListWhatsappTemplateResponse}
 */
proto.da.proto.ListWhatsappTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ListWhatsappTemplateResponse;
  return proto.da.proto.ListWhatsappTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ListWhatsappTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ListWhatsappTemplateResponse}
 */
proto.da.proto.ListWhatsappTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappTemplate;
      reader.readMessage(value,proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader);
      msg.addTemplates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ListWhatsappTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ListWhatsappTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ListWhatsappTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.WhatsappTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WhatsappTemplate templates = 1;
 * @return {!Array<!proto.da.proto.WhatsappTemplate>}
 */
proto.da.proto.ListWhatsappTemplateResponse.prototype.getTemplatesList = function() {
  return /** @type{!Array<!proto.da.proto.WhatsappTemplate>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.WhatsappTemplate, 1));
};


/**
 * @param {!Array<!proto.da.proto.WhatsappTemplate>} value
 * @return {!proto.da.proto.ListWhatsappTemplateResponse} returns this
*/
proto.da.proto.ListWhatsappTemplateResponse.prototype.setTemplatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.WhatsappTemplate=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.ListWhatsappTemplateResponse.prototype.addTemplates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.WhatsappTemplate, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ListWhatsappTemplateResponse} returns this
 */
proto.da.proto.ListWhatsappTemplateResponse.prototype.clearTemplatesList = function() {
  return this.setTemplatesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateWhatsappTemplateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateWhatsappTemplateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateWhatsappTemplateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappTemplateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && proto.da.proto.WhatsappTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateWhatsappTemplateRequest}
 */
proto.da.proto.UpdateWhatsappTemplateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateWhatsappTemplateRequest;
  return proto.da.proto.UpdateWhatsappTemplateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateWhatsappTemplateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateWhatsappTemplateRequest}
 */
proto.da.proto.UpdateWhatsappTemplateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappTemplate;
      reader.readMessage(value,proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateWhatsappTemplateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateWhatsappTemplateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateWhatsappTemplateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappTemplateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappTemplate template = 1;
 * @return {?proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.UpdateWhatsappTemplateRequest.prototype.getTemplate = function() {
  return /** @type{?proto.da.proto.WhatsappTemplate} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappTemplate, 1));
};


/**
 * @param {?proto.da.proto.WhatsappTemplate|undefined} value
 * @return {!proto.da.proto.UpdateWhatsappTemplateRequest} returns this
*/
proto.da.proto.UpdateWhatsappTemplateRequest.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateWhatsappTemplateRequest} returns this
 */
proto.da.proto.UpdateWhatsappTemplateRequest.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateWhatsappTemplateRequest.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateWhatsappTemplateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateWhatsappTemplateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateWhatsappTemplateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappTemplateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    template: (f = msg.getTemplate()) && proto.da.proto.WhatsappTemplate.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateWhatsappTemplateResponse}
 */
proto.da.proto.UpdateWhatsappTemplateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateWhatsappTemplateResponse;
  return proto.da.proto.UpdateWhatsappTemplateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateWhatsappTemplateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateWhatsappTemplateResponse}
 */
proto.da.proto.UpdateWhatsappTemplateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappTemplate;
      reader.readMessage(value,proto.da.proto.WhatsappTemplate.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateWhatsappTemplateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateWhatsappTemplateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateWhatsappTemplateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappTemplateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappTemplate.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappTemplate template = 1;
 * @return {?proto.da.proto.WhatsappTemplate}
 */
proto.da.proto.UpdateWhatsappTemplateResponse.prototype.getTemplate = function() {
  return /** @type{?proto.da.proto.WhatsappTemplate} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappTemplate, 1));
};


/**
 * @param {?proto.da.proto.WhatsappTemplate|undefined} value
 * @return {!proto.da.proto.UpdateWhatsappTemplateResponse} returns this
*/
proto.da.proto.UpdateWhatsappTemplateResponse.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateWhatsappTemplateResponse} returns this
 */
proto.da.proto.UpdateWhatsappTemplateResponse.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateWhatsappTemplateResponse.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateWhatsappCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateWhatsappCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateWhatsappCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: (f = msg.getCampaign()) && proto.da.proto.WhatsappCampaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateWhatsappCampaignRequest}
 */
proto.da.proto.CreateWhatsappCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateWhatsappCampaignRequest;
  return proto.da.proto.CreateWhatsappCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateWhatsappCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateWhatsappCampaignRequest}
 */
proto.da.proto.CreateWhatsappCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappCampaign;
      reader.readMessage(value,proto.da.proto.WhatsappCampaign.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateWhatsappCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateWhatsappCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateWhatsappCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappCampaign.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappCampaign campaign = 1;
 * @return {?proto.da.proto.WhatsappCampaign}
 */
proto.da.proto.CreateWhatsappCampaignRequest.prototype.getCampaign = function() {
  return /** @type{?proto.da.proto.WhatsappCampaign} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappCampaign, 1));
};


/**
 * @param {?proto.da.proto.WhatsappCampaign|undefined} value
 * @return {!proto.da.proto.CreateWhatsappCampaignRequest} returns this
*/
proto.da.proto.CreateWhatsappCampaignRequest.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateWhatsappCampaignRequest} returns this
 */
proto.da.proto.CreateWhatsappCampaignRequest.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateWhatsappCampaignRequest.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateWhatsappCampaignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateWhatsappCampaignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateWhatsappCampaignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappCampaignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: (f = msg.getCampaign()) && proto.da.proto.WhatsappCampaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateWhatsappCampaignResponse}
 */
proto.da.proto.CreateWhatsappCampaignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateWhatsappCampaignResponse;
  return proto.da.proto.CreateWhatsappCampaignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateWhatsappCampaignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateWhatsappCampaignResponse}
 */
proto.da.proto.CreateWhatsappCampaignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappCampaign;
      reader.readMessage(value,proto.da.proto.WhatsappCampaign.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateWhatsappCampaignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateWhatsappCampaignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateWhatsappCampaignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateWhatsappCampaignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappCampaign.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappCampaign campaign = 1;
 * @return {?proto.da.proto.WhatsappCampaign}
 */
proto.da.proto.CreateWhatsappCampaignResponse.prototype.getCampaign = function() {
  return /** @type{?proto.da.proto.WhatsappCampaign} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappCampaign, 1));
};


/**
 * @param {?proto.da.proto.WhatsappCampaign|undefined} value
 * @return {!proto.da.proto.CreateWhatsappCampaignResponse} returns this
*/
proto.da.proto.CreateWhatsappCampaignResponse.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateWhatsappCampaignResponse} returns this
 */
proto.da.proto.CreateWhatsappCampaignResponse.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateWhatsappCampaignResponse.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ViewWhatsappCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ViewWhatsappCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ViewWhatsappCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ViewWhatsappCampaignRequest}
 */
proto.da.proto.ViewWhatsappCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ViewWhatsappCampaignRequest;
  return proto.da.proto.ViewWhatsappCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ViewWhatsappCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ViewWhatsappCampaignRequest}
 */
proto.da.proto.ViewWhatsappCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ViewWhatsappCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ViewWhatsappCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ViewWhatsappCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.ViewWhatsappCampaignRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ViewWhatsappCampaignRequest} returns this
 */
proto.da.proto.ViewWhatsappCampaignRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ViewWhatsappCampaignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ViewWhatsappCampaignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ViewWhatsappCampaignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaign: (f = msg.getCampaign()) && proto.da.proto.WhatsappCampaign.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ViewWhatsappCampaignResponse}
 */
proto.da.proto.ViewWhatsappCampaignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ViewWhatsappCampaignResponse;
  return proto.da.proto.ViewWhatsappCampaignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ViewWhatsappCampaignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ViewWhatsappCampaignResponse}
 */
proto.da.proto.ViewWhatsappCampaignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappCampaign;
      reader.readMessage(value,proto.da.proto.WhatsappCampaign.deserializeBinaryFromReader);
      msg.setCampaign(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ViewWhatsappCampaignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ViewWhatsappCampaignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ViewWhatsappCampaignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaign();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappCampaign.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappCampaign campaign = 1;
 * @return {?proto.da.proto.WhatsappCampaign}
 */
proto.da.proto.ViewWhatsappCampaignResponse.prototype.getCampaign = function() {
  return /** @type{?proto.da.proto.WhatsappCampaign} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappCampaign, 1));
};


/**
 * @param {?proto.da.proto.WhatsappCampaign|undefined} value
 * @return {!proto.da.proto.ViewWhatsappCampaignResponse} returns this
*/
proto.da.proto.ViewWhatsappCampaignResponse.prototype.setCampaign = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.ViewWhatsappCampaignResponse} returns this
 */
proto.da.proto.ViewWhatsappCampaignResponse.prototype.clearCampaign = function() {
  return this.setCampaign(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.ViewWhatsappCampaignResponse.prototype.hasCampaign = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ListWhatsappCampaignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ListWhatsappCampaignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ListWhatsappCampaignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappCampaignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ListWhatsappCampaignRequest}
 */
proto.da.proto.ListWhatsappCampaignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ListWhatsappCampaignRequest;
  return proto.da.proto.ListWhatsappCampaignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ListWhatsappCampaignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ListWhatsappCampaignRequest}
 */
proto.da.proto.ListWhatsappCampaignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ListWhatsappCampaignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ListWhatsappCampaignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ListWhatsappCampaignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappCampaignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ListWhatsappCampaignResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ListWhatsappCampaignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ListWhatsappCampaignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ListWhatsappCampaignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappCampaignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignsList: jspb.Message.toObjectList(msg.getCampaignsList(),
    proto.da.proto.WhatsappCampaign.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ListWhatsappCampaignResponse}
 */
proto.da.proto.ListWhatsappCampaignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ListWhatsappCampaignResponse;
  return proto.da.proto.ListWhatsappCampaignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ListWhatsappCampaignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ListWhatsappCampaignResponse}
 */
proto.da.proto.ListWhatsappCampaignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappCampaign;
      reader.readMessage(value,proto.da.proto.WhatsappCampaign.deserializeBinaryFromReader);
      msg.addCampaigns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ListWhatsappCampaignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ListWhatsappCampaignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ListWhatsappCampaignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ListWhatsappCampaignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.WhatsappCampaign.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WhatsappCampaign campaigns = 1;
 * @return {!Array<!proto.da.proto.WhatsappCampaign>}
 */
proto.da.proto.ListWhatsappCampaignResponse.prototype.getCampaignsList = function() {
  return /** @type{!Array<!proto.da.proto.WhatsappCampaign>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.WhatsappCampaign, 1));
};


/**
 * @param {!Array<!proto.da.proto.WhatsappCampaign>} value
 * @return {!proto.da.proto.ListWhatsappCampaignResponse} returns this
*/
proto.da.proto.ListWhatsappCampaignResponse.prototype.setCampaignsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.WhatsappCampaign=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.WhatsappCampaign}
 */
proto.da.proto.ListWhatsappCampaignResponse.prototype.addCampaigns = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.WhatsappCampaign, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ListWhatsappCampaignResponse} returns this
 */
proto.da.proto.ListWhatsappCampaignResponse.prototype.clearCampaignsList = function() {
  return this.setCampaignsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.WhatsappTemplateStatusUpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && proto.da.proto.StatusEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.WhatsappTemplateStatusUpdateRequest}
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.WhatsappTemplateStatusUpdateRequest;
  return proto.da.proto.WhatsappTemplateStatusUpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.WhatsappTemplateStatusUpdateRequest}
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.StatusEvent;
      reader.readMessage(value,proto.da.proto.StatusEvent.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.WhatsappTemplateStatusUpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.StatusEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusEvent event = 1;
 * @return {?proto.da.proto.StatusEvent}
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.prototype.getEvent = function() {
  return /** @type{?proto.da.proto.StatusEvent} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.StatusEvent, 1));
};


/**
 * @param {?proto.da.proto.StatusEvent|undefined} value
 * @return {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} returns this
*/
proto.da.proto.WhatsappTemplateStatusUpdateRequest.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.WhatsappTemplateStatusUpdateRequest} returns this
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.WhatsappTemplateStatusUpdateRequest.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    campaignId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ViewWhatsappCampaignAnalyticsRequest;
  return proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCampaignId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCampaignId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string campaign_id = 1;
 * @return {string}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.prototype.getCampaignId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} returns this
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.prototype.setCampaignId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsRequest} returns this
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    analyticsList: jspb.Message.toObjectList(msg.getAnalyticsList(),
    proto.da.proto.WhatsappAnalytics.toObject, includeInstance),
    count: (f = msg.getCount()) && proto.da.proto.AnalyticsCount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ViewWhatsappCampaignAnalyticsResponse;
  return proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappAnalytics;
      reader.readMessage(value,proto.da.proto.WhatsappAnalytics.deserializeBinaryFromReader);
      msg.addAnalytics(value);
      break;
    case 2:
      var value = new proto.da.proto.AnalyticsCount;
      reader.readMessage(value,proto.da.proto.AnalyticsCount.deserializeBinaryFromReader);
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalyticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.WhatsappAnalytics.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.da.proto.AnalyticsCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WhatsappAnalytics analytics = 1;
 * @return {!Array<!proto.da.proto.WhatsappAnalytics>}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.getAnalyticsList = function() {
  return /** @type{!Array<!proto.da.proto.WhatsappAnalytics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.WhatsappAnalytics, 1));
};


/**
 * @param {!Array<!proto.da.proto.WhatsappAnalytics>} value
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} returns this
*/
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.setAnalyticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.WhatsappAnalytics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.WhatsappAnalytics}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.addAnalytics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.WhatsappAnalytics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} returns this
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.clearAnalyticsList = function() {
  return this.setAnalyticsList([]);
};


/**
 * optional AnalyticsCount count = 2;
 * @return {?proto.da.proto.AnalyticsCount}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.getCount = function() {
  return /** @type{?proto.da.proto.AnalyticsCount} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.AnalyticsCount, 2));
};


/**
 * @param {?proto.da.proto.AnalyticsCount|undefined} value
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} returns this
*/
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.setCount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.ViewWhatsappCampaignAnalyticsResponse} returns this
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.clearCount = function() {
  return this.setCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.ViewWhatsappCampaignAnalyticsResponse.prototype.hasCount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateWhatsappAnalyticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    analytics: (f = msg.getAnalytics()) && proto.da.proto.MessageStatusEvent.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsRequest}
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateWhatsappAnalyticsRequest;
  return proto.da.proto.UpdateWhatsappAnalyticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsRequest}
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.MessageStatusEvent;
      reader.readMessage(value,proto.da.proto.MessageStatusEvent.deserializeBinaryFromReader);
      msg.setAnalytics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateWhatsappAnalyticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalytics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.MessageStatusEvent.serializeBinaryToWriter
    );
  }
};


/**
 * optional MessageStatusEvent analytics = 1;
 * @return {?proto.da.proto.MessageStatusEvent}
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.prototype.getAnalytics = function() {
  return /** @type{?proto.da.proto.MessageStatusEvent} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.MessageStatusEvent, 1));
};


/**
 * @param {?proto.da.proto.MessageStatusEvent|undefined} value
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsRequest} returns this
*/
proto.da.proto.UpdateWhatsappAnalyticsRequest.prototype.setAnalytics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsRequest} returns this
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.prototype.clearAnalytics = function() {
  return this.setAnalytics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateWhatsappAnalyticsRequest.prototype.hasAnalytics = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateWhatsappAnalyticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    analytics: (f = msg.getAnalytics()) && proto.da.proto.WhatsappAnalytics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsResponse}
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateWhatsappAnalyticsResponse;
  return proto.da.proto.UpdateWhatsappAnalyticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsResponse}
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.WhatsappAnalytics;
      reader.readMessage(value,proto.da.proto.WhatsappAnalytics.deserializeBinaryFromReader);
      msg.setAnalytics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateWhatsappAnalyticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateWhatsappAnalyticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnalytics();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.WhatsappAnalytics.serializeBinaryToWriter
    );
  }
};


/**
 * optional WhatsappAnalytics analytics = 1;
 * @return {?proto.da.proto.WhatsappAnalytics}
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.prototype.getAnalytics = function() {
  return /** @type{?proto.da.proto.WhatsappAnalytics} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.WhatsappAnalytics, 1));
};


/**
 * @param {?proto.da.proto.WhatsappAnalytics|undefined} value
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsResponse} returns this
*/
proto.da.proto.UpdateWhatsappAnalyticsResponse.prototype.setAnalytics = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateWhatsappAnalyticsResponse} returns this
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.prototype.clearAnalytics = function() {
  return this.setAnalytics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateWhatsappAnalyticsResponse.prototype.hasAnalytics = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.da.proto);
