import { App } from "../pb/apps_pb";
import { Branch } from "../pb/branch_pb";
import {
	Contact,
	CustomField,
	Document,
	SocialProfile,
} from "../pb/contact_pb";
import { Department } from "../pb/department_pb";
import { Group } from "../pb/group_pb";
import { Label } from "../pb/label_pb";
import { Organisation } from "../pb/organisations_pb";
import { Permission, Role } from "../pb/rbac_pb";
import { Tag } from "../pb/tag_pb";
import { User } from "../pb/user_pb";

export const formatOrgJsonToOrgClass = (
	data: Organisation.AsObject
): Organisation => {
	let org = new Organisation();

	org.setId(data.id ?? "");
	org.setName(data.name ?? "");
	org.setDescription(data.description ?? "");
	org.setSerialNumber(data.serialNumber ?? "");
	org.setStatus(data.status ?? "");
	org.setReferralCode(data.referralCode ?? "");
	org.setShortCode(data.shortCode ?? "");
	org.setCreatedAt(data.createdAt ?? "");

	return org;
};

export const newUserClass = (data: User.AsObject): User => {
	let user = new User();

	user.setId(data.id ?? "");
	user.setFirstName(data.firstName ?? "");
	user.setLastName(data.lastName ?? "");
	user.setEmail(data.email ?? "");
	user.setPhone(data.phone ?? "");
	user.setUsername(data.username ?? "");
	user.setBreak(data.pb_break ?? false);
	user.setOrganisationId(data.organisationId ?? "");
	user.setStatusCode(data.statusCode ?? "");
	user.setOtpEnabled(data.otpEnabled ?? false);
	user.setOtpVerified(data.otpVerified ?? false);
	user.setStatus(data.status ?? "");
	user.setEmailSignature(data.emailSignature ?? "");

	return user;
};

export const updateUserClass = (user: User.AsObject): User => {
	const updatedUser = new User();
	updatedUser.setId(user.id ?? "");
	updatedUser.setOrganisationId(user.organisationId);
	updatedUser.setFirstName(user.firstName);
	updatedUser.setLastName(user.lastName);
	updatedUser.setEmail(user.email);
	updatedUser.setPhone(user.phone);
	updatedUser.setUsername(user.username);
	updatedUser.setEmailSignature(user.emailSignature);

	return updatedUser;
};

export const newRoleClass = (data: Role.AsObject): Role => {
	let role = new Role();

	role.setName(data.name ?? "");
	role.setDescription(data.description ?? "");
	role.setId(data.id ?? "");
	role.setOrganisationid(data.organisationid ?? "");
	role.setPermissionsList(data.permissionsList ?? []);

	return role;
};

export const newAppClass = (data: App.AsObject): App => {
	let app = new App();

	app.setId(data.id ?? "");
	app.setName(data.name ?? "");
	app.setDescription(data.description ?? "");
	app.setToken(data.token ?? "");
	app.setSecret(data.secret ?? "");
	app.setCreatedAt(data.createdAt ?? "");
	app.setUpdatedAt(data.updatedAt ?? "");
	app.setCreatedBy(data.createdBy ?? "");
	app.setUpdatedBy(data.updatedBy ?? "");
	app.setApprovedBy(data.approvedBy ?? "");
	app.setApprovedAt(data.approvedAt ?? "");
	app.setRejectedBy(data.rejectedBy ?? "");
	app.setRejectedAt(data.rejectedAt ?? "");
	app.setBranchId(data.branchId ?? "");
	app.setDepartmentId(data.departmentId ?? "");
	app.setOrganisationId(data.organisationId ?? "");
	app.setStatus(data.status ?? "");
	app.setPermissionsCodenamesList(data.permissionsCodenamesList ?? []);

	return app;
};

export const newPermissionListClasses = (
	data: Permission.AsObject[]
): Permission[] => {
	let permissions: Permission[] = [];
	for (let i = 0; i < data.length; i++) {
		let perm = data[i];
		let permission = new Permission();
		permission.setName(perm.name ?? "");
		permission.setCodeName(perm.codeName ?? "");
		permission.setService(perm.service ?? "");
		permission.setConsole(perm.console ?? false);
		permissions.push(permission);
	}

	return permissions;
};

export const newContactClass = (data: Contact.AsObject): Contact => {
	let contact = new Contact();

	contact.setId(data.id ?? "");
	contact.setFirstName(data.firstName ?? "");
	contact.setLastName(data.lastName ?? "");
	contact.setOtherName(data.otherName ?? "");
	contact.setNickname(data.nickname ?? "");
	contact.setEmail(data.email ?? "");
	contact.setPhone(data.phone ?? "");
	contact.setOtherPhone(data.otherPhone ?? "");
	contact.setTitle(data.title ?? "");
	contact.setPrefix(data.prefix ?? "");
	contact.setSuffix(data.suffix ?? "");
	contact.setBranchId(data.branchId ?? "");
	contact.setDepartmentId(data.departmentId ?? "");
	contact.setCountry(data.country ?? "");
	contact.setCity(data.city ?? "");
	contact.setState(data.state ?? "");
	contact.setStreet(data.street ?? "");
	contact.setPostalCode(data.postalCode ?? "");
	contact.setCompany(data.company ?? "");
	contact.setJobTitle(data.jobTitle ?? "");
	contact.setJobDepartment(data.jobDepartment ?? "");
	contact.setBirthday(data.birthday ?? "");
	contact.setUrl(data.url ?? "");
	contact.setNotes(data.notes ?? "");
	contact.setTagIdsList(data.tagIdsList ?? "");
	contact.setLabelIdsList(data.labelIdsList ?? "");
	contact.setCreatedAt(data.createdAt ?? "");
	contact.setCustomFieldsList(
		data.customFieldsList.map((field) => {
			const customField = new CustomField();
			customField.setName(field.name ?? "");
			customField.setValue(field.value ?? "");
			customField.setType(field.type ?? "");
			return customField;
		}) ?? []
	);
	contact.setOwnerId(data.ownerId ?? "");
	contact.setOwnerType(data.ownerType ?? 5);
	contact.setParentId(data.parentId ?? "");
	// contact.setTeamId(data.teamId ?? "");
	contact.setAccountId(data.accountId ?? "");

	return contact;
};

export const socialProfileListClass = (
	data: SocialProfile.AsObject[]
): SocialProfile[] => {
	let socialProfiles: SocialProfile[] = [];

	for (let i = 0; i < data.length; i++) {
		newSocialProfileClass(data[i]);
	}
	return socialProfiles;
};

export const newSocialProfileClass = (
	data: SocialProfile.AsObject
): SocialProfile => {
	let socialProfile = new SocialProfile();

	socialProfile.setPlatform(data.platform ?? "");
	socialProfile.setUsername(data.username ?? "");
	socialProfile.setLink(data.link ?? "");
	return socialProfile;
};

export const newUploadDocumentClass = (
	file: File,
	fileName: string
): Document => {
	let reader = new FileReader();
	let content = new Uint8Array();

	reader.readAsArrayBuffer(file);
	reader.onload = () => {
		let arrayBuffer = reader.result as ArrayBuffer;
		content = new Uint8Array(arrayBuffer);
	};

	let document = new Document();
	document.setFilename(fileName);
	document.setContent(content);

	return document;
};

export const newContactGroupClass = (data: Group.AsObject): Group => {
	let group = new Group();

	group.setId(data.id ?? "");
	group.setName(data.name ?? "");
	group.setOrganisationId(data.organisationId ?? "");
	group.setBranchId(data.branchId ?? "");
	group.setDepartmentId(data.departmentId ?? "");
	group.setContactIdsList(data.contactIdsList ?? []);
	group.setOwnerId(data.ownerId ?? "");
	group.setOwnerType(data.ownerType ?? 0);
	// group.setTeamId(data.teamId ?? "");

	return group;
};

export const newTagClass = (data: Tag.AsObject): Tag => {
	let tag = new Tag();

	tag.setId(data.id ?? "");
	tag.setName(data.name ?? "");
	tag.setColorCode(data.colorCode ?? "");
	tag.setCreatedAt(data.createdAt ?? "");
	tag.setUpdatedAt(data.updatedAt ?? "");
	tag.setCreatedAt(data.createdAt ?? "");

	return tag;
};

export const newLabelClass = (data: Label.AsObject): Label => {
	let label = new Label();

	label.setId(data.id ?? "");
	label.setName(data.name ?? "");
	label.setCreatedAt(data.createdAt ?? "");
	label.setUpdatedAt(data.updatedAt ?? "");
	label.setCreatedAt(data.createdAt ?? "");

	return label;
};

export const newBranchClass = (data: Branch.AsObject): Branch => {
	let branch = new Branch();

	branch.setId(data.id ?? "");
	branch.setDescription(data.description ?? "");
	branch.setParentId(data.parentId ?? "");
	branch.setChildId(data.childId ?? "");
	branch.setName(data.name ?? "");
	branch.setCreatedAt(data.createdAt ?? "");
	branch.setStatus(data.status ?? "");

	return branch;
};

export const newDepartmentClass = (data: Department.AsObject): Department => {
	let department = new Department();

	department.setId(data.id ?? "");
	department.setName(data.name ?? "");
	department.setDescription(data.description ?? "");
	department.setCreatedAt(data.createdAt ?? "");
	department.setStatus(data.status ?? "");

	return department;
};
