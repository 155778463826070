// source: group.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var owner_type_pb = require('./owner_type_pb.js');
goog.object.extend(proto, owner_type_pb);
goog.exportSymbol('proto.da.proto.Group', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.Group.repeatedFields_, null);
};
goog.inherits(proto.da.proto.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.Group.displayName = 'proto.da.proto.Group';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.Group.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    branchId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    contactIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    ownerId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ownerType: jspb.Message.getFieldWithDefault(msg, 10, 0),
    teamId: jspb.Message.getFieldWithDefault(msg, 41, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.Group}
 */
proto.da.proto.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.Group;
  return proto.da.proto.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.Group}
 */
proto.da.proto.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addContactIds(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 10:
      var value = /** @type {!proto.da.proto.OwnerType} */ (reader.readEnum());
      msg.setOwnerType(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBranchId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getContactIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOwnerType();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.Group.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.da.proto.Group.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organisation_id = 3;
 * @return {string}
 */
proto.da.proto.Group.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string branch_id = 4;
 * @return {string}
 */
proto.da.proto.Group.prototype.getBranchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string department_id = 5;
 * @return {string}
 */
proto.da.proto.Group.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.da.proto.Group.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string updated_at = 7;
 * @return {string}
 */
proto.da.proto.Group.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string contact_ids = 8;
 * @return {!Array<string>}
 */
proto.da.proto.Group.prototype.getContactIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setContactIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.addContactIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.clearContactIdsList = function() {
  return this.setContactIdsList([]);
};


/**
 * optional string owner_id = 9;
 * @return {string}
 */
proto.da.proto.Group.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional OwnerType owner_type = 10;
 * @return {!proto.da.proto.OwnerType}
 */
proto.da.proto.Group.prototype.getOwnerType = function() {
  return /** @type {!proto.da.proto.OwnerType} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.da.proto.OwnerType} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setOwnerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional string team_id = 41;
 * @return {string}
 */
proto.da.proto.Group.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Group} returns this
 */
proto.da.proto.Group.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


goog.object.extend(exports, proto.da.proto);
