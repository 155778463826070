import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { RpcError } from "grpc-web";
import { ReactNode, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { CgOptions } from "react-icons/cg";
import { HiScale } from "react-icons/hi";
import {
  MdAbc,
  MdAdd,
  MdOutlineCheckBox,
  MdOutlineContactMail,
  MdSave,
} from "react-icons/md";
import { TbCalendarCheck, TbStar } from "react-icons/tb";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notify } from "../../../gateway/Functions";
import { createSurvey } from "../../../gateway/gRPC/Utils/SurveyMethods";
import { sortQuestionsByIndex } from "../../../gateway/gRPC/Utils/utils";
import {
  CreateSurveyResponse,
  Question,
  QuestionType,
  Section,
  Survey,
} from "../../../gateway/gRPC/pb/surveys_pb";
import strings from "../../../localization/main";
import { gray_50, primaryGray, primaryGrayDark } from "../../../theme/theme";
import CustomButton from "../../buttons/CustomButton";
import CustomTextButton from "../../buttons/CustomTextButton";
import PageContainer from "../../containers/PageContainer";
import FormContainer from "../../forms/FormContainer/FormContainer";
import FormInput from "../../forms/FormContainer/FormInput";
import FormTextArea from "../../forms/FormContainer/FormTextArea";
import BongaCard from "../../general/Card";
import CustomGeneralTag from "../../general/CustomGeneralTag";
import CustomPageHeader from "../../headers/CustomPageHeader";
import { LgHeading, SubHeading } from "../../headings/Headings";
import SurveyQuestion from "./Question";
import SurveySectionForm from "./SectionForm";
import SurveyQuestionForm from "./SurveyQuestionForm";
import QuestionTypeMenu from "./QuestionTypeMenu";
import { User } from "../../../gateway/gRPC/pb/user_pb";

/*
    This component is used to create a new survey.
*/
interface SurveyHeaderProps {
  title: string;
  description: string;
  maxResponses: number;
}

function CreateQuestionnaire() {
  const [loading, setLoading] = useState<boolean>(false);
  const [survey, setSurvey] = useState<Survey.AsObject>({
    id: "",
    title: "",
    description: "",
    organisation: "",
    brandPoster: "",
    sectionsList: [],
    serialNumber: "",
    responseCount: 0,
    maxResponses: 0,
  });
  const [sections, setSections] = useState<Section.AsObject[]>([]);
  const [questions, setQuestions] = useState<Question.AsObject[]>([]);
  const user:User.AsObject = useSelector((state: any) => state.Profile);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const loggedIn = useSelector((state: any) => state.LoggedIn);
  const navigate = useNavigate();
  const {
    isOpen: sectionOpen,
    onOpen: onSectionOpen,
    onClose: onSectionClose,
  } = useDisclosure();
  const {
    isOpen: detailOpen,
    onOpen: onDetailOpen,
    onClose: onDetailClose,
  } = useDisclosure();
  const [currentQuestionType, setCurrentQuestionType] = useState<QuestionType>(
    QuestionType["TEXT"]
  );

  const getSectionQuestions = (sectionIndex: number) => {
    return questions.filter(
      (question: Question.AsObject) => question.sectionId === `${sectionIndex}`
    );
  };

  const handleAddSurveyHeader = (data: SurveyHeaderProps) => {
    onDetailOpen();
    setSurvey((prevState: Survey.AsObject) => {
      return {
        ...prevState,
        title: data.title,
        description: data.description,
        maxResponses: data.maxResponses,
        organisation: user.organisationId ?? "",
        brandPoster: "",
      };
    });
  };
  const handleAddSection: SubmitHandler<Section.AsObject> = (
    data: Section.AsObject
  ) => {
    setSections((prevState: Section.AsObject[]) => [...prevState, data]);
    onSectionClose();
  };
  const handleAddQuestion: SubmitHandler<Question.AsObject> = (
    data: Question.AsObject
  ) => {
    setQuestions((prevState: Question.AsObject[]) => [...prevState, data]);
    onClose();
  };
  const handleUpdateSurveySections = (data: Section.AsObject) => {
    setSections((prevState: Section.AsObject[]) => {
      return prevState.map((section: Section.AsObject) => {
        if (section.id === data.id) {
          return data;
        }
        return section;
      });
    });
  };
  const handleUpdateSurveyQuestions = (data: Question.AsObject) => {
    setQuestions((prevState: Question.AsObject[]) => {
      return prevState.map((question: Question.AsObject) => {
        if (question.id === data.id) {
          return data;
        }
        return question;
      });
    });
  };
  const handleRemoveSurveySection = (data: Section.AsObject) => {
    setSections((prevState: Section.AsObject[]) => {
      return prevState.filter((section: Section.AsObject) => {
        return section.id !== data.id;
      });
    });
  };
  const handleRemoveSurveyQuestion = (question: Question.AsObject) => {
    setQuestions((prevState: Question.AsObject[]) => {
      return prevState.filter((thisQuestion: Question.AsObject) => {
        return thisQuestion.id !== question.id;
      });
    });
  };

  const handleSubmitSurvey = async () => {
    const newSurvey: Survey.AsObject = {
      ...survey,
      organisation: user.organisationId ?? "",
      sectionsList: sections.map((section: Section.AsObject) => {
        return {
          ...section,
          questionsList: questions.filter(
            (question: Question.AsObject) =>
              question.sectionId === `${section.index}`
          ),
        };
      }),
    };

    if (!!survey && sections.length !== 0 && questions.length !== 0) {
      createSurvey(newSurvey)
        .then((res: CreateSurveyResponse.AsObject) => {
          notify(
            "success",
            "bottomLeft",
            `Success`,
            `Your Survey : ${res.res?.title} has been added successfully`
          );
          navigate(`/apps/surveys/${res.res?.id}/`);
        })
        .catch((err: RpcError) => {
          notify(
            "error",
            "bottomLeft",
            "Error",
            `Error adding survey: ${err.message}`
          );
        });
    }
  };

  const addNewQuestionCallBack: (type: QuestionType) => void = (
    type: QuestionType
  ): void => {
    onOpen();
    setCurrentQuestionType(type);
  };
  const updateNewQuestion = () => {
    // implementing form view logic
  };

  const addNewSection = () => {
    onSectionOpen();
  };
  return (
    <PageContainer
      h={loggedIn ? "auto" : "100vh"}
      overflowY={"auto"}
      p={loggedIn ? ".5rem" : "3rem 10rem"}
    >
      <Box w={loggedIn ? "70%" : "100%"}>
        <CustomPageHeader title={"Create new survey"} closable />
        <BongaCard loading={loading} p={"1rem"}>
          <VStack
            w={"100%"}
            spacing={"1rem"}
            alignItems={"stretch"}
            justifyContent={"flex-start"}
          >
            {!detailOpen && (
              <SurveyHeaderForm onSubmit={handleAddSurveyHeader} />
            )}
            {detailOpen && (
              <>
                <Box p={".5rem"} w={"100%"} bg={primaryGray}>
                  <HStack>
                    <LgHeading>{`${strings.survey}: ${survey.title}`}</LgHeading>
                    <Spacer />
                    <CustomGeneralTag
                      size="sm"
                      text={`${survey.maxResponses}`}
                      weight="bold"
                      colorScheme="green"
                    />
                  </HStack>
                  <Text>{survey.description}</Text>
                </Box>
                <Divider my={".5rem"} />
              </>
            )}
            {sections.length === 0 && (
              <SurveySectionForm
                onSubmit={handleAddSection}
                sectionIndex={`${sections.length + 1}`}
              />
            )}
            {sections.length > 0 &&
              sections.map((section, index) => (
                <VStack
                  spacing={".5rem"}
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                  key={index}
                  w={"100%"}
                >
                  <Box p={".5rem"} w={"100%"} bg={primaryGrayDark}>
                    <SubHeading>{`${strings.section}-${section.index}: ${section.title}`}</SubHeading>
                    <Text>{section.description}</Text>
                  </Box>
                  {sortQuestionsByIndex(
                    getSectionQuestions(section.index),
                    "ASC"
                  ).map((question: Question.AsObject, index: number) => (
                    <SurveyQuestion
                      key={`${index}-Question-${question.index}`}
                      question={question}
                      handleEditQuestionSubmit={handleUpdateSurveyQuestions}
                      handleDeleteQuestion={handleRemoveSurveyQuestion}
                    />
                  ))}
                </VStack>
              ))}
            {isOpen && (
              <SurveyQuestionForm
                onSubmit={handleAddQuestion}
                sectionIndex={`${sections.length === 0 ? 1 : sections.length}`}
                questionIndex={`${questions.length + 1}`}
                type={currentQuestionType}
              />
            )}
            {sectionOpen && (
              <SurveySectionForm
                onSubmit={handleAddSection}
                sectionIndex={`${sections.length + 1}`}
              />
            )}
          </VStack>
          <HStack justifyContent={"flex-end"} gap={".5rem"} py={"0.5rem"}>
            <QuestionTypeMenu
              isDisabled={!(!!survey && sections.length !== 0)}
              callback={addNewQuestionCallBack}
            />
            {sections.length > 0 && (
              <CustomTextButton onClick={onSectionOpen}>
                <MdAdd /> {"Add New Section"}
              </CustomTextButton>
            )}
            <CustomButton
              onClick={handleSubmitSurvey}
              isDisabled={
                !(!!survey && sections.length !== 0 && questions.length !== 0)
              }
            >
              <MdSave /> {"Create new survey"}
            </CustomButton>
          </HStack>
        </BongaCard>
      </Box>
    </PageContainer>
  );
}
export default CreateQuestionnaire;

interface SurveyHeaderFormProps {
  onSubmit: SubmitHandler<Survey.AsObject>;
}

const SurveyHeaderForm = ({ onSubmit }: SurveyHeaderFormProps) => {
  return (
    <Box w={"100%"} _hover={{ bg: gray_50 }} p={".5rem"}>
      <FormContainer columns={1} noFooter onSubmit={onSubmit}>
        {/* TODO: Change submit handler */}
        <SubHeading>{"Add Survey Details"}</SubHeading>
        <FormInput name="title" placeholder={"Enter a title for your survey"} />
        <FormTextArea
          name="description"
          placeholder={"Survey Description (Max 300 characters)"}
          maxLength={300}
        />
        <FormInput
          name="maxResponses"
          placeholder={"Maximum number of responses (optional)"}
          type="number"
        />
        <HStack justifyContent={"flex-end"}>
          <CustomButton type="submit">
            <MdAdd />
            {"Add SurveyDetails"}
          </CustomButton>
        </HStack>
      </FormContainer>
    </Box>
  );
};
