import Chart from "react-apexcharts";
import { lightGrayShades, subtleColors } from "../../assets/data";
import {
  MatrixOptions,
  MatrixTypeResponseAnalytics,
} from "../../gateway/gRPC/pb/surveys_pb";

interface Props {
  data: MatrixTypeResponseAnalytics.AsObject;
}

const SurveysRadarChart = ({ data }: Props) => {
  const series = [
    ...data.matrixList.map((option: MatrixOptions.AsObject) => {
      return {
        name: option.statement,
        data: option.optionsList.map((options) => options.count),
      };
    }),
  ];
  const options = {
    colors: [
      ...data.optionsList.map((_, index: number) => subtleColors[index]),
    ],
    title: {
      text: "Response Counts /Option for each Statement",
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      // add the dtmf options here to make it render each option as a category
      categories: [...data.optionsList],
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      radar: {
        size: 140,
        polygons: {
          strokeColors: "#e9e9e9",
          fill: {
            colors: [
              ...data.optionsList.map(
                (_, index: number) => lightGrayShades[index]
              ),
            ],
          },
        },
      },
    },
  };

  return (
    <Chart
      key={"myradar"}
      type={"radar"}
      width={500}
      height={400}
      options={options}
      series={series}
    />
  );
};

export default SurveysRadarChart;
