import { FormControl, TextareaProps } from "@chakra-ui/react";
import { useContext } from "react";
import CustomTextArea from "../CustomTextArea";
import ErrorContainer from "../ErrorContainer";
import { FormContext } from "./FormContainer";

interface Props extends TextareaProps {
  name: string;
  [key: string]: any;
}

function FormTextArea(props: Props) {
  const {
    register: propRegister,
    isRequired,
    onChange,
    name,
    placeholder,
  } = props;
  const formContext = useContext(FormContext);
  const { initialValues, register, errors, hover } = formContext;
  if (register == null && !props.register) {
    throw new Error(
      "This component must be used in the context of a <FormContainer> component"
    );
  }
  const options = { required: isRequired, onChange: onChange };
  return (
    <ErrorContainer error={errors[props.name]}>
      <FormControl>
        <CustomTextArea
          bg={"transparent"}
          border={"1px"}
          defaultValue={`${initialValues[name] ?? ""}`}
          placeholder={`${placeholder}`}
          {...props}
          {...(register
            ? register(name, { ...options })
            : propRegister(name, { ...options }))}
          boxShadow={"none"}
        />
      </FormControl>
    </ErrorContainer>
  );
}

export default FormTextArea;
