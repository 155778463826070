import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Radio,
  Select,
  SimpleGrid,
  Spacer,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { BsTextareaResize } from "react-icons/bs";
import {
  MdAdd,
  MdCalendarToday,
  MdCheckBoxOutlineBlank,
  MdEmail,
  MdEvent,
  MdLink,
  MdNumbers,
  MdOutlineRadioButtonUnchecked,
  MdPhone,
  MdRadioButtonUnchecked,
  MdTimer,
} from "react-icons/md";
import { TbAbc, TbTrash } from "react-icons/tb";
import {
  ChoiceTypeQuestion,
  ContactTypeQuestion,
  DateTypeQuestion,
  MatrixTypeQuestion,
  NPSTypeQuestion,
  Question,
  QuestionType,
  RatingTypeQuestion,
  TextTypeQuestion,
} from "../../../gateway/gRPC/pb/surveys_pb";
import {
  borderColor,
  primaryGrayDark,
  primaryWhite,
} from "../../../theme/theme";
import CustomTextButton from "../../buttons/CustomTextButton";
import IconButton from "../../buttons/IconButton";
import CustomInput from "../../forms/CustomInput";
import FormInput from "../../forms/FormContainer/FormInput";
import FormSelect from "../../forms/FormContainer/FormSelect";
import { SubHeading } from "../../headings/Headings";
import { RatingIconType, ratingIcons } from "./Question";

interface QuestionTypeFormProps {
  type: QuestionType;
  fieldSetter: Dispatch<SetStateAction<{
    [key: string]:
      ChoiceTypeQuestion.AsObject
      | TextTypeQuestion.AsObject
      | NPSTypeQuestion.AsObject
      | ContactTypeQuestion.AsObject
      | DateTypeQuestion.AsObject
      | RatingTypeQuestion.AsObject
      | MatrixTypeQuestion.AsObject;
  }| undefined>>;
  initializer?: Question.AsObject;
}
type Setter<T> = React.Dispatch<React.SetStateAction<T>>;

const QuestionTypeForm = ({
  type,
  fieldSetter,
  initializer,
}: QuestionTypeFormProps) => {
  switch (type) {
    case QuestionType.TEXT:
      return (
        <TextTypeForm
          setTextQuestion={
            fieldSetter as Setter<{
              [key: string]: TextTypeQuestion.AsObject;
            }>
          }
          initialValues={initializer?.text as TextTypeQuestion.AsObject}
        />
      );
    case QuestionType.CHOICE:
      return (
        <ChoiceQuestionForm
          setChoiceQuestion={
            fieldSetter as Setter<{
              [key: string]: ChoiceTypeQuestion.AsObject;
            }>
          }
          initialValues={initializer?.choice as ChoiceTypeQuestion.AsObject}
        />
      );
    case QuestionType.NPS:
      return (
        <NPSTypeQuestionForm
          setNPSQuestion={
            fieldSetter as Setter<{ [key: string]: NPSTypeQuestion.AsObject }>
          }
          initialValues={initializer?.nps as NPSTypeQuestion.AsObject}
        />
      );
    case QuestionType.DATE:
      return (
        <DateTypeQuestionForm
          setDateQuestion={
            fieldSetter as Setter<{ [key: string]: DateTypeQuestion.AsObject }>
          }
          initialValues={initializer?.date as DateTypeQuestion.AsObject}
        />
      );
    case QuestionType.CONTACT:
      return (
        <ContactTypeQuestionForm
          setContactQuestion={
            fieldSetter as Setter<{
              [key: string]: ContactTypeQuestion.AsObject;
            }>
          }
          initialValues={initializer?.contact as ContactTypeQuestion.AsObject}
        />
      );
    case QuestionType.RATING:
      return (
        <RatingTypeQuestionForm
          setRatingQuestion={
            fieldSetter as Setter<{
              [key: string]: RatingTypeQuestion.AsObject;
            }>
          }
          initialValues={initializer?.rating as RatingTypeQuestion.AsObject}
        />
      );
    case QuestionType.MATRIX:
      return (
        <MatrixTypeQuestionForm
          setMatrixQuestion={
            fieldSetter as Setter<{
              [key: string]: MatrixTypeQuestion.AsObject;
            }>
          }
          initialValues={initializer?.matrix as MatrixTypeQuestion.AsObject}
        />
      );
    default:
      return null;
  }
};

interface ChoiceOptionProps {
  setChoiceQuestion: Setter<{ [key: string]: ChoiceTypeQuestion.AsObject }>;
  initialValues: ChoiceTypeQuestion.AsObject;
}
const ChoiceQuestionForm = ({
  setChoiceQuestion,
  initialValues,
}: ChoiceOptionProps) => {
  const [options, setOptions] = useState<string[]>([]);
  const [currentOption, setCurrentOption] = useState<string>("");
  const [type, setType] = useState<ChoiceTypeQuestion.ChoiceType>(0);
  const [multipleChoice, setMultipleChoice] = useState<boolean>(false);
  const optionRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!!initialValues) {
      setOptions([...initialValues?.optionsList]);
      setMultipleChoice(initialValues?.multiple);
      setType(initialValues?.type);
    }
  }, [initialValues]);
  const removeOption = (option: string) => {
    setOptions((prevState: string[]) => [
      ...prevState.filter((o: string) => o != option),
    ]);
  };
  const handleAddOption = () => {
    setOptions([...options, currentOption]);
    setCurrentOption("");
    if (optionRef && optionRef.current) {
      optionRef.current.value = "";
    }
  };
  const onSubmitOption = () => {
    setChoiceQuestion({
      choice: { type: type, optionsList: options, multiple: multipleChoice },
    });
  };
  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"1rem"}
    >
      <VStack
        flex={4}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        {options.map((option: string) => (
          <HStack h={"2rem"} p={".5rem 2rem .5rem .5rem"} role={"group"}>
            <Text>
              {type === ChoiceTypeQuestion.ChoiceType.RADIO ? (
                <MdOutlineRadioButtonUnchecked />
              ) : (
                <MdCheckBoxOutlineBlank />
              )}
              {` ${option}`}
            </Text>
            <Spacer />
            <IconButton
              display={"none"}
              fluid
              icon={<TbTrash />}
              onClick={() => removeOption(option)}
              _groupHover={{
                display: "block",
              }}
            />
          </HStack>
        ))}
        <CustomInput
          ref={optionRef}
          fontSize={"sm"}
          border={"none"}
          borderBottom={`1px solid ${borderColor}`}
          name={`option_${options.length + 1}`}
          placeholder={"NewOption"}
          type={"text"}
          _focus={{
            outline: "none",
            shadow: "none",
            borderColor: primaryGrayDark,
          }}
          onChange={(e: any) => setCurrentOption(e.target.value)}
        />
        <HStack>
          <Spacer />
          {currentOption.length > 2 && (
            <CustomTextButton onClick={handleAddOption}>
              <MdAdd />
            </CustomTextButton>
          )}
        </HStack>
      </VStack>
      <VStack
        flex={2}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <SubHeading>Input Type</SubHeading>
        <Select
          size={"sm"}
          defaultValue={
            initialValues?.type ?? ChoiceTypeQuestion.ChoiceType.RADIO
          }
          onChange={(e) => setType(parseInt(e.target.value))}
        >
          <option value={ChoiceTypeQuestion.ChoiceType.RADIO}>
            {" "}
            <MdRadioButtonUnchecked />
            Radio
          </option>
          <option value={ChoiceTypeQuestion.ChoiceType.CHECKBOX}>
            {" "}
            <MdCheckBoxOutlineBlank />
            Checkbox
          </option>
        </Select>
        {/* <HStack>
          <Switch
            size={"sm"}
            checked={multipleChoice}
            onChange={() => setMultipleChoice(!multipleChoice)}
          />
          <Text>Multiple</Text>
        </HStack> */}
        <CustomTextButton onClick={onSubmitOption}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};
interface TextOptionProps {
  setTextQuestion: Setter<{ [key: string]: TextTypeQuestion.AsObject }>;
  initialValues: TextTypeQuestion.AsObject;
}
const TextTypeForm = ({ setTextQuestion, initialValues }: TextOptionProps) => {
  const [placeholder, setPlaceholder] = useState<string>("");
  const [type, setType] = useState<TextTypeQuestion.TextType>(0);

  useEffect(() => {
    if (!!initialValues) {
      setPlaceholder(initialValues?.placeholder);
      setType(initialValues?.type);
    }
  }, [initialValues]);
  const handleSubmit = () => {
    setTextQuestion({
      text: {
        type: type,
        placeholder: placeholder,
      },
    });
  };
  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"2rem"}
    >
      <VStack
        flex={4}
        spacing={".5rem"}
        h={"min-content"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <FormInput
          name={`placeholder`}
          placeholder={"Add a placeholder"}
          type={"text"}
          defaultValue={initialValues?.placeholder ?? undefined}
          onChange={(e) => setPlaceholder(e.target.value)}
        />
      </VStack>
      <VStack
        flex={2}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <SubHeading>Input Type</SubHeading>
        <Select
          size={"sm"}
          defaultValue={initialValues?.type ?? TextTypeQuestion.TextType.TEXT}
          onChange={(e) => setType(parseInt(e.target.value))}
        >
          <option value={TextTypeQuestion.TextType.TEXT}>
            <TbAbc />
            Text
          </option>
          <option value={TextTypeQuestion.TextType.TEXTAREA}>
            <BsTextareaResize />
            Paragraph
          </option>
          <option value={TextTypeQuestion.TextType.NUMBER}>
            <MdNumbers />
            Number
          </option>
          <option value={TextTypeQuestion.TextType.URL}>
            <MdLink />
            Link
          </option>
        </Select>
        <CustomTextButton onClick={handleSubmit}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};

interface NPSOptionProps {
  setNPSQuestion: Setter<{ [key: string]: NPSTypeQuestion.AsObject }>;
  initialValues: NPSTypeQuestion.AsObject;
}
const NPSTypeQuestionForm = ({
  setNPSQuestion,
  initialValues,
}: NPSOptionProps) => {
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [minLabel, setMinLabel] = useState<string>("");
  const [maxLabel, setMaxLabel] = useState<string>("");
  useEffect(() => {
    if (!!initialValues) {
      setMinLabel(initialValues?.labelMin);
      setMaxLabel(initialValues?.labelMax);
      setMin(initialValues?.min);
      setMax(initialValues?.max);
    }
  }, [initialValues]);
  const handleSubmit = () => {
    const newData: NPSTypeQuestion.AsObject = {
      min: min,
      max: max,
      labelMin: minLabel,
      labelMax: maxLabel,
    };
    setNPSQuestion({ nps: newData });
  };

  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"2rem"}
    >
      <SimpleGrid gap={".5rem"} columns={2} flex={4} h={"max-content"}>
        <FormInput
          name={`min`}
          placeholder={"Add a minimum score"}
          type={"number"}
          defaultValue={initialValues?.min ?? undefined}
          onChange={(e: any) => setMin(parseInt(e.target.value))}
        />

        <FormInput
          name={`max`}
          placeholder={"Add a maximum score"}
          type={"number"}
          defaultValue={initialValues?.max ?? undefined}
          onChange={(e: any) => setMax(parseInt(e.target.value))}
        />
        <FormInput
          name={`minLabel`}
          placeholder={"Describe the minimum score"}
          type={"text"}
          defaultValue={initialValues?.labelMin ?? undefined}
          onChange={(e: any) => setMinLabel(e.target.value)}
        />
        <FormInput
          name={`maxLabel`}
          placeholder={"Describe the maximum score"}
          type={"text"}
          defaultValue={initialValues?.labelMax ?? undefined}
          onChange={(e: any) => setMaxLabel(e.target.value)}
        />
      </SimpleGrid>
      <VStack
        flex={2}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <Spacer />
        <CustomTextButton onClick={handleSubmit}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};

interface DateOptionProps {
  setDateQuestion: Setter<{ [key: string]: DateTypeQuestion.AsObject }>;
  initialValues: DateTypeQuestion.AsObject;
}

const DateTypeQuestionForm = ({
  setDateQuestion,
  initialValues,
}: DateOptionProps) => {
  const [type, setType] = useState<DateTypeQuestion.DateType>(0);
  useEffect(() => {
    if (!!initialValues) {
      setType(initialValues?.type);
    }
  }, [initialValues]);
  const handleSubmit = () => {
    const newData: DateTypeQuestion.AsObject = {
      type: type,
    };
    setDateQuestion({ date: newData });
  };
  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"2rem"}
    >
      <VStack
        flex={4}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <FormSelect
          name={`type`}
          defaultValue={initialValues?.type ?? DateTypeQuestion.DateType.DATE}
          onChange={(e) => setType(parseInt(e.target.value))}
        >
          <option value={DateTypeQuestion.DateType.DATE}>
            <MdCalendarToday />
            Date
          </option>
          <option value={DateTypeQuestion.DateType.DATETIME}>
            <MdEvent />
            DateTime
          </option>
          <option value={DateTypeQuestion.DateType.TIME}>
            <MdTimer />
            Time
          </option>
        </FormSelect>
      </VStack>
      <VStack
        flex={2}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <CustomTextButton onClick={handleSubmit}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};

interface ContactOptionProps {
  setContactQuestion: Setter<{ [key: string]: ContactTypeQuestion.AsObject }>;
  initialValues: ContactTypeQuestion.AsObject;
}

const ContactTypeQuestionForm = ({
  setContactQuestion,
  initialValues,
}: ContactOptionProps) => {
  const [type, setType] = useState<ContactTypeQuestion.ContactType>(0);
  const [placeholder, setPlaceholder] = useState<string>("");
  useEffect(() => {
    if (!!initialValues) {
      setPlaceholder(initialValues?.placeholder);
      setType(initialValues?.type);
    }
  }, [initialValues]);
  const handleSubmit = () => {
    const newData: ContactTypeQuestion.AsObject = {
      placeholder: placeholder,
      type: type,
    };
    setContactQuestion({ contact: newData });
  };
  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"2rem"}
    >
      <VStack
        flex={4}
        h={"min-content"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <FormInput
          name={`placeholder`}
          placeholder={"Add a placeholder"}
          type={"text"}
          defaultValue={initialValues?.placeholder ?? undefined}
          onChange={(e) => setPlaceholder(e.target.value)}
        />
        <Spacer />
      </VStack>
      <VStack
        flex={2}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <FormSelect
          name={`type`}
          defaultValue={
            initialValues?.type ?? ContactTypeQuestion.ContactType.EMAIL
          }
          onChange={(e) => setType(parseInt(e.target.value))}
        >
          <option value={ContactTypeQuestion.ContactType.EMAIL}>
            <MdEmail />
            Email
          </option>
          <option value={ContactTypeQuestion.ContactType.TEL}>
            <MdPhone />
            Phone
          </option>
        </FormSelect>
        <CustomTextButton onClick={handleSubmit}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};

interface MatrixOptionProps {
  setMatrixQuestion: Setter<{ [key: string]: MatrixTypeQuestion.AsObject }>;
  initialValues: MatrixTypeQuestion.AsObject;
}

const MatrixTypeQuestionForm = ({
  setMatrixQuestion,
  initialValues,
}: MatrixOptionProps) => {
  const [rows, setRows] = useState<string[]>([]);
  const [options, setOptions] = useState<string[]>([]);
  const [multipleChoice, setMultipleChoice] = useState<boolean>(false);
  useEffect(() => {
    if (!!initialValues) {
      setOptions([...initialValues?.optionsList]);
      setRows([...initialValues?.rowsList]);
      setMultipleChoice(initialValues?.multiple);
    }
  }, [initialValues]);

  const handleSubmit = () => {
    const newData: MatrixTypeQuestion.AsObject = {
      rowsList: rows,
      optionsList: options,
      multiple: multipleChoice,
    };
    setMatrixQuestion({ matrix: newData });
  };
  return (
    <VStack
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      spacing={"1rem"}
    >
      <MatrixForm
        rows={rows}
        options={options}
        setOptions={setOptions}
        setRows={setRows}
        multiple={multipleChoice}
        setMultiple={setMultipleChoice}
        handleSubmit={handleSubmit}
      />
    </VStack>
  );
};
const MatrixForm = ({
  rows,
  options,
  setOptions,
  setRows,
  multiple,
  setMultiple,
  handleSubmit,
}: {
  multiple: boolean;
  rows: string[];
  options: string[];
  setOptions: Dispatch<SetStateAction<string[]>>;
  setRows: Dispatch<SetStateAction<string[]>>;
  setMultiple: Dispatch<SetStateAction<boolean>>;
  handleSubmit: () => void;
}) => {
  const [option, setOption] = useState<string>("");
  const [row, setRow] = useState<string>("");
  const optionRef = useRef<HTMLInputElement>(null);
  const rowRef = useRef<HTMLInputElement>(null);
  const handleAddOption = () => {
    setOptions((prevState: string[]) => [...prevState, option]);
    if (optionRef && optionRef.current) {
      optionRef.current.value = "";
    }
  };
  const handleAddRow = () => {
    setRows((prevState: string[]) => [...prevState, row]);
    if (rowRef && rowRef.current) {
      rowRef.current.value = "";
    }
  };

  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"1rem"}
    >
      <Grid
        flex={3}
        w={"100%"}
        templateColumns={`repeat(${options.length + 2}, 1fr)`}
        border={`1px solid ${borderColor}`}
        gap={4}
        bg={primaryWhite}
        borderRadius={"3px"}
        p={"1rem 2rem"}
        overflowX={"auto"}
      >
        <GridItem colSpan={2}></GridItem>
        {options.map((option, optionIndex) => (
          <Text w={"100%"} key={optionIndex} textAlign="center">
            {option}
          </Text>
        ))}

        {rows.map((row, rowIndex) => (
          <Fragment key={rowIndex}>
            <GridItem colSpan={2}>
              <Text textAlign="left">{row}</Text>
            </GridItem>
            {options.map((_, optionIndex: number) => (
              <Center w={"100%"} key={optionIndex}>
                <Radio isReadOnly />
              </Center>
            ))}
          </Fragment>
        ))}
      </Grid>
      <VStack
        flex={1}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <HStack>
          <CustomInput
            ref={optionRef}
            fontSize={"sm"}
            border={"none"}
            borderBottom={`1px solid ${borderColor}`}
            name={`option_${options.length + 1}`}
            placeholder={"New Option"}
            type={"text"}
            _focus={{
              outline: "none",
              shadow: "none",
              borderColor: primaryGrayDark,
            }}
            onChange={(e: any) => setOption(e.target.value)}
          />
          {option.length > 2 && (
            <CustomTextButton onClick={handleAddOption}>
              <MdAdd />
            </CustomTextButton>
          )}
        </HStack>
        <HStack>
          <CustomInput
            ref={rowRef}
            fontSize={"sm"}
            border={"none"}
            borderBottom={`1px solid ${borderColor}`}
            name={`option_${options.length + 1}`}
            placeholder={"New Row"}
            type={"text"}
            _focus={{
              outline: "none",
              shadow: "none",
              borderColor: primaryGrayDark,
            }}
            onChange={(e: any) => setRow(e.target.value)}
          />
          <Text></Text>
          {row.length > 2 && (
            <CustomTextButton onClick={handleAddRow}>
              <MdAdd />
            </CustomTextButton>
          )}
        </HStack>
        <HStack>
          <Switch
            size={"sm"}
            checked={multiple}
            onChange={() => setMultiple(!multiple)}
          />
          <Text>Multiple</Text>
        </HStack>
        <CustomTextButton onClick={handleSubmit}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};

interface RatingOptionProps {
  setRatingQuestion: Setter<{ [key: string]: RatingTypeQuestion.AsObject }>;
  initialValues: RatingTypeQuestion.AsObject;
}

const RatingTypeQuestionForm = ({
  setRatingQuestion,
  initialValues,
}: RatingOptionProps) => {
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [icon, setIcon] = useState<RatingTypeQuestion.IconType>(0);
  useEffect(() => {
    if (!!initialValues) {
      setMin(initialValues?.min);
      setMax(initialValues?.max);
      setIcon(initialValues?.icon);
    }
  }, [initialValues]);
  const handleSubmit = () => {
    const newData: RatingTypeQuestion.AsObject = {
      min: min,
      max: max,
      icon: icon,
    };
    setRatingQuestion({ rating: newData });
  };

  return (
    <Flex
      w={"100%"}
      justifyContent={"space-between"}
      alignItems={"stretch"}
      gap={"2rem"}
    >
      <VStack
        flex={4}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <Box>
          <FormInput
            name={`min`}
            placeholder={"Add a minimum score"}
            type={"number"}
            defaultValue={initialValues ? initialValues?.min : undefined}
            onChange={(e: any) => setMin(parseInt(e.target.value))}
          />
        </Box>
        <Box>
          <FormInput
            name={`max`}
            placeholder={"Add a maximum score"}
            type={"number"}
            defaultValue={initialValues ? initialValues?.max : undefined}
            onChange={(e: any) => setMax(parseInt(e.target.value))}
          />
        </Box>
        <Box>
          <Select
            size={"sm"}
            defaultValue={
              initialValues?.icon ?? RatingTypeQuestion.IconType.STAR
            }
            onChange={(e: any) => setIcon(parseInt(e.target.value))}
          >
            {ratingIcons.map((option: RatingIconType, optionIndex: number) => (
              <option key={optionIndex} value={option.iconValue}>
                {" "}
                <Text>
                  {option.icon} {` ${option.iconName}`}
                </Text>
              </option>
            ))}
          </Select>
        </Box>
      </VStack>
      <VStack
        flex={2}
        spacing={".5rem"}
        w={"100%"}
        justifyContent={"flex-start"}
        alignItems={"stretch"}
      >
        <Spacer />
        <CustomTextButton onClick={handleSubmit}>
          <MdAdd /> {"Save Question"}
        </CustomTextButton>
      </VStack>
    </Flex>
  );
};

export default QuestionTypeForm;
