import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "../components/buttons/CustomButton";

export default function StripeCheckoutSuccess() {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const home = process.env.REACT_APP_HOME_ROUTE || "";

  useEffect(() => {
    if (searchParams.get("mode") === "setup") {
      setMessage(
        "Credit card details successfully updated. Please close this page."
      );
    } else {
      setMessage("Payment successfully processed. Please close this page.");
    }
  }, [searchParams]);

  useEffect(() => {
    if (window.opener) {
      setTimeout(() => {
        window.close();
      }, 5000);
    } else {
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }, [navigate]);

  return (
    <Grid
      placeItems={"center"}
      height="100vh"
      width="100vh"
    >
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
        w={[300, 400, 500]}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Success!
        </AlertTitle>
        <AlertDescription maxWidth="sm">{message}</AlertDescription>
        <CustomButton
          cursor={"pointer"}
          fontWeight={"bold"}
          onClick={() => navigate(home)}
        >
          Go Home
        </CustomButton>
      </Alert>
    </Grid>
  );
}
