import React from "react";
import { TbEdit } from "react-icons/tb";
import { Button, PlacementWithLogical, Tooltip } from "@chakra-ui/react";

interface EditButtonProps {
    tooltipLabel: string;
    hasArrow?: boolean;
    placement?:PlacementWithLogical;
    [key: string]: any;
    onClick: () => void
}


function CustomEditButton({tooltipLabel,hasArrow,placement,onClick,...rest}:EditButtonProps) {
    return (
        <Tooltip label={tooltipLabel} placement={placement??"bottom"} hasArrow={hasArrow}>
            <Button
                placeItems={"center"}
                bg={"gray.200"}
                borderRadius={"3px"}
                _hover={{bg: "gray.300"}}
                color={"gray.700"}
                size={"xs"}
                onClick={onClick}
                {...rest}
            >
                <TbEdit/>
            </Button>
        </Tooltip>
    );
}
export default CustomEditButton;
