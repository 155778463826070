import { Branch } from "../pb/branch_pb";
import { Department } from "../pb/department_pb";

export const newBranchClass = (branchData: Branch.AsObject): Branch => {
  const newBranch = new Branch();
  newBranch.setId(branchData.id);
  newBranch.setName(branchData.name);
  newBranch.setDescription(branchData.description);
  newBranch.setOrganisationId(branchData.organisationId);
  newBranch.setParentId(branchData.parentId);
  newBranch.setCreatedAt(branchData.createdAt);
  newBranch.setStatus(branchData.status);
  newBranch.setOldBranchId(branchData.oldBranchId);
  newBranch.setOldBranchData(branchData.oldBranchData);
  newBranch.setChildId(branchData.childId);
  newBranch.setCreatedBy(branchData.createdBy);

  return newBranch;
};


export const updateBranchClass = (branchData: Branch.AsObject): Branch => {
  const updatedBranch = new Branch();
  updatedBranch.setId(branchData.id);
  updatedBranch.setName(branchData.name);
  updatedBranch.setDescription(branchData.description);
  updatedBranch.setOrganisationId(branchData.organisationId);
  updatedBranch.setParentId(branchData.parentId);
  updatedBranch.setCreatedAt(branchData.createdAt);
  updatedBranch.setStatus(branchData.status);
  updatedBranch.setOldBranchId(branchData.oldBranchId);
  updatedBranch.setOldBranchData(branchData.oldBranchData);
  updatedBranch.setChildId(branchData.childId);
  updatedBranch.setCreatedBy(branchData.createdBy);

  return updatedBranch;
};

export const newDepartmentClass = (departmentData: Department.AsObject): Department => {
  const newDepartment = new Department();
  newDepartment.setId(departmentData.id);
  newDepartment.setName(departmentData.name);
  newDepartment.setDescription(departmentData.description);
  newDepartment.setOrganisationId(departmentData.organisationId);
  newDepartment.setCreatedAt(departmentData.createdAt);
  newDepartment.setStatus(departmentData.status);
  newDepartment.setCreatedBy(departmentData.createdBy);

  return newDepartment;
};

export const updateDepartmentClass = (departmentData: Department.AsObject): Department => {
  const updatedDepartment = new Department();
  updatedDepartment.setId(departmentData.id);
  updatedDepartment.setName(departmentData.name);
  updatedDepartment.setDescription(departmentData.description);
  updatedDepartment.setOrganisationId(departmentData.organisationId);
  updatedDepartment.setCreatedAt(departmentData.createdAt);
  updatedDepartment.setStatus(departmentData.status);
  updatedDepartment.setCreatedBy(departmentData.createdBy);

  return updatedDepartment;
};