import { Tag, Tooltip } from "@chakra-ui/react";
import React from "react";

interface CustomIconButtonProps {
  tooltipLabel?: string;
  hasArrow?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
  children?: React.ReactNode;
  onClick?: () => void;
  [key: string]: any;
}

function CustomTextButton(props: CustomIconButtonProps) {
  const {
    tooltipLabel,
    hasArrow,
    placement,
    colorScheme,
    color,
    width,
    children,
    loading,
    loadingText,
    onClick,
    ...rest
  } = props;
  return (
    <Tooltip
      label={tooltipLabel ?? ""}
      placement={placement}
      hasArrow={hasArrow}
    >
      <Tag
        colorScheme={colorScheme ?? "gray"}
        color={color}
        borderRadius={"3px"}
        gap={".4rem"}
        fontSize={"xs"}
        size={"xs"}
        p={".5rem 1rem"}
        w={width ?? "fit-content"}
        onClick={onClick ? onClick : () => null}
        cursor={"pointer"}
        {...rest}
        textTransform={"capitalize"}
      >
        {children}
      </Tag>
    </Tooltip>
  );
}

export default CustomTextButton;
