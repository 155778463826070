import { Box, Flex } from "@chakra-ui/react";
import { Layout } from "antd";
import React, { Suspense, lazy, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../components/general/Loader";
import {
	SocketContext,
	SocketProviderProps,
} from "../components/phone/SocketIoContextProvider";
import useDetector from "../hooks/useDetector";
import allRoutes from "../routes/allRoutes";
import mobileRoutes from "../routes/mobileRoutes";
import { primaryGray } from "../theme/theme";

const SideBar = lazy(() => import("./SideBar/SideBar"));
const Header = lazy(() => import("./Header/Header"));
const RightBar = lazy(() => import("./SideBars/RightBar"));
const BottomBar = lazy(() => import("./BottomBar/BottomBar"));
const RouteNavigator = lazy(() => import("../routes/RouteNavigator"));

const BongaLayout: React.FC = () => {
	const detector = useDetector();
	const userId = useSelector((state: any) => state.Profile.id);
	const organizationId = useSelector(
		(state: any) => state.Profile.organisationId
	);

	const socketContext = useContext(SocketContext);
	const { socket }: SocketProviderProps = socketContext;

	useEffect(() => {
		let reconnectTimeout: any;
		const handleRetry = () => {
			if (!socket.connected) {
				reconnectTimeout = setInterval(() => {
					socket.connect();
				}, 5000);
			} else {
				clearInterval(reconnectTimeout);
			}
		};

		socket.on("connect", () => {
			//clearInterval(reconnectTimeout);
			socket.emit("AGENT_CONNECTED", {
				userId: userId,
				organizationId: organizationId,
			});
		});

		socket.on("disconnect", handleRetry);
		socket.on("connect_error", handleRetry);
		socket.on("connect_timeout", handleRetry);

		return () => {
			clearInterval(reconnectTimeout);
			socket.off("connect");
			socket.off("disconnect");
			socket.off("connect_error");
			socket.off("connect_timeout");
			socket.emit("AGENT_DISCONNECTED", {
				userId: userId,
				organizationId: organizationId,
			});
		};
	}, []);

	const hasSupport = process.env.REACT_APP_MODULES?.includes("SUPPORT");

	return (
		<Layout>
			{detector.isPhone() ? (
				<Suspense>
					<Header />
					<Box w={"100%"} p={".2rem"} h={"100%"} overflow={"scroll"}>
						<RouteNavigator routes={mobileRoutes} />
					</Box>
					<BottomBar />
				</Suspense>
			) : (
				<Suspense fallback={<Loader />}>
					<Suspense>
						<SideBar />
					</Suspense>
					<Flex w={"100%"} h={"100vh"} flexFlow={"column"} bg={primaryGray}>
						<Header />
						<Flex
							flexFlow={"row nowrap"}
							justifyContent={"space-between"}
							alignItems={"flex-start"}
							w={"calc(100vw - 100px)"} // 100px is the width of the sidebar
							h={"calc(100vh - 3rem)"} // 5rem is the height of the header and bottom bar
						>
							<Box flexGrow={1} h={"100%"}>
								<RouteNavigator routes={allRoutes} />
							</Box>

							<RightBar />
						</Flex>
						<BottomBar />
					</Flex>
				</Suspense>
			)}
			{hasSupport && (
				<>
					{/* @ts-ignore */}
					<da-web-chat
						greetings="Hey There,"
						message="How can I help you today?"
						APIKey="a191dc5a-d5be-49f3-90b6-2d1b22d9c9cf"
					/>
				</>
			)}
		</Layout>
	);
};

export default BongaLayout;
