import React from 'react';
import {Heading, Text} from "@chakra-ui/react";
import {primaryBlack, primaryColor} from "../../theme/theme";

interface SubHeadingProps {
    title?: string;
    color?: string;
    children?: React.ReactNode;
    [key: string]: any;

}
interface LgHeadingProps {
    currency?: boolean;
    children: React.ReactNode;
    [key: string]: any;
}
interface MainHeadingProps {
    [key: string]: any;
    children?: React.ReactNode;
}
export const SubHeading = (props: SubHeadingProps) => {
    return (
        <Text {...props} textTransform={"capitalize"} color={props.color??`${primaryBlack}bb`} fontSize={"xs"} fontWeight={800}>
            {props.title??props.children}
        </Text>

    );
};
export const LgHeading = ({currency,children,...rest}: LgHeadingProps) => {
    return (
        <Heading  color={primaryBlack}
                 fontSize={currency ? "lg" : "2xl"} fontWeight={"bold"} {...rest}>
            {children}
        </Heading>
    );
};
export const MainHeading = (props: MainHeadingProps) => {
    return (
        <Heading
            py={".5rem"}
            fontSize={"sm"}
            fontWeight={"bold"}
            {...props}>
            {props.children}
        </Heading>
    );
};
export const GHeading = (props: MainHeadingProps) => {
    return (
        <Heading
            color={'gray'}
            py={".5rem"}
            fontSize={"sm"}
            fontWeight={"normal"}
            {...props}>
            {props.children}
        </Heading>
    );
};
export const CHeading = (props: MainHeadingProps) => {
    return (
        <Heading
            color={primaryColor}
            py={".5rem"}
            fontSize={"sm"}
            fontWeight={"bold"}
            {...props}>
            {props.children}
        </Heading>
    );
};