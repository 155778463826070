import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import PageContainer from "../../containers/PageContainer";
import BongaCard from "../../general/Card";
import CustomPageHeader from "../../headers/CustomPageHeader";
import CustomTextButton from "../../buttons/CustomTextButton";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

export default function SurveySubmitted() {
  const [loading, setLoading] = useState<boolean>(false);
  const loggedIn = useSelector((state: any) => state.LoggedIn);
  const navigate = useNavigate();
  return (
    <PageContainer
      h={loggedIn ? "auto" : "100vh"}
      overflowY={"scroll"}
      p={loggedIn ? ".5rem" : "3rem 10rem"}
    >
      <CustomPageHeader title={"Survey Response"} />
      <BongaCard loading={loading} p={"1rem"} h={"auto"}>
        <Alert
          flexDirection={"column"}
          status="success"
          alignItems={"center"}
          justifyContent={"center"}
        >
          <AlertIcon boxSize={"3rem"} />
          <AlertTitle fontSize={"1.5rem"} my={".5rem"}>
            {"Survey Submitted Successfully"}
          </AlertTitle>
          <AlertDescription>
            {
              "Thank you for submitting your response. An email will be sent to your email address."
            }
          </AlertDescription>
        </Alert>

        <HStack my={".5rem"}>
          <Spacer />
          <CustomTextButton
            onClick={() => navigate("/apps/surveys")}
            colorScheme={"gray"}
            fontWeight={"bold"}
          >
            <MdArrowBack />
            {"Go Back"}
          </CustomTextButton>
        </HStack>
      </BongaCard>
    </PageContainer>
  );
}
