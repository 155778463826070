import { SET_PHONE_CONNECT } from "../actions/actions";

const PhoneConnectState = (state: boolean = false, action: any) => {
    switch (action.type) {
        case SET_PHONE_CONNECT:
            return action.payload;
        default:
            return state;
    }
};
export default PhoneConnectState;