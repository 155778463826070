/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: rbac.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var user_pb = require('./user_pb.js')

var branch_pb = require('./branch_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./rbac_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.RoleServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.RoleServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateRoleRequest,
 *   !proto.da.proto.CreateRoleResponse>}
 */
const methodDescriptor_RoleService_CreateRole = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/CreateRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateRoleRequest,
  proto.da.proto.CreateRoleResponse,
  /**
   * @param {!proto.da.proto.CreateRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.createRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/CreateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_CreateRole,
      callback);
};


/**
 * @param {!proto.da.proto.CreateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.createRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/CreateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_CreateRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadRoleRequest,
 *   !proto.da.proto.ReadRoleResponse>}
 */
const methodDescriptor_RoleService_ReadRole = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/ReadRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadRoleRequest,
  proto.da.proto.ReadRoleResponse,
  /**
   * @param {!proto.da.proto.ReadRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.readRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/ReadRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_ReadRole,
      callback);
};


/**
 * @param {!proto.da.proto.ReadRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.readRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/ReadRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_ReadRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadRolesRequest,
 *   !proto.da.proto.ReadRolesResponse>}
 */
const methodDescriptor_RoleService_ReadRoles = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/ReadRoles',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadRolesRequest,
  proto.da.proto.ReadRolesResponse,
  /**
   * @param {!proto.da.proto.ReadRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadRolesResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.readRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/ReadRoles',
      request,
      metadata || {},
      methodDescriptor_RoleService_ReadRoles,
      callback);
};


/**
 * @param {!proto.da.proto.ReadRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadRolesResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.readRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/ReadRoles',
      request,
      metadata || {},
      methodDescriptor_RoleService_ReadRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateRoleRequest,
 *   !proto.da.proto.UpdateRoleResponse>}
 */
const methodDescriptor_RoleService_UpdateRole = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/UpdateRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateRoleRequest,
  proto.da.proto.UpdateRoleResponse,
  /**
   * @param {!proto.da.proto.UpdateRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.updateRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/UpdateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_UpdateRole,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.updateRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/UpdateRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_UpdateRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteRoleRequest,
 *   !proto.da.proto.DeleteRoleResponse>}
 */
const methodDescriptor_RoleService_DeleteRole = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/DeleteRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteRoleRequest,
  proto.da.proto.DeleteRoleResponse,
  /**
   * @param {!proto.da.proto.DeleteRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.deleteRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/DeleteRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_DeleteRole,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.deleteRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/DeleteRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_DeleteRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.PermissionToRoleRequest,
 *   !proto.da.proto.PermissionToRoleResponse>}
 */
const methodDescriptor_RoleService_AddPermissionToRole = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/AddPermissionToRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.PermissionToRoleRequest,
  proto.da.proto.PermissionToRoleResponse,
  /**
   * @param {!proto.da.proto.PermissionToRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.PermissionToRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.PermissionToRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.PermissionToRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.PermissionToRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.addPermissionToRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/AddPermissionToRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_AddPermissionToRole,
      callback);
};


/**
 * @param {!proto.da.proto.PermissionToRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.PermissionToRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.addPermissionToRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/AddPermissionToRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_AddPermissionToRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.PermissionToRoleRequest,
 *   !proto.da.proto.PermissionToRoleResponse>}
 */
const methodDescriptor_RoleService_RemovePermissionFromRole = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/RemovePermissionFromRole',
  grpc.web.MethodType.UNARY,
  proto.da.proto.PermissionToRoleRequest,
  proto.da.proto.PermissionToRoleResponse,
  /**
   * @param {!proto.da.proto.PermissionToRoleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.PermissionToRoleResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.PermissionToRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.PermissionToRoleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.PermissionToRoleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.removePermissionFromRole =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/RemovePermissionFromRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_RemovePermissionFromRole,
      callback);
};


/**
 * @param {!proto.da.proto.PermissionToRoleRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.PermissionToRoleResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.removePermissionFromRole =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/RemovePermissionFromRole',
      request,
      metadata || {},
      methodDescriptor_RoleService_RemovePermissionFromRole);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadRolesRequest,
 *   !proto.da.proto.ReadRolesResponse>}
 */
const methodDescriptor_RoleService_ReadUserRoles = new grpc.web.MethodDescriptor(
  '/da.proto.RoleService/ReadUserRoles',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadRolesRequest,
  proto.da.proto.ReadRolesResponse,
  /**
   * @param {!proto.da.proto.ReadRolesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadRolesResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.RoleServiceClient.prototype.readUserRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.RoleService/ReadUserRoles',
      request,
      metadata || {},
      methodDescriptor_RoleService_ReadUserRoles,
      callback);
};


/**
 * @param {!proto.da.proto.ReadRolesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadRolesResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.RoleServicePromiseClient.prototype.readUserRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.RoleService/ReadUserRoles',
      request,
      metadata || {},
      methodDescriptor_RoleService_ReadUserRoles);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.PermissionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.PermissionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadPermissionsRequest,
 *   !proto.da.proto.ReadPermissionsResponse>}
 */
const methodDescriptor_PermissionService_ReadPermissions = new grpc.web.MethodDescriptor(
  '/da.proto.PermissionService/ReadPermissions',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadPermissionsRequest,
  proto.da.proto.ReadPermissionsResponse,
  /**
   * @param {!proto.da.proto.ReadPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.PermissionServiceClient.prototype.readPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.PermissionService/ReadPermissions',
      request,
      metadata || {},
      methodDescriptor_PermissionService_ReadPermissions,
      callback);
};


/**
 * @param {!proto.da.proto.ReadPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.PermissionServicePromiseClient.prototype.readPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.PermissionService/ReadPermissions',
      request,
      metadata || {},
      methodDescriptor_PermissionService_ReadPermissions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadUsersByPermissionRequest,
 *   !proto.da.proto.ReadUsersByPermissionResponse>}
 */
const methodDescriptor_PermissionService_ReadUsersByPermission = new grpc.web.MethodDescriptor(
  '/da.proto.PermissionService/ReadUsersByPermission',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadUsersByPermissionRequest,
  proto.da.proto.ReadUsersByPermissionResponse,
  /**
   * @param {!proto.da.proto.ReadUsersByPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadUsersByPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadUsersByPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadUsersByPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadUsersByPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.PermissionServiceClient.prototype.readUsersByPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.PermissionService/ReadUsersByPermission',
      request,
      metadata || {},
      methodDescriptor_PermissionService_ReadUsersByPermission,
      callback);
};


/**
 * @param {!proto.da.proto.ReadUsersByPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadUsersByPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.PermissionServicePromiseClient.prototype.readUsersByPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.PermissionService/ReadUsersByPermission',
      request,
      metadata || {},
      methodDescriptor_PermissionService_ReadUsersByPermission);
};


module.exports = proto.da.proto;

