import { SET_LANGUAGE } from "../actions/actions";

export default function Language(state: string = "en", action: any) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}
