import {UPDATE_TICKET_THREADS} from "../actions/actions";

const TicketUpdate =(state:boolean=false, action:any)=>{
    switch (action.type) {
        case UPDATE_TICKET_THREADS:
            return !state;
        default:
            return state;
    }
};
export default  TicketUpdate;