/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: branch.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./branch_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.BranchServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.BranchServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateBranchRequest,
 *   !proto.da.proto.CreateBranchResponse>}
 */
const methodDescriptor_BranchService_CreateBranch = new grpc.web.MethodDescriptor(
  '/da.proto.BranchService/CreateBranch',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateBranchRequest,
  proto.da.proto.CreateBranchResponse,
  /**
   * @param {!proto.da.proto.CreateBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateBranchResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.BranchServiceClient.prototype.createBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.BranchService/CreateBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_CreateBranch,
      callback);
};


/**
 * @param {!proto.da.proto.CreateBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateBranchResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.BranchServicePromiseClient.prototype.createBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.BranchService/CreateBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_CreateBranch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadBranchRequest,
 *   !proto.da.proto.ReadBranchResponse>}
 */
const methodDescriptor_BranchService_ReadBranch = new grpc.web.MethodDescriptor(
  '/da.proto.BranchService/ReadBranch',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadBranchRequest,
  proto.da.proto.ReadBranchResponse,
  /**
   * @param {!proto.da.proto.ReadBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadBranchResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.BranchServiceClient.prototype.readBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.BranchService/ReadBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_ReadBranch,
      callback);
};


/**
 * @param {!proto.da.proto.ReadBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadBranchResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.BranchServicePromiseClient.prototype.readBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.BranchService/ReadBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_ReadBranch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadBranchesRequest,
 *   !proto.da.proto.ReadBranchesResponse>}
 */
const methodDescriptor_BranchService_ReadBranches = new grpc.web.MethodDescriptor(
  '/da.proto.BranchService/ReadBranches',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadBranchesRequest,
  proto.da.proto.ReadBranchesResponse,
  /**
   * @param {!proto.da.proto.ReadBranchesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadBranchesResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadBranchesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadBranchesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadBranchesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.BranchServiceClient.prototype.readBranches =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.BranchService/ReadBranches',
      request,
      metadata || {},
      methodDescriptor_BranchService_ReadBranches,
      callback);
};


/**
 * @param {!proto.da.proto.ReadBranchesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadBranchesResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.BranchServicePromiseClient.prototype.readBranches =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.BranchService/ReadBranches',
      request,
      metadata || {},
      methodDescriptor_BranchService_ReadBranches);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateBranchRequest,
 *   !proto.da.proto.UpdateBranchResponse>}
 */
const methodDescriptor_BranchService_UpdateBranch = new grpc.web.MethodDescriptor(
  '/da.proto.BranchService/UpdateBranch',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateBranchRequest,
  proto.da.proto.UpdateBranchResponse,
  /**
   * @param {!proto.da.proto.UpdateBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateBranchResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.BranchServiceClient.prototype.updateBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.BranchService/UpdateBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_UpdateBranch,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateBranchResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.BranchServicePromiseClient.prototype.updateBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.BranchService/UpdateBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_UpdateBranch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteBranchRequest,
 *   !proto.da.proto.DeleteBranchResponse>}
 */
const methodDescriptor_BranchService_DeleteBranch = new grpc.web.MethodDescriptor(
  '/da.proto.BranchService/DeleteBranch',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteBranchRequest,
  proto.da.proto.DeleteBranchResponse,
  /**
   * @param {!proto.da.proto.DeleteBranchRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteBranchResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteBranchResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteBranchResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.BranchServiceClient.prototype.deleteBranch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.BranchService/DeleteBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_DeleteBranch,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteBranchRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteBranchResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.BranchServicePromiseClient.prototype.deleteBranch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.BranchService/DeleteBranch',
      request,
      metadata || {},
      methodDescriptor_BranchService_DeleteBranch);
};


module.exports = proto.da.proto;

