// ES6 module syntax
import LocalizedStrings from "react-localization";
import en from "./en";
import fr from "./fr";

let strings = new LocalizedStrings({
	en,
	fr,
});

export const setLanguage = (language: string) => {
	strings.setLanguage(language);
};

export default strings;
