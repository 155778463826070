import { Box, Center, HStack, Text } from "@chakra-ui/react";
import { Spin } from "antd";
import * as grpc from "grpc-web";
import { RpcError } from "grpc-web";
import { ReactNode, useEffect, useState } from "react";
import { BiCloudDownload } from "react-icons/bi";
import { useSelector } from "react-redux";
import { subtleColors } from "../../../assets/data";
import { notify } from "../../../gateway/Functions";
import {
  getCSVStream,
  ReadResponseAnalytics,
} from "../../../gateway/gRPC/Utils/SurveyMethods";
import {
  Analytics,
  ChoiceTypeResponse,
  ChoiceTypeResponseAnalytics,
  ContactTypeResponseAnalytics,
  DateTypeResponseAnalytics,
  MatrixTypeResponseAnalytics,
  NPSTypeResponseAnalytics,
  QuestionAnalytics,
  QuestionType,
  RatingTypeResponseAnalytics,
  ReadAnalyticsResponse,
  ReadResponseCSVResponse,
  Survey,
  TextTypeResponseAnalytics,
} from "../../../gateway/gRPC/pb/surveys_pb";
import strings from "../../../localization/main";
import { gray_50 } from "../../../theme/theme";
import CustomButton from "../../buttons/CustomButton";
import BongaCard from "../../general/Card";
import BarGraph from "../../stats/BarGraph";
import SurveysRadarChart from "../../stats/SurveysRadarChart";
import SimplePieChart from "../../stats/livereports/SimplePieChart";
import SimpleRadialBar from "../../stats/livereports/SimpleRadialBar";
import QuestionBase from "../questionnaire/QuestionBase";

const ResponsesComponent = ({
  survey,
  loading,
}: {
  survey: Survey.AsObject;
  loading: boolean;
}) => {
  const [response, setResponse] = useState<Analytics.AsObject>();
  const [csvLoading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (!!survey) {
      ReadResponseAnalytics(survey?.id)
        .then((responses: ReadAnalyticsResponse.AsObject) => {
          setResponse(responses.res);
        })
        .catch((err: RpcError) => {
          console.log(err);
        });
    }
  }, [survey?.id]);
  const handleCSVDownload = () => {
    getCSVStream(survey.id)
      .then((responses: ReadResponseCSVResponse[]) => {
        const csv = responses
          .map((response) => response.getRowList())
          .join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${
          survey.serialNumber
        }_responses_${new Date().toDateString()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        notify(
          "success",
          "bottomLeft",
          "Success",
          "Your responses data has been successfully downloaded to your computer."
        );
      })
      .catch((err: grpc.RpcError) => {
        notify(
          "error",
          "bottomLeft",
          "Error",
          "Your responses data could not be downloaded"
        );
      });
  };

  return (
    <Spin spinning={csvLoading}>
      <BongaCard loading={loading} p={"1rem"}>
        <Center bg={"blue.100"} p={"1rem"}>
          <HStack
            w={"100%"}
            gap={"1rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box textAlign={"center"}>
              <Text fontWeight={"bold"} fontSize={"2xl"}>
                {response?.responseCount}
              </Text>
              <Text>{"Total Responses"}</Text>
            </Box>
            <Box textAlign={"center"}>
              <Text fontWeight={"bold"} fontSize={"2xl"}>
                {response?.averageResponseTime}
              </Text>
              <Text>{"Average Response time"}</Text>
            </Box>
            <Box textAlign={"center"}>
              <CustomButton colorScheme={"blue"} onClick={handleCSVDownload}>
                <BiCloudDownload />
                {strings.download}
              </CustomButton>
            </Box>
          </HStack>
        </Center>
        {response?.questionsList.map(
          (question: QuestionAnalytics.AsObject, index: number) => (
            <QuestionBase isAnalytics question={question.question!}>
              <SwitchResponsesType
                questionType={question.question?.type!}
                typeData={
                  question?.question?.type === QuestionType["CHOICE"]
                    ? question.choice!
                    : question?.question?.type === QuestionType["CONTACT"]
                    ? question.contact!
                    : question?.question?.type === QuestionType["RATING"]
                    ? question.rating!
                    : question?.question?.type === QuestionType["MATRIX"]
                    ? question.matrix!
                    : question?.question?.type === QuestionType["NPS"]
                    ? question.nps!
                    : question?.question?.type === QuestionType["DATE"]
                    ? question.date!
                    : question.text!
                }
              />
            </QuestionBase>
          )
        )}
      </BongaCard>
    </Spin>
  );
};

const SwitchResponsesType = ({
  questionType,
  typeData,
}: {
  questionType: QuestionType;
  typeData:
    | MatrixTypeResponseAnalytics.AsObject
    | TextTypeResponseAnalytics.AsObject
    | ChoiceTypeResponseAnalytics.AsObject
    | NPSTypeResponseAnalytics.AsObject
    | RatingTypeResponseAnalytics.AsObject
    | DateTypeResponseAnalytics.AsObject
    | ContactTypeResponseAnalytics.AsObject;
}) => {
  switch (questionType) {
    case QuestionType.CHOICE:
      return (
        <ChoiceType
          options={typeData as ChoiceTypeResponseAnalytics.AsObject}
        />
      );
    case QuestionType.TEXT:
      return (
        <SimpleTypes
          responses={
            (typeData as TextTypeResponseAnalytics.AsObject).recentResponsesList
          }
          count={(typeData as TextTypeResponseAnalytics.AsObject).responseCount}
        />
      );
    case QuestionType.RATING:
      return (
        <RatingType
          typeData={typeData as RatingTypeResponseAnalytics.AsObject}
        />
      );
    case QuestionType.MATRIX:
      return (
        <MatrixType
          typeData={typeData as MatrixTypeResponseAnalytics.AsObject}
        />
      );
    case QuestionType.DATE:
      return (
        <SimpleTypes
          responses={
            (typeData as DateTypeResponseAnalytics.AsObject).recentResponsesList
          }
          count={(typeData as DateTypeResponseAnalytics.AsObject).responseCount}
        />
      );
    case QuestionType.CONTACT:
      return (
        <SimpleTypes
          responses={
            (typeData as ContactTypeResponseAnalytics.AsObject)
              .recentResponsesList
          }
          count={
            (typeData as ContactTypeResponseAnalytics.AsObject).responseCount
          }
        />
      );
    case QuestionType.NPS:
      return (
        <NPSType npsType={typeData as NPSTypeResponseAnalytics.AsObject} />
      );
    default:
      return null;
  }
};

const ChoiceType = ({
  options,
}: {
  options: ChoiceTypeResponseAnalytics.AsObject;
}) => {
  const { expanded } = useSelector((state: any) => state.expandRightBar);
  return (
    <AnalyticsWrapper>
      <Box>
        {options.optionsList.map(
          (option: ChoiceTypeResponse.AsObject, index: number) => (
            <HStack key={index}>
              <Box w={"10px"} h={"10px"} bg={subtleColors[index]} />
              <Text fontSize={"sm"}>{option.option}</Text>
              <Text fontSize={"sm"}>{":"}</Text>
              <Text fontWeight={"bold"} fontSize={"sm"}>
                {option.count}
              </Text>
            </HStack>
          )
        )}
      </Box>
      <Box textAlign={"center"}>
        <SimplePieChart
          reload={expanded}
          labels={options.optionsList.map((options) => options.option)}
          series={options.optionsList.map((options) => options.count)}
        />
      </Box>
    </AnalyticsWrapper>
  );
};
const RatingType = ({
  typeData,
}: {
  typeData: RatingTypeResponseAnalytics.AsObject;
}) => {
  const { averageRating, ratingsMap } = typeData;
  return (
    <AnalyticsWrapper>
      <Box textAlign={"center"}>
        <Text fontWeight={"bold"} fontSize={"2xl"}>
          {averageRating.toFixed(2)}
        </Text>
        <Text fontSize={"sm"}>{"Average ratings"}</Text>
      </Box>
      <Box textAlign={"center"}>
        <BarGraph
          series={ratingsMap.map((rating: [string, number]) => rating[1])}
          reload
          scale={ratingsMap.map((rating: [string, number]) => rating[0])}
        />
      </Box>
    </AnalyticsWrapper>
  );
};

const MatrixType = ({
  typeData,
}: {
  typeData: MatrixTypeResponseAnalytics.AsObject;
}) => {
  const { matrixList, responseCount, optionsList, statementsList } = typeData;
  return (
    <AnalyticsWrapper>
      <Box textAlign={"center"}>
        <Text fontWeight={"bold"} fontSize={"1xl"}>
          {responseCount}
        </Text>
        <Text>{"Responses"}</Text>
      </Box>
      <Box textAlign={"center"}>
        {/* <MultiBarGraph
          reload
          options={[]}
          labels={optionsList}
          statements={statementsList}
        /> */}
        <SurveysRadarChart data={typeData} />
      </Box>
    </AnalyticsWrapper>
  );
};
const NPSType = ({
  npsType,
}: {
  npsType: NPSTypeResponseAnalytics.AsObject;
}) => {
  const { averageScore, rating, responseCount } = npsType;
  const labels: string[] = [...Object.keys(rating!)];
  const series: number[] = [
    ...Object.keys(rating!).map((option) =>
      parseInt(`${(rating as any)[option]}`)
    ),
  ];
  const nps: number = rating?.promoters! ?? 0 - rating?.detractors! ?? 0;
  const maxNumber: number = Math.max(...series);
  const labelWithMaxNumber: string = `${labels.find(
    (label: string) => (rating as any)[label] === maxNumber
  )}`;
  return (
    <AnalyticsWrapper>
      <Box>
        {labels.map((key: string, index: number) => (
          <HStack key={index}>
            <Box w={"10px"} h={"10px"} bg={subtleColors[index]} />
            <Text fontSize={"sm"} textTransform={"capitalize"}>
              {key}
            </Text>
            <Text fontSize={"sm"}>{":"}</Text>
            <Text fontWeight={"bold"} fontSize={"sm"}>
              {parseFloat((rating as any)[key]).toFixed(0)}%
            </Text>
          </HStack>
        ))}
      </Box>
      <Box textAlign={"center"}>
        <SimpleRadialBar
          reload={false}
          labels={labels}
          series={series}
          nps={`${
            rating?.promoters! < rating?.detractors! ? "-" : "+"
          }${nps.toFixed(0)}`}
        />
      </Box>
    </AnalyticsWrapper>
  );
};

const SimpleTypes = ({
  responses,
  count,
}: {
  responses: string[];
  count: number;
}) => {
  return (
    <AnalyticsWrapper>
      <Box textAlign={"center"}>
        <Text fontWeight={"bold"} fontSize={"2xl"}>
          {count}
        </Text>
        <Text>{"Responses"}</Text>
      </Box>
      <Box textAlign={"center"}>
        {responses.map((response, index) => (
          <Text textTransform={"capitalize"} fontWeight={"bold"} key={index}>
            {`"${response}"`}
          </Text>
        ))}
        <Text>{"Recent Responses"}</Text>
      </Box>
    </AnalyticsWrapper>
  );
};

const AnalyticsWrapper = ({ children }: { children: ReactNode }) => (
  <HStack
    bg={gray_50}
    p={"2rem"}
    spacing={4}
    align="center"
    justify="space-between"
    width="100%"
  >
    {children}
  </HStack>
);
export default ResponsesComponent;
