import { HStack } from '@chakra-ui/react'
import React from 'react'

interface Props {
    children: React.ReactNode
    [key: string]: any
}
export default function TableActionContainer({children: Children,...rest}: Props) {
  return (
    <HStack {...rest}>
        {Children}
    </HStack>
  )
}
