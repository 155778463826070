import { TbEye } from "react-icons/tb";
import { Button, Tooltip } from "@chakra-ui/react";
import { useNavigate } from 'react-router-dom';


interface EditButtonProps {
    tooltipLabel?: string;
    hasArrow?: boolean;
    onClick?: () => void;
    route?: string;
    [key: string]: any;
}

function CustomViewButton({ route, tooltipLabel, placement, hasArrow, ...rest }: EditButtonProps) {
    const navigate = useNavigate();
    return (
        <Tooltip label={tooltipLabel} placement={placement} hasArrow={hasArrow}>
            <Button
                placeItems={"center"}
                bg={"gray.200"}
                borderRadius={"3px"}
                _hover={{ bg: "gray.300" }}
                _active={{ bg: "gray.400" }}
                color={"gray.700"}
                size={"xs"}
                onClick={!route ? rest.onClick : () => navigate(route)}
                {...rest}
            >
                <TbEye />
            </Button>
        </Tooltip>
    );
}

export default CustomViewButton;