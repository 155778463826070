/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: contact_groups.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var contact_pb = require('./contact_pb.js')

var group_pb = require('./group_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./contact_groups_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.GroupServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.GroupServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateGroupRequest,
 *   !proto.da.proto.CreateGroupResponse>}
 */
const methodDescriptor_GroupService_CreateGroup = new grpc.web.MethodDescriptor(
  '/da.proto.GroupService/CreateGroup',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateGroupRequest,
  proto.da.proto.CreateGroupResponse,
  /**
   * @param {!proto.da.proto.CreateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateGroupResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.GroupServiceClient.prototype.createGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.GroupService/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateGroup,
      callback);
};


/**
 * @param {!proto.da.proto.CreateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateGroupResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.GroupServicePromiseClient.prototype.createGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.GroupService/CreateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_CreateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadGroupRequest,
 *   !proto.da.proto.ReadGroupResponse>}
 */
const methodDescriptor_GroupService_ReadGroup = new grpc.web.MethodDescriptor(
  '/da.proto.GroupService/ReadGroup',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadGroupRequest,
  proto.da.proto.ReadGroupResponse,
  /**
   * @param {!proto.da.proto.ReadGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadGroupResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.GroupServiceClient.prototype.readGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.GroupService/ReadGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_ReadGroup,
      callback);
};


/**
 * @param {!proto.da.proto.ReadGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadGroupResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.GroupServicePromiseClient.prototype.readGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.GroupService/ReadGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_ReadGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadGroupsRequest,
 *   !proto.da.proto.ReadGroupsResponse>}
 */
const methodDescriptor_GroupService_ReadGroups = new grpc.web.MethodDescriptor(
  '/da.proto.GroupService/ReadGroups',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadGroupsRequest,
  proto.da.proto.ReadGroupsResponse,
  /**
   * @param {!proto.da.proto.ReadGroupsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.GroupServiceClient.prototype.readGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.GroupService/ReadGroups',
      request,
      metadata || {},
      methodDescriptor_GroupService_ReadGroups,
      callback);
};


/**
 * @param {!proto.da.proto.ReadGroupsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadGroupsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.GroupServicePromiseClient.prototype.readGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.GroupService/ReadGroups',
      request,
      metadata || {},
      methodDescriptor_GroupService_ReadGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateGroupRequest,
 *   !proto.da.proto.UpdateGroupResponse>}
 */
const methodDescriptor_GroupService_UpdateGroup = new grpc.web.MethodDescriptor(
  '/da.proto.GroupService/UpdateGroup',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateGroupRequest,
  proto.da.proto.UpdateGroupResponse,
  /**
   * @param {!proto.da.proto.UpdateGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateGroupResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.GroupServiceClient.prototype.updateGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.GroupService/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateGroup,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateGroupResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.GroupServicePromiseClient.prototype.updateGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.GroupService/UpdateGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_UpdateGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteGroupRequest,
 *   !proto.da.proto.DeleteGroupResponse>}
 */
const methodDescriptor_GroupService_DeleteGroup = new grpc.web.MethodDescriptor(
  '/da.proto.GroupService/DeleteGroup',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteGroupRequest,
  proto.da.proto.DeleteGroupResponse,
  /**
   * @param {!proto.da.proto.DeleteGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteGroupResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.GroupServiceClient.prototype.deleteGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.GroupService/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_DeleteGroup,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteGroupResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.GroupServicePromiseClient.prototype.deleteGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.GroupService/DeleteGroup',
      request,
      metadata || {},
      methodDescriptor_GroupService_DeleteGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadGroupContactsRequest,
 *   !proto.da.proto.ReadGroupContactsResponse>}
 */
const methodDescriptor_GroupService_ReadGroupContacts = new grpc.web.MethodDescriptor(
  '/da.proto.GroupService/ReadGroupContacts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadGroupContactsRequest,
  proto.da.proto.ReadGroupContactsResponse,
  /**
   * @param {!proto.da.proto.ReadGroupContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadGroupContactsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadGroupContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadGroupContactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadGroupContactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.GroupServiceClient.prototype.readGroupContacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.GroupService/ReadGroupContacts',
      request,
      metadata || {},
      methodDescriptor_GroupService_ReadGroupContacts,
      callback);
};


/**
 * @param {!proto.da.proto.ReadGroupContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadGroupContactsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.GroupServicePromiseClient.prototype.readGroupContacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.GroupService/ReadGroupContacts',
      request,
      metadata || {},
      methodDescriptor_GroupService_ReadGroupContacts);
};


module.exports = proto.da.proto;

