import { SET_USER_SIP, REMOVE_USER_SIP } from "../actions/actions";
import { UserSip } from "../../types/DataTypes";


const SipSettings = (state: Partial<UserSip> = {}, action: any) => {
    switch (action.type) {
        case SET_USER_SIP:
            return action.payload;
        case REMOVE_USER_SIP:
            return action.payload;
        default:
            return state;
    }
}
export default SipSettings;