import {
  ClientReadableStream,
  Metadata,
  Request,
  RpcError,
  StreamInterceptor,
} from "grpc-web";
import { AuthServiceClient } from "../pb/auth_grpc_web_pb";
import { BranchServiceClient } from "../pb/branch_grpc_web_pb";
import { GroupServiceClient } from "../pb/contact_groups_grpc_web_pb";
import { ContactServiceClient } from "../pb/contact_grpc_web_pb";
import { DepartmentServiceClient } from "../pb/department_grpc_web_pb";
import { LabelServiceClient } from "../pb/label_grpc_web_pb";
import { OrganisationServiceClient } from "../pb/organisations_grpc_web_pb";

import { DocumentServiceClient } from "../pb/document_grpc_web_pb";
import {
  PermissionServiceClient,
  RoleServiceClient,
} from "../pb/rbac_grpc_web_pb";
import {
  QuestionServiceClient,
  ResponseServiceClient,
  SectionServiceClient,
  SurveyServiceClient,
} from "../pb/surveys_grpc_web_pb";
import { TagServiceClient } from "../pb/tag_grpc_web_pb";
import { UserServiceClient } from "../pb/user_grpc_web_pb";
import { WhatsappServiceClient } from "../pb/whatsapp_campaigns_grpc_web_pb";
import { AppServiceClient } from "../pb/apps_grpc_web_pb";

export const surveyHostName = (): string => {
  return (
    (process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_SURVEY_HOST_NAME_LOCAL
      : process.env.REACT_APP_SURVEY_HOST_NAME) ?? ""
  );
};
export const orgHostName = (): string => {
  return (
    (process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_ORGANIZATION_HOST_NAME_LOCAL
      : process.env.REACT_APP_ORGANIZATION_HOST_NAME) ?? ""
  );
};

export const campaignsHostName = (): string => {
  return process.env.REACT_APP_CAMPAIGNS_HOST_DEV ?? "";
};

export const whatsappCampaignsService = new WhatsappServiceClient(
  campaignsHostName(),
  null,
  null
);

export const surveyService = new SurveyServiceClient(
  surveyHostName(),
  null,
  null
);

export const responseService = new ResponseServiceClient(
  surveyHostName(),
  null,
  null
);
export const questionService = new QuestionServiceClient(
  surveyHostName(),
  null,
  null
);

export const sectionService = new SectionServiceClient(
  surveyHostName(),
  null,
  null
);

export const orgService = new OrganisationServiceClient(
  orgHostName(),
  null,
  null
);

export const userService = new UserServiceClient(orgHostName(), null, null);
export const authService = new AuthServiceClient(orgHostName(), null, null);
export const appService = new AppServiceClient(orgHostName(), null, null);
export const roleService = new RoleServiceClient(orgHostName(), null, null);
export const permissionService = new PermissionServiceClient(
  orgHostName(),
  null,
  null
);
export const contactService = new ContactServiceClient(
  orgHostName(),
  null,
  null
);
export const contactGroupService = new GroupServiceClient(
  orgHostName(),
  null,
  null
);
export const tagService = new TagServiceClient(orgHostName(), null, null);
export const labelService = new LabelServiceClient(orgHostName(), null, null);
export const branchService = new BranchServiceClient(orgHostName(), null, null);
export const departmentService = new DepartmentServiceClient(
  orgHostName(),
  null,
  null
);

export const documentService = new DocumentServiceClient(
  orgHostName(),
  null,
  null
);

class AuthInterceptorStream<REQ extends Request<any, any>, RES = any>
  implements StreamInterceptor<REQ, RES>
{
  intercept(
    request: REQ,
    invoker: (request: REQ, metadata?: Metadata) => ClientReadableStream<RES>
  ) {
    const metadata = request.getMetadata();
    const token = localStorage.getItem("accessToken");
    if (token) metadata["authorization"] = token;
    metadata["Content-Type"] = "application/grpc-web-text+proto";
    const stream = invoker(request);
    return new AuthReadableStreamWrapper<RES>(stream);
  }
}

class AuthReadableStreamWrapper<RES = any> {
  private stream: ClientReadableStream<RES>;

  constructor(stream: ClientReadableStream<RES>) {
    this.stream = stream;
  }

  on<F extends Function>(eventType: any, callback: F) {
    if (eventType === "error") {
      this.stream.on("error", (err: RpcError) => {
        callback(err);
      });
    } else if (eventType === "data") {
      this.stream.on("data", (resp) => {
        callback(resp);
      });
    } else if (eventType === "status") {
      this.stream.on("status", (status) => {
        callback(status);
      });
    } else if (eventType === "end") {
      this.stream.on("end", callback as any);
    }
    return this;
  }

  removeListener(eventType: any, callback: any) {
    this.stream.removeListener(eventType, callback);
  }

  cancel() {
    this.stream.cancel();
    return this;
  }
}

export { AuthInterceptorStream };
