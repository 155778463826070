import React from "react";
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { ModalDisclosure } from "../../types/DataTypes";
import { SubHeading } from "../headings/Headings";

interface MyModalProps {
	size?:
	| "xs"
	| "sm"
	| "md"
	| "lg"
	| "xl"
	| "full"
	| "3xl"
	| "2xl"
	| "4xl"
	| "5xl"
	| "6xl"
	| "7xl"
	| "8xl"
	| "9xl"
	| undefined;
	closable?: boolean;
	children?: React.ReactNode;
	disclosure: ModalDisclosure;
	title?: string | null;
	scrollBehavior?: "inside" | "outside" | undefined;
	[key: string]: any;
}
const MyModal = ({
	title,
	scrollBehavior,
	size,
	closeOnOverlayClick,
	children,
	disclosure,
	...rest
}: MyModalProps) => {
	const { isOpen, onClose } = disclosure;
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={size}
			isCentered
			closeOnOverlayClick={closeOnOverlayClick}
			scrollBehavior={scrollBehavior ?? "inside"}
		>
			<ModalOverlay />
			<ModalContent onClick={(e: any) => e.stopPropagation()}>
				<ModalHeader>
					<ModalCloseButton />
					<SubHeading py={".5rem"}>{title}</SubHeading>
				</ModalHeader>
				<ModalBody {...rest}>{children}</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default MyModal;
