/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: tag.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.proto = require('./tag_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.TagServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.TagServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateTagRequest,
 *   !proto.da.proto.CreateTagResponse>}
 */
const methodDescriptor_TagService_CreateTag = new grpc.web.MethodDescriptor(
  '/da.proto.TagService/CreateTag',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateTagRequest,
  proto.da.proto.CreateTagResponse,
  /**
   * @param {!proto.da.proto.CreateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateTagResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TagServiceClient.prototype.createTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TagService/CreateTag',
      request,
      metadata || {},
      methodDescriptor_TagService_CreateTag,
      callback);
};


/**
 * @param {!proto.da.proto.CreateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateTagResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TagServicePromiseClient.prototype.createTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TagService/CreateTag',
      request,
      metadata || {},
      methodDescriptor_TagService_CreateTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadTagRequest,
 *   !proto.da.proto.ReadTagResponse>}
 */
const methodDescriptor_TagService_ReadTag = new grpc.web.MethodDescriptor(
  '/da.proto.TagService/ReadTag',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadTagRequest,
  proto.da.proto.ReadTagResponse,
  /**
   * @param {!proto.da.proto.ReadTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadTagResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TagServiceClient.prototype.readTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TagService/ReadTag',
      request,
      metadata || {},
      methodDescriptor_TagService_ReadTag,
      callback);
};


/**
 * @param {!proto.da.proto.ReadTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadTagResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TagServicePromiseClient.prototype.readTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TagService/ReadTag',
      request,
      metadata || {},
      methodDescriptor_TagService_ReadTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadTagsRequest,
 *   !proto.da.proto.ReadTagsResponse>}
 */
const methodDescriptor_TagService_ReadTags = new grpc.web.MethodDescriptor(
  '/da.proto.TagService/ReadTags',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadTagsRequest,
  proto.da.proto.ReadTagsResponse,
  /**
   * @param {!proto.da.proto.ReadTagsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadTagsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadTagsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadTagsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TagServiceClient.prototype.readTags =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TagService/ReadTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ReadTags,
      callback);
};


/**
 * @param {!proto.da.proto.ReadTagsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadTagsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TagServicePromiseClient.prototype.readTags =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TagService/ReadTags',
      request,
      metadata || {},
      methodDescriptor_TagService_ReadTags);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateTagRequest,
 *   !proto.da.proto.UpdateTagResponse>}
 */
const methodDescriptor_TagService_UpdateTag = new grpc.web.MethodDescriptor(
  '/da.proto.TagService/UpdateTag',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateTagRequest,
  proto.da.proto.UpdateTagResponse,
  /**
   * @param {!proto.da.proto.UpdateTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateTagResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TagServiceClient.prototype.updateTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TagService/UpdateTag',
      request,
      metadata || {},
      methodDescriptor_TagService_UpdateTag,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateTagResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TagServicePromiseClient.prototype.updateTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TagService/UpdateTag',
      request,
      metadata || {},
      methodDescriptor_TagService_UpdateTag);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteTagRequest,
 *   !proto.da.proto.DeleteTagResponse>}
 */
const methodDescriptor_TagService_DeleteTag = new grpc.web.MethodDescriptor(
  '/da.proto.TagService/DeleteTag',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteTagRequest,
  proto.da.proto.DeleteTagResponse,
  /**
   * @param {!proto.da.proto.DeleteTagRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteTagResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteTagResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteTagResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TagServiceClient.prototype.deleteTag =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TagService/DeleteTag',
      request,
      metadata || {},
      methodDescriptor_TagService_DeleteTag,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteTagRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteTagResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TagServicePromiseClient.prototype.deleteTag =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TagService/DeleteTag',
      request,
      metadata || {},
      methodDescriptor_TagService_DeleteTag);
};


module.exports = proto.da.proto;

