import { HStack, Spacer, Text, Tag } from "@chakra-ui/react";
interface Props {
    colorScheme: string;
    size: string;
    weight: string;
    text: string;
    icon?: any;
    action?: React.ReactNode
    [key: string]: any;
}
const CustomGeneralTag = ({ text, icon, action, colorScheme, size, weight, ...rest }: Props) => {
    return (
        <Tag
            w={"fit-content"}
            colorScheme={colorScheme}
            p={".2rem .5rem"}
            fontSize={size}
            size={size}
            borderRadius={"3px"}
            key={text}
            {...rest}
        >
            <HStack w={"100%"} gap={"0"}>
                {icon}
                <Text m={0} textTransform={"capitalize"} fontSize={size} fontWeight={weight}>
                    {text}
                </Text>
                <Spacer />
                {action}
            </HStack>
        </Tag>
    );
};

export default CustomGeneralTag;
