import { Flex } from "@chakra-ui/react";
import { Spin } from 'antd';

interface Props {
    children: React.ReactNode;
    loading?: boolean
    [key: string]: any;
}
const PageContainer = ({ children, loading, ...rest }: Props) => {
    return (
        <Spin spinning={loading ?? false}>
            <Flex
                w={"100%"}
                h={"100%"}
                flexFlow={"column"}
                {...rest}
                gap={".5rem"}
            >
                {children}
            </Flex>
        </Spin>
    );
};

export default PageContainer;
