import {COLLAPSE_SIDEBAR} from "../actions/actions";

const CollapsedSideBar = (state = false, action: any) => {
    switch (action.type) {
        case COLLAPSE_SIDEBAR:
        return !state;
        default:
        return state;
    }
}
export default CollapsedSideBar;