import { Menu, MenuButton, MenuGroup, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { CgOptions } from 'react-icons/cg';
import { HiScale } from 'react-icons/hi';
import { MdAbc, MdAdd, MdOutlineCheckBox, MdOutlineContactMail } from 'react-icons/md';
import { TbCalendarCheck, TbStar } from 'react-icons/tb';
import { QuestionType } from '../../../gateway/gRPC/pb/surveys_pb';
import CustomButton from '../../buttons/CustomButton';

interface QuestionTypeMenuItem {
    typeName: string;
    typeIcon: ReactNode;
    onClick: () => void;
}
type QuestionTypeCallback = (type: QuestionType,sectionId?: string) => void;
const QuestionTypeMenu = ({
    callback,
    isDisabled,
    sectionId,
}: {
    callback: QuestionTypeCallback;
    isDisabled: boolean;
    sectionId?: string;
}) => {
    const questionTypes: QuestionTypeMenuItem[] = [
        {
        typeName: "CHOICE",
        typeIcon: <MdOutlineCheckBox />,
        onClick: () => callback(QuestionType["CHOICE"],sectionId??""),
        },
        {
        typeName: "TEXT",
        typeIcon: <MdAbc />,
        onClick: () => callback(QuestionType["TEXT"],sectionId??""),
        },
        {
        typeName: "NPS",
        typeIcon: <HiScale />,
        onClick: () => callback(QuestionType["NPS"],sectionId??""),
        },
        {
        typeName: "CONTACT",
        typeIcon: <MdOutlineContactMail />,
        onClick: () => callback(QuestionType["CONTACT"],sectionId??""),
        },
        {
        typeName: "DATE",
        typeIcon: <TbCalendarCheck />,
        onClick: () => callback(QuestionType["DATE"],sectionId??""),
        },
        {
        typeName: "RATING",
        typeIcon: <TbStar />,
        onClick: () => callback(QuestionType["RATING"],sectionId??""),
        },
        {
        typeName: "MATRIX",
        typeIcon: <CgOptions />,
        onClick: () => callback(QuestionType["MATRIX"],sectionId??""),
        },
    ];
    return (
        <Menu closeOnSelect placement="bottom-end" isLazy>
        <MenuButton disabled={isDisabled}>
            <CustomButton isDisabled={isDisabled}>
            <MdAdd /> {"Add New Question"}
            </CustomButton>
        </MenuButton>
        <MenuList>
            <MenuGroup>
            {questionTypes.map((type: QuestionTypeMenuItem) => (
                <MenuItem key={type.typeName} onClick={type.onClick}>
                <Text>
                    {type.typeIcon} {` ${type.typeName}`}
                </Text>
                </MenuItem>
            ))}
            </MenuGroup>
        </MenuList>
        </Menu>
    );
};
  

export default QuestionTypeMenu