import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import {
  Answer,
  ChoiceTypeAnswer,
  ChoiceTypeQuestion,
  ContactTypeAnswer,
  ContactTypeQuestion,
  DateTypeAnswer,
  DateTypeQuestion,
  MatrixSelected,
  MatrixTypeAnswer,
  MatrixTypeQuestion,
  NPSTypeAnswer,
  NPSTypeQuestion,
  Question,
  QuestionType,
  RatingTypeAnswer,
  RatingTypeQuestion,
  Respondent,
  Response,
  Section,
  Survey,
  TextTypeAnswer,
  TextTypeQuestion,
} from "../pb/surveys_pb";

export const formatSurveyJsonToSurveyClass = (
  surveyJson: Survey.AsObject,
): Survey => {
  const survey = new Survey();
  survey.setOrganisation(surveyJson.organisation);
  survey.setTitle(surveyJson.title);
  survey.setDescription(surveyJson.description);
  survey.setBrandPoster(surveyJson.brandPoster);
  survey.setSectionsList(
    formatSectionsJsonToSectionsClass(surveyJson.sectionsList),
  );
  survey.setSerialNumber(surveyJson.serialNumber);
  survey.setExpires(
    formatDateToTimeStamp(surveyJson.expires as Timestamp.AsObject),
  );
  survey.setMaxResponses(surveyJson.maxResponses);
  return survey;
};

const formatDateToTimeStamp = (timestamp: Timestamp.AsObject): Timestamp => {
  const time = new Timestamp();
  time.setSeconds(timestamp?.seconds);
  time.setNanos(timestamp?.nanos);
  return time;
};

export const formatSectionJsonToSectionClass = (
  sectionJson: Section.AsObject,
): Section => {
  const section = new Section();
  section.setSurveyId(sectionJson.surveyId);
  section.setTitle(sectionJson.title);
  section.setDescription(sectionJson.description);
  section.setIndex(sectionJson.index);
  section.setQuestionsList(
    formatQuestionJsonToQuestionClasses( sectionJson.questionsList ?? []),
  );
  return section;
}
const formatSectionsJsonToSectionsClass = (
  sectionsJson: Section.AsObject[],
): Section[] => {
  const sections : Section[] = [];
  for (let i = 0; i < sectionsJson.length; i++) {
    sections.push(formatSectionJsonToSectionClass(sectionsJson[i]));
  }
  return sections;
};

export const formatQuestionJsonToQuestionClass = (questionJson : Question.AsObject):Question => {
  const question = new Question();
  question.setId(questionJson.id);
  question.setQuestion(questionJson.question);
  question.setDescription(questionJson.description);
  question.setRequired(questionJson.required);
  question.setIndex(questionJson.index);
  question.setSurveyId(questionJson.surveyId);
  question.setSectionId(questionJson.sectionId);
  question.setType(questionJson.type);
  question.setCreatedAt(formatDateToTimeStamp(questionJson.createdAt as Timestamp.AsObject));
  questionTypeSwitch(question, questionJson);
  return question;
}

export const formatQuestionJsonToQuestionClasses = (
  questionJsonList: Question.AsObject[],
): Question[] => {
  
  let questions: Question[] = [];
  for (let i = 0; i < questionJsonList.length; i++) {
    questions.push(formatQuestionJsonToQuestionClass(questionJsonList[i]));
  }
  return questions;
};

const questionTypeSwitch = (
  question: Question,
  questionJson: Question.AsObject,
) => {
  switch (questionJson.type) {
    case QuestionType["CHOICE"]:
      return question.setChoice(
        formatChoiceTypeQuestionJsonToChoiceTypeQuestionClass(
          questionJson.choice!,
        ),
      );
    case QuestionType["NPS"]:
      return question.setNps(
        formatNPSTypeQuestionJsonToNPSTypeQuestionClass(questionJson.nps!),
      );
    case QuestionType["TEXT"]:
      return question.setText(
        formatTextTypeQuestionJsonToTextTypeQuestionClass(questionJson.text!),
      );
    case QuestionType["DATE"]:
      return question.setDate(
        formatDateTypeQuestionJsonToDateTypeQuestionClass(questionJson.date!),
      );
    case QuestionType["CONTACT"]:
      return question.setContact(
        formatContactTypeQuestionJsonToContactTypeQuestionClass(
          questionJson.contact!,
        ),
      );
    case QuestionType["RATING"]:
      return question.setRating(
        formatRatingTypeQuestionJsonToRatingTypeQuestionClass(
          questionJson.rating!,
        ),
      );
    case QuestionType["MATRIX"]:
      return question.setMatrix(
        formatMatrixTypeQuestionJsonToMatrixTypeQuestionClass(
          questionJson.matrix!,
        ),
      );
    default:
      return null;
  }
};
const formatChoiceTypeQuestionJsonToChoiceTypeQuestionClass = (
  choiceTypeQuestionJson: ChoiceTypeQuestion.AsObject,
) => {
  const choiceTypeQuestion = new ChoiceTypeQuestion();
  choiceTypeQuestion.setOptionsList([...choiceTypeQuestionJson.optionsList]);
  choiceTypeQuestion.setMultiple(choiceTypeQuestionJson.multiple);
  choiceTypeQuestion.setType(choiceTypeQuestionJson.type);
  return choiceTypeQuestion;
};
const formatNPSTypeQuestionJsonToNPSTypeQuestionClass = (
  npsTypeQuestionJson: NPSTypeQuestion.AsObject,
) => {
  const npsTypeQuestion = new NPSTypeQuestion();
  npsTypeQuestion.setMin(npsTypeQuestionJson.min);
  npsTypeQuestion.setMax(npsTypeQuestionJson.max);
  npsTypeQuestion.setLabelMax(npsTypeQuestionJson.labelMax);
  npsTypeQuestion.setLabelMin(npsTypeQuestionJson.labelMin);
  return npsTypeQuestion;
};
const formatTextTypeQuestionJsonToTextTypeQuestionClass = (
  textTypeQuestionJson: TextTypeQuestion.AsObject,
) => {
  const textTypeQuestion = new TextTypeQuestion();
  textTypeQuestion.setPlaceholder(textTypeQuestionJson.placeholder);
  textTypeQuestion.setType(textTypeQuestionJson.type);
  return textTypeQuestion;
};
const formatDateTypeQuestionJsonToDateTypeQuestionClass = (
  dateTypeQuestionJson: DateTypeQuestion.AsObject,
) => {
  const dateTypeQuestion = new DateTypeQuestion();
  dateTypeQuestion.setType(dateTypeQuestionJson.type);
  return dateTypeQuestion;
};
const formatContactTypeQuestionJsonToContactTypeQuestionClass = (
  contactTypeQuestionJson: ContactTypeQuestion.AsObject,
) => {
  const contactTypeQuestion = new ContactTypeQuestion();
  contactTypeQuestion.setPlaceholder(contactTypeQuestionJson.placeholder);
  contactTypeQuestion.setType(contactTypeQuestionJson.type);
  return contactTypeQuestion;
};
const formatRatingTypeQuestionJsonToRatingTypeQuestionClass = (
  ratingTypeQuestionJson: RatingTypeQuestion.AsObject,
) => {
  const ratingTypeQuestion = new RatingTypeQuestion();
  ratingTypeQuestion.setMin(ratingTypeQuestionJson.min);
  ratingTypeQuestion.setMax(ratingTypeQuestionJson.max);
  ratingTypeQuestion.setIcon(ratingTypeQuestionJson.icon);
  return ratingTypeQuestion;
};

const formatMatrixTypeQuestionJsonToMatrixTypeQuestionClass = (
  matrixTypeQuestionJson: MatrixTypeQuestion.AsObject,
) => {
  const matrixTypeQuestion = new MatrixTypeQuestion();
  matrixTypeQuestion.setOptionsList([...matrixTypeQuestionJson.optionsList]);
  matrixTypeQuestion.setRowsList([...matrixTypeQuestionJson.rowsList]);
  matrixTypeQuestion.setMultiple(matrixTypeQuestionJson.multiple);
  return matrixTypeQuestion;
};

export const formatResponseJsonToResponseClass = (
  responseJson: Response.AsObject,
) => {
  const response = new Response();
  response.setSurveyId(responseJson.surveyId);
  response.setAnswersList(
    formatAnswersJsonToAnswersClass(responseJson.answersList),
  );
  response.setRespondent(
    formatRespondentJsonToRespondentClass(responseJson.respondent!),
  );
  response.setAverageResponseTime(responseJson.averageResponseTime);
  return response;
};
const formatRespondentJsonToRespondentClass = (
  respondentJson: Respondent.AsObject,
): Respondent => {
  const data = new Respondent();
  data.setId(respondentJson.id);
  data.setName(respondentJson.name);
  data.setEmail(respondentJson.email);
  data.setPhoneNumber(respondentJson.phoneNumber);

  return data;
};

const formatAnswersJsonToAnswersClass = (answers: Answer.AsObject[]) => {
  const answersClass = answers.map((answerJson) => {
    const answer = new Answer();
    answer.setId(answerJson.id);
    answer.setQuestionId(answerJson.questionId);
    answer.setSurveyId(answerJson.surveyId);
    answer.setQuestionType(answerJson.questionType);
    answer.setResponseId(answerJson.responseId);
    answerTypeSwitch(answer, answerJson);
    return answer;
  });
  return answersClass;
};
const answerTypeSwitch = (answer: Answer, answerJson: Answer.AsObject) => {
  switch (answerJson.questionType) {
    case QuestionType["CHOICE"]:
      return answer.setChoice(
        formatChoiceTypeAnswerJsonToChoiceTypeAnswerClass(
          answerJson.choice! ?? {},
        ),
      );
    case QuestionType["NPS"]:
      return answer.setNps(
        formatNPSTypeAnswerJsonToNPSTypeAnswerClass(answerJson.nps! ?? {}),
      );
    case QuestionType["TEXT"]:
      return answer.setText(
        formatTextTypeAnswerJsonToTextTypeAnswerClass(answerJson.text! ?? {}),
      );
    case QuestionType["DATE"]:
      return answer.setDate(
        formatDateTypeAnswerJsonToDateTypeAnswerClass(answerJson.date! ?? {}),
      );
    case QuestionType["CONTACT"]:
      return answer.setContact(
        formatContactTypeAnswerJsonToContactTypeAnswerClass(
          answerJson.contact! ?? {},
        ),
      );
    case QuestionType["RATING"]:
      return answer.setRating(
        formatRatingTypeAnswerJsonToRatingTypeAnswerClass(
          answerJson.rating! ?? {},
        ),
      );
    case QuestionType["MATRIX"]:
      return answer.setMatrix(
        formatMatrixTypeAnswerJsonToMatrixTypeAnswerClass(
          answerJson.matrix! ?? {},
        ),
      );
    default:
      return null;
  }
};

const formatChoiceTypeAnswerJsonToChoiceTypeAnswerClass = (
  choiceTypeAnswerJson: ChoiceTypeAnswer.AsObject,
) => {
  const choiceTypeAnswer = new ChoiceTypeAnswer();
  choiceTypeAnswer.setSelectedList([...choiceTypeAnswerJson.selectedList]);
  return choiceTypeAnswer;
};

const formatNPSTypeAnswerJsonToNPSTypeAnswerClass = (
  npsTypeAnswerJson: NPSTypeAnswer.AsObject,
) => {
  const npsTypeAnswer = new NPSTypeAnswer();
  npsTypeAnswer.setValue(npsTypeAnswerJson.value ?? 0);
  return npsTypeAnswer;
};

const formatTextTypeAnswerJsonToTextTypeAnswerClass = (
  textTypeAnswerJson: TextTypeAnswer.AsObject,
) => {
  const textTypeAnswer = new TextTypeAnswer();
  textTypeAnswer.setValue(textTypeAnswerJson.value ?? "");
  return textTypeAnswer;
};

const formatDateTypeAnswerJsonToDateTypeAnswerClass = (
  dateTypeAnswerJson: DateTypeAnswer.AsObject,
) => {
  const dateTypeAnswer = new DateTypeAnswer();
  dateTypeAnswer.setValue(dateTypeAnswerJson.value ?? "");
  return dateTypeAnswer;
};

const formatContactTypeAnswerJsonToContactTypeAnswerClass = (
  contactTypeAnswerJson: ContactTypeAnswer.AsObject,
) => {
  const contactTypeAnswer = new ContactTypeAnswer();
  contactTypeAnswer.setValue(contactTypeAnswerJson.value ?? "");
  return contactTypeAnswer;
};

const formatRatingTypeAnswerJsonToRatingTypeAnswerClass = (
  ratingTypeAnswerJson: RatingTypeAnswer.AsObject,
) => {
  const ratingTypeAnswer = new RatingTypeAnswer();
  ratingTypeAnswer.setValue(ratingTypeAnswerJson.value ?? 0);
  return ratingTypeAnswer;
};

const formatMatrixTypeAnswerJsonToMatrixTypeAnswerClass = (
  matrixTypeAnswerJson: MatrixTypeAnswer.AsObject,
) => {
  const matrixTypeAnswer = new MatrixTypeAnswer();

  matrixTypeAnswer.setSelectedList(
    formatMatrixSelected(matrixTypeAnswerJson.selectedList ?? []),
  );
  return matrixTypeAnswer;
};

const formatMatrixSelected = (
  selected: MatrixSelected.AsObject[],
): MatrixSelected[] => {
  const selectedList: MatrixSelected[] = [];
  for (let i = 0; i < selected.length; i++) {
    let item = new MatrixSelected();
    item.setRow(selected[i].row ?? "");
    item.setOption(selected[i].option ?? "");
    selectedList.push(item);
  }
  return selectedList;
};
