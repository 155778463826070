import { Grid, Text } from "@chakra-ui/react";
import { CallDispositionDataType } from "../../types/DataTypes";
import CustomButton from "../buttons/CustomButton";

interface Props {
  disposition: CallDispositionDataType;
  activeCall: boolean;
  permissionAvailable?: boolean;
}

export default function DispositionButton({
  disposition,
  activeCall,
  permissionAvailable,
}: Props) {
  const { label, icon, enabled, color, onclick } = disposition;
  let isDisabled = true;

  if (permissionAvailable) {
    if (enabled) {
      isDisabled = !activeCall;
    }
  }
  return (
    <CustomButton
      key={disposition.key}
      flexGrow={1}
      h={"70px"}
      bg={color ? `${color}.100` : "gray.100"}
      color={color ? `${color}.700` : "gray.600"}
      fontSize={"10px"}
      fontWeight={"normal"}
      borderRadius={"5px"}
      isDisabled={isDisabled}
      onClick={onclick}
    >
      <Grid placeItems={"center"} w={"100%"} h={"60%"}>
        {icon}
        <Text w={"min-content"} wordBreak={"break-word"}>
          {label}
        </Text>
      </Grid>
    </CustomButton>
  );
}
