import { Box, HStack, Spacer, Text } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { borderColor } from "../../theme/theme";
import IconButton from "../buttons/IconButton";
import { SubHeading } from "../headings/Headings";

interface PageHeaderProps {
  title: string;
  description?: string;
  closable?: boolean;
  action?: JSX.Element;
}

const CustomPageHeader = ({
  title,
  action,
  description,
  closable,
}: PageHeaderProps) => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };
  return (
    <HStack
      alignItems={"center"}
      w={"100%"}
      borderBottom={`1px solid ${borderColor}`}
      mb={"1rem"}
      py={".5rem"}
      gap={".5rem"}
    >
      <Box>
        <SubHeading title={title} />
        <Text>{description}</Text>
      </Box>
      <Spacer />
      {action ?? <></>}
      {closable && <IconButton fluid icon={<MdClose />} onClick={onClose} />}
    </HStack>
  );
};

export default CustomPageHeader;
