import {
  Box,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  Grid,
  GridItem,
  HStack,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";

import { DatePicker, TimePicker } from "antd/es";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { MdOutlineLightbulb } from "react-icons/md";
import { TbHeart, TbStar, TbTrophy } from "react-icons/tb";
import { isEven } from "../../../gateway/gRPC/Utils/utils";
import {
  ChoiceTypeAnswer,
  ChoiceTypeQuestion,
  ContactTypeAnswer,
  ContactTypeQuestion,
  DateTypeAnswer,
  DateTypeQuestion,
  MatrixSelected,
  MatrixTypeAnswer,
  MatrixTypeQuestion,
  NPSTypeAnswer,
  NPSTypeQuestion,
  Question,
  QuestionType,
  RatingTypeAnswer,
  RatingTypeQuestion,
  TextTypeAnswer,
  TextTypeQuestion,
} from "../../../gateway/gRPC/pb/surveys_pb";
import {
  borderColor,
  gray_50,
  primaryColor,
  primaryWhite,
} from "../../../theme/theme";
import CustomInput from "../../forms/CustomInput";
import CustomTextArea from "../../forms/CustomTextArea";
import QuestionBase from "./QuestionBase";

export interface RatingIconType {
  iconName: string;
  icon: ReactNode;
  iconValue: number;
}
interface TextTypes {
  type: string;
  icon: ReactNode;
}
export const ratingIcons: RatingIconType[] = [
  {
    iconName: "Star",
    icon: <TbStar />,
    iconValue: RatingTypeQuestion.IconType.STAR,
  },
  {
    iconName: "Heart",
    icon: <TbHeart />,
    iconValue: RatingTypeQuestion.IconType.HEART,
  },
  {
    iconName: "Bulb",
    icon: <MdOutlineLightbulb />,
    iconValue: RatingTypeQuestion.IconType.BULB,
  },
  {
    iconName: "Trophy",
    icon: <TbTrophy />,
    iconValue: RatingTypeQuestion.IconType.TROPHY,
  },
];
const textTypes: TextTypes[] = [
  {
    type: "Text",
    icon: <MdOutlineLightbulb />,
  },
];

interface Props {
  question: Question.AsObject;
  isResponse?: boolean;
  isAnalytics?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
  handleEditQuestionSubmit?: (data: Question.AsObject) => void;
  handleDeleteQuestion?: (question: Question.AsObject) => void;
}

export default function SurveyQuestion({
  question,
  isResponse,
  isAnalytics,
  handleAnswer,
  handleEditQuestionSubmit,
  handleDeleteQuestion,
}: Props) {
  switch (question.type) {
    case QuestionType["CHOICE"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.choice && (
            <ChoiceType
              questionId={question.id}
              isResponse={isResponse ?? false}
              handleAnswer={handleAnswer}
              choiceType={question.choice!}
            />
          )}
        </QuestionBase>
      );
    case QuestionType["NPS"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.nps && (
            <NpsChart
              questionId={question.id}
              isResponse={isResponse ?? false}
              handleAnswer={handleAnswer}
              npsType={question.nps!}
            />
          )}
        </QuestionBase>
      );
    case QuestionType["TEXT"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.text && (
            <TextType
              questionId={question.id}
              isResponse={isResponse ?? false}
              handleAnswer={handleAnswer}
              textType={question.text!}
            />
          )}
        </QuestionBase>
      );
    case QuestionType["DATE"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.date && (
            <DateType
              questionId={question.id}
              isResponse={isResponse ?? false}
              handleAnswer={handleAnswer}
              dateType={question.date}
            />
          )}
        </QuestionBase>
      );
    case QuestionType["CONTACT"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.contact && (
            <ContactType
              isResponse={isResponse ?? false}
              handleAnswer={handleAnswer}
              questionId={question.id}
              contactType={question.contact}
            />
          )}
        </QuestionBase>
      );
    case QuestionType["RATING"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.rating && (
            <RatingType
              isResponse={isResponse ?? false}
              ratingType={question.rating}
              questionId={question.id}
              handleAnswer={handleAnswer}
            />
          )}
        </QuestionBase>
      );
    case QuestionType["MATRIX"]:
      return (
        <QuestionBase
          question={question}
          isResponse={isResponse}
          isAnalytics={isAnalytics}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestion={handleEditQuestionSubmit}
        >
          {question.matrix && (
            <MatrixType
              questionId={question.id}
              isResponse={isResponse ?? false}
              handleAnswer={handleAnswer}
              matrixType={question.matrix}
            />
          )}
        </QuestionBase>
      );
    default:
      return null;
  }
}

const ChoiceType = ({
  choiceType,
  isResponse,
  handleAnswer,
  questionId,
}: {
  choiceType: ChoiceTypeQuestion.AsObject;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
  questionId: string;
}) => {
  const handleChange = (value: string) => {
    handleAnswer?.<{ [key: string]: ChoiceTypeAnswer.AsObject }>(
      `${questionId}`,
      {
        choice: {
          selectedList: [value],
        },
      },
    );
  };
  const handleMultipleChange = (values: string[]) => {
    handleAnswer?.<{ [key: string]: ChoiceTypeAnswer.AsObject }>(
      `${questionId}`,
      {
        choice: {
          selectedList: values,
        },
      },
    );
  };
  const handleSelectChange = (e: any) => {
    e.preventDefault();
    handleAnswer?.<{ [key: string]: ChoiceTypeAnswer.AsObject }>(
      `${questionId}`,
      {
        choice: {
          selectedList: [e.target.value],
        },
      },
    );
  };
  return (
    <ResponseWrapper>
      <Box flex={1}>
        {choiceType.type === ChoiceTypeQuestion.ChoiceType["RADIO"] ? (
          <RadioGroup isDisabled={!isResponse} onChange={handleChange}>
            <Stack direction={"column"} spacing={".5rem"}>
              {choiceType.optionsList.map((option: string, index: number) => (
                <Radio key={index} value={option}>
                  {option}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        ) : choiceType.type === ChoiceTypeQuestion.ChoiceType["CHECKBOX"] ? (
          <CheckboxGroup
            isDisabled={!isResponse}
            onChange={handleMultipleChange}
          >
            <Stack direction={"column"} spacing={".5rem"}>
              {choiceType.optionsList.map((option: string, index: number) => (
                <Checkbox key={index} value={option}>
                  {option}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        ) : (
          <Select isReadOnly={!isResponse} onChange={handleSelectChange}>
            {choiceType.optionsList.map((option: string, index: number) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Select>
        )}
      </Box>
    </ResponseWrapper>
  );
};

const NpsChart = ({
  npsType,
  isResponse,
  handleAnswer,
  questionId,
}: {
  npsType: NPSTypeQuestion.AsObject;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
  questionId: string;
}) => {
  const scores: number[] = [];
  const [selected, setSelected] = useState<number>(10000000000000);
  for (let i = npsType.min; i <= npsType.max; i++) {
    scores.push(i);
  }

  useEffect(() => {
    handleAnswer?.<{ [key: string]: NPSTypeAnswer.AsObject }>(`${questionId}`, {
      nps: {
        value: selected,
      },
    });
  }, [selected]);

  return (
    <ResponseWrapper>
      <Box flex={1}>
        <Grid
          w={"100%"}
          templateColumns={`repeat(${scores.length}, 1fr)`}
          gap={0}
          alignItems="flex-end"
          textAlign="center"
        >
          {scores.map((score: number) => (
            <Box
              key={score}
              gridColumn="span 1"
              border={`1px solid ${borderColor}`}
              _hover={{
                bg: "orange.400",
              }}
              bg={
                selected === score && isResponse ? primaryColor : primaryWhite
              }
              cursor={"pointer"}
              onClick={isResponse ? setSelected.bind(this, score) : () => null}
            >
              <Text w={"100%"} textAlign={"center"}>
                {score}
              </Text>
            </Box>
          ))}
        </Grid>
        <HStack
          w={"100%"}
          spacing={".5rem"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text>{npsType.labelMin}</Text>
          <Text>{npsType.labelMax}</Text>
        </HStack>
      </Box>
    </ResponseWrapper>
  );
};

const TextType = ({
  textType,
  isResponse,
  handleAnswer,
  questionId,
}: {
  textType: TextTypeQuestion.AsObject;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
  questionId: string;
}) => {
  const handleChange = (event: any) => {
    event.preventDefault();
    handleAnswer?.<{ [key: string]: TextTypeAnswer.AsObject }>(questionId, {
      text: { value: event.target.value },
    });
  };
  switch (textType.type) {
    case TextTypeQuestion.TextType["NUMBER"]:
      return (
        <ResponseWrapper>
          <CustomTextArea
            isReadOnly={!isResponse}
            onChange={handleChange}
            placeholder={textType.placeholder}
          />
        </ResponseWrapper>
      );
    default:
      return (
        <ResponseWrapper>
          <CustomInput
            isReadOnly={!isResponse}
            onChange={handleChange}
            placeholder={textType.placeholder}
            type={textType.type.toString().toLowerCase()}
          />
        </ResponseWrapper>
      );
  }
};
const DateType = ({
  dateType,
  isResponse,
  handleAnswer,
  questionId,
}: {
  dateType: DateTypeQuestion.AsObject;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
  questionId?: string;
}) => {
  const handleChange = (_: any, value: string) => {
    if (handleAnswer) {
      handleAnswer<{ [key: string]: DateTypeAnswer.AsObject }>(
        `${questionId}`,
        {
          date: {
            value: value,
          },
        },
      );
    }
  };
  return (
    <ResponseWrapper>
      {dateType.type == DateTypeQuestion.DateType["TIME"] ? (
        <TimePicker disabled={!isResponse} onChange={handleChange} />
      ) : dateType.type == DateTypeQuestion.DateType["DATETIME"] ? (
        <DatePicker disabled={!isResponse} showTime onChange={handleChange} />
      ) : (
        <DatePicker disabled={!isResponse} onChange={handleChange} />
      )}
    </ResponseWrapper>
  );
};
const ContactType = ({
  contactType,
  isResponse,
  handleAnswer,
  questionId,
}: {
  contactType: ContactTypeQuestion.AsObject;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
  questionId?: string;
}) => {
  const handleChange = (event: any) => {
    event.preventDefault();
    if (handleAnswer) {
      handleAnswer<{ [key: string]: ContactTypeAnswer.AsObject }>(
        `${questionId}`,
        { contact: { value: event.target.value } },
      );
    }
  };
  return (
    <ResponseWrapper>
      <CustomInput
        placeholder={contactType.placeholder}
        type={contactType.type.toString().toLowerCase()}
        onBlur={handleChange}
        isReadOnly={!isResponse}
      />
    </ResponseWrapper>
  );
};

const ResponseWrapper = ({ children }: { children: ReactNode }) => (
  <Flex
    gap={"2rem"}
    alignItems={"flex-start"}
    justifyContent={"space-between"}
    w={"100%"}
    mt={"1rem"}
  >
    {children}
  </Flex>
);
const RatingType = ({
  ratingType,
  questionId,
  isResponse,
  handleAnswer,
}: {
  ratingType: RatingTypeQuestion.AsObject;
  questionId: string;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
}) => {
  const [rating, setRating] = useState(0);
  const { min, max, icon: iconType } = ratingType;
  const scale: number[] = [];
  for (let i = min; i <= max; i++) {
    scale.push(i);
  }
  const handleRatingClick = (value: number) => {
    setRating(value);
    if (handleAnswer) {
      handleAnswer<{ [key: string]: RatingTypeAnswer.AsObject }>(
        `${questionId}`,
        {
          rating: {
            value: value,
          },
        },
      );
    }
  };

  return (
    <ResponseWrapper>
      <Flex align="center">
        {(scale ?? [...Array(5)]).map((_, index) => {
          const value = index + 1;
          const isActive = value <= rating;

          return (
            <RatingIcon
              key={value}
              icon={iconType}
              color={rating >= value ? "orange.500" : "gray.500"}
              hoverColor={isResponse ? "orange.400" : "gray.500"}
              boxSize={"40px"}
              cursor={"pointer"}
              onClick={isResponse ? () => handleRatingClick(value) : () => null}
            />
          );
        })}
        <Text ml={2} fontSize="sm">
          {rating} / {max}
        </Text>
      </Flex>
    </ResponseWrapper>
  );
};
const RatingIcon = ({
  icon,
  onClick,
  boxSize,
  hoverColor,
  color,
  rest,
}: {
  icon: RatingTypeQuestion.IconType;
  onClick: (value: number) => void;
  [key: string]: any;
}) => (
  <Center
    w={boxSize}
    h={boxSize}
    onClick={onClick}
    color={color}
    _hover={{
      color: hoverColor,
    }}
    fontSize={"1.3rem"}
    {...rest}
  >
    {icon === RatingTypeQuestion.IconType["BULB"] ? (
      <MdOutlineLightbulb />
    ) : icon === RatingTypeQuestion.IconType["HEART"] ? (
      <TbHeart />
    ) : icon === RatingTypeQuestion.IconType["TROPHY"] ? (
      <TbTrophy />
    ) : (
      <TbStar />
    )}
  </Center>
);

const MatrixType = ({
  questionId,
  matrixType,
  isResponse,
  handleAnswer,
}: {
  questionId?: string;
  matrixType: MatrixTypeQuestion.AsObject;
  isResponse?: boolean;
  handleAnswer?: <T>(questionId: string, answerTypeData: T) => void;
}) => {
  const [selected, setSelected] = useState<Array<MatrixSelected.AsObject>>([]);
  const { optionsList, rowsList, multiple } = matrixType;

  const handleRadioChange = (row: string, value: string) => {
    const data: MatrixSelected.AsObject = {
      row: row,
      option: value,
    };
    setSelected((prevState: MatrixSelected.AsObject[]) => [
      ...prevState.filter(
        (item: MatrixSelected.AsObject) => item.row !== data.row,
      ),
      data,
    ]);
  };
  useEffect(() => {
    if (handleAnswer) {
      handleAnswer<{ [key: string]: MatrixTypeAnswer.AsObject }>(
        `${questionId}`,
        {
          matrix: {
            selectedList: selected,
          },
        },
      );
    }
  }, [selected]);
  return (
    <ResponseWrapper>
      <Grid
        w={"100%"}
        templateColumns={`repeat(${optionsList.length + 1}, 1fr)`}
        gap={4}
        bg={primaryWhite}
        borderRadius={".5rem"}
        p={"1rem 2rem"}
      >
        <Text></Text>
        {optionsList.map((option, optionIndex) => (
          <Text w={"100%"} key={optionIndex} textAlign="center">
            {option}
          </Text>
        ))}
        {rowsList.map((row: string, rowIndex: number) => (
          <Fragment key={rowIndex}>
            <Text textAlign="left">{row}</Text>
            <GridItem colSpan={optionsList.length}>
              <RadioGroup
                onChange={(value: string) => handleRadioChange(row, value)}
              >
                <Stack direction="row">
                  {optionsList.map((option: string, optionIndex: number) => (
                    <Center w={"100%"} key={optionIndex}>
                      <Radio value={option} isReadOnly={!isResponse} />
                    </Center>
                  ))}
                </Stack>
              </RadioGroup>
            </GridItem>
          </Fragment>
        ))}
      </Grid>
    </ResponseWrapper>
  );
};
