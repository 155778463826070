import { MdOutlineArrowBack } from "react-icons/md";
import strings from "../localization/main";
import { Result } from "antd";
import { Box, HStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import Loader from "../components/general/Loader";
import { RiPagesLine } from "react-icons/ri";

interface Props {
    to: string;
}
const CustomButton = lazy(() => import("../components/buttons/CustomButton"));


const Redirect = ({ to }: Props) => {
    const navigate = useNavigate();
    return (
        <Suspense fallback={<Loader />}>
            <Box w={"100%"} h={"100%"}>
                <Result
                    status="404"
                    title={strings.page_moved}
                    subTitle={strings.page_moved_description}
                    extra={<HStack justifyContent={"center"} alignItems={"center"} gap={".5rem"}>
                        <CustomButton onClick={() => navigate(-1)}>
                            <MdOutlineArrowBack />
                            {strings.back}
                        </CustomButton>
                        <CustomButton onClick={() => navigate(to)}>
                            <RiPagesLine />
                            {strings.go_to_new_page}
                        </CustomButton>
                    </HStack>}
                />
            </Box>
        </Suspense>
    );
};

export default Redirect;
