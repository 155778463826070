import { Box, Flex, Tag } from "@chakra-ui/react";
import { RpcError } from "grpc-web";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { RiBuildingFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import searchData from "../../../../gateway/SearchData";
import { gRPCReadDepartments } from "../../../../gateway/gRPC/Utils/BranchMethods";
import {
  Department,
  ReadDepartmentsResponse,
} from "../../../../gateway/gRPC/pb/department_pb";
import strings from "../../../../localization/main";
import MyTable from "../../../MyTable";
import DeleteDepartmentItem from "../../../chatDesk/ticketingCrud/DeleteDepartmentItem";
import PopOver from "../../../general/PopOver";
import AddNewDepartment from "./AddNewDepartment";
import EditDepartment from "./EditDepartment";

const DepartmentsAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState<Department.AsObject[]>([]);
  const [search, setSearch] = useState<string>("");
  const [filteredData, setFilteredData] = useState<Department.AsObject[]>([]);
  const update = useSelector((state: any) => state.UpdateTables);

  useEffect(() => {
    setLoading(true);
    gRPCReadDepartments()
      .then((res: ReadDepartmentsResponse.AsObject) => {
        setDepartments(res.departmentsList);
      })
      .catch((err: RpcError) => {
        console.error("Error fetching departments:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [update]);

  useEffect(() => {
    if (search) {
      searchData(search, departments, ["name", "description", "created_at"])
        .then((res: any) => {
          setFilteredData(res);
        })
        .catch((err: any) => {
          console.error("Error filtering departments:", err);
        });
    } else {
      setFilteredData([]);
    }
  }, [search, departments]);

  const columns = useMemo(() => {
    return [
      {
        title: "",
        dataIndex: "id",
        width: 50,
        align: "center",
        key: "icon",
        render: (_: any) => (
          <Box>
            <RiBuildingFill />
          </Box>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Branch",
        dataIndex: "branch",
        key: "branch",
        render: (_: any, record: Department.AsObject) => (
          <span>{record.branch ? record.branch.name : "N/A"}</span>
        ),
      },
      {
        title: "Created On",
        dataIndex: "created_at",
        key: "created_at",
        render: (_: any, record: Department.AsObject) => (
          <Tag
            borderRadius={"3px"}
            colorScheme={"gray"}
            size={"xs"}
            p={".2rem .5rem"}
          >
            {`${moment(record.createdAt).format("MMM DD, YYYY")}`}
          </Tag>
        ),
      },
      {
        title: "Actions",
        dataIndex: "id",
        key: "action",
        align: "center",
        width: 100,
        render: (id: string, record: Department.AsObject) => (
          <Flex justifyContent={"center"} gap={".5rem"} w={"100%"}>
            <EditDepartment initialValues={record} />
            <DeleteDepartmentItem id={id} />
          </Flex>
        ),
      },
    ];
  }, []);

  return (
    <Flex flexFlow={"column"} gap={".5rem"}>
      <MyTable
        count={departments.length}
        loading={loading}
        columns={columns}
        data={search ? filteredData : departments}
        setSearch={setSearch}
        searchPlaceholder={"Search department name or description"}
        action={<AddNewDepartment />}
        title={
          <>
            {strings.department}{" "}
            <PopOver content={strings.department_details} />
          </>
        }
      />
    </Flex>
  );
};

export default DepartmentsAdmin;
