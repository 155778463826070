import React, { ReactNode, useId, useState } from "react";
import { borderColor, primaryWhite } from "../theme/theme";
import { Box, Flex, HStack, Spacer, Tag, Text } from "@chakra-ui/react";
import { Table } from "antd";
import { Key } from "antd/es/table/interface";
import Search from "./inputs/Search";
import strings from "../localization/main";

interface TableProps {
	columns: any[];
	data: any[];
	expandedRowRender?: (record: any) => ReactNode;
	action?: ReactNode;
	title?: string | React.ReactNode;
	noBorder?: boolean;
	loading?: boolean;
	setSearch?: React.Dispatch<React.SetStateAction<string>>;
	onChange?: any;
	count?: number;
	loadNextPage?: any;
	rowSelection?: any;
	searchValue?: string | number;
	searchPlaceholder?: string;
	[key: string]: any;
}

const MyTable = ({
	columns,
	loading,
	data,
	noBorder,
	action,
	title,
	setSearch,
	searchPlaceholder,
	expandedRowRender,
	onChange,
	count,
	loadNextPage,
	rowSelection,
	searchValue,
	id,
}: TableProps) => {
	const [expandedRowKeys, setExpandedRowKeys] = useState<Key[]>([]);
	const tableId = useId();
	const onTableRowExpand = (expanded: boolean, record: any) => {
		const keys = [];
		if (expanded) {
			keys.push(record.id);
		}
		setExpandedRowKeys([...keys]);
	};

	return (
		<Flex
			flexFlow={"column"}
			justifyContent={"flex-start"}
			gap={".5rem"}
			w={"100%"}
			h={"fit-content"}
			border={`1px solid ${borderColor}`}
			borderRadius={"3px"}
			p={"1rem"}
			bg={primaryWhite}
		>
			<HStack
				w={"100%"}
				gap={".5rem"}
				p={".5rem 0"}
				justifyContent={"space-between"}
				alignItems={"center"}
			>
				{title && (
					<Box>
						<Text
							color={"gray.600"}
							fontSize={"xs"}
							fontWeight={"bold"}
							textTransform={"capitalize"}
						>
							{title}
						</Text>
					</Box>
				)}
				{count && (
					<>
						<Tag
							size={"sm"}
							fontSize={"xs"}
							borderRadius={"3px"}
							colorScheme={"whatsapp"}
						>
							<Text fontSize={"xs"}>
								{count}
								<Text as={"span"} color={"gray.500"}>
									{" " + (count === 1 ? strings.entry : strings.entries)}
								</Text>
							</Text>
						</Tag>
					</>
				)}
				<Spacer />
				{setSearch && (
					<Search
						iconWidth={".5rem"}
						width={"10rem"}
						flexGrow={1}
						height={"1.5rem"}
						setSearch={setSearch}
						searchValue={searchValue}
						placeholder={searchPlaceholder}
					/>
				)}

				{action}
			</HStack>
			{!action && count && (
				<Flex justifyContent={"flex-start"} alignItems={"center"}>
					{strings.entries}
					<Tag
						ml={".5rem"}
						size={"sm"}
						fontSize={"xs"}
						borderRadius={"50%"}
						colorScheme={"whatsapp"}
					>
						{count}
					</Tag>
				</Flex>
			)}

			<Table
				id={id ?? tableId}
				size={"small"}
				pagination={{
					position: ["bottomRight"],
					showSizeChanger: true,
					showQuickJumper: true,
					pageSizeOptions: ["10", "20", "50", "100"],
					defaultPageSize: 10,
					total: data?.length,
				}}
				bordered={!noBorder}
				loading={!!loading}
				rowKey={"id"}
				columns={[
					...columns,
					...(rowSelection ? [Table.SELECTION_COLUMN] : []),
					...(expandedRowRender ? [Table.EXPAND_COLUMN] : []),
				]}
				dataSource={[...data]}
				expandable={{
					expandedRowRender,
					defaultExpandAllRows: false,
					expandedRowKeys: [...expandedRowKeys],
					onExpand: onTableRowExpand,
					columnWidth: 30,
					columnTitle: "",
					fixed: true,
				}}
				onChange={(pagination: any, filters, sorter) => {
					//onChange();

					if (
						pagination?.current ===
						Math.ceil(pagination?.total / pagination?.pageSize)
					) {
						// If we are on last page, check if server count is more than current data in table
						if ((count ?? 0) > pagination.total) {
							loadNextPage();
						}
					} else {
						// Otherwise, just exit
						return;
					}
				}}
				rowSelection={rowSelection}
			/>
		</Flex>
	);
};

export default MyTable;
