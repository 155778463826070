import React from "react";
import { Input, InputProps, Textarea, TextareaProps } from "@chakra-ui/react";

/**
 * For forms, use [FormInputDate] instead. This is intended to be a more generic component.
 */
export const onInputHover = {
	borderColor: "gray.700",
};

const CustomTextArea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	(props, ref) => {
		const customStyles: any = {
			_focus: {
				boxShadow: "none",
				borderColor: "initial",
			},
		};

		return (
			<Textarea
				_hover={onInputHover}
				ref={ref}
				fontSize={"sm"}
				sx={customStyles}
				{...props}
				borderRadius={"3px"}
			/>
		);
	}
);

export default CustomTextArea;
