import { lazy } from "react";
import { Navigate } from "react-router-dom";

const UserProfile = lazy(
  () => import("../components/userManagement/UserProfile")
);
const MobileBody = lazy(() => import("../layout/BottomBar/MobileBody"));
const Profile = lazy(() => import("../pages/Profile"));
const Apps = lazy(() => import("../components/apps/Apps"));
const MyCalendar = lazy(() => import("../components/calendar/MyCalendar"));
const AccountEmails = lazy(() => import("../components/crm/AccountEmails"));
const ContactsTable = lazy(
  () => import("../components/crm/tables/ContactsTable")
);
const TasksTable = lazy(() => import("../components/crm/tables/TasksTable"));
const ViewTask = lazy(() => import("../components/crm/tasks/ViewTask"));
const moduleList = process.env.REACT_APP_MODULES?.split(",");
const mobileRoutes = [
  {
    path: "/",
    module: "DASHBOARD",
    component: <Navigate to={"/Dialer"} />,
  },
  {
    path: "/Dialer",
    module: "CALLMODULE",
    component: <MobileBody />,
  },
  {
    module: "PROFILE",
    path: "/profile",
    component: <Profile />,
  },
  {
    path: "/org/users/:id",
    component: <UserProfile />,
  },
  {
    module: "APPS",
    path: "/apps",
    component: <Apps />,
    children: [
      {
        path: "/",
        component: <Navigate to={"/apps/calendar"} />,
      },
      {
        path: "/calendar",
        component: <MyCalendar />,
      },
      {
        path: "/emails",
        component: <AccountEmails />,
      },
      {
        path: "/contacts",
        component: <ContactsTable />,
      },
      {
        path: "/tasks",
        component: <TasksTable />,
        children: [
          {
            path: "/:taskId",
            component: <ViewTask />,
          },
        ],
      },
    ],
  },
  {
    module: "NOTFOUND",
    path: "*",
    component: <Navigate to={"/Dialer"} />,
  },
].filter((route: any) => {
  return (moduleList ?? []).includes(route.module);
});

export default mobileRoutes;
