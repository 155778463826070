import { Box, HStack, Spacer, Text } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { TbEdit, TbTrash } from "react-icons/tb";
import { isEven } from "../../../gateway/gRPC/Utils/utils";
import { Question } from "../../../gateway/gRPC/pb/surveys_pb";
import { gray_50 } from "../../../theme/theme";
import IconButton from "../../buttons/IconButton";
import SurveyQuestionForm from "./SurveyQuestionForm";

const QuestionBase = ({
  children,
  question,
  isAnalytics,
  isResponse,
  handleDeleteQuestion,
  handleEditQuestion,
}: {
  children: ReactNode;
  question: Question.AsObject;
  isAnalytics?: boolean;
  isResponse?: boolean;
  handleDeleteQuestion?: (question: Question.AsObject) => void;
  handleEditQuestion?: (data: Question.AsObject) => void;
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleDelete = () => {
    try {
      handleDeleteQuestion!(question);
    } catch (e) {
      console.log(e);
    }
  };
  const onSubmit = (data: Question.AsObject) => {
    try {
      setIsEditing(false);
      handleEditQuestion!(data);
      console.log("question", data);
    } catch (e) {
      console.log(e);
    }
  };
  return !isEditing ? (
    <Box
      w={"100%"}
      p={"2rem .5rem"}
      bg={!isAnalytics && isEven(question.index) ? gray_50 : "transparent"}
      _hover={{
        bg: !isAnalytics ? "blue.50" : "transparent",
      }}
      role="group"
    >
      <HStack
        spacing={".5rem"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        <Text>{question.index}.</Text>
        <Box>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            {question.question}
          </Text>
          <Text fontStyle={"italic"} fontSize={"xs"}>
            {question.description}
          </Text>
        </Box>
        <Spacer />
        {!isAnalytics && !isResponse && (
          <HStack
            minW={"fit-content"}
            wrap={"nowrap"}
            spacing={".5rem"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}
            display={"none"}
            _groupHover={{
              display: "flex",
            }}
          >
            <IconButton fluid icon={<TbEdit />} onClick={handleEdit} />
            <IconButton fluid icon={<TbTrash />} onClick={handleDelete} />
          </HStack>
        )}
      </HStack>
      {children}
    </Box>
  ) : (
    <SurveyQuestionForm
      questionIndex={`${question.index}`}
      sectionIndex={`${question.sectionId}`}
      type={question.type}
      initialValues={question}
      onSubmit={onSubmit}
      isEditing={isEditing}
    />
  );
};
export default QuestionBase;
