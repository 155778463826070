import { User } from "../../gateway/gRPC/pb/user_pb";
import { SET_USER_PROFILE } from "../actions/actions";

interface SetUserProfileAction {
  type: typeof SET_USER_PROFILE;
  payload: User.AsObject;
}

const initialUser: User.AsObject = new User().toObject();

const UserProfile = (
  state: User.AsObject = initialUser,
  action: SetUserProfileAction
): User.AsObject => {
  switch (action.type) {
    case SET_USER_PROFILE:
      return action.payload;
    default:
      return state;
  }
};

export default UserProfile;
