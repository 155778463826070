/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: surveys.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./surveys_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.SurveyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.SurveyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateSurveyRequest,
 *   !proto.da.proto.CreateSurveyResponse>}
 */
const methodDescriptor_SurveyService_CreateSurvey = new grpc.web.MethodDescriptor(
  '/da.proto.SurveyService/CreateSurvey',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateSurveyRequest,
  proto.da.proto.CreateSurveyResponse,
  /**
   * @param {!proto.da.proto.CreateSurveyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateSurveyResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateSurveyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateSurveyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SurveyServiceClient.prototype.createSurvey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SurveyService/CreateSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_CreateSurvey,
      callback);
};


/**
 * @param {!proto.da.proto.CreateSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateSurveyResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SurveyServicePromiseClient.prototype.createSurvey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SurveyService/CreateSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_CreateSurvey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadSurveyRequest,
 *   !proto.da.proto.ReadSurveyResponse>}
 */
const methodDescriptor_SurveyService_ReadSurvey = new grpc.web.MethodDescriptor(
  '/da.proto.SurveyService/ReadSurvey',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadSurveyRequest,
  proto.da.proto.ReadSurveyResponse,
  /**
   * @param {!proto.da.proto.ReadSurveyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadSurveyResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadSurveyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadSurveyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SurveyServiceClient.prototype.readSurvey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SurveyService/ReadSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_ReadSurvey,
      callback);
};


/**
 * @param {!proto.da.proto.ReadSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadSurveyResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SurveyServicePromiseClient.prototype.readSurvey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SurveyService/ReadSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_ReadSurvey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateSurveyRequest,
 *   !proto.da.proto.UpdateSurveyResponse>}
 */
const methodDescriptor_SurveyService_UpdateSurvey = new grpc.web.MethodDescriptor(
  '/da.proto.SurveyService/UpdateSurvey',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateSurveyRequest,
  proto.da.proto.UpdateSurveyResponse,
  /**
   * @param {!proto.da.proto.UpdateSurveyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateSurveyResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateSurveyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateSurveyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SurveyServiceClient.prototype.updateSurvey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SurveyService/UpdateSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_UpdateSurvey,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateSurveyResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SurveyServicePromiseClient.prototype.updateSurvey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SurveyService/UpdateSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_UpdateSurvey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteSurveyRequest,
 *   !proto.da.proto.DeleteSurveyResponse>}
 */
const methodDescriptor_SurveyService_DeleteSurvey = new grpc.web.MethodDescriptor(
  '/da.proto.SurveyService/DeleteSurvey',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteSurveyRequest,
  proto.da.proto.DeleteSurveyResponse,
  /**
   * @param {!proto.da.proto.DeleteSurveyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteSurveyResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteSurveyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteSurveyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SurveyServiceClient.prototype.deleteSurvey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SurveyService/DeleteSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_DeleteSurvey,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteSurveyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteSurveyResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SurveyServicePromiseClient.prototype.deleteSurvey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SurveyService/DeleteSurvey',
      request,
      metadata || {},
      methodDescriptor_SurveyService_DeleteSurvey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadSurveyListRequest,
 *   !proto.da.proto.ReadSurveyListResponse>}
 */
const methodDescriptor_SurveyService_ReadSurveyList = new grpc.web.MethodDescriptor(
  '/da.proto.SurveyService/ReadSurveyList',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadSurveyListRequest,
  proto.da.proto.ReadSurveyListResponse,
  /**
   * @param {!proto.da.proto.ReadSurveyListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadSurveyListResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadSurveyListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadSurveyListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadSurveyListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SurveyServiceClient.prototype.readSurveyList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SurveyService/ReadSurveyList',
      request,
      metadata || {},
      methodDescriptor_SurveyService_ReadSurveyList,
      callback);
};


/**
 * @param {!proto.da.proto.ReadSurveyListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadSurveyListResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SurveyServicePromiseClient.prototype.readSurveyList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SurveyService/ReadSurveyList',
      request,
      metadata || {},
      methodDescriptor_SurveyService_ReadSurveyList);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.SectionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.SectionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateSectionRequest,
 *   !proto.da.proto.CreateSectionResponse>}
 */
const methodDescriptor_SectionService_CreateSection = new grpc.web.MethodDescriptor(
  '/da.proto.SectionService/CreateSection',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateSectionRequest,
  proto.da.proto.CreateSectionResponse,
  /**
   * @param {!proto.da.proto.CreateSectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateSectionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateSectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateSectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SectionServiceClient.prototype.createSection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SectionService/CreateSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_CreateSection,
      callback);
};


/**
 * @param {!proto.da.proto.CreateSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateSectionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SectionServicePromiseClient.prototype.createSection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SectionService/CreateSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_CreateSection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadSectionRequest,
 *   !proto.da.proto.ReadSectionResponse>}
 */
const methodDescriptor_SectionService_ReadSection = new grpc.web.MethodDescriptor(
  '/da.proto.SectionService/ReadSection',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadSectionRequest,
  proto.da.proto.ReadSectionResponse,
  /**
   * @param {!proto.da.proto.ReadSectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadSectionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadSectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadSectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SectionServiceClient.prototype.readSection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SectionService/ReadSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_ReadSection,
      callback);
};


/**
 * @param {!proto.da.proto.ReadSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadSectionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SectionServicePromiseClient.prototype.readSection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SectionService/ReadSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_ReadSection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateSectionRequest,
 *   !proto.da.proto.UpdateSectionResponse>}
 */
const methodDescriptor_SectionService_UpdateSection = new grpc.web.MethodDescriptor(
  '/da.proto.SectionService/UpdateSection',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateSectionRequest,
  proto.da.proto.UpdateSectionResponse,
  /**
   * @param {!proto.da.proto.UpdateSectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateSectionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateSectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateSectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SectionServiceClient.prototype.updateSection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SectionService/UpdateSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_UpdateSection,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateSectionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SectionServicePromiseClient.prototype.updateSection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SectionService/UpdateSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_UpdateSection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteSectionRequest,
 *   !proto.da.proto.DeleteSectionResponse>}
 */
const methodDescriptor_SectionService_DeleteSection = new grpc.web.MethodDescriptor(
  '/da.proto.SectionService/DeleteSection',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteSectionRequest,
  proto.da.proto.DeleteSectionResponse,
  /**
   * @param {!proto.da.proto.DeleteSectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteSectionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteSectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteSectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.SectionServiceClient.prototype.deleteSection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.SectionService/DeleteSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_DeleteSection,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteSectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteSectionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.SectionServicePromiseClient.prototype.deleteSection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.SectionService/DeleteSection',
      request,
      metadata || {},
      methodDescriptor_SectionService_DeleteSection);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.QuestionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.QuestionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateQuestionRequest,
 *   !proto.da.proto.CreateQuestionResponse>}
 */
const methodDescriptor_QuestionService_CreateQuestion = new grpc.web.MethodDescriptor(
  '/da.proto.QuestionService/CreateQuestion',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateQuestionRequest,
  proto.da.proto.CreateQuestionResponse,
  /**
   * @param {!proto.da.proto.CreateQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.QuestionServiceClient.prototype.createQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.QuestionService/CreateQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_CreateQuestion,
      callback);
};


/**
 * @param {!proto.da.proto.CreateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.QuestionServicePromiseClient.prototype.createQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.QuestionService/CreateQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_CreateQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadQuestionRequest,
 *   !proto.da.proto.ReadQuestionResponse>}
 */
const methodDescriptor_QuestionService_ReadQuestion = new grpc.web.MethodDescriptor(
  '/da.proto.QuestionService/ReadQuestion',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadQuestionRequest,
  proto.da.proto.ReadQuestionResponse,
  /**
   * @param {!proto.da.proto.ReadQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.QuestionServiceClient.prototype.readQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.QuestionService/ReadQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_ReadQuestion,
      callback);
};


/**
 * @param {!proto.da.proto.ReadQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.QuestionServicePromiseClient.prototype.readQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.QuestionService/ReadQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_ReadQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateQuestionRequest,
 *   !proto.da.proto.UpdateQuestionResponse>}
 */
const methodDescriptor_QuestionService_UpdateQuestion = new grpc.web.MethodDescriptor(
  '/da.proto.QuestionService/UpdateQuestion',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateQuestionRequest,
  proto.da.proto.UpdateQuestionResponse,
  /**
   * @param {!proto.da.proto.UpdateQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.QuestionServiceClient.prototype.updateQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.QuestionService/UpdateQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_UpdateQuestion,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.QuestionServicePromiseClient.prototype.updateQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.QuestionService/UpdateQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_UpdateQuestion);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteQuestionRequest,
 *   !proto.da.proto.DeleteQuestionResponse>}
 */
const methodDescriptor_QuestionService_DeleteQuestion = new grpc.web.MethodDescriptor(
  '/da.proto.QuestionService/DeleteQuestion',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteQuestionRequest,
  proto.da.proto.DeleteQuestionResponse,
  /**
   * @param {!proto.da.proto.DeleteQuestionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteQuestionResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteQuestionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteQuestionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.QuestionServiceClient.prototype.deleteQuestion =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.QuestionService/DeleteQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_DeleteQuestion,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteQuestionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteQuestionResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.QuestionServicePromiseClient.prototype.deleteQuestion =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.QuestionService/DeleteQuestion',
      request,
      metadata || {},
      methodDescriptor_QuestionService_DeleteQuestion);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ResponseServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ResponseServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateResponseRequest,
 *   !proto.da.proto.CreateResponseResponse>}
 */
const methodDescriptor_ResponseService_CreateResponse = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/CreateResponse',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateResponseRequest,
  proto.da.proto.CreateResponseResponse,
  /**
   * @param {!proto.da.proto.CreateResponseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateResponseResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateResponseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateResponseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.createResponse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ResponseService/CreateResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_CreateResponse,
      callback);
};


/**
 * @param {!proto.da.proto.CreateResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateResponseResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ResponseServicePromiseClient.prototype.createResponse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ResponseService/CreateResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_CreateResponse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadResponseRequest,
 *   !proto.da.proto.ReadResponseResponse>}
 */
const methodDescriptor_ResponseService_ReadResponse = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/ReadResponse',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadResponseRequest,
  proto.da.proto.ReadResponseResponse,
  /**
   * @param {!proto.da.proto.ReadResponseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadResponseResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadResponseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadResponseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.readResponse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ResponseService/ReadResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponse,
      callback);
};


/**
 * @param {!proto.da.proto.ReadResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadResponseResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ResponseServicePromiseClient.prototype.readResponse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ResponseService/ReadResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateResponseRequest,
 *   !proto.da.proto.UpdateResponseResponse>}
 */
const methodDescriptor_ResponseService_UpdateResponse = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/UpdateResponse',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateResponseRequest,
  proto.da.proto.UpdateResponseResponse,
  /**
   * @param {!proto.da.proto.UpdateResponseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateResponseResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateResponseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateResponseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.updateResponse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ResponseService/UpdateResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_UpdateResponse,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateResponseResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ResponseServicePromiseClient.prototype.updateResponse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ResponseService/UpdateResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_UpdateResponse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteResponseRequest,
 *   !proto.da.proto.DeleteResponseResponse>}
 */
const methodDescriptor_ResponseService_DeleteResponse = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/DeleteResponse',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteResponseRequest,
  proto.da.proto.DeleteResponseResponse,
  /**
   * @param {!proto.da.proto.DeleteResponseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteResponseResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteResponseResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteResponseResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.deleteResponse =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ResponseService/DeleteResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_DeleteResponse,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteResponseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteResponseResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ResponseServicePromiseClient.prototype.deleteResponse =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ResponseService/DeleteResponse',
      request,
      metadata || {},
      methodDescriptor_ResponseService_DeleteResponse);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ResponseListRequest,
 *   !proto.da.proto.ResponseListResponse>}
 */
const methodDescriptor_ResponseService_ReadResponseList = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/ReadResponseList',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ResponseListRequest,
  proto.da.proto.ResponseListResponse,
  /**
   * @param {!proto.da.proto.ResponseListRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ResponseListResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ResponseListRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ResponseListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ResponseListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.readResponseList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ResponseService/ReadResponseList',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponseList,
      callback);
};


/**
 * @param {!proto.da.proto.ResponseListRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ResponseListResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ResponseServicePromiseClient.prototype.readResponseList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ResponseService/ReadResponseList',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponseList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAnalyticsRequest,
 *   !proto.da.proto.ReadAnalyticsResponse>}
 */
const methodDescriptor_ResponseService_ReadResponseAnalytics = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/ReadResponseAnalytics',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAnalyticsRequest,
  proto.da.proto.ReadAnalyticsResponse,
  /**
   * @param {!proto.da.proto.ReadAnalyticsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAnalyticsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAnalyticsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAnalyticsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAnalyticsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.readResponseAnalytics =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ResponseService/ReadResponseAnalytics',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponseAnalytics,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAnalyticsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAnalyticsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ResponseServicePromiseClient.prototype.readResponseAnalytics =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ResponseService/ReadResponseAnalytics',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponseAnalytics);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadResponseCSVRequest,
 *   !proto.da.proto.ReadResponseCSVResponse>}
 */
const methodDescriptor_ResponseService_ReadResponseCSV = new grpc.web.MethodDescriptor(
  '/da.proto.ResponseService/ReadResponseCSV',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.da.proto.ReadResponseCSVRequest,
  proto.da.proto.ReadResponseCSVResponse,
  /**
   * @param {!proto.da.proto.ReadResponseCSVRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadResponseCSVResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadResponseCSVRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadResponseCSVResponse>}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServiceClient.prototype.readResponseCSV =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/da.proto.ResponseService/ReadResponseCSV',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponseCSV);
};


/**
 * @param {!proto.da.proto.ReadResponseCSVRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadResponseCSVResponse>}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ResponseServicePromiseClient.prototype.readResponseCSV =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/da.proto.ResponseService/ReadResponseCSV',
      request,
      metadata || {},
      methodDescriptor_ResponseService_ReadResponseCSV);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AnswerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AnswerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateAnswerRequest,
 *   !proto.da.proto.CreateAnswerResponse>}
 */
const methodDescriptor_AnswerService_CreateAnswer = new grpc.web.MethodDescriptor(
  '/da.proto.AnswerService/CreateAnswer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateAnswerRequest,
  proto.da.proto.CreateAnswerResponse,
  /**
   * @param {!proto.da.proto.CreateAnswerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateAnswerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateAnswerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateAnswerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AnswerServiceClient.prototype.createAnswer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AnswerService/CreateAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_CreateAnswer,
      callback);
};


/**
 * @param {!proto.da.proto.CreateAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateAnswerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AnswerServicePromiseClient.prototype.createAnswer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AnswerService/CreateAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_CreateAnswer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAnswerRequest,
 *   !proto.da.proto.ReadAnswerResponse>}
 */
const methodDescriptor_AnswerService_ReadAnswer = new grpc.web.MethodDescriptor(
  '/da.proto.AnswerService/ReadAnswer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAnswerRequest,
  proto.da.proto.ReadAnswerResponse,
  /**
   * @param {!proto.da.proto.ReadAnswerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAnswerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAnswerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAnswerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AnswerServiceClient.prototype.readAnswer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AnswerService/ReadAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_ReadAnswer,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAnswerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AnswerServicePromiseClient.prototype.readAnswer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AnswerService/ReadAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_ReadAnswer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateAnswerRequest,
 *   !proto.da.proto.UpdateAnswerResponse>}
 */
const methodDescriptor_AnswerService_UpdateAnswer = new grpc.web.MethodDescriptor(
  '/da.proto.AnswerService/UpdateAnswer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateAnswerRequest,
  proto.da.proto.UpdateAnswerResponse,
  /**
   * @param {!proto.da.proto.UpdateAnswerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateAnswerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateAnswerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateAnswerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AnswerServiceClient.prototype.updateAnswer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AnswerService/UpdateAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_UpdateAnswer,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateAnswerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AnswerServicePromiseClient.prototype.updateAnswer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AnswerService/UpdateAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_UpdateAnswer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteAnswerRequest,
 *   !proto.da.proto.DeleteAnswerResponse>}
 */
const methodDescriptor_AnswerService_DeleteAnswer = new grpc.web.MethodDescriptor(
  '/da.proto.AnswerService/DeleteAnswer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteAnswerRequest,
  proto.da.proto.DeleteAnswerResponse,
  /**
   * @param {!proto.da.proto.DeleteAnswerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteAnswerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteAnswerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteAnswerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AnswerServiceClient.prototype.deleteAnswer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AnswerService/DeleteAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_DeleteAnswer,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteAnswerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AnswerServicePromiseClient.prototype.deleteAnswer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AnswerService/DeleteAnswer',
      request,
      metadata || {},
      methodDescriptor_AnswerService_DeleteAnswer);
};


module.exports = proto.da.proto;

