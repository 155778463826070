import React from 'react';
import {Flex, FlexProps, Text} from "@chakra-ui/react";

interface Props extends FlexProps {
    error?: string,
    children: any
}

function ErrorContainer(props: Props) {
    const {error, children} = props;
    return (
        <Flex justifyContent={"center"} h={"100%"} width={"100%"} {...props} direction={"column"}>
            {children}
            {error && <Text color={"red.500"}>This is an error!</Text>}
        </Flex>
    );
};

export default ErrorContainer;