import * as React from 'react';
import { chakra } from "@chakra-ui/react"

interface BrandProps {
    size?: string,
    icon?: boolean,
    fill?: string,
    background?: string
}

const BrandIcon = ({ icon, size, fill, background: bg }: BrandProps) => {
    return (
        <>
            <chakra.svg margin={".3rem 0"} height={size ? size : "2rem"}
                viewBox={`0 0 ${icon ? "35" : "175"} 34`}
                fill={bg ? bg : "none"} xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.00459914 14.1781C0.00459914 16.7024 -0.213451 19.6839 2.35325 20.971C3.16959 21.3803 4.46031 21.8967 4.86664 20.6155C5.5154 18.5701 3.93461 14.1077 7.20763 8.64913C7.96924 7.37923 8.56611 6.7947 9.57981 5.78254C10.7554 4.6087 12.9682 3.50218 14.6554 3.07624C17.8306 2.27452 21.5539 2.94525 24.055 4.80589L24.9633 5.5107C25.4254 5.86452 26.2041 6.69807 26.5894 7.16682C28.0676 8.96492 29.2508 11.5532 29.6353 13.8409C30.1463 16.8807 30.0317 19.1743 28.8675 21.9598C28.1733 23.6207 26.7133 25.5289 25.4127 26.8501C24.8209 27.451 24.6165 27.3127 24.6165 28.4702C24.6165 28.7076 24.752 29.0648 24.8564 29.2077C25.5825 30.2021 27.173 28.8361 27.7511 28.2552C31.1415 24.8472 30.5931 22.5584 32.168 21.3933C32.3526 21.2567 32.5 21.1798 32.6818 21.0428C33.3958 20.5048 33.8123 19.8701 34.1338 19.0416C34.5629 17.9357 34.7942 15.6029 34.653 14.4817C34.4228 12.6541 34.3318 10.5323 32.798 9.37958C32.4853 9.14465 32.0316 8.97309 31.6454 8.80266C31.1276 8.5742 30.8498 8.46546 30.4576 8.08855C29.5948 7.25922 29.0566 6.15411 28.3009 5.23745C28.0055 4.87885 27.7401 4.65095 27.4571 4.29657L26.5568 3.46922C25.3911 2.56975 24.9743 2.14551 23.4043 1.37787C20.3417 -0.119652 16.9615 -0.387267 13.6516 0.529951C12.3906 0.87926 11.3443 1.38322 10.2912 2.01282L9.12471 2.80553C8.93275 2.94863 8.75042 3.07005 8.57008 3.23034L7.52974 4.14812C6.95668 4.72194 6.25688 5.57071 5.75727 6.23298L4.9018 7.33444C4.57912 7.68036 4.24538 7.95953 3.85124 8.24236C3.44973 8.53026 3.059 8.74998 2.66175 9.01196C0.619052 10.3593 0.00459914 11.3816 0.00459914 14.1784V14.1781Z"
                    fill={`${fill ?? "#333235"}`} />
                <path
                    d="M21.2081 21.1234C21.7267 21.4684 21.4724 20.7408 21.266 20.4346C20.5582 20.4346 20.5769 20.6693 19.8088 20.4476C19.6333 20.3968 19.2686 20.2723 19.1209 20.2675C18.7991 20.2571 18.7242 20.4061 18.7242 20.7216C18.4875 20.7216 18.2022 20.6684 18.0426 20.5377L17.9612 20.4464C17.7857 20.2732 17.7153 20.3346 17.4511 20.2642C17.2809 20.2188 17.1874 20.163 17.0484 20.0901C17.1301 19.7867 17.93 19.0588 15.7047 19.5013C15.4365 19.5548 15.3055 19.6008 15.0508 19.6571C14.6054 19.7554 14.6326 19.643 14.3329 19.5737C13.1479 20.3619 13.5517 20.6856 13.1108 20.9425C12.8113 21.1172 12.8593 21.0028 12.5839 21.2803C12.3937 21.4721 11.9193 22.0009 11.8691 22.2927C11.8212 22.5716 11.9797 22.6006 11.845 23.0136C11.7713 23.2395 11.6536 23.4051 11.5915 23.6265C11.4319 24.1947 11.921 24.435 12.1382 24.739C13.2435 26.2869 13.3577 25.6861 14.4335 25.7894C14.898 25.8342 14.9348 25.6179 15.3888 25.5525C15.6894 25.5092 15.7197 25.5725 15.7773 25.6007C16.1022 25.7593 15.6417 25.5435 16.166 25.9604L16.5783 26.5312C16.838 26.8495 16.4975 26.6884 16.7357 27.1137C16.9415 27.4813 17.5821 27.9101 17.5299 28.9076C17.478 29.8975 16.8273 29.4834 17.5503 30.8989C17.8205 31.4283 17.8038 31.7331 17.9793 32.1948C18.0624 32.4131 18.3338 32.8137 18.4004 33.1001C18.4574 33.3455 18.2484 33.752 19.1047 33.6148C20.0892 33.4573 20.0172 33.5064 20.8474 32.7041C21.1265 32.4345 21.0383 32.55 21.1608 32.1545C21.2634 31.8229 21.3468 31.9153 21.592 31.7215C22.0985 31.3215 21.273 30.9167 21.959 30.5367C22.5745 30.1955 22.7574 30.2747 22.8692 29.6611C23.0696 28.5583 22.3982 28.3422 22.9054 27.5163C23.4972 26.5523 24.0995 26.4602 24.5994 25.6404C24.7678 25.3644 25.16 24.7446 24.9635 24.3376C24.5231 24.1277 23.9024 24.7677 23.5159 24.4576C23.2386 24.235 23.5298 24.4962 23.4243 24.1959C23.2854 23.8004 22.6213 24.0206 21.9592 22.3285C21.6788 21.6118 21.3156 21.5234 21.2081 21.1234V21.1234Z"
                    fill={`${fill ?? "#EF4823"}`} />
            </chakra.svg>
        </>
    )
}
export default BrandIcon