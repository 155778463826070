/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: department.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var branch_pb = require('./branch_pb.js')

var user_pb = require('./user_pb.js')

var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./department_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.DepartmentServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.DepartmentServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateDepartmentRequest,
 *   !proto.da.proto.CreateDepartmentResponse>}
 */
const methodDescriptor_DepartmentService_CreateDepartment = new grpc.web.MethodDescriptor(
  '/da.proto.DepartmentService/CreateDepartment',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateDepartmentRequest,
  proto.da.proto.CreateDepartmentResponse,
  /**
   * @param {!proto.da.proto.CreateDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateDepartmentResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateDepartmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateDepartmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.DepartmentServiceClient.prototype.createDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.DepartmentService/CreateDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_CreateDepartment,
      callback);
};


/**
 * @param {!proto.da.proto.CreateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateDepartmentResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.DepartmentServicePromiseClient.prototype.createDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.DepartmentService/CreateDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_CreateDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadDepartmentRequest,
 *   !proto.da.proto.ReadDepartmentResponse>}
 */
const methodDescriptor_DepartmentService_ReadDepartment = new grpc.web.MethodDescriptor(
  '/da.proto.DepartmentService/ReadDepartment',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadDepartmentRequest,
  proto.da.proto.ReadDepartmentResponse,
  /**
   * @param {!proto.da.proto.ReadDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadDepartmentResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadDepartmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadDepartmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.DepartmentServiceClient.prototype.readDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.DepartmentService/ReadDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_ReadDepartment,
      callback);
};


/**
 * @param {!proto.da.proto.ReadDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadDepartmentResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.DepartmentServicePromiseClient.prototype.readDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.DepartmentService/ReadDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_ReadDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadDepartmentsRequest,
 *   !proto.da.proto.ReadDepartmentsResponse>}
 */
const methodDescriptor_DepartmentService_ReadDepartments = new grpc.web.MethodDescriptor(
  '/da.proto.DepartmentService/ReadDepartments',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadDepartmentsRequest,
  proto.da.proto.ReadDepartmentsResponse,
  /**
   * @param {!proto.da.proto.ReadDepartmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadDepartmentsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadDepartmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadDepartmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadDepartmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.DepartmentServiceClient.prototype.readDepartments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.DepartmentService/ReadDepartments',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_ReadDepartments,
      callback);
};


/**
 * @param {!proto.da.proto.ReadDepartmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadDepartmentsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.DepartmentServicePromiseClient.prototype.readDepartments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.DepartmentService/ReadDepartments',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_ReadDepartments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateDepartmentRequest,
 *   !proto.da.proto.UpdateDepartmentResponse>}
 */
const methodDescriptor_DepartmentService_UpdateDepartment = new grpc.web.MethodDescriptor(
  '/da.proto.DepartmentService/UpdateDepartment',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateDepartmentRequest,
  proto.da.proto.UpdateDepartmentResponse,
  /**
   * @param {!proto.da.proto.UpdateDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateDepartmentResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateDepartmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateDepartmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.DepartmentServiceClient.prototype.updateDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.DepartmentService/UpdateDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_UpdateDepartment,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateDepartmentResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.DepartmentServicePromiseClient.prototype.updateDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.DepartmentService/UpdateDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_UpdateDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteDepartmentRequest,
 *   !proto.da.proto.DeleteDepartmentResponse>}
 */
const methodDescriptor_DepartmentService_DeleteDepartment = new grpc.web.MethodDescriptor(
  '/da.proto.DepartmentService/DeleteDepartment',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteDepartmentRequest,
  proto.da.proto.DeleteDepartmentResponse,
  /**
   * @param {!proto.da.proto.DeleteDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteDepartmentResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteDepartmentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteDepartmentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.DepartmentServiceClient.prototype.deleteDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.DepartmentService/DeleteDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_DeleteDepartment,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteDepartmentResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.DepartmentServicePromiseClient.prototype.deleteDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.DepartmentService/DeleteDepartment',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_DeleteDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadBranchDepartmentsRequest,
 *   !proto.da.proto.ReadBranchDepartmentsResponse>}
 */
const methodDescriptor_DepartmentService_ReadBranchDepartments = new grpc.web.MethodDescriptor(
  '/da.proto.DepartmentService/ReadBranchDepartments',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadBranchDepartmentsRequest,
  proto.da.proto.ReadBranchDepartmentsResponse,
  /**
   * @param {!proto.da.proto.ReadBranchDepartmentsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadBranchDepartmentsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadBranchDepartmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadBranchDepartmentsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadBranchDepartmentsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.DepartmentServiceClient.prototype.readBranchDepartments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.DepartmentService/ReadBranchDepartments',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_ReadBranchDepartments,
      callback);
};


/**
 * @param {!proto.da.proto.ReadBranchDepartmentsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadBranchDepartmentsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.DepartmentServicePromiseClient.prototype.readBranchDepartments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.DepartmentService/ReadBranchDepartments',
      request,
      metadata || {},
      methodDescriptor_DepartmentService_ReadBranchDepartments);
};


module.exports = proto.da.proto;

