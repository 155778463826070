import { HStack, Input, Spacer } from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { borderColor, primaryWhite } from "../../theme/theme";
import IconButton from "../buttons/IconButton";

interface SearchProps {
	[key: string]: any;
	placeholder?: string;
	icon?: React.ReactNode;
	iconWidth?: string;
	width?: string;
	height?: string;
	setSearch?: React.Dispatch<React.SetStateAction<string>>;
	searchValue?: string | number;
	handleSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	clearAction?: () => void;
}

const Search = ({
	placeholder,
	icon,
	iconWidth,
	width,
	height,
	setSearch,
	searchValue,
	handleSearch,
	clearAction,
	...rest
}: SearchProps) => {
	const [searchVal, setSearchVal] = useState(searchValue || "");

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchVal(e.target.value);
		if (setSearch) setSearch(e.target.value);
		if (handleSearch) handleSearch(e);
	};
	const handleClearFilters = () => {
		if (clearAction) clearAction();
		setSearchVal("");
		setSearch && setSearch("");
	};
	return (
		<HStack
			flexFlow={"row nowrap"}
			alignItems={"center"}
			bg={primaryWhite}
			w={{ base: "auto", lg: width ? width : "20rem" }}
			p={".5rem .5rem"}
			borderRadius={"3px"}
			h={height ? height : "2.0rem"}
			fontSize={"1rem"}
			border={"1px"}
			borderColor={borderColor}
			{...rest}
		>
			<IconButton
				p={0}
				width={iconWidth ? iconWidth : "auto"}
				height={"auto"}
				bg={"transparent"}
				_hover={{
					border: "none",
					transform: "scale(1.1)",
					transition: "all 0.2s ease-in-out",
				}}
				icon={icon ?? <BiSearchAlt />}
				onClick={() => ""}
			/>
			<Input
				value={searchVal ?? ""}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
					handleSearchChange(e)
				}
				placeholder={placeholder ?? "Search"}
				justifyContent={"space-between"}
				alignItems={"center"}
				flexGrow={1}
				border={"none"}
				fontSize={"sm"}
				bg={"transparent"}
				w={"80%"}
				h={"1rem"}
				_focus={{
					outline: "none",
					boxShadow: "none",
				}}
				_placeholder={{
					color: "gray.400",
				}}
			/>
			<Spacer />
			{searchVal && (
				<IconButton fluid icon={<MdClose />} onClick={handleClearFilters} />
			)}
		</HStack>
	);
};

export default Search;
