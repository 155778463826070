import {SET_SELECTED_TICKET} from "../actions/actions";

const SelectedTicket = (state: string = "", action: any) => {
    switch (action.type) {
        case SET_SELECTED_TICKET:
            return action.payload;
        default:
            return state;
    }
}
export default SelectedTicket;