import {initialCountry} from "../../assets/countries";
import {CountryCodeData} from "../../types/DataTypes";

const UserLocation = (state = initialCountry, action: any) : CountryCodeData => {
    switch (action.type) {
        case "SET_USER_LOCATION":
            return action.payload;
        default:
            return state;
    }
}
export default UserLocation;