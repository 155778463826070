import {ADD_CALL_TO_QUEUE, SET_AGENT_REGISTER_STATE} from "../actions/actions";
import {PhoneStateType} from "../../types/DataTypes";

const initialState:PhoneStateType = {
    registererState: 'Unregistered',
    isConnected: false,
    callQueue: [],
}
const PhoneState = (state: PhoneStateType = initialState, action: any) => {
    switch (action.type) {
        case SET_AGENT_REGISTER_STATE:
            return action.payload;
        case ADD_CALL_TO_QUEUE:
            return {...state,callQueue:[...(state.callQueue as Array<any>), action.payload]};
        default:
            return state;
    }
};
export default PhoneState;