import React from "react";
import { Input, InputProps } from "@chakra-ui/react";

/**
 * For forms, use [FormInputDate] instead. This is intended to be a more generic component.
 **/
export const onInputHover = {
	borderColor: "black.500",
};
const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(
	(props, ref) => {
		return (
			<Input
				focusBorderColor={props.isRequired ? "red.500" : ""}
				fontSize={"xs"}
				size={"sm"}
				{...props}
				_placeholder={{ textTransform: "capitalize" }}
				ref={ref}
			/>
		);
	}
);

export default CustomInput;
