import {
	REMOVE_USER_PERMISSIONS,
	SET_USER_PERMISSIONS,
} from "../actions/actions";

function UserPermissions(state: any[] = [], action: any) {
	switch (action.type) {
		case SET_USER_PERMISSIONS:
			return action.payload;
		case REMOVE_USER_PERMISSIONS:
			return action.payload;
		default:
			return state;
	}
}

export default UserPermissions;
