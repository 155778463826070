import { useDisclosure } from "@chakra-ui/react";
import React from "react";
import { SubmitHandler } from "react-hook-form";
import { useDispatch } from "react-redux";
import { notify } from "../../../../gateway/Functions";
import strings from "../../../../localization/main";
import { updateTables } from "../../../../redux/actions/actions";
import CustomEditButton from "../../../buttons/CustomEditButton";

import { Department } from "../../../../gateway/gRPC/pb/department_pb";
import { gRPCUpdateDepartment } from "../../../../gateway/gRPC/Utils/BranchMethods";
import MyModal from "../../../general/MyModal";
import NewDepartmentForm from "./NewDepartmentForm";

interface Props{
  initialValues: Department.AsObject;
}

const EditDepartment = ({ initialValues }: Props) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<Department.AsObject> = async (
    data: Department.AsObject
  ) => {
    setLoading(true);
    let newData = { ...initialValues, ...data };
    gRPCUpdateDepartment(newData)
      .then(() => {
        notify(
          "success",
          "bottomLeft",
          strings.success,
          strings.updated_successfully
        );
        dispatch(updateTables());
        onClose();
      })
      .catch((error) => {
        notify("error", "bottomLeft", strings.error, error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CustomEditButton
        tooltipLabel={strings.edit_department}
        hasArrow
        fontSize={"xs"}
        onClick={onOpen}
      />
      <MyModal
        size={"2xl"}
        closeOnOverlayClick={false}
        disclosure={{ isOpen, onClose, onOpen }}
        scrollBehavior={"inside"}
        title={strings.edit_department}
      >
        <NewDepartmentForm
          loading={loading}
          onSubmit={onSubmit}
          title={strings.edit_department}
          onClose={onClose}
          initialValues={initialValues}
        />
      </MyModal>
    </>
  );
};

export default EditDepartment;
