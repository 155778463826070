import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { MdSave } from "react-icons/md";
import {
  Branch,
  ReadBranchesResponse,
} from "../../../../gateway/gRPC/pb/branch_pb";
import { gRPCReadBranches } from "../../../../gateway/gRPC/Utils/BranchMethods";
import strings from "../../../../localization/main";
import CustomButton from "../../../buttons/CustomButton";
import FormCancelButton from "../../../buttons/FormCancelButton";
import FormContainer from "../../../forms/FormContainer/FormContainer";
import FormInput from "../../../forms/FormContainer/FormInput";
import FormSelect from "../../../forms/FormContainer/FormSelect";
import { Department } from "../../../../gateway/gRPC/pb/department_pb";

interface NewDepartmentFormProps {
  initialValues?: Department.AsObject;
  loading?: boolean;
  title: string;
  onClose: () => void;
  onSubmit: SubmitHandler<any>;
}

const NewDepartmentForm: React.FC<NewDepartmentFormProps> = ({
  initialValues,
  loading,
  title,
  onClose,
  onSubmit,
}) => {
  const [branches, setBranches] = useState<Branch.AsObject[]>([]);
  useEffect(() => {
    gRPCReadBranches()
      .then((res: ReadBranchesResponse.AsObject) => {
        setBranches(res.branchesList);
      })
      .catch((error) => {
        console.error("Error reading branches:", error);
      });
  }, []);

  return (
    <Flex w="100%">
      <Flex
        gap=".5rem"
        borderRadius="5px"
        w="full"
        h="fit-content"
        direction="column"
      >
        <FormContainer initialValues={initialValues} onSubmit={onSubmit} columns={1}>
          <FormInput
            name="name"
            isRequired
            placeholder={strings.name}
            type="text"
          />

          <FormInput
            name="description"
            placeholder={strings.description}
            type="text"
          />

          <FormSelect defaultValue={initialValues?.branchId} name="branchId" isRequired placeholder={strings.branch}>
            {branches.map((branch) => (
              <option key={branch.id} value={branch.id}>
                {branch.name}
              </option>
            ))}
          </FormSelect>

          <Flex justify="flex-end" align="center">
            <FormCancelButton onClick={onClose} />
            <CustomButton type="submit" isLoading={loading}>
              <MdSave /> {"  "}
              {title}
            </CustomButton>
          </Flex>
        </FormContainer>
      </Flex>
    </Flex>
  );
};

export default NewDepartmentForm;
