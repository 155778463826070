// source: contact.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var tag_pb = require('./tag_pb.js');
goog.object.extend(proto, tag_pb);
var label_pb = require('./label_pb.js');
goog.object.extend(proto, label_pb);
var group_pb = require('./group_pb.js');
goog.object.extend(proto, group_pb);
var owner_type_pb = require('./owner_type_pb.js');
goog.object.extend(proto, owner_type_pb);
goog.exportSymbol('proto.da.proto.BulkContactsRequest', null, global);
goog.exportSymbol('proto.da.proto.BulkContactsResponse', null, global);
goog.exportSymbol('proto.da.proto.BulkImportContactRequest', null, global);
goog.exportSymbol('proto.da.proto.BulkImportContactResponse', null, global);
goog.exportSymbol('proto.da.proto.Contact', null, global);
goog.exportSymbol('proto.da.proto.ContactList', null, global);
goog.exportSymbol('proto.da.proto.CreateContactRequest', null, global);
goog.exportSymbol('proto.da.proto.CreateContactResponse', null, global);
goog.exportSymbol('proto.da.proto.CreateContactWithoutTokenRequest', null, global);
goog.exportSymbol('proto.da.proto.CreateContactWithoutTokenResponse', null, global);
goog.exportSymbol('proto.da.proto.CustomField', null, global);
goog.exportSymbol('proto.da.proto.CustomFieldDefinition', null, global);
goog.exportSymbol('proto.da.proto.DeleteContactRequest', null, global);
goog.exportSymbol('proto.da.proto.DeleteContactResponse', null, global);
goog.exportSymbol('proto.da.proto.Document', null, global);
goog.exportSymbol('proto.da.proto.FindContactByEmailOrPhoneRequest', null, global);
goog.exportSymbol('proto.da.proto.FindContactByEmailOrPhoneResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadAccountContactsRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadAccountContactsResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadContactChildrenRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadContactChildrenResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadContactRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadContactResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadContactsRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadContactsResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadCustomFieldDefinitionsRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadCustomFieldDefinitionsResponse', null, global);
goog.exportSymbol('proto.da.proto.SearchContactsRequest', null, global);
goog.exportSymbol('proto.da.proto.SearchContactsResponse', null, global);
goog.exportSymbol('proto.da.proto.SocialPlatform', null, global);
goog.exportSymbol('proto.da.proto.SocialProfile', null, global);
goog.exportSymbol('proto.da.proto.UpdateContactRequest', null, global);
goog.exportSymbol('proto.da.proto.UpdateContactResponse', null, global);
goog.exportSymbol('proto.da.proto.UploadDocumentRequest', null, global);
goog.exportSymbol('proto.da.proto.UploadDocumentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.Contact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.Contact.repeatedFields_, null);
};
goog.inherits(proto.da.proto.Contact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.Contact.displayName = 'proto.da.proto.Contact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CustomField = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CustomField, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CustomField.displayName = 'proto.da.proto.CustomField';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.SocialProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.SocialProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.SocialProfile.displayName = 'proto.da.proto.SocialProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateContactRequest.displayName = 'proto.da.proto.CreateContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateContactResponse.displayName = 'proto.da.proto.CreateContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadContactRequest.displayName = 'proto.da.proto.ReadContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadContactResponse.displayName = 'proto.da.proto.ReadContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadContactsRequest.displayName = 'proto.da.proto.ReadContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadContactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ReadContactsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ReadContactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadContactsResponse.displayName = 'proto.da.proto.ReadContactsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateContactRequest.displayName = 'proto.da.proto.UpdateContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateContactResponse.displayName = 'proto.da.proto.UpdateContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.DeleteContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.DeleteContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.DeleteContactRequest.displayName = 'proto.da.proto.DeleteContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.DeleteContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.DeleteContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.DeleteContactResponse.displayName = 'proto.da.proto.DeleteContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.BulkImportContactRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.BulkImportContactRequest.repeatedFields_, null);
};
goog.inherits(proto.da.proto.BulkImportContactRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.BulkImportContactRequest.displayName = 'proto.da.proto.BulkImportContactRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.BulkImportContactResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.BulkImportContactResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.BulkImportContactResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.BulkImportContactResponse.displayName = 'proto.da.proto.BulkImportContactResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.Document.displayName = 'proto.da.proto.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UploadDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UploadDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UploadDocumentRequest.displayName = 'proto.da.proto.UploadDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UploadDocumentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UploadDocumentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UploadDocumentResponse.displayName = 'proto.da.proto.UploadDocumentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.BulkContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.BulkContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.BulkContactsRequest.displayName = 'proto.da.proto.BulkContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.BulkContactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.BulkContactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.BulkContactsResponse.displayName = 'proto.da.proto.BulkContactsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ContactList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ContactList.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ContactList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ContactList.displayName = 'proto.da.proto.ContactList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CustomFieldDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CustomFieldDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CustomFieldDefinition.displayName = 'proto.da.proto.CustomFieldDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadCustomFieldDefinitionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadCustomFieldDefinitionsRequest.displayName = 'proto.da.proto.ReadCustomFieldDefinitionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ReadCustomFieldDefinitionsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ReadCustomFieldDefinitionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadCustomFieldDefinitionsResponse.displayName = 'proto.da.proto.ReadCustomFieldDefinitionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.SearchContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.SearchContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.SearchContactsRequest.displayName = 'proto.da.proto.SearchContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.SearchContactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.SearchContactsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.SearchContactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.SearchContactsResponse.displayName = 'proto.da.proto.SearchContactsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.FindContactByEmailOrPhoneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.FindContactByEmailOrPhoneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.FindContactByEmailOrPhoneRequest.displayName = 'proto.da.proto.FindContactByEmailOrPhoneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.FindContactByEmailOrPhoneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.FindContactByEmailOrPhoneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.FindContactByEmailOrPhoneResponse.displayName = 'proto.da.proto.FindContactByEmailOrPhoneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateContactWithoutTokenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateContactWithoutTokenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateContactWithoutTokenRequest.displayName = 'proto.da.proto.CreateContactWithoutTokenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateContactWithoutTokenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateContactWithoutTokenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateContactWithoutTokenResponse.displayName = 'proto.da.proto.CreateContactWithoutTokenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadContactChildrenRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadContactChildrenRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadContactChildrenRequest.displayName = 'proto.da.proto.ReadContactChildrenRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadContactChildrenResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ReadContactChildrenResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ReadContactChildrenResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadContactChildrenResponse.displayName = 'proto.da.proto.ReadContactChildrenResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadAccountContactsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadAccountContactsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadAccountContactsRequest.displayName = 'proto.da.proto.ReadAccountContactsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadAccountContactsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ReadAccountContactsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ReadAccountContactsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadAccountContactsResponse.displayName = 'proto.da.proto.ReadAccountContactsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.Contact.repeatedFields_ = [7,34,35,36,37];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.Contact.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.Contact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.Contact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Contact.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    otherName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tagIdsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    organisationId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    serialNumber: jspb.Message.getFieldWithDefault(msg, 10, ""),
    title: jspb.Message.getFieldWithDefault(msg, 11, ""),
    prefix: jspb.Message.getFieldWithDefault(msg, 12, ""),
    suffix: jspb.Message.getFieldWithDefault(msg, 13, ""),
    jobDepartment: jspb.Message.getFieldWithDefault(msg, 14, ""),
    jobTitle: jspb.Message.getFieldWithDefault(msg, 15, ""),
    company: jspb.Message.getFieldWithDefault(msg, 16, ""),
    nickname: jspb.Message.getFieldWithDefault(msg, 18, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 19, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 20, ""),
    url: jspb.Message.getFieldWithDefault(msg, 22, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 23, ""),
    otherPhone: jspb.Message.getFieldWithDefault(msg, 24, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 25, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 26, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 27, ""),
    branchId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    country: jspb.Message.getFieldWithDefault(msg, 29, ""),
    state: jspb.Message.getFieldWithDefault(msg, 30, ""),
    city: jspb.Message.getFieldWithDefault(msg, 31, ""),
    street: jspb.Message.getFieldWithDefault(msg, 32, ""),
    postalCode: jspb.Message.getFieldWithDefault(msg, 33, ""),
    labelIdsList: (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
    labelsList: jspb.Message.toObjectList(msg.getLabelsList(),
    label_pb.Label.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    tag_pb.Tag.toObject, includeInstance),
    customFieldsList: jspb.Message.toObjectList(msg.getCustomFieldsList(),
    proto.da.proto.CustomField.toObject, includeInstance),
    ownerId: jspb.Message.getFieldWithDefault(msg, 38, ""),
    ownerType: jspb.Message.getFieldWithDefault(msg, 39, 0),
    parentId: jspb.Message.getFieldWithDefault(msg, 40, ""),
    teamId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 42, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.Contact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.Contact;
  return proto.da.proto.Contact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.Contact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.Contact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addTagIds(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialNumber(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefix(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuffix(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobDepartment(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobTitle(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOtherPhone(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchId(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCity(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreet(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setPostalCode(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.addLabelIds(value);
      break;
    case 35:
      var value = new label_pb.Label;
      reader.readMessage(value,label_pb.Label.deserializeBinaryFromReader);
      msg.addLabels(value);
      break;
    case 36:
      var value = new tag_pb.Tag;
      reader.readMessage(value,tag_pb.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 37:
      var value = new proto.da.proto.CustomField;
      reader.readMessage(value,proto.da.proto.CustomField.deserializeBinaryFromReader);
      msg.addCustomFields(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 39:
      var value = /** @type {!proto.da.proto.OwnerType} */ (reader.readEnum());
      msg.setOwnerType(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setTeamId(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.Contact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.Contact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.Contact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Contact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOtherName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTagIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSerialNumber();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPrefix();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getSuffix();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getJobDepartment();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getJobTitle();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOtherPhone();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getBranchId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getCity();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getStreet();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getPostalCode();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getLabelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      34,
      f
    );
  }
  f = message.getLabelsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      label_pb.Label.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      36,
      f,
      tag_pb.Tag.serializeBinaryToWriter
    );
  }
  f = message.getCustomFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      37,
      f,
      proto.da.proto.CustomField.serializeBinaryToWriter
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getOwnerType();
  if (f !== 0.0) {
    writer.writeEnum(
      39,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getTeamId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first_name = 2;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string last_name = 3;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string other_name = 4;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getOtherName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setOtherName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string tag_ids = 7;
 * @return {!Array<string>}
 */
proto.da.proto.Contact.prototype.getTagIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setTagIdsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.addTagIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.clearTagIdsList = function() {
  return this.setTagIdsList([]);
};


/**
 * optional string organisation_id = 8;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string serial_number = 10;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getSerialNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setSerialNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string title = 11;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string prefix = 12;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string suffix = 13;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setSuffix = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string job_department = 14;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getJobDepartment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setJobDepartment = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string job_title = 15;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getJobTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setJobTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string company = 16;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string nickname = 18;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string notes = 19;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string birthday = 20;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string url = 22;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string created_by = 23;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string other_phone = 24;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getOtherPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setOtherPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string department_id = 25;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string created_at = 26;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string updated_at = 27;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string branch_id = 28;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getBranchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string country = 29;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string state = 30;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string city = 31;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getCity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setCity = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string street = 32;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getStreet = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setStreet = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string postal_code = 33;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getPostalCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setPostalCode = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * repeated string label_ids = 34;
 * @return {!Array<string>}
 */
proto.da.proto.Contact.prototype.getLabelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 34));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setLabelIdsList = function(value) {
  return jspb.Message.setField(this, 34, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.addLabelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.clearLabelIdsList = function() {
  return this.setLabelIdsList([]);
};


/**
 * repeated Label labels = 35;
 * @return {!Array<!proto.da.proto.Label>}
 */
proto.da.proto.Contact.prototype.getLabelsList = function() {
  return /** @type{!Array<!proto.da.proto.Label>} */ (
    jspb.Message.getRepeatedWrapperField(this, label_pb.Label, 35));
};


/**
 * @param {!Array<!proto.da.proto.Label>} value
 * @return {!proto.da.proto.Contact} returns this
*/
proto.da.proto.Contact.prototype.setLabelsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.da.proto.Label=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Label}
 */
proto.da.proto.Contact.prototype.addLabels = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.da.proto.Label, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.clearLabelsList = function() {
  return this.setLabelsList([]);
};


/**
 * repeated Tag tags = 36;
 * @return {!Array<!proto.da.proto.Tag>}
 */
proto.da.proto.Contact.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.da.proto.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, tag_pb.Tag, 36));
};


/**
 * @param {!Array<!proto.da.proto.Tag>} value
 * @return {!proto.da.proto.Contact} returns this
*/
proto.da.proto.Contact.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 36, value);
};


/**
 * @param {!proto.da.proto.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Tag}
 */
proto.da.proto.Contact.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 36, opt_value, proto.da.proto.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * repeated CustomField custom_fields = 37;
 * @return {!Array<!proto.da.proto.CustomField>}
 */
proto.da.proto.Contact.prototype.getCustomFieldsList = function() {
  return /** @type{!Array<!proto.da.proto.CustomField>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.CustomField, 37));
};


/**
 * @param {!Array<!proto.da.proto.CustomField>} value
 * @return {!proto.da.proto.Contact} returns this
*/
proto.da.proto.Contact.prototype.setCustomFieldsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 37, value);
};


/**
 * @param {!proto.da.proto.CustomField=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.CustomField}
 */
proto.da.proto.Contact.prototype.addCustomFields = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 37, opt_value, proto.da.proto.CustomField, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.clearCustomFieldsList = function() {
  return this.setCustomFieldsList([]);
};


/**
 * optional string owner_id = 38;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional OwnerType owner_type = 39;
 * @return {!proto.da.proto.OwnerType}
 */
proto.da.proto.Contact.prototype.getOwnerType = function() {
  return /** @type {!proto.da.proto.OwnerType} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {!proto.da.proto.OwnerType} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setOwnerType = function(value) {
  return jspb.Message.setProto3EnumField(this, 39, value);
};


/**
 * optional string parent_id = 40;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string team_id = 41;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getTeamId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setTeamId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string account_id = 42;
 * @return {string}
 */
proto.da.proto.Contact.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Contact} returns this
 */
proto.da.proto.Contact.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CustomField.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CustomField.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CustomField} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CustomField.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CustomField}
 */
proto.da.proto.CustomField.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CustomField;
  return proto.da.proto.CustomField.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CustomField} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CustomField}
 */
proto.da.proto.CustomField.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CustomField.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CustomField.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CustomField} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CustomField.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.da.proto.CustomField.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomField} returns this
 */
proto.da.proto.CustomField.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.da.proto.CustomField.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomField} returns this
 */
proto.da.proto.CustomField.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.da.proto.CustomField.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomField} returns this
 */
proto.da.proto.CustomField.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.SocialProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.SocialProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.SocialProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.SocialProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    platform: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, ""),
    link: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.SocialProfile}
 */
proto.da.proto.SocialProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.SocialProfile;
  return proto.da.proto.SocialProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.SocialProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.SocialProfile}
 */
proto.da.proto.SocialProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.da.proto.SocialPlatform} */ (reader.readEnum());
      msg.setPlatform(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLink(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.SocialProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.SocialProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.SocialProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.SocialProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlatform();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLink();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional SocialPlatform platform = 1;
 * @return {!proto.da.proto.SocialPlatform}
 */
proto.da.proto.SocialProfile.prototype.getPlatform = function() {
  return /** @type {!proto.da.proto.SocialPlatform} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.da.proto.SocialPlatform} value
 * @return {!proto.da.proto.SocialProfile} returns this
 */
proto.da.proto.SocialProfile.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.da.proto.SocialProfile.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.SocialProfile} returns this
 */
proto.da.proto.SocialProfile.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string link = 3;
 * @return {string}
 */
proto.da.proto.SocialProfile.prototype.getLink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.SocialProfile} returns this
 */
proto.da.proto.SocialProfile.prototype.setLink = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateContactRequest}
 */
proto.da.proto.CreateContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateContactRequest;
  return proto.da.proto.CreateContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateContactRequest}
 */
proto.da.proto.CreateContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.CreateContactRequest.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.CreateContactRequest} returns this
*/
proto.da.proto.CreateContactRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateContactRequest} returns this
 */
proto.da.proto.CreateContactRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateContactRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateContactResponse}
 */
proto.da.proto.CreateContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateContactResponse;
  return proto.da.proto.CreateContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateContactResponse}
 */
proto.da.proto.CreateContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.CreateContactResponse.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.CreateContactResponse} returns this
*/
proto.da.proto.CreateContactResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateContactResponse} returns this
 */
proto.da.proto.CreateContactResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateContactResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadContactRequest}
 */
proto.da.proto.ReadContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadContactRequest;
  return proto.da.proto.ReadContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadContactRequest}
 */
proto.da.proto.ReadContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string contact_id = 1;
 * @return {string}
 */
proto.da.proto.ReadContactRequest.prototype.getContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadContactRequest} returns this
 */
proto.da.proto.ReadContactRequest.prototype.setContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organisation_id = 2;
 * @return {string}
 */
proto.da.proto.ReadContactRequest.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadContactRequest} returns this
 */
proto.da.proto.ReadContactRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadContactResponse}
 */
proto.da.proto.ReadContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadContactResponse;
  return proto.da.proto.ReadContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadContactResponse}
 */
proto.da.proto.ReadContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.ReadContactResponse.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.ReadContactResponse} returns this
*/
proto.da.proto.ReadContactResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.ReadContactResponse} returns this
 */
proto.da.proto.ReadContactResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.ReadContactResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    organisationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadContactsRequest}
 */
proto.da.proto.ReadContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadContactsRequest;
  return proto.da.proto.ReadContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadContactsRequest}
 */
proto.da.proto.ReadContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string organisation_id = 1;
 * @return {string}
 */
proto.da.proto.ReadContactsRequest.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadContactsRequest} returns this
 */
proto.da.proto.ReadContactsRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.da.proto.ReadContactsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.ReadContactsRequest} returns this
 */
proto.da.proto.ReadContactsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 per_page = 3;
 * @return {number}
 */
proto.da.proto.ReadContactsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.ReadContactsRequest} returns this
 */
proto.da.proto.ReadContactsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ReadContactsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadContactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadContactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadContactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadContactsResponse}
 */
proto.da.proto.ReadContactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadContactsResponse;
  return proto.da.proto.ReadContactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadContactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadContactsResponse}
 */
proto.da.proto.ReadContactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadContactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadContactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadContactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.ReadContactsResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.ReadContactsResponse} returns this
*/
proto.da.proto.ReadContactsResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.ReadContactsResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ReadContactsResponse} returns this
 */
proto.da.proto.ReadContactsResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.da.proto.ReadContactsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.ReadContactsResponse} returns this
 */
proto.da.proto.ReadContactsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f),
    organisationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateContactRequest}
 */
proto.da.proto.UpdateContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateContactRequest;
  return proto.da.proto.UpdateContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateContactRequest}
 */
proto.da.proto.UpdateContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.UpdateContactRequest.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.UpdateContactRequest} returns this
*/
proto.da.proto.UpdateContactRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateContactRequest} returns this
 */
proto.da.proto.UpdateContactRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateContactRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string organisation_id = 2;
 * @return {string}
 */
proto.da.proto.UpdateContactRequest.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.UpdateContactRequest} returns this
 */
proto.da.proto.UpdateContactRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateContactResponse}
 */
proto.da.proto.UpdateContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateContactResponse;
  return proto.da.proto.UpdateContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateContactResponse}
 */
proto.da.proto.UpdateContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.UpdateContactResponse.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.UpdateContactResponse} returns this
*/
proto.da.proto.UpdateContactResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateContactResponse} returns this
 */
proto.da.proto.UpdateContactResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateContactResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.DeleteContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.DeleteContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.DeleteContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.DeleteContactRequest}
 */
proto.da.proto.DeleteContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.DeleteContactRequest;
  return proto.da.proto.DeleteContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.DeleteContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.DeleteContactRequest}
 */
proto.da.proto.DeleteContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.DeleteContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.DeleteContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.DeleteContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string contact_id = 1;
 * @return {string}
 */
proto.da.proto.DeleteContactRequest.prototype.getContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.DeleteContactRequest} returns this
 */
proto.da.proto.DeleteContactRequest.prototype.setContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string organisation_id = 2;
 * @return {string}
 */
proto.da.proto.DeleteContactRequest.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.DeleteContactRequest} returns this
 */
proto.da.proto.DeleteContactRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.DeleteContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.DeleteContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.DeleteContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.DeleteContactResponse}
 */
proto.da.proto.DeleteContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.DeleteContactResponse;
  return proto.da.proto.DeleteContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.DeleteContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.DeleteContactResponse}
 */
proto.da.proto.DeleteContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.DeleteContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.DeleteContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.DeleteContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.da.proto.DeleteContactResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.da.proto.DeleteContactResponse} returns this
 */
proto.da.proto.DeleteContactResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.BulkImportContactRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.BulkImportContactRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.BulkImportContactRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.BulkImportContactRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkImportContactRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.BulkImportContactRequest}
 */
proto.da.proto.BulkImportContactRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.BulkImportContactRequest;
  return proto.da.proto.BulkImportContactRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.BulkImportContactRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.BulkImportContactRequest}
 */
proto.da.proto.BulkImportContactRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.BulkImportContactRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.BulkImportContactRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.BulkImportContactRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkImportContactRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.BulkImportContactRequest.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.BulkImportContactRequest} returns this
*/
proto.da.proto.BulkImportContactRequest.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.BulkImportContactRequest.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.BulkImportContactRequest} returns this
 */
proto.da.proto.BulkImportContactRequest.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.BulkImportContactResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.BulkImportContactResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.BulkImportContactResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.BulkImportContactResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkImportContactResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.BulkImportContactResponse}
 */
proto.da.proto.BulkImportContactResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.BulkImportContactResponse;
  return proto.da.proto.BulkImportContactResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.BulkImportContactResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.BulkImportContactResponse}
 */
proto.da.proto.BulkImportContactResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.BulkImportContactResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.BulkImportContactResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.BulkImportContactResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkImportContactResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.BulkImportContactResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.BulkImportContactResponse} returns this
*/
proto.da.proto.BulkImportContactResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.BulkImportContactResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.BulkImportContactResponse} returns this
 */
proto.da.proto.BulkImportContactResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    content: msg.getContent_asB64(),
    filename: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.Document}
 */
proto.da.proto.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.Document;
  return proto.da.proto.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.Document}
 */
proto.da.proto.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContent_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bytes content = 1;
 * @return {!(string|Uint8Array)}
 */
proto.da.proto.Document.prototype.getContent = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes content = 1;
 * This is a type-conversion wrapper around `getContent()`
 * @return {string}
 */
proto.da.proto.Document.prototype.getContent_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContent()));
};


/**
 * optional bytes content = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContent()`
 * @return {!Uint8Array}
 */
proto.da.proto.Document.prototype.getContent_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContent()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.da.proto.Document} returns this
 */
proto.da.proto.Document.prototype.setContent = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional string filename = 2;
 * @return {string}
 */
proto.da.proto.Document.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Document} returns this
 */
proto.da.proto.Document.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UploadDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UploadDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UploadDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UploadDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.da.proto.Document.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UploadDocumentRequest}
 */
proto.da.proto.UploadDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UploadDocumentRequest;
  return proto.da.proto.UploadDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UploadDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UploadDocumentRequest}
 */
proto.da.proto.UploadDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Document;
      reader.readMessage(value,proto.da.proto.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UploadDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UploadDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UploadDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UploadDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Document.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.da.proto.Document}
 */
proto.da.proto.UploadDocumentRequest.prototype.getDocument = function() {
  return /** @type{?proto.da.proto.Document} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Document, 1));
};


/**
 * @param {?proto.da.proto.Document|undefined} value
 * @return {!proto.da.proto.UploadDocumentRequest} returns this
*/
proto.da.proto.UploadDocumentRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UploadDocumentRequest} returns this
 */
proto.da.proto.UploadDocumentRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UploadDocumentRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UploadDocumentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UploadDocumentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UploadDocumentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UploadDocumentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UploadDocumentResponse}
 */
proto.da.proto.UploadDocumentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UploadDocumentResponse;
  return proto.da.proto.UploadDocumentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UploadDocumentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UploadDocumentResponse}
 */
proto.da.proto.UploadDocumentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UploadDocumentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UploadDocumentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UploadDocumentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UploadDocumentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string documentId = 1;
 * @return {string}
 */
proto.da.proto.UploadDocumentResponse.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.UploadDocumentResponse} returns this
 */
proto.da.proto.UploadDocumentResponse.prototype.setDocumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.BulkContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.BulkContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.BulkContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: msg.getData_asB64(),
    group: (f = msg.getGroup()) && group_pb.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.BulkContactsRequest}
 */
proto.da.proto.BulkContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.BulkContactsRequest;
  return proto.da.proto.BulkContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.BulkContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.BulkContactsRequest}
 */
proto.da.proto.BulkContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setData(value);
      break;
    case 2:
      var value = new group_pb.Group;
      reader.readMessage(value,group_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.BulkContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.BulkContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.BulkContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getData_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      group_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes data = 1;
 * @return {!(string|Uint8Array)}
 */
proto.da.proto.BulkContactsRequest.prototype.getData = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes data = 1;
 * This is a type-conversion wrapper around `getData()`
 * @return {string}
 */
proto.da.proto.BulkContactsRequest.prototype.getData_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getData()));
};


/**
 * optional bytes data = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getData()`
 * @return {!Uint8Array}
 */
proto.da.proto.BulkContactsRequest.prototype.getData_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getData()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.da.proto.BulkContactsRequest} returns this
 */
proto.da.proto.BulkContactsRequest.prototype.setData = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional Group group = 2;
 * @return {?proto.da.proto.Group}
 */
proto.da.proto.BulkContactsRequest.prototype.getGroup = function() {
  return /** @type{?proto.da.proto.Group} */ (
    jspb.Message.getWrapperField(this, group_pb.Group, 2));
};


/**
 * @param {?proto.da.proto.Group|undefined} value
 * @return {!proto.da.proto.BulkContactsRequest} returns this
*/
proto.da.proto.BulkContactsRequest.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.BulkContactsRequest} returns this
 */
proto.da.proto.BulkContactsRequest.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.BulkContactsRequest.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.BulkContactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.BulkContactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.BulkContactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkContactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.BulkContactsResponse}
 */
proto.da.proto.BulkContactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.BulkContactsResponse;
  return proto.da.proto.BulkContactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.BulkContactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.BulkContactsResponse}
 */
proto.da.proto.BulkContactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.BulkContactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.BulkContactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.BulkContactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.BulkContactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.da.proto.BulkContactsResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.da.proto.BulkContactsResponse} returns this
 */
proto.da.proto.BulkContactsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ContactList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ContactList.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ContactList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ContactList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ContactList.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ContactList}
 */
proto.da.proto.ContactList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ContactList;
  return proto.da.proto.ContactList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ContactList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ContactList}
 */
proto.da.proto.ContactList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ContactList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ContactList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ContactList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ContactList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.ContactList.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.ContactList} returns this
*/
proto.da.proto.ContactList.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.ContactList.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ContactList} returns this
 */
proto.da.proto.ContactList.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CustomFieldDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CustomFieldDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CustomFieldDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CustomFieldDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    branchId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CustomFieldDefinition}
 */
proto.da.proto.CustomFieldDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CustomFieldDefinition;
  return proto.da.proto.CustomFieldDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CustomFieldDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CustomFieldDefinition}
 */
proto.da.proto.CustomFieldDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CustomFieldDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CustomFieldDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CustomFieldDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CustomFieldDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBranchId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string created_at = 4;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string updated_at = 5;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string organisation_id = 6;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string branch_id = 7;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getBranchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string department_id = 8;
 * @return {string}
 */
proto.da.proto.CustomFieldDefinition.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.CustomFieldDefinition} returns this
 */
proto.da.proto.CustomFieldDefinition.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadCustomFieldDefinitionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    branchId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    departmentId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsRequest}
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadCustomFieldDefinitionsRequest;
  return proto.da.proto.ReadCustomFieldDefinitionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsRequest}
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadCustomFieldDefinitionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string branch_id = 1;
 * @return {string}
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.prototype.getBranchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsRequest} returns this
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string department_id = 2;
 * @return {string}
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsRequest} returns this
 */
proto.da.proto.ReadCustomFieldDefinitionsRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadCustomFieldDefinitionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customFieldDefinitionsList: jspb.Message.toObjectList(msg.getCustomFieldDefinitionsList(),
    proto.da.proto.CustomFieldDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsResponse}
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadCustomFieldDefinitionsResponse;
  return proto.da.proto.ReadCustomFieldDefinitionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsResponse}
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.CustomFieldDefinition;
      reader.readMessage(value,proto.da.proto.CustomFieldDefinition.deserializeBinaryFromReader);
      msg.addCustomFieldDefinitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadCustomFieldDefinitionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomFieldDefinitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.CustomFieldDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomFieldDefinition custom_field_definitions = 1;
 * @return {!Array<!proto.da.proto.CustomFieldDefinition>}
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.prototype.getCustomFieldDefinitionsList = function() {
  return /** @type{!Array<!proto.da.proto.CustomFieldDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.CustomFieldDefinition, 1));
};


/**
 * @param {!Array<!proto.da.proto.CustomFieldDefinition>} value
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsResponse} returns this
*/
proto.da.proto.ReadCustomFieldDefinitionsResponse.prototype.setCustomFieldDefinitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.CustomFieldDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.CustomFieldDefinition}
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.prototype.addCustomFieldDefinitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.CustomFieldDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ReadCustomFieldDefinitionsResponse} returns this
 */
proto.da.proto.ReadCustomFieldDefinitionsResponse.prototype.clearCustomFieldDefinitionsList = function() {
  return this.setCustomFieldDefinitionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.SearchContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.SearchContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.SearchContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.SearchContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    groupId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.SearchContactsRequest}
 */
proto.da.proto.SearchContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.SearchContactsRequest;
  return proto.da.proto.SearchContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.SearchContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.SearchContactsRequest}
 */
proto.da.proto.SearchContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.SearchContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.SearchContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.SearchContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.SearchContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getGroupId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.da.proto.SearchContactsRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.SearchContactsRequest} returns this
 */
proto.da.proto.SearchContactsRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.da.proto.SearchContactsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.SearchContactsRequest} returns this
 */
proto.da.proto.SearchContactsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 per_page = 3;
 * @return {number}
 */
proto.da.proto.SearchContactsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.SearchContactsRequest} returns this
 */
proto.da.proto.SearchContactsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string group_id = 4;
 * @return {string}
 */
proto.da.proto.SearchContactsRequest.prototype.getGroupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.SearchContactsRequest} returns this
 */
proto.da.proto.SearchContactsRequest.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.da.proto.SearchContactsRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.SearchContactsRequest} returns this
 */
proto.da.proto.SearchContactsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.SearchContactsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.SearchContactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.SearchContactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.SearchContactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.SearchContactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.SearchContactsResponse}
 */
proto.da.proto.SearchContactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.SearchContactsResponse;
  return proto.da.proto.SearchContactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.SearchContactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.SearchContactsResponse}
 */
proto.da.proto.SearchContactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.SearchContactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.SearchContactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.SearchContactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.SearchContactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.SearchContactsResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.SearchContactsResponse} returns this
*/
proto.da.proto.SearchContactsResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.SearchContactsResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.SearchContactsResponse} returns this
 */
proto.da.proto.SearchContactsResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.da.proto.SearchContactsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.SearchContactsResponse} returns this
 */
proto.da.proto.SearchContactsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.FindContactByEmailOrPhoneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.FindContactByEmailOrPhoneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.FindContactByEmailOrPhoneRequest}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.FindContactByEmailOrPhoneRequest;
  return proto.da.proto.FindContactByEmailOrPhoneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.FindContactByEmailOrPhoneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.FindContactByEmailOrPhoneRequest}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.FindContactByEmailOrPhoneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.FindContactByEmailOrPhoneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.FindContactByEmailOrPhoneRequest} returns this
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.FindContactByEmailOrPhoneRequest} returns this
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string organisation_id = 3;
 * @return {string}
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.FindContactByEmailOrPhoneRequest} returns this
 */
proto.da.proto.FindContactByEmailOrPhoneRequest.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.FindContactByEmailOrPhoneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.FindContactByEmailOrPhoneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.FindContactByEmailOrPhoneResponse}
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.FindContactByEmailOrPhoneResponse;
  return proto.da.proto.FindContactByEmailOrPhoneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.FindContactByEmailOrPhoneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.FindContactByEmailOrPhoneResponse}
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.FindContactByEmailOrPhoneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.FindContactByEmailOrPhoneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.FindContactByEmailOrPhoneResponse} returns this
*/
proto.da.proto.FindContactByEmailOrPhoneResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.FindContactByEmailOrPhoneResponse} returns this
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.FindContactByEmailOrPhoneResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateContactWithoutTokenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateContactWithoutTokenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateContactWithoutTokenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactWithoutTokenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateContactWithoutTokenRequest}
 */
proto.da.proto.CreateContactWithoutTokenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateContactWithoutTokenRequest;
  return proto.da.proto.CreateContactWithoutTokenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateContactWithoutTokenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateContactWithoutTokenRequest}
 */
proto.da.proto.CreateContactWithoutTokenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateContactWithoutTokenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateContactWithoutTokenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateContactWithoutTokenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactWithoutTokenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.CreateContactWithoutTokenRequest.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.CreateContactWithoutTokenRequest} returns this
*/
proto.da.proto.CreateContactWithoutTokenRequest.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateContactWithoutTokenRequest} returns this
 */
proto.da.proto.CreateContactWithoutTokenRequest.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateContactWithoutTokenRequest.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateContactWithoutTokenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateContactWithoutTokenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateContactWithoutTokenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactWithoutTokenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contact: (f = msg.getContact()) && proto.da.proto.Contact.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateContactWithoutTokenResponse}
 */
proto.da.proto.CreateContactWithoutTokenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateContactWithoutTokenResponse;
  return proto.da.proto.CreateContactWithoutTokenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateContactWithoutTokenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateContactWithoutTokenResponse}
 */
proto.da.proto.CreateContactWithoutTokenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateContactWithoutTokenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateContactWithoutTokenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateContactWithoutTokenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateContactWithoutTokenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * optional Contact contact = 1;
 * @return {?proto.da.proto.Contact}
 */
proto.da.proto.CreateContactWithoutTokenResponse.prototype.getContact = function() {
  return /** @type{?proto.da.proto.Contact} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {?proto.da.proto.Contact|undefined} value
 * @return {!proto.da.proto.CreateContactWithoutTokenResponse} returns this
*/
proto.da.proto.CreateContactWithoutTokenResponse.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateContactWithoutTokenResponse} returns this
 */
proto.da.proto.CreateContactWithoutTokenResponse.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateContactWithoutTokenResponse.prototype.hasContact = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadContactChildrenRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadContactChildrenRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadContactChildrenRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactChildrenRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadContactChildrenRequest}
 */
proto.da.proto.ReadContactChildrenRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadContactChildrenRequest;
  return proto.da.proto.ReadContactChildrenRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadContactChildrenRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadContactChildrenRequest}
 */
proto.da.proto.ReadContactChildrenRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setContactId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadContactChildrenRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadContactChildrenRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadContactChildrenRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactChildrenRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string contact_id = 1;
 * @return {string}
 */
proto.da.proto.ReadContactChildrenRequest.prototype.getContactId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadContactChildrenRequest} returns this
 */
proto.da.proto.ReadContactChildrenRequest.prototype.setContactId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ReadContactChildrenResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadContactChildrenResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadContactChildrenResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadContactChildrenResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactChildrenResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadContactChildrenResponse}
 */
proto.da.proto.ReadContactChildrenResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadContactChildrenResponse;
  return proto.da.proto.ReadContactChildrenResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadContactChildrenResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadContactChildrenResponse}
 */
proto.da.proto.ReadContactChildrenResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadContactChildrenResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadContactChildrenResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadContactChildrenResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadContactChildrenResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.ReadContactChildrenResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.ReadContactChildrenResponse} returns this
*/
proto.da.proto.ReadContactChildrenResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.ReadContactChildrenResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ReadContactChildrenResponse} returns this
 */
proto.da.proto.ReadContactChildrenResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadAccountContactsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadAccountContactsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadAccountContactsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadAccountContactsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadAccountContactsRequest}
 */
proto.da.proto.ReadAccountContactsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadAccountContactsRequest;
  return proto.da.proto.ReadAccountContactsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadAccountContactsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadAccountContactsRequest}
 */
proto.da.proto.ReadAccountContactsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadAccountContactsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadAccountContactsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadAccountContactsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadAccountContactsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.da.proto.ReadAccountContactsRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadAccountContactsRequest} returns this
 */
proto.da.proto.ReadAccountContactsRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.da.proto.ReadAccountContactsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.ReadAccountContactsRequest} returns this
 */
proto.da.proto.ReadAccountContactsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 per_page = 3;
 * @return {number}
 */
proto.da.proto.ReadAccountContactsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.ReadAccountContactsRequest} returns this
 */
proto.da.proto.ReadAccountContactsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ReadAccountContactsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadAccountContactsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadAccountContactsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadAccountContactsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadAccountContactsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    contactsList: jspb.Message.toObjectList(msg.getContactsList(),
    proto.da.proto.Contact.toObject, includeInstance),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadAccountContactsResponse}
 */
proto.da.proto.ReadAccountContactsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadAccountContactsResponse;
  return proto.da.proto.ReadAccountContactsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadAccountContactsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadAccountContactsResponse}
 */
proto.da.proto.ReadAccountContactsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Contact;
      reader.readMessage(value,proto.da.proto.Contact.deserializeBinaryFromReader);
      msg.addContacts(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadAccountContactsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadAccountContactsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadAccountContactsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadAccountContactsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContactsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Contact.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated Contact contacts = 1;
 * @return {!Array<!proto.da.proto.Contact>}
 */
proto.da.proto.ReadAccountContactsResponse.prototype.getContactsList = function() {
  return /** @type{!Array<!proto.da.proto.Contact>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Contact, 1));
};


/**
 * @param {!Array<!proto.da.proto.Contact>} value
 * @return {!proto.da.proto.ReadAccountContactsResponse} returns this
*/
proto.da.proto.ReadAccountContactsResponse.prototype.setContactsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Contact=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Contact}
 */
proto.da.proto.ReadAccountContactsResponse.prototype.addContacts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Contact, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ReadAccountContactsResponse} returns this
 */
proto.da.proto.ReadAccountContactsResponse.prototype.clearContactsList = function() {
  return this.setContactsList([]);
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.da.proto.ReadAccountContactsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.da.proto.ReadAccountContactsResponse} returns this
 */
proto.da.proto.ReadAccountContactsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.da.proto.SocialPlatform = {
  SOCIAL_PLATFORM_UNKNOWN: 0,
  LINKEDIN: 1,
  TWITTER: 2,
  FACEBOOK: 3,
  INSTAGRAM: 4
};

goog.object.extend(exports, proto.da.proto);
