// source: department.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var branch_pb = require('./branch_pb.js');
goog.object.extend(proto, branch_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var owner_type_pb = require('./owner_type_pb.js');
goog.object.extend(proto, owner_type_pb);
goog.exportSymbol('proto.da.proto.CreateDepartmentRequest', null, global);
goog.exportSymbol('proto.da.proto.CreateDepartmentResponse', null, global);
goog.exportSymbol('proto.da.proto.DeleteDepartmentRequest', null, global);
goog.exportSymbol('proto.da.proto.DeleteDepartmentResponse', null, global);
goog.exportSymbol('proto.da.proto.Department', null, global);
goog.exportSymbol('proto.da.proto.ReadBranchDepartmentsRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadBranchDepartmentsResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadDepartmentRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadDepartmentResponse', null, global);
goog.exportSymbol('proto.da.proto.ReadDepartmentsRequest', null, global);
goog.exportSymbol('proto.da.proto.ReadDepartmentsResponse', null, global);
goog.exportSymbol('proto.da.proto.UpdateDepartmentRequest', null, global);
goog.exportSymbol('proto.da.proto.UpdateDepartmentResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.Department = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.Department.repeatedFields_, null);
};
goog.inherits(proto.da.proto.Department, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.Department.displayName = 'proto.da.proto.Department';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateDepartmentRequest.displayName = 'proto.da.proto.CreateDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.CreateDepartmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.CreateDepartmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.CreateDepartmentResponse.displayName = 'proto.da.proto.CreateDepartmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadDepartmentRequest.displayName = 'proto.da.proto.ReadDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadDepartmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadDepartmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadDepartmentResponse.displayName = 'proto.da.proto.ReadDepartmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadDepartmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadDepartmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadDepartmentsRequest.displayName = 'proto.da.proto.ReadDepartmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadDepartmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ReadDepartmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ReadDepartmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadDepartmentsResponse.displayName = 'proto.da.proto.ReadDepartmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadBranchDepartmentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.ReadBranchDepartmentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadBranchDepartmentsRequest.displayName = 'proto.da.proto.ReadBranchDepartmentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.ReadBranchDepartmentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.da.proto.ReadBranchDepartmentsResponse.repeatedFields_, null);
};
goog.inherits(proto.da.proto.ReadBranchDepartmentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.ReadBranchDepartmentsResponse.displayName = 'proto.da.proto.ReadBranchDepartmentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateDepartmentRequest.displayName = 'proto.da.proto.UpdateDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.UpdateDepartmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.UpdateDepartmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.UpdateDepartmentResponse.displayName = 'proto.da.proto.UpdateDepartmentResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.DeleteDepartmentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.DeleteDepartmentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.DeleteDepartmentRequest.displayName = 'proto.da.proto.DeleteDepartmentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.da.proto.DeleteDepartmentResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.da.proto.DeleteDepartmentResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.da.proto.DeleteDepartmentResponse.displayName = 'proto.da.proto.DeleteDepartmentResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.Department.repeatedFields_ = [15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.Department.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.Department.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.Department} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Department.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    organisationId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    branch: (f = msg.getBranch()) && branch_pb.Branch.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 13, ""),
    updatedBy: jspb.Message.getFieldWithDefault(msg, 14, ""),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    user_pb.User.toObject, includeInstance),
    branchId: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.Department}
 */
proto.da.proto.Department.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.Department;
  return proto.da.proto.Department.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.Department} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.Department}
 */
proto.da.proto.Department.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrganisationId(value);
      break;
    case 5:
      var value = new branch_pb.Branch;
      reader.readMessage(value,branch_pb.Branch.deserializeBinaryFromReader);
      msg.setBranch(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedAt(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setUpdatedBy(value);
      break;
    case 15:
      var value = new user_pb.User;
      reader.readMessage(value,user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.Department.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.Department.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.Department} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.Department.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrganisationId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBranch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      branch_pb.Branch.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getUpdatedBy();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      user_pb.User.serializeBinaryToWriter
    );
  }
  f = message.getBranchId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.Department.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.da.proto.Department.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.da.proto.Department.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string organisation_id = 4;
 * @return {string}
 */
proto.da.proto.Department.prototype.getOrganisationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setOrganisationId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Branch branch = 5;
 * @return {?proto.da.proto.Branch}
 */
proto.da.proto.Department.prototype.getBranch = function() {
  return /** @type{?proto.da.proto.Branch} */ (
    jspb.Message.getWrapperField(this, branch_pb.Branch, 5));
};


/**
 * @param {?proto.da.proto.Branch|undefined} value
 * @return {!proto.da.proto.Department} returns this
*/
proto.da.proto.Department.prototype.setBranch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.clearBranch = function() {
  return this.setBranch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.Department.prototype.hasBranch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string created_at = 6;
 * @return {string}
 */
proto.da.proto.Department.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.da.proto.Department.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string created_by = 12;
 * @return {string}
 */
proto.da.proto.Department.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string updated_at = 13;
 * @return {string}
 */
proto.da.proto.Department.prototype.getUpdatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string updated_by = 14;
 * @return {string}
 */
proto.da.proto.Department.prototype.getUpdatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setUpdatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated User users = 15;
 * @return {!Array<!proto.da.proto.User>}
 */
proto.da.proto.Department.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.da.proto.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, user_pb.User, 15));
};


/**
 * @param {!Array<!proto.da.proto.User>} value
 * @return {!proto.da.proto.Department} returns this
*/
proto.da.proto.Department.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.da.proto.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.User}
 */
proto.da.proto.Department.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.da.proto.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};


/**
 * optional string branch_id = 16;
 * @return {string}
 */
proto.da.proto.Department.prototype.getBranchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.Department} returns this
 */
proto.da.proto.Department.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && proto.da.proto.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateDepartmentRequest}
 */
proto.da.proto.CreateDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateDepartmentRequest;
  return proto.da.proto.CreateDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateDepartmentRequest}
 */
proto.da.proto.CreateDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.da.proto.Department}
 */
proto.da.proto.CreateDepartmentRequest.prototype.getDepartment = function() {
  return /** @type{?proto.da.proto.Department} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {?proto.da.proto.Department|undefined} value
 * @return {!proto.da.proto.CreateDepartmentRequest} returns this
*/
proto.da.proto.CreateDepartmentRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateDepartmentRequest} returns this
 */
proto.da.proto.CreateDepartmentRequest.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateDepartmentRequest.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.CreateDepartmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.CreateDepartmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.CreateDepartmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateDepartmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && proto.da.proto.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.CreateDepartmentResponse}
 */
proto.da.proto.CreateDepartmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.CreateDepartmentResponse;
  return proto.da.proto.CreateDepartmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.CreateDepartmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.CreateDepartmentResponse}
 */
proto.da.proto.CreateDepartmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.CreateDepartmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.CreateDepartmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.CreateDepartmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.CreateDepartmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.da.proto.Department}
 */
proto.da.proto.CreateDepartmentResponse.prototype.getDepartment = function() {
  return /** @type{?proto.da.proto.Department} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {?proto.da.proto.Department|undefined} value
 * @return {!proto.da.proto.CreateDepartmentResponse} returns this
*/
proto.da.proto.CreateDepartmentResponse.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.CreateDepartmentResponse} returns this
 */
proto.da.proto.CreateDepartmentResponse.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.CreateDepartmentResponse.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadDepartmentRequest}
 */
proto.da.proto.ReadDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadDepartmentRequest;
  return proto.da.proto.ReadDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadDepartmentRequest}
 */
proto.da.proto.ReadDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.ReadDepartmentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadDepartmentRequest} returns this
 */
proto.da.proto.ReadDepartmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadDepartmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadDepartmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadDepartmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && proto.da.proto.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadDepartmentResponse}
 */
proto.da.proto.ReadDepartmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadDepartmentResponse;
  return proto.da.proto.ReadDepartmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadDepartmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadDepartmentResponse}
 */
proto.da.proto.ReadDepartmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadDepartmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadDepartmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadDepartmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.da.proto.Department}
 */
proto.da.proto.ReadDepartmentResponse.prototype.getDepartment = function() {
  return /** @type{?proto.da.proto.Department} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {?proto.da.proto.Department|undefined} value
 * @return {!proto.da.proto.ReadDepartmentResponse} returns this
*/
proto.da.proto.ReadDepartmentResponse.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.ReadDepartmentResponse} returns this
 */
proto.da.proto.ReadDepartmentResponse.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.ReadDepartmentResponse.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadDepartmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadDepartmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadDepartmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadDepartmentsRequest}
 */
proto.da.proto.ReadDepartmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadDepartmentsRequest;
  return proto.da.proto.ReadDepartmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadDepartmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadDepartmentsRequest}
 */
proto.da.proto.ReadDepartmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadDepartmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadDepartmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadDepartmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ReadDepartmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadDepartmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadDepartmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadDepartmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    proto.da.proto.Department.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadDepartmentsResponse}
 */
proto.da.proto.ReadDepartmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadDepartmentsResponse;
  return proto.da.proto.ReadDepartmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadDepartmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadDepartmentsResponse}
 */
proto.da.proto.ReadDepartmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadDepartmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadDepartmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadDepartmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadDepartmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Department departments = 1;
 * @return {!Array<!proto.da.proto.Department>}
 */
proto.da.proto.ReadDepartmentsResponse.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.da.proto.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {!Array<!proto.da.proto.Department>} value
 * @return {!proto.da.proto.ReadDepartmentsResponse} returns this
*/
proto.da.proto.ReadDepartmentsResponse.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Department}
 */
proto.da.proto.ReadDepartmentsResponse.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ReadDepartmentsResponse} returns this
 */
proto.da.proto.ReadDepartmentsResponse.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadBranchDepartmentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadBranchDepartmentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadBranchDepartmentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadBranchDepartmentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    branchId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadBranchDepartmentsRequest}
 */
proto.da.proto.ReadBranchDepartmentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadBranchDepartmentsRequest;
  return proto.da.proto.ReadBranchDepartmentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadBranchDepartmentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadBranchDepartmentsRequest}
 */
proto.da.proto.ReadBranchDepartmentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBranchId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadBranchDepartmentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadBranchDepartmentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadBranchDepartmentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadBranchDepartmentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string branch_id = 1;
 * @return {string}
 */
proto.da.proto.ReadBranchDepartmentsRequest.prototype.getBranchId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.ReadBranchDepartmentsRequest} returns this
 */
proto.da.proto.ReadBranchDepartmentsRequest.prototype.setBranchId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.da.proto.ReadBranchDepartmentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.ReadBranchDepartmentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.ReadBranchDepartmentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.ReadBranchDepartmentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadBranchDepartmentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    departmentsList: jspb.Message.toObjectList(msg.getDepartmentsList(),
    proto.da.proto.Department.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.ReadBranchDepartmentsResponse}
 */
proto.da.proto.ReadBranchDepartmentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.ReadBranchDepartmentsResponse;
  return proto.da.proto.ReadBranchDepartmentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.ReadBranchDepartmentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.ReadBranchDepartmentsResponse}
 */
proto.da.proto.ReadBranchDepartmentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.addDepartments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.ReadBranchDepartmentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.ReadBranchDepartmentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.ReadBranchDepartmentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.ReadBranchDepartmentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Department departments = 1;
 * @return {!Array<!proto.da.proto.Department>}
 */
proto.da.proto.ReadBranchDepartmentsResponse.prototype.getDepartmentsList = function() {
  return /** @type{!Array<!proto.da.proto.Department>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {!Array<!proto.da.proto.Department>} value
 * @return {!proto.da.proto.ReadBranchDepartmentsResponse} returns this
*/
proto.da.proto.ReadBranchDepartmentsResponse.prototype.setDepartmentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.da.proto.Department=} opt_value
 * @param {number=} opt_index
 * @return {!proto.da.proto.Department}
 */
proto.da.proto.ReadBranchDepartmentsResponse.prototype.addDepartments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.da.proto.Department, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.da.proto.ReadBranchDepartmentsResponse} returns this
 */
proto.da.proto.ReadBranchDepartmentsResponse.prototype.clearDepartmentsList = function() {
  return this.setDepartmentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && proto.da.proto.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateDepartmentRequest}
 */
proto.da.proto.UpdateDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateDepartmentRequest;
  return proto.da.proto.UpdateDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateDepartmentRequest}
 */
proto.da.proto.UpdateDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.da.proto.Department}
 */
proto.da.proto.UpdateDepartmentRequest.prototype.getDepartment = function() {
  return /** @type{?proto.da.proto.Department} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {?proto.da.proto.Department|undefined} value
 * @return {!proto.da.proto.UpdateDepartmentRequest} returns this
*/
proto.da.proto.UpdateDepartmentRequest.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateDepartmentRequest} returns this
 */
proto.da.proto.UpdateDepartmentRequest.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateDepartmentRequest.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.UpdateDepartmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.UpdateDepartmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.UpdateDepartmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateDepartmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    department: (f = msg.getDepartment()) && proto.da.proto.Department.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.UpdateDepartmentResponse}
 */
proto.da.proto.UpdateDepartmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.UpdateDepartmentResponse;
  return proto.da.proto.UpdateDepartmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.UpdateDepartmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.UpdateDepartmentResponse}
 */
proto.da.proto.UpdateDepartmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.da.proto.Department;
      reader.readMessage(value,proto.da.proto.Department.deserializeBinaryFromReader);
      msg.setDepartment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.UpdateDepartmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.UpdateDepartmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.UpdateDepartmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.UpdateDepartmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepartment();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.da.proto.Department.serializeBinaryToWriter
    );
  }
};


/**
 * optional Department department = 1;
 * @return {?proto.da.proto.Department}
 */
proto.da.proto.UpdateDepartmentResponse.prototype.getDepartment = function() {
  return /** @type{?proto.da.proto.Department} */ (
    jspb.Message.getWrapperField(this, proto.da.proto.Department, 1));
};


/**
 * @param {?proto.da.proto.Department|undefined} value
 * @return {!proto.da.proto.UpdateDepartmentResponse} returns this
*/
proto.da.proto.UpdateDepartmentResponse.prototype.setDepartment = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.UpdateDepartmentResponse} returns this
 */
proto.da.proto.UpdateDepartmentResponse.prototype.clearDepartment = function() {
  return this.setDepartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.UpdateDepartmentResponse.prototype.hasDepartment = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.DeleteDepartmentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.DeleteDepartmentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.DeleteDepartmentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteDepartmentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.DeleteDepartmentRequest}
 */
proto.da.proto.DeleteDepartmentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.DeleteDepartmentRequest;
  return proto.da.proto.DeleteDepartmentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.DeleteDepartmentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.DeleteDepartmentRequest}
 */
proto.da.proto.DeleteDepartmentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.DeleteDepartmentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.DeleteDepartmentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.DeleteDepartmentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteDepartmentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.da.proto.DeleteDepartmentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.da.proto.DeleteDepartmentRequest} returns this
 */
proto.da.proto.DeleteDepartmentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.da.proto.DeleteDepartmentResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.da.proto.DeleteDepartmentResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.da.proto.DeleteDepartmentResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteDepartmentResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && owner_type_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.da.proto.DeleteDepartmentResponse}
 */
proto.da.proto.DeleteDepartmentResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.da.proto.DeleteDepartmentResponse;
  return proto.da.proto.DeleteDepartmentResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.da.proto.DeleteDepartmentResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.da.proto.DeleteDepartmentResponse}
 */
proto.da.proto.DeleteDepartmentResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new owner_type_pb.Status;
      reader.readMessage(value,owner_type_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.da.proto.DeleteDepartmentResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.da.proto.DeleteDepartmentResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.da.proto.DeleteDepartmentResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.da.proto.DeleteDepartmentResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      owner_type_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional Status status = 1;
 * @return {?proto.da.proto.Status}
 */
proto.da.proto.DeleteDepartmentResponse.prototype.getStatus = function() {
  return /** @type{?proto.da.proto.Status} */ (
    jspb.Message.getWrapperField(this, owner_type_pb.Status, 1));
};


/**
 * @param {?proto.da.proto.Status|undefined} value
 * @return {!proto.da.proto.DeleteDepartmentResponse} returns this
*/
proto.da.proto.DeleteDepartmentResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.da.proto.DeleteDepartmentResponse} returns this
 */
proto.da.proto.DeleteDepartmentResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.da.proto.DeleteDepartmentResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.da.proto);
