import {SET_ACTIVE_MENU} from "../actions/actions";

const ActiveMenu = (state = "Dashboard", action: any) => {
    switch (action.type) {
        case SET_ACTIVE_MENU:
        return action.payload;
        default:
        return state;
    }
}
export default ActiveMenu;