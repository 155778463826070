import {
  Box,
  Divider,
  HStack,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { Spin, Tabs } from "antd";
import { RpcError } from "grpc-web";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { MdAdd, MdClose } from "react-icons/md";
import { TbTrash } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "../../gateway/Functions";
import {
  createQuestion,
  createSection,
  deleteQuestion,
  deleteSection,
  getSurveyById,
} from "../../gateway/gRPC/Utils/SurveyMethods";
import {
  getSurveyQuestions,
  sortQuestionsByIndex,
  sortSectionsByIndex,
} from "../../gateway/gRPC/Utils/utils";
import {
  CreateSectionResponse,
  DeleteQuestionResponse,
  DeleteSectionResponse,
  Question,
  QuestionType,
  Section,
  Survey,
  UpdateQuestionResponse,
} from "../../gateway/gRPC/pb/surveys_pb";
import strings from "../../localization/main";
import { openModalAlert } from "../../redux/ModalAlertObj";
import { updateTables } from "../../redux/actions/actions";
import { primaryGrayDark } from "../../theme/theme";
import CustomTextButton from "../buttons/CustomTextButton";
import IconButton from "../buttons/IconButton";
import PageContainer from "../containers/PageContainer";
import BongaCard from "../general/Card";
import CustomGeneralTag from "../general/CustomGeneralTag";
import CustomPageHeader from "../headers/CustomPageHeader";
import { SubHeading } from "../headings/Headings";
import SurveyQuestion from "./questionnaire/Question";
import QuestionTypeMenu from "./questionnaire/QuestionTypeMenu";
import SurveySectionForm from "./questionnaire/SectionForm";
import SurveyQuestionForm from "./questionnaire/SurveyQuestionForm";
import ResponsesComponent from "./responses/ViewResponses";

export default function ViewQuestionnaire() {
  const { surveyId: id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [survey, setSurvey] = useState<Survey.AsObject>();
  const [questionsLength, setLength] = useState<number>(0);
  const update = useSelector((state: any) => state.UpdateTables);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (id) {
      getSurveyById(id)
        .then((res) => {
          if (res.survey) {
            setSurvey(res.survey);
            setLength(getSurveyQuestions(res.survey).length);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, update]);

  return (
    <PageContainer>
      <CustomPageHeader
        title={`${strings.survey} : ${survey?.serialNumber.toUpperCase()}`}
        action={
          <IconButton
            fluid
            icon={<MdClose />}
            onClick={() => navigate("/apps/surveys")}
          />
        }
      />
      <Tabs
        defaultActiveKey="survey"
        items={[
          {
            key: "survey",
            label: strings.survey,
            children: (
              <SurveyComponent
                survey={survey!}
                loading={loading}
                setLoading={setLoading}
                questionsLength={questionsLength}
              />
            ),
          },
          {
            key: "responses",
            label: strings.responses,
            children: (
              <ResponsesComponent
                survey={survey as Survey.AsObject}
                loading={loading}
              />
            ),
          },
        ]}
      />
    </PageContainer>
  );
}

const SurveyComponent = ({
  survey,
  loading,
  setLoading,
  questionsLength,
}: {
  survey: Survey.AsObject;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  questionsLength: number;
}) => {
  const dispatch = useDispatch();
  const [currentQuestionType, setCurrentQuestionType] = useState<QuestionType>(
    QuestionType["TEXT"]
  );
  const [currentSection, setCurrecntSection] = useState<string>("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: sectionOpen,
    onOpen: onSectionOpen,
    onClose: onSectionClose,
  } = useDisclosure();
  // const [sections, setSections] = useState<Section.AsObject[]>([]);
  // const [questions, setQuestions] = useState<Question.AsObject[]>([]);

  const handleDeleteQuestion = (question: Question.AsObject) => {
    dispatch(
      openModalAlert(
        "Delete Question!",
        "Are you sure you want to delete this question?. This action cannot be undone.",
        "danger",
        true,
        true,
        () => deleteCallBack(question)
      )
    );
  };
  const deleteCallBack = (question: Question.AsObject) => {
    setLoading(true);
    deleteQuestion(question)
      .then((res: DeleteQuestionResponse) => {
        let { status } = res.toObject();
        if (status?.code === 200) {
          notify(
            "success",
            "bottomLeft",
            "Successfully deleted question",
            status.message
          );
          dispatch(updateTables());
        }
      })
      .catch((err: RpcError) => {
        console.log(err);
        notify("error", "bottomLeft", "Failed to delete question", err.message);
      })
      .finally(() => setLoading(false));
  };
  const handleEditQuestion = (data: Question.AsObject) => {
    onClose();
  };
  const handleDeleteSection = (section: Section.AsObject) => {
    setLoading(true);
    console.log("section", section);

    dispatch(
      openModalAlert(
        "Delete Section",
        "Are you sure you want to delete this section?",
        "danger",
        true,
        true,
        () => deleteSectionCallback(section)
      )
    );
  };
  const deleteSectionCallback = (section: Section.AsObject) => {
    deleteSection(section)
      .then((res: DeleteSectionResponse) => {
        let { status } = res.toObject();
        if (status?.code === 200) {
          notify(
            "success",
            "bottomLeft",
            "Successfully deleted section",
            status.message
          );
          dispatch(updateTables());
        }
      })
      .catch((error: RpcError) => {
        console.log(error);
        notify(
          "error",
          "bottomLeft",
          "Failed to delete section",
          error.message
        );
      })
      .finally(() => setLoading(false));
  };
  const handleAddSection: SubmitHandler<Section.AsObject> = (
    data: Section.AsObject
  ) => {
    setLoading(true);
    data.questionsList = [];
    data.surveyId = survey.id;
    createSection(data)
      .then((res: CreateSectionResponse.AsObject) => {
        if (res.status?.code === 200) {
          notify(
            "success",
            "bottomLeft",
            "Successfully added section",
            res.status?.message
          );
          dispatch(updateTables());
        }
      })
      .catch((err: RpcError) => {
        console.log(err);
        notify("error", "bottomLeft", "Failed to add section", err.message);
      })
      .finally(() => {
        setLoading(false);
        onSectionClose();
      });
  };
  const handleAddQuestion: SubmitHandler<Question.AsObject> = (
    data: Question.AsObject
  ) => {
    setLoading(true);
    data.sectionId = currentSection ?? survey.sectionsList[survey.sectionsList.length - 1].id;
    data.surveyId = survey.id;
    createQuestion(data)
      .then((res: UpdateQuestionResponse.AsObject) => {
        if (res.status?.code === 200) {
          notify(
            "success",
            "bottomLeft",
            "Successfully added question",
            res.status?.message
          );
          dispatch(updateTables());
        }
      })
      .catch((err: RpcError) => {
        console.log(err);
        notify("error", "bottomLeft", "Failed to add question", err.message);
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };
  const addNewQuestionCallBack: (
    type: QuestionType,
    sectionId?: string
  ) => void = (type: QuestionType, sectionId?: string): void => {
    onOpen();
    setCurrentQuestionType(type);
    setCurrecntSection(sectionId ?? "");
  };

  return (
    <BongaCard loading={loading} p={"1rem"}>
      <HStack spacing={4} align="center" justify="space-between" width="100%">
        <Box>
          <Text fontSize="lg" fontWeight="bold">
            {survey?.title}
          </Text>
          <Text>{survey?.description}</Text>
        </Box>
        <Spacer />
        <CustomGeneralTag
          text={`
            ${survey?.responseCount ? survey?.responseCount : "0"}
            ${survey?.maxResponses ? `/${survey?.maxResponses}` : ""} 
            ${strings.responses}`}
          colorScheme="green"
          size="xs"
          weight="bold"
        />
      </HStack>
      <Divider my=".5rem" />
      <VStack
        spacing={"1.5rem"}
        w={"100%"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        {survey?.sectionsList.length === 0 && (
          <SurveySectionForm
            onSubmit={handleAddSection}
            sectionIndex={`${survey.sectionsList.length + 1}`}
            loading={loading}
          />
        )}
        <SectionsList
          survey={survey}
          addNewQuestionCallBack={addNewQuestionCallBack}
          handleDeleteSection={handleDeleteSection}
          handleDeleteQuestion={handleDeleteQuestion}
          isOpen={isOpen}
          onClose={onClose}
          loading={loading}
          currentSection={currentSection}
          handleAddQuestion={handleAddQuestion}
          questionsLength={questionsLength}
          currentQuestionType={currentQuestionType}
          handleEditQuestion={handleEditQuestion}
        />

        {sectionOpen && (
          <SurveySectionForm
            onSubmit={handleAddSection}
            sectionIndex={`${survey?.sectionsList.length + 1}`}
            loading={loading}
          />
        )}
        <HStack justifyContent={"flex-end"} gap={".5rem"} py={"0.5rem"}>
          {survey?.sectionsList?.length > 0 && (
            <CustomTextButton onClick={onSectionOpen}>
              <MdAdd /> {"Add New Section"}
            </CustomTextButton>
          )}
        </HStack>
      </VStack>
    </BongaCard>
  );
};

interface SectionsListProps {
  survey: Survey.AsObject;
  addNewQuestionCallBack: (type: QuestionType, sectionId?: string) => void;
  handleDeleteSection: any;
  isOpen: boolean;
  currentSection: string;
  loading: boolean;
  handleAddQuestion: SubmitHandler<Question.AsObject>;
  questionsLength: number;
  currentQuestionType: QuestionType;
  onClose: () => void;
  handleDeleteQuestion: (question: Question.AsObject) => void;
  handleEditQuestion: (question: Question.AsObject) => void;
}
const SectionsList = ({
  survey,
  addNewQuestionCallBack,
  handleDeleteSection,
  isOpen,
  currentSection,
  loading,
  handleAddQuestion,
  questionsLength,
  currentQuestionType,
  onClose,
  handleDeleteQuestion,
  handleEditQuestion,
}: SectionsListProps) => {
  return (survey?.sectionsList ?? []).map((section: Section.AsObject) => (
    <VStack
      key={section.id}
      spacing={".5rem"}
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      w={"100%"}
    >
      <HStack p={".5rem"} w={"100%"} bg={primaryGrayDark} role="group">
        <Box>
          <SubHeading>{`${strings.section}-${section.index}: ${section.title}`}</SubHeading>
          <Text>{section.description}</Text>
        </Box>
        <Spacer />
        <Box display={"none"} _groupHover={{ display: "block" }}>
          <QuestionTypeMenu
            isDisabled={!!!survey}
            sectionId={section.id}
            callback={addNewQuestionCallBack}
          />
        </Box>
        <Box>
          <IconButton
            fluid
            icon={<TbTrash />}
            onClick={handleDeleteSection.bind(this, section)}
          />
        </Box>
      </HStack>
      {isOpen && currentSection === section.id && (
        <Spin spinning={loading}>
          <SurveyQuestionForm
            onSubmit={handleAddQuestion}
            sectionIndex={`${section.id}`}
            questionIndex={`${questionsLength + 1}`}
            type={currentQuestionType}
            onClose={onClose}
          />
        </Spin>
      )}

      {(section.questionsList ?? []).map((question: Question.AsObject) => (
        <SurveyQuestion
          key={`${question.sectionId}-Question-${question.index}`}
          question={question}
          handleDeleteQuestion={handleDeleteQuestion}
          handleEditQuestionSubmit={handleEditQuestion}
        />
      ))}
    </VStack>
  ));
};
