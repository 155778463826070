import {
  extendTheme,
  withDefaultColorScheme,
  withDefaultSize,
} from "@chakra-ui/react";

// Create a boolean variable for dark mode
const lightMode = true;

const brand = process.env.REACT_APP_CUSTOM_BRAND;

const brandColor = brand==="MNOTIFY"? "#FF7F41" : "#EF4823"

// Use the dark mode variable to instantiate the selected variables
export const primaryColor = lightMode ? brandColor : "#E5E5E5";
export const primaryBlack = lightMode ? "#333235" : "#393939";
export const primaryWhite = lightMode ? "#FFFFFF" : "#1A1A1A";
export const primaryGray = lightMode ? "#FAFBFC" : "#6B6B6B";
export const primaryGrayDark = lightMode ? "#eaeaea" : "#4D4D4D";
export const primaryGreen = lightMode ? "#16AC52" : "#00FF00";
export const borderColor = lightMode ? "#e8e8e8" : "#4B4B4B";
export const twitterBlue = lightMode ? "#1DA1F2" : "#00BFFF";
export const facebookBlue = lightMode ? "#3b5998" : "#0000FF";
export const gray_50 = lightMode ? "#F7FAFC" : "#0D0D0D";
export const gray_100 = lightMode ? "#EDF2F7" : "#1C1C1C";
export const gray_200 = lightMode ? "#E2E8F0" : "#2B2B2B";
export const gray_300 = lightMode ? "#CBD5E0" : "#3A3A3A";
export const gray_400 = lightMode ? "#A0AEC0" : "#494949";
export const gray_500 = lightMode ? "#718096" : "#585858";
export const gray_600 = lightMode ? "#4A5568" : "#676767";
export const gray_700 = lightMode ? "#2D3748" : "#767676";
export const gray_800 = lightMode ? "#1A202C" : "#868686";
export const gray_900 = lightMode ? "#171923" : "#969696";

const styles = {
  global: (props: any) => ({
    body: {
      svg: {
        display: "iconOnly-block",
      },
      "chakra-select__icon-wrapper": {
        display: "none",
      },
    },
  }),
};

const components = {
  Heading: {
    colors: primaryBlack,
    sizes: {
      sm: {
        fontWeight: "600",
      },
      md: {
        fontWeight: "600",
      },
      "2xl": {
        fontWeight: "800",
      },
      lg: {
        fontWeight: "500",
      },
    },
  },
  Text: {
    colors: primaryBlack,
    m: 0,
    sizes: {
      sm: {
        fontSize: "12px",
        fontWeight: "400",
      },
      md: {
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  },
  Button: {
    borderRadius: "3px",
  },
};

export const theme = extendTheme(
  withDefaultSize({
    size: "sm",
    components: ["Heading", "Text", "Tag"],
  }),
  {
    fonts: {
      body: "DM Sans, sans-serif",
      heading: "DM Sans, sans-serif",
      mono: "DM Sans, sans-serif",
    },
    fontSizes: {
      sm: ".8rem",
    },

    colors: {
      white: primaryWhite,
      brand: {
        50: "#f37e7f",
        100: "#ed6d6e",
        200: "#e75d5e",
        300: "#e04d4f",
        400: "#d73f41",
        500: "#EF4823",
        600: "#ce3234",
        700: "#ba2626",
        800: "#a61818",
        900: "#92080a",
      },
      instagram: {
        50: "#f8eaff",
        100: "#dfc4f0",
        200: "#c79ee2",
        300: "#af78d5",
        400: "#9851c7",
        500: "#7e38ae",
        600: "#622c88",
        700: "#461e62",
        800: "#2b113d",
        900: "#120319",
      },
      tiktok: {
        50: "#d9ffff",
        100: "#adfcfa",
        200: "#80faf7",
        300: "#50f6f2",
        400: "#25f4ee",
        500: "#0bdad4",
        600: "#00aaa5",
        700: "#007a76",
        800: "#004a48",
        900: "#001a1a",
      },
      black: {
        500: primaryBlack,
      },
      grey: {
        900: primaryGrayDark,
      },
      gray: {
        50: gray_50,
        100: gray_100,
        200: gray_200,
        300: gray_300,
        400: gray_400,
        500: gray_500,
        600: gray_600,
        700: gray_700,
        800: gray_800,
        900: gray_900,
      },
      borderColor: {
        500: "#e8e8e8",
      },
      secondary: {
        100: "#5e5e5e",
        200: "#f3f3f3",
        300: "#f4f4f4",
        400: "#f2f3f4",
        500: "#F5F5F5",
        600: "#F6F6F6",
        700: "#F8F8F8",
        800: "#878787",
        900: "#EAEAEA",
        50: "#F9F9F9",
      },
      green: {
        500: "#179848",
      },
      orange: {
        500: "#EF4823",
      },
      alerts: {
        primary: "#ce3234",
        info: "#1E64ED",
        dark: "#000",
        success: "#28a745",
        warning: "#f2c914",
        orange: "#EF4823",
        light: "#e9ecef",
        danger: "#d9534f",
        grey: "#4a4f54",
        muted: "#616161",
        secondary: "#A9A9A9",
        secondaryLight: "#EBE9E9",
        anchor_tag: "#0000EE",
      },
    },
    components,
    styles,
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);
