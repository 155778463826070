import { SET_CHATDESK_SEARCH } from "../actions/actions";

interface SetChatdeskSearchAction {
	type: typeof SET_CHATDESK_SEARCH;
	payload: string;
}

const ChatdeskSearch = (
	state: string = "",
	action: SetChatdeskSearchAction
): string => {
	switch (action.type) {
		case SET_CHATDESK_SEARCH:
			return action.payload;
		default:
			return state;
	}
};

export default ChatdeskSearch;
