/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var user_pb = require('./user_pb.js')

var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.LoginRequest,
 *   !proto.da.proto.LoginResponse>}
 */
const methodDescriptor_AuthService_Login = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/Login',
  grpc.web.MethodType.UNARY,
  proto.da.proto.LoginRequest,
  proto.da.proto.LoginResponse,
  /**
   * @param {!proto.da.proto.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login,
      callback);
};


/**
 * @param {!proto.da.proto.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/Login',
      request,
      metadata || {},
      methodDescriptor_AuthService_Login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.LogoutRequest,
 *   !proto.da.proto.LogoutResponse>}
 */
const methodDescriptor_AuthService_Logout = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/Logout',
  grpc.web.MethodType.UNARY,
  proto.da.proto.LogoutRequest,
  proto.da.proto.LogoutResponse,
  /**
   * @param {!proto.da.proto.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout,
      callback);
};


/**
 * @param {!proto.da.proto.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/Logout',
      request,
      metadata || {},
      methodDescriptor_AuthService_Logout);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ValidateTokenRequest,
 *   !proto.da.proto.ValidateTokenResponse>}
 */
const methodDescriptor_AuthService_Validate = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/Validate',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ValidateTokenRequest,
  proto.da.proto.ValidateTokenResponse,
  /**
   * @param {!proto.da.proto.ValidateTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ValidateTokenResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ValidateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ValidateTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ValidateTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.validate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/Validate',
      request,
      metadata || {},
      methodDescriptor_AuthService_Validate,
      callback);
};


/**
 * @param {!proto.da.proto.ValidateTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ValidateTokenResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.validate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/Validate',
      request,
      metadata || {},
      methodDescriptor_AuthService_Validate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.RefreshTokenRequest,
 *   !proto.da.proto.RefreshTokenResponse>}
 */
const methodDescriptor_AuthService_RefreshToken = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/RefreshToken',
  grpc.web.MethodType.UNARY,
  proto.da.proto.RefreshTokenRequest,
  proto.da.proto.RefreshTokenResponse,
  /**
   * @param {!proto.da.proto.RefreshTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.RefreshTokenResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.RefreshTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.RefreshTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.refreshToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken,
      callback);
};


/**
 * @param {!proto.da.proto.RefreshTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.RefreshTokenResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.refreshToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/RefreshToken',
      request,
      metadata || {},
      methodDescriptor_AuthService_RefreshToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ForgotPasswordRequest,
 *   !proto.da.proto.ForgotPasswordResponse>}
 */
const methodDescriptor_AuthService_ForgotPassword = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/ForgotPassword',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ForgotPasswordRequest,
  proto.da.proto.ForgotPasswordResponse,
  /**
   * @param {!proto.da.proto.ForgotPasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ForgotPasswordResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ForgotPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ForgotPasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ForgotPasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.forgotPassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ForgotPassword,
      callback);
};


/**
 * @param {!proto.da.proto.ForgotPasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ForgotPasswordResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.forgotPassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/ForgotPassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ForgotPassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ChangePasswordRequest,
 *   !proto.da.proto.ChangePasswordResponse>}
 */
const methodDescriptor_AuthService_ChangePassword = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/ChangePassword',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ChangePasswordRequest,
  proto.da.proto.ChangePasswordResponse,
  /**
   * @param {!proto.da.proto.ChangePasswordRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ChangePasswordResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ChangePasswordResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ChangePasswordResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.changePassword =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePassword,
      callback);
};


/**
 * @param {!proto.da.proto.ChangePasswordRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ChangePasswordResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.changePassword =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/ChangePassword',
      request,
      metadata || {},
      methodDescriptor_AuthService_ChangePassword);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAuthContextRequest,
 *   !proto.da.proto.ReadAuthContextResponse>}
 */
const methodDescriptor_AuthService_ReadAuthContext = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/ReadAuthContext',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAuthContextRequest,
  proto.da.proto.ReadAuthContextResponse,
  /**
   * @param {!proto.da.proto.ReadAuthContextRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAuthContextResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAuthContextRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAuthContextResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAuthContextResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.readAuthContext =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/ReadAuthContext',
      request,
      metadata || {},
      methodDescriptor_AuthService_ReadAuthContext,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAuthContextRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAuthContextResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.readAuthContext =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/ReadAuthContext',
      request,
      metadata || {},
      methodDescriptor_AuthService_ReadAuthContext);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadUserPermissionsRequest,
 *   !proto.da.proto.ReadUserPermissionsResponse>}
 */
const methodDescriptor_AuthService_ReadUserPermissions = new grpc.web.MethodDescriptor(
  '/da.proto.AuthService/ReadUserPermissions',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadUserPermissionsRequest,
  proto.da.proto.ReadUserPermissionsResponse,
  /**
   * @param {!proto.da.proto.ReadUserPermissionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadUserPermissionsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadUserPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadUserPermissionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadUserPermissionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AuthServiceClient.prototype.readUserPermissions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AuthService/ReadUserPermissions',
      request,
      metadata || {},
      methodDescriptor_AuthService_ReadUserPermissions,
      callback);
};


/**
 * @param {!proto.da.proto.ReadUserPermissionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadUserPermissionsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AuthServicePromiseClient.prototype.readUserPermissions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AuthService/ReadUserPermissions',
      request,
      metadata || {},
      methodDescriptor_AuthService_ReadUserPermissions);
};


module.exports = proto.da.proto;

