import { Box, Grid } from '@chakra-ui/react'
import { Spin } from 'antd'
import Brand from '../../assets/Brand'

export default function Loader({ fullScreen }: { fullScreen?: boolean }) {
    return (
        <Grid
            h={fullScreen ? "100vh" : "stretch"}
            w={fullScreen ? "100vw" : "100%"}
            placeItems={"center"}
            p={fullScreen ? 0 : "20rem"}
        >
            <Box textAlign={"center"}>
                <Spin size={'large'} />
                <Brand/>
            </Box>
        </Grid>
    )
}
