import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Grid,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "../components/buttons/CustomButton";

export default function StripeCheckoutCancelled() {
  const navigate = useNavigate();
  const home = process.env.REACT_APP_HOME_ROUTE || "";
  useEffect(() => {
    // Check if window has an `opener`. If so, attempt to close the window within 5 seconds
    if (window.opener) {
      setTimeout(() => {
        window.close();
      }, 5000);
    }
  }, []);

  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (searchParams.get("mode") === "setup") {
      setMessage(
        "Could not validate credit card information. Please close this page and try again."
      );
    } else {
      setMessage("Payment failed. Please close this page and try again.");
    }
  }, [searchParams]);

  return (
    <Grid placeItems={"center"} height="100vh" width="100vh">
      <Alert
        status="warning"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
        w={[300, 400, 500]}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          Failed!
        </AlertTitle>
        <AlertDescription maxWidth="sm">{message}</AlertDescription>
        <CustomButton
          cursor={"pointer"}
          fontWeight={"bold"}
          onClick={() => navigate(home)}
        >
          Go Home
        </CustomButton>
      </Alert>
    </Grid>
  );
}
