import { HStack, Spacer, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { ChoiceTypeQuestion, ContactTypeQuestion, DateTypeQuestion, MatrixTypeQuestion, NPSTypeQuestion, Question, QuestionType, RatingTypeQuestion, TextTypeQuestion, UpdateQuestionResponse } from "../../../gateway/gRPC/pb/surveys_pb";
import { gray_50 } from "../../../theme/theme";
import FormContainer from "../../forms/FormContainer/FormContainer";
import FormInput from "../../forms/FormContainer/FormInput";
import FormTextArea from "../../forms/FormContainer/FormTextArea";
import { SubHeading } from "../../headings/Headings";
import QuestionTypeForm from "./QuestionTypeForm";
import { updateQuestion } from "../../../gateway/gRPC/Utils/SurveyMethods";
import { notify } from "../../../gateway/Functions";
import { useDispatch } from "react-redux";
import { updateTables } from "../../../redux/actions/actions";
import { RpcError } from "grpc-web";
import { Spin } from "antd";
import CustomCloseButton from "../../general/CustomCloseButton";
import IconButton from "../../buttons/IconButton";
import { MdClose } from "react-icons/md";

interface QuestionFormProps {
  onSubmit: SubmitHandler<Question.AsObject>;
  sectionIndex: string;
  questionIndex: string;
  type: QuestionType;
  initialValues?: Question.AsObject;
  isEditing?: boolean;
  onClose?: () => void;
}

type QuestionTypeData = {
  [key: string]:
    | ChoiceTypeQuestion.AsObject
    | TextTypeQuestion.AsObject
    | NPSTypeQuestion.AsObject
    | ContactTypeQuestion.AsObject
    | DateTypeQuestion.AsObject
    | RatingTypeQuestion.AsObject
    | MatrixTypeQuestion.AsObject;
};

const SurveyQuestionForm = ({
  questionIndex,
  sectionIndex,
  type,
  initialValues,
  onSubmit,
  isEditing,
  onClose,
}: QuestionFormProps) => {
  const [typeData, setTypeData] = useState<QuestionTypeData>();
  const [question, setQuestion] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleSubmitQuestion = (data: Question.AsObject) => {
    onSubmit(data);
  };
  useEffect(() => {
    if(!isEditing && typeData){
      let newData: Question.AsObject = new Question().toObject();
      newData.question = question;
      newData.description = description;
      newData.type = type;
      newData.required = false;
      newData.sectionId = sectionIndex;
      newData.index = parseInt(questionIndex);
      newData = {
        ...newData,
        ...typeData
      }
      handleSubmitQuestion(newData);
    } else if (isEditing && typeData && initialValues) {
      let newData: Question.AsObject = new Question().toObject();
      newData.id = initialValues.id;
      newData.required = initialValues.required;

      newData.index = initialValues.index;
      newData.sectionId = initialValues.sectionId;
      newData.surveyId = initialValues.surveyId;
      newData.type = initialValues.type;
      newData.question = question?.length > 0 ? question : initialValues.question;
      newData.description = description?.length > 0 ? description : initialValues.description;
      newData = {
        ...newData,
        ...typeData
      }
      setLoading(true)
      updateQuestion(newData)
      .then((res:UpdateQuestionResponse.AsObject) => {
         if (res.status?.code === 200) {
          notify(
            "success",
            "bottomLeft",
            "Successfully updated question",
            res.status.message,
          );
          handleSubmitQuestion(newData);
          console.log("res question", res);
          dispatch(updateTables());
         }
      })
      .catch((err:RpcError) => {
        notify(
          "error",
          "bottomLeft",
          "Failed to update question",
          err.message,
        )
      })
      .finally(() => {
        setLoading(false);
      })
    }
  }, [typeData]);

  return (
    <VStack
      spacing={".5rem"}
      w={"100%"}
      _hover={{ bg: gray_50 }}
      p={".5rem .5rem 50px .5rem"}
      h={"max-content"}
    >
      <Spin spinning={loading}>
        <FormContainer
          initialValues={initialValues}
          noFooter
          columns={1}
          onSubmit={handleSubmitQuestion}
        >
          <HStack justifyContent={"space-between"} w={"100%"}>
            <SubHeading>
              {isEditing ? `Edit Question ${questionIndex}` : `Add New Question ${questionIndex}`}
            </SubHeading>
            <Spacer />
            {onClose && 
            <IconButton 
              onClick={onClose} 
              icon={<MdClose/>}
            />}
          </HStack>
          <FormInput
            name="question"
            placeholder={"Add your Question here"}
            onChange={(e: any) => {
              setQuestion(e.target.value);
            }}
          />
          <FormTextArea
            name="description"
            placeholder={"Add a small description (Max 300 characters)"}
            onChange={(e: any) => {
              setDescription(e.target.value);
            }}
          />
          <QuestionTypeForm type={type} fieldSetter={setTypeData} initializer={initialValues} />
        </FormContainer>
      </Spin>

    </VStack>
  );
};

export default SurveyQuestionForm;
