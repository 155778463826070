import { Popover } from "antd";
import { MdOutlineHelpOutline } from "react-icons/md";
import { Box, Text } from "@chakra-ui/react";
import { primaryBlack } from "../../theme/theme";

interface Props {
	content: string;
}

function PopOver({ content }: Props) {
	return (
		<Popover
			content={
				<Box maxW={"150px"}>
					<Text>{content}</Text>
				</Box>
			}
		>
			<MdOutlineHelpOutline
				style={{
					marginLeft: ".2rem",
					cursor: "pointer",
					fontSize: "10px",
					color: primaryBlack,
				}}
			/>
		</Popover>
	);
}

export default PopOver;
