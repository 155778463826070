interface Data {
  [key: string]: any;
}

async function searchData(
  searchQuery: string,
  objects: Data[],
  keysToMatch: string[]
): Promise<Data[]> {
  const filteredAgents: Data[] = [];
  const lowercaseSearchQuery = searchQuery.toLowerCase();
  for (let object of objects) {
    let isMatched = false;
    console.log("object", object);
    
    for (let key of keysToMatch) {
      const value =
        key
          .split(".")
          .reduce((obj, k) => obj?.[k], object)
          ?.toString()
          ?.toLowerCase() ?? "";
      if (value.includes(lowercaseSearchQuery)) {
        isMatched = true;
        break;
      }
    }
    if (isMatched) {
      filteredAgents.push(object);
    }
  }
  return filteredAgents;
}
export default searchData;
