import {UPDATE_TABLES} from "../actions/actions";

const UpdateTables =(state:number=0, action:any)=>{
    switch (action.type) {
        case UPDATE_TABLES:
            return state+1;
        default:
            return state;
    }
};
export default  UpdateTables;