import axios, { AxiosResponse, CancelToken } from "axios";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import moment from "moment";
import { formatDate } from "../../Functions";
import { Question, Section, Survey } from "../pb/surveys_pb";

/**
 *
 * @param method
 * @param url
 * @param data
 * @param successFunction is included here for legacy support, in cases where you'd prefer callbacks to await promises
 * @param errorFunction
 * @param finallyFunction
 * @param cancelToken
 * @param domain
 */
export type MakeRequestMethods =
  | "POST"
  | "GET"
  | "DELETE"
  | "PUT"
  | "PATCH"
  | "OPTIONS";

export interface MakeRequestParams {
  method: MakeRequestMethods;
  url: string;
  data?: any;
  cancelToken?: CancelToken;
  successFunction?: (response: AxiosResponse | undefined) => void;
  errorFunction?: (error: any) => void;
  finallyFunction?: () => void;
  domain?: string;
}
export const makeRequest = async ({
  method,
  url,
  data,
  successFunction,
  errorFunction,
  finallyFunction,
  cancelToken,
  domain = "",
}: MakeRequestParams): Promise<AxiosResponse | undefined> => {
  if (url.length < 3) return;
  //make sure url starts with "/"
  if (!url.startsWith("/")) {
    url = `/${url}`;
  }

  // Set the domain
  axios.defaults.baseURL = domain;

  let response: AxiosResponse | undefined;
  try {
    switch (method) {
      case "GET":
        response = await axios.get(url, { ...data, cancelToken });
        break;
      case "POST":
        response = await axios.post(url, data, { cancelToken });
        break;
      case "DELETE":
        response = await axios.delete(url, { ...data, cancelToken });
        break;
      case "PUT":
        response = await axios.put(url, { ...data, cancelToken });
        break;
      case "PATCH":
        response = await axios.patch(url, { ...data, cancelToken });
        break;
      case "OPTIONS":
        response = await axios.options(url, { ...data, cancelToken });
        break;
      default:
        break;
    }
    if (successFunction) successFunction(response);
  } catch (e) {
    if (errorFunction) errorFunction(e);
    throw e;
  }
  if (finallyFunction) finallyFunction();
  return response;
};

export const getCookie = function getCookie(name: string) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  return cookieValue || "";
};

const JWT_TOKEN: string = "authToken";
const REFRESH_TOKEN: string = "refreshToken";
export const getToken = function () {
  return localStorage.getItem(JWT_TOKEN);
};

export const saveToken = function (token: string) {
  localStorage.setItem(JWT_TOKEN, token);
};

export const saveRefreshToken = function (token: string) {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = function () {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const isUserLoggedIn = function () {
  return !!localStorage.getItem(JWT_TOKEN);
};

export function formatTimeStamp(timestamp: Timestamp.AsObject): string {
  // Convert seconds to milliseconds and add nanoseconds converted to milliseconds
  const timestampInMilliseconds =
    timestamp.seconds * 1000 + timestamp.nanos / 1000000;
  // Convert the timestamp into a moment and format it into a string
  const date = moment(timestampInMilliseconds).toString();
  return formatDate(date);
}
export const isEven = (number: number): boolean => {
  return number % 2 === 0;
};
export const sortQuestionsByIndex = (
  questions: Question.AsObject[],
  order?: "ASC" | "DES",
): Question.AsObject[] => {
  if (order === "DES") return questions.sort((a, b) => b.index - a.index);
  return questions.sort((a, b) => a.index - b.index);
};

export const sortSectionsByIndex = (
  sections: Section.AsObject[],
  order?: "ASC" | "DES",
): Section.AsObject[] => {
  if (order === "DES") return sections.sort((a, b) => b.index - a.index);
  return sections.sort((a, b) => a.index - b.index);
}

export const getSurveyQuestions = (survey: Survey.AsObject) => {
  const questions: Question.AsObject[] = [];
  for (let i = 0; i < survey.sectionsList.length; i++) {
    let section = survey.sectionsList[i];
    let questionsList: Question.AsObject[] = [];
    for (let j = 0; j < section.questionsList.length; j++) {
      let question = section.questionsList[j];
      questionsList.push(question);
    }
    questions.push(...questionsList);
  }
  return sortQuestionsByIndex(questions);
};



