/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: contact.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var tag_pb = require('./tag_pb.js')

var label_pb = require('./label_pb.js')

var group_pb = require('./group_pb.js')

var owner_type_pb = require('./owner_type_pb.js')
const proto = {};
proto.da = {};
proto.da.proto = require('./contact_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ContactServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ContactServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateContactRequest,
 *   !proto.da.proto.CreateContactResponse>}
 */
const methodDescriptor_ContactService_CreateContact = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/CreateContact',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateContactRequest,
  proto.da.proto.CreateContactResponse,
  /**
   * @param {!proto.da.proto.CreateContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateContactResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.createContact =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/CreateContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_CreateContact,
      callback);
};


/**
 * @param {!proto.da.proto.CreateContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateContactResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.createContact =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/CreateContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_CreateContact);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadContactRequest,
 *   !proto.da.proto.ReadContactResponse>}
 */
const methodDescriptor_ContactService_ReadContact = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/ReadContact',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadContactRequest,
  proto.da.proto.ReadContactResponse,
  /**
   * @param {!proto.da.proto.ReadContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadContactResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.readContact =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/ReadContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadContact,
      callback);
};


/**
 * @param {!proto.da.proto.ReadContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadContactResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.readContact =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/ReadContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadContact);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadContactsRequest,
 *   !proto.da.proto.ReadContactsResponse>}
 */
const methodDescriptor_ContactService_ReadContacts = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/ReadContacts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadContactsRequest,
  proto.da.proto.ReadContactsResponse,
  /**
   * @param {!proto.da.proto.ReadContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadContactsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadContactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadContactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.readContacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/ReadContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadContacts,
      callback);
};


/**
 * @param {!proto.da.proto.ReadContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadContactsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.readContacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/ReadContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateContactRequest,
 *   !proto.da.proto.UpdateContactResponse>}
 */
const methodDescriptor_ContactService_UpdateContact = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/UpdateContact',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateContactRequest,
  proto.da.proto.UpdateContactResponse,
  /**
   * @param {!proto.da.proto.UpdateContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateContactResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.updateContact =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/UpdateContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_UpdateContact,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateContactResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.updateContact =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/UpdateContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_UpdateContact);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteContactRequest,
 *   !proto.da.proto.DeleteContactResponse>}
 */
const methodDescriptor_ContactService_DeleteContact = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/DeleteContact',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteContactRequest,
  proto.da.proto.DeleteContactResponse,
  /**
   * @param {!proto.da.proto.DeleteContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteContactResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.deleteContact =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/DeleteContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_DeleteContact,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteContactResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.deleteContact =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/DeleteContact',
      request,
      metadata || {},
      methodDescriptor_ContactService_DeleteContact);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.BulkImportContactRequest,
 *   !proto.da.proto.BulkImportContactResponse>}
 */
const methodDescriptor_ContactService_BulkImportContacts = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/BulkImportContacts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.BulkImportContactRequest,
  proto.da.proto.BulkImportContactResponse,
  /**
   * @param {!proto.da.proto.BulkImportContactRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.BulkImportContactResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.BulkImportContactRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.BulkImportContactResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.BulkImportContactResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.bulkImportContacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/BulkImportContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_BulkImportContacts,
      callback);
};


/**
 * @param {!proto.da.proto.BulkImportContactRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.BulkImportContactResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.bulkImportContacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/BulkImportContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_BulkImportContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UploadDocumentRequest,
 *   !proto.da.proto.UploadDocumentResponse>}
 */
const methodDescriptor_ContactService_UploadDocument = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/UploadDocument',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UploadDocumentRequest,
  proto.da.proto.UploadDocumentResponse,
  /**
   * @param {!proto.da.proto.UploadDocumentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UploadDocumentResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UploadDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UploadDocumentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UploadDocumentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.uploadDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/UploadDocument',
      request,
      metadata || {},
      methodDescriptor_ContactService_UploadDocument,
      callback);
};


/**
 * @param {!proto.da.proto.UploadDocumentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UploadDocumentResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.uploadDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/UploadDocument',
      request,
      metadata || {},
      methodDescriptor_ContactService_UploadDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadCustomFieldDefinitionsRequest,
 *   !proto.da.proto.ReadCustomFieldDefinitionsResponse>}
 */
const methodDescriptor_ContactService_ReadCustomFieldDefinitions = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/ReadCustomFieldDefinitions',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadCustomFieldDefinitionsRequest,
  proto.da.proto.ReadCustomFieldDefinitionsResponse,
  /**
   * @param {!proto.da.proto.ReadCustomFieldDefinitionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadCustomFieldDefinitionsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadCustomFieldDefinitionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadCustomFieldDefinitionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.readCustomFieldDefinitions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/ReadCustomFieldDefinitions',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadCustomFieldDefinitions,
      callback);
};


/**
 * @param {!proto.da.proto.ReadCustomFieldDefinitionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadCustomFieldDefinitionsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.readCustomFieldDefinitions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/ReadCustomFieldDefinitions',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadCustomFieldDefinitions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.BulkContactsRequest,
 *   !proto.da.proto.BulkContactsResponse>}
 */
const methodDescriptor_ContactService_BulkContacts = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/BulkContacts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.BulkContactsRequest,
  proto.da.proto.BulkContactsResponse,
  /**
   * @param {!proto.da.proto.BulkContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.BulkContactsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.BulkContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.BulkContactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.BulkContactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.bulkContacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/BulkContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_BulkContacts,
      callback);
};


/**
 * @param {!proto.da.proto.BulkContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.BulkContactsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.bulkContacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/BulkContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_BulkContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.SearchContactsRequest,
 *   !proto.da.proto.SearchContactsResponse>}
 */
const methodDescriptor_ContactService_SearchContacts = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/SearchContacts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.SearchContactsRequest,
  proto.da.proto.SearchContactsResponse,
  /**
   * @param {!proto.da.proto.SearchContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.SearchContactsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.SearchContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.SearchContactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.SearchContactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.searchContacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/SearchContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_SearchContacts,
      callback);
};


/**
 * @param {!proto.da.proto.SearchContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.SearchContactsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.searchContacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/SearchContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_SearchContacts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.FindContactByEmailOrPhoneRequest,
 *   !proto.da.proto.FindContactByEmailOrPhoneResponse>}
 */
const methodDescriptor_ContactService_FindContactByEmailOrPhone = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/FindContactByEmailOrPhone',
  grpc.web.MethodType.UNARY,
  proto.da.proto.FindContactByEmailOrPhoneRequest,
  proto.da.proto.FindContactByEmailOrPhoneResponse,
  /**
   * @param {!proto.da.proto.FindContactByEmailOrPhoneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.FindContactByEmailOrPhoneResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.FindContactByEmailOrPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.FindContactByEmailOrPhoneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.FindContactByEmailOrPhoneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.findContactByEmailOrPhone =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/FindContactByEmailOrPhone',
      request,
      metadata || {},
      methodDescriptor_ContactService_FindContactByEmailOrPhone,
      callback);
};


/**
 * @param {!proto.da.proto.FindContactByEmailOrPhoneRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.FindContactByEmailOrPhoneResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.findContactByEmailOrPhone =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/FindContactByEmailOrPhone',
      request,
      metadata || {},
      methodDescriptor_ContactService_FindContactByEmailOrPhone);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateContactWithoutTokenRequest,
 *   !proto.da.proto.CreateContactWithoutTokenResponse>}
 */
const methodDescriptor_ContactService_CreateContactWithoutToken = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/CreateContactWithoutToken',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateContactWithoutTokenRequest,
  proto.da.proto.CreateContactWithoutTokenResponse,
  /**
   * @param {!proto.da.proto.CreateContactWithoutTokenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateContactWithoutTokenResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateContactWithoutTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateContactWithoutTokenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateContactWithoutTokenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.createContactWithoutToken =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/CreateContactWithoutToken',
      request,
      metadata || {},
      methodDescriptor_ContactService_CreateContactWithoutToken,
      callback);
};


/**
 * @param {!proto.da.proto.CreateContactWithoutTokenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateContactWithoutTokenResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.createContactWithoutToken =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/CreateContactWithoutToken',
      request,
      metadata || {},
      methodDescriptor_ContactService_CreateContactWithoutToken);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadContactChildrenRequest,
 *   !proto.da.proto.ReadContactChildrenResponse>}
 */
const methodDescriptor_ContactService_ReadContactChildren = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/ReadContactChildren',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadContactChildrenRequest,
  proto.da.proto.ReadContactChildrenResponse,
  /**
   * @param {!proto.da.proto.ReadContactChildrenRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadContactChildrenResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadContactChildrenRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadContactChildrenResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadContactChildrenResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.readContactChildren =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/ReadContactChildren',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadContactChildren,
      callback);
};


/**
 * @param {!proto.da.proto.ReadContactChildrenRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadContactChildrenResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.readContactChildren =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/ReadContactChildren',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadContactChildren);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadAccountContactsRequest,
 *   !proto.da.proto.ReadAccountContactsResponse>}
 */
const methodDescriptor_ContactService_ReadAccountContacts = new grpc.web.MethodDescriptor(
  '/da.proto.ContactService/ReadAccountContacts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadAccountContactsRequest,
  proto.da.proto.ReadAccountContactsResponse,
  /**
   * @param {!proto.da.proto.ReadAccountContactsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadAccountContactsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadAccountContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadAccountContactsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadAccountContactsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ContactServiceClient.prototype.readAccountContacts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ContactService/ReadAccountContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadAccountContacts,
      callback);
};


/**
 * @param {!proto.da.proto.ReadAccountContactsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadAccountContactsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ContactServicePromiseClient.prototype.readAccountContacts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ContactService/ReadAccountContacts',
      request,
      metadata || {},
      methodDescriptor_ContactService_ReadAccountContacts);
};


module.exports = proto.da.proto;

