import {SET_SELECTED_ACCOUNT} from "../actions/actions";

const SelectedAccount = (state: number = 0, action: any) => {
    switch (action.type) {
        case SET_SELECTED_ACCOUNT:
            return action.payload;
        default:
            return state;
    }
}
export default SelectedAccount;