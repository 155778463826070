import { Flex } from "@chakra-ui/react";
import { Suspense, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-h5-audio-player/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ShowAlert from "./components/ShowAlert";
import Loader from "./components/general/Loader";
import SocketIoContextProvider from "./components/phone/SocketIoContextProvider";
import {
  geoFindMe,
  logoutFunc,
  notify,
  websocketUrl,
} from "./gateway/Functions";
import { gRPCRefreshToken } from "./gateway/gRPC/Utils/OrgMethods";
import { RefreshTokenResponse } from "./gateway/gRPC/pb/auth_pb";
import BongaLayout from "./layout/Layout";
import StripeCheckoutCancelled from "./pages/StripeCheckoutCancelled";
import StripeCheckoutSuccess from "./pages/StripeCheckoutSuccess";
import { setTokens } from "./redux/actions/actions";
import authRoutes from "./routes/AuthRoutes";
import { primaryGray } from "./theme/theme";
import { RpcError } from "grpc-web";

function App() {
  const loggedIn = useSelector((state: any) => state.LoggedIn);
  const dispatch = useDispatch();
  const { refreshToken } = useSelector((state: any) => state.AccessToken);

  useEffect(() => {
    geoFindMe(dispatch);
  }, [dispatch, refreshToken]);
  // useEffect(() => {
  //   const refresher = setInterval(() => {
  //     if (loggedIn && refreshToken) {
  //       gRPCRefreshToken(refreshToken)
  //         .then((accessTokens: RefreshTokenResponse.AsObject) => {
  //           dispatch(setTokens(accessTokens));
  //           localStorage.setItem("accessToken", accessTokens.accessToken);
  //         })
  //         .catch((err: RpcError) => {
  //           console.log("err", err);
  //           logoutFunc(dispatch);
  //           notify(
  //             "warning",
  //             "bottomLeft",
  //             "Session Expired",
  //             "Your session has expired, please login again"
  //           );
  //         });
  //     }
  //   }, 1000 * 60 * 10 );

  //   return () => {
  //     clearInterval(refresher);
  //   };
  // }, [refreshToken]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Flex
        height="100vh"
        width="100vw"
        backgroundColor={primaryGray}
        // filter={"auto"}
        // invert={"90%"}
      >
        <ShowAlert />
        <Router>
          <Suspense fallback={<Loader fullScreen />}>
            <Routes>
              {!loggedIn && (
                <>
                  {authRoutes.map((route, index) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.component}
                    />
                  ))}
                  <Route path="*" element={<Navigate to={"/login"} />} />
                </>
              )}
            </Routes>
          </Suspense>
          {/* The stripe checkout routes should not load in a logged-in layout */}
          {window.location.href.includes("/stripe/checkout-") && (
            <Routes>
              <Route
                path="/stripe/checkout-success"
                element={<StripeCheckoutSuccess />}
              />
              <Route
                path="/stripe/checkout-cancelled"
                element={<StripeCheckoutCancelled />}
              />
            </Routes>
          )}
          {/* The stripe checkout routes should not load in a logged-in layout */}
          {loggedIn && !window.location.href.includes("/stripe/checkout-") && (
            <SocketIoContextProvider url={websocketUrl ?? ""}>
              <BongaLayout />
            </SocketIoContextProvider>
          )}
        </Router>
      </Flex>
    </DndProvider>
  );
}

export default App;
