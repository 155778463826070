import { NOTIFICATIONS_COUNT } from "../actions/actions";

const NotificationsCount = (state: number = 0, action: any) => {
	switch (action.type) {
		case NOTIFICATIONS_COUNT:
			return state + 1;
		default:
			return state;
	}
};
export default NotificationsCount;
