import { RpcError } from "grpc-web";
import {
  branchService,
  departmentService,
} from "../globalClient/GlobalClients";
import {
  Branch,
  CreateBranchRequest,
  CreateBranchResponse,
  DeleteBranchRequest,
  DeleteBranchResponse,
  ReadBranchRequest,
  ReadBranchResponse,
  ReadBranchesRequest,
  ReadBranchesResponse,
  UpdateBranchRequest,
  UpdateBranchResponse,
} from "../pb/branch_pb";
import {
  CreateDepartmentRequest,
  CreateDepartmentResponse,
  DeleteDepartmentRequest,
  DeleteDepartmentResponse,
  Department,
  ReadDepartmentRequest,
  ReadDepartmentResponse,
  ReadDepartmentsRequest,
  ReadDepartmentsResponse,
  UpdateDepartmentRequest,
  UpdateDepartmentResponse,
} from "../pb/department_pb";
import { newBranchClass, newDepartmentClass } from "./BranchUtils";

const tokenObject = () => {
  const accessToken = localStorage.getItem("accessToken");

  return {
    authorization: accessToken || "",
  };
};

export const gRPCCreateBranch = (
  data: Branch.AsObject
): Promise<CreateBranchResponse.AsObject> => {
  let request = new CreateBranchRequest();
  request.setBranch(newBranchClass(data));
  return new Promise<CreateBranchResponse.AsObject>((resolve, reject) => {
    branchService.createBranch(request, tokenObject(), (err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const gRPCReadBranch = (
  branchId: string
): Promise<ReadBranchResponse.AsObject> => {
  const request = new ReadBranchRequest();
  request.setId(String(branchId));
  return new Promise<ReadBranchResponse.AsObject>((resolve, reject) => {
    branchService.readBranch(
      request,
      tokenObject(),
      (err: RpcError, response: ReadBranchResponse) => {
        if (err) {
          reject(err);
        } else {
          resolve(response.toObject());
        }
      }
    );
  });
};

export const gRPCReadBranches = (): Promise<ReadBranchesResponse.AsObject> => {
  let request = new ReadBranchesRequest();
  return new Promise<ReadBranchesResponse.AsObject>((resolve, reject) => {
    branchService.readBranches(request, tokenObject(), (err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const gRPCUpdateBranch = (
  data: Branch.AsObject
): Promise<UpdateBranchResponse.AsObject> => {
  let request = new UpdateBranchRequest();
  request.setBranch(newBranchClass(data));
  return new Promise<UpdateBranchResponse.AsObject>((resolve, reject) => {
    branchService.updateBranch(request, tokenObject(), (err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const gRPCDeleteBranch = (
  data: string
): Promise<DeleteBranchResponse.AsObject> => {
  let request = new DeleteBranchRequest();
  request.setId(data);
  return new Promise<DeleteBranchResponse.AsObject>((resolve, reject) => {
    branchService.deleteBranch(request, tokenObject(), (err, response) => {
      if (err) {
        reject(err);
      } else {
        resolve(response.toObject());
      }
    });
  });
};

export const gRPCCreateDepartment = (
  data: Department.AsObject
): Promise<CreateDepartmentResponse.AsObject> => {
  let request = new CreateDepartmentRequest();
  request.setDepartment(newDepartmentClass(data));
  return new Promise<CreateDepartmentResponse.AsObject>((resolve, reject) => {
    departmentService.createDepartment(
      request,
      tokenObject(),
      (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response.toObject());
        }
      }
    );
  });
};

export const gRPCReadDepartment = (
  departmentId: string
): Promise<ReadDepartmentResponse.AsObject> => {
  const request = new ReadDepartmentRequest();
  request.setId(String(departmentId));
  return new Promise<ReadDepartmentResponse.AsObject>((resolve, reject) => {
    departmentService.readDepartment(
      request,
      tokenObject(),
      (err: RpcError, response: ReadDepartmentResponse) => {
        if (err) {
          reject(err);
        } else {
          resolve(response.toObject());
        }
      }
    );
  });
};

export const gRPCReadDepartments =
  (): Promise<ReadDepartmentsResponse.AsObject> => {
    let request = new ReadDepartmentsRequest();
    return new Promise<ReadDepartmentsResponse.AsObject>((resolve, reject) => {
      departmentService.readDepartments(
        request,
        tokenObject(),
        (err, response) => {
          if (err) {
            reject(err);
          } else {
            resolve(response.toObject());
          }
        }
      );
    });
  };

export const gRPCUpdateDepartment = (
  data: Department.AsObject
): Promise<UpdateDepartmentResponse.AsObject> => {
  return new Promise<UpdateDepartmentResponse.AsObject>((resolve, reject) => {
    const request = new UpdateDepartmentRequest();
    request.setDepartment(newDepartmentClass(data));
    departmentService.updateDepartment(
      request,
      tokenObject(),
      (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response.toObject());
        }
      }
    );
  });
};

export const gRPCDeleteDepartment = (
  data: string
): Promise<DeleteDepartmentResponse.AsObject> => {
  let request = new DeleteDepartmentRequest();
  request.setId(data);
  return new Promise<DeleteDepartmentResponse.AsObject>((resolve, reject) => {
    departmentService.deleteDepartment(
      request,
      tokenObject(),
      (err, response) => {
        if (err) {
          reject(err);
        } else {
          resolve(response.toObject());
        }
      }
    );
  });
};

