import { Button, ButtonProps, Tooltip } from "@chakra-ui/react";
import React from "react";

interface CustomIconButtonProps extends ButtonProps {
  tooltipLabel?: string;
  hasArrow?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
  children?: React.ReactNode;
  [key: string]: any;
}

function CustomButton(props: CustomIconButtonProps) {
  const {
    tooltipLabel,
    hasArrow,
    placement,
    colorScheme,
    color,
    width,
    children,
    loading,
    loadingText,
    ...rest
  } = props;
  return (
    <Tooltip
      label={tooltipLabel ?? ""}
      placement={placement}
      hasArrow={hasArrow}
    >
      <Button
        colorScheme={colorScheme ?? "gray"}
        color={color}
        borderRadius={"3px"}
        gap={".4rem"}
        fontSize={"xs"}
        size={"xs"}
        w={width ?? "fit-content"}
        loading={loading ?? false}
        loadingText={loadingText}
        {...rest}
        textTransform={"capitalize"}
      >
        {children}
      </Button>
    </Tooltip>
  );
}

export default CustomButton;
