class UserAgentParser {
    private readonly userAgent: string;

    constructor(userAgent: string) {
        this.userAgent = userAgent;
    }

    getBrowser(): string {
        const regexList = [
            { regex: /Edge\/([\d.]+)/, browser: "Microsoft Edge" },
            { regex: /MSIE ([\d.]+)/, browser: "Internet Explorer" },
            { regex: /Trident.*rv:([\d.]+)/, browser: "Internet Explorer" },
            { regex: /Firefox\/([\d.]+)/, browser: "Firefox" },
            { regex: /Chrome\/([\d.]+)/, browser: "Chrome" },
            { regex: /Version\/([\d.]+).*Safari/, browser: "Safari" },
            { regex: /Safari\/([\d.]+)/, browser: "Safari" },
        ];

        const match = regexList.find((r) => r.regex.test(this.userAgent));
        return match?.browser ?? "Unknown";
    }

    getOperatingSystem(): string {
        const regexList = [
            { regex: /Windows NT 10\.0/, os: "Windows 10" },
            { regex: /Windows NT 6\.3/, os: "Windows 8.1" },
            { regex: /Windows NT 6\.2/, os: "Windows 8" },
            { regex: /Windows NT 6\.1/, os: "Windows 7" },
            { regex: /Windows NT 6\.0/, os: "Windows Vista" },
            { regex: /Windows NT 5\.1|Windows XP/, os: "Windows XP" },
            { regex: /Mac OS X (\d+)[_\d]+/, os: "Mac OS X" },
            { regex: /Linux/, os: "Linux" },
            { regex: /iPhone|iPad|iPod/, os: "iOS" },
            { regex: /Android (\d+\.\d+)/, os: "Android" },
        ];

        const match = regexList.find((r) => r.regex.test(this.userAgent));
        return match?.os ?? "Unknown";
    }

    isPhone(): boolean {
        const regex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(this.userAgent);
    }

    isWindows(): boolean {
        const regex = /Windows NT/;
        return regex.test(this.userAgent);
    }

    isMacOS(): boolean {
        const regex = /Mac OS X/;
        return regex.test(this.userAgent);
    }

    isLinux(): boolean {
        const regex = /Linux/;
        return regex.test(this.userAgent);
    }

    isChrome(): boolean {
        const regex = /Chrome/;
        return regex.test(this.userAgent);
    }

    isFirefox(): boolean {
        const regex = /Firefox/;
        return regex.test(this.userAgent);
    }

    isSafari(): boolean {
        const regex = /Safari/;
        return regex.test(this.userAgent) && !this.isChrome();
    }

    isInternetExplorer(): boolean {
        const regex = /MSIE|Trident/;
        return regex.test(this.userAgent);
    }

    isEdge(): boolean {
        const regex = /Edge/;
        return regex.test(this.userAgent);
    }
}
export default UserAgentParser;
