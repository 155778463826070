import React, { useId, useMemo } from "react";
import { Socket, io } from "socket.io-client";

type Io = "io";
type Ws = "ws";
interface SecondarySocketProviderProps {
  children: React.ReactNode;
  url: string;
}
export interface SocketProviderProps {
  socket: Socket;
  id: string;
 url: string;
}
export const SocketContext = React.createContext<SocketProviderProps>({
  socket: {} as Socket,
  id: "",
  url: "",
});

export default function SocketIoContextProvider({
  url,
  children,
}: SecondarySocketProviderProps) {
  const ioOptions = useMemo(
    () => ({
      transports: ["websocket"],
      reconnection: false,
      autoConnect: false,
    }),
    []
  );
  const id = useId();

  const socket = useMemo(() => io(url, ioOptions), [url, ioOptions]);

  const typedSocket: SocketProviderProps = {
    socket: socket,
    id: id,
    url: url,
  };

  return (
    <SocketContext.Provider key={id} value={{ ...typedSocket }}>
      {children}
    </SocketContext.Provider>
  );
}
