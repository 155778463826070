import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { primaryBlack, primaryColor } from "../../theme/theme";
import { LineGraphProps } from "../../types/DataTypes";

interface BarGraphProps extends LineGraphProps {
  series: number[];
  scale: string[];
}

const BarGraph = ({ reload, series: s, scale }: BarGraphProps) => {
  const [graphKey, setGraphKey] = React.useState(0);
  useEffect(() => {
    setGraphKey(graphKey + 1);
  }, [reload]);

  const series = [
    {
      name: "Level Responses",
      data: [...s],
    },
  ];
  return (
    <ReactApexChart
      options={{
        chart: {
          type: "bar",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [primaryColor],
        xaxis: {
          labels: {
            show: true,
            style: {
              fontFamily: "inherit",
            },
          },

          categories: [...scale],
        },

        fill: {
          type: "solid",
        },
      }}
      series={series}
      type="bar"
      height={300}
      width={"100%"}
    />
  );
};

export default BarGraph;
