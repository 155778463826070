import { RefreshTokenResponse } from "../../gateway/gRPC/pb/auth_pb";
import { SET_ACCESS_TOKENS,RESET_ACCESS_TOKENS } from "../actions/actions";

const initialState = {
  accessToken: "",
  refreshToken: "",
};
export default function AccessTokens(state = initialState, action:{type:string,payload:RefreshTokenResponse.AsObject}) {
  switch (action.type) {
    case SET_ACCESS_TOKENS:
        return action.payload;
        case RESET_ACCESS_TOKENS:
            return initialState;
    default:
        return state;
  }
}