import { User } from "../../gateway/gRPC/pb/user_pb";
import {USER_LOGIN, USER_LOGOUT} from "../actions/actions";

interface UserObjPayload {
    user:User.AsObject;
    accessToken:string;
    refreshToken:string;
}
interface UserObjActionType{
    type:string;
    payload:UserObjPayload;
}

const UserObj = (state:any={}, action:UserObjActionType) => {
    switch (action.type){
        case USER_LOGIN:
            localStorage.setItem("accessToken", action.payload.accessToken);
            localStorage.setItem("refreshToken", action.payload.refreshToken);
            return action.payload
        case USER_LOGOUT:
            localStorage.removeItem("persist:root")
            return {}
        default:
            return state
    }
}
export default UserObj;