import {ModalAlertState} from "../types/DataTypes";

const initialState: ModalAlertState = {
    title: "",
    message: "",
    isOpen: false,
    type: "",
};

function ModalAlertReducer(
    state = initialState,
    action: ModalAlertAction
): ModalAlertState {
    switch (action.type) {
        case "OPEN_MODAL_ALERT":
            return {...state, ...action.payload};
        case "CLOSE_MODAL_ALERT":
            return {...state, isOpen: false};
        default:
            return state;
    }
};
export const openModalAlert = (
    title: string,
    message: string,
    type: ModalAlertType,
    isOpen: boolean,
    confirm?: boolean,
    callback?: () => void
) => {
    return {
        type: "OPEN_MODAL_ALERT",
        payload: {
            title,
            message,
            type,
            isOpen,
            confirm,
            callback,
        },
    };
};
export const closeModalAlert = () => {
    return {
        type: "CLOSE_MODAL_ALERT",
    };
};
export type ModalAlertAction = {
    type: string;
    payload: {
        title: string;
        message: string;
        type: ModalAlertType;
        isOpen: boolean;
        confirm?: boolean;
        callback?: () => void;
    };
};
export type ModalAlertType =
    | "success"
    | "error"
    | "warning"
    | "info"
    | "danger"
    | "";

export default ModalAlertReducer;