import {cities, countries, initialCountry} from "../assets/countries";
import {Coordinates, CountryCodeData} from "../types/DataTypes";



function geoCode(coordinates: Coordinates): CountryCodeData {
    const tolerance = 1.0;

    for (const city of cities) {
        const {latitude: cityLat, longitude: cityLon} = city.coordinates;
        const {latitude: inputLat, longitude: inputLon} = coordinates;

        const latDiff = Math.abs(cityLat - inputLat);
        const lonDiff = Math.abs(cityLon - inputLon);

        if (latDiff <= tolerance && lonDiff <= tolerance) {
            return countries.find((country) => country.name === city.countryName) || initialCountry;
        }
    }
    return initialCountry;
}

export default geoCode;