import React, { useEffect, useId, useState } from "react";
import { useSelector } from "react-redux";
import strings from "./main";
import Language from "../redux/reducers/Language";

export default function LanguageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const systemLanguage = useSelector((state: any) => state.Language);
  const [key, setKey] = useState(0);
  const id = useId();
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [systemLanguage]);
  
  useEffect(() => {
    if (systemLanguage !== strings.getLanguage()){
      strings.setLanguage(systemLanguage);
    }
    if (!systemLanguage){
      strings.setLanguage("en")
    }
  }, []);

  return <React.Fragment key={`${key}-${id}`}>{children}</React.Fragment>;
}
